import React from 'react'
import { Box, Dialog, DialogContent } from '@mui/material'
import cross from 'app/Assets/cross.svg'
import { ArrowDownward } from '@mui/icons-material'
import Image from 'mui-image'

const PreviewImage = ({ open, onClose, imageUrl }) => {
  const downloadFile = (attachment) => {
    const fileUrl = attachment

    // Create a temporary anchor element
    const link = document.createElement(`a`)
    link.href = fileUrl
    link.setAttribute(`document`, `filename`) // Set the desired filename for the downloaded file

    // Programmatically click on the anchor element to initiate the download
    link.click()
  }
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: `transparent`, // Set background to transparent
        },
      }}
      sx={{ backdropFilter: `blur(1px)` }}
    >
      <DialogContent
        style={{
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        <Box
          sx={{
            position: `relative`,
            display: `flex`,
          }}
        >
          <Image
            src={imageUrl}
            alt="Picture"
            style={{
              maxWidth: `100%`,
              maxHeight: `100%`,
            }}
          />

          <Box
            sx={{
              display: `flex`,
              flexDirection: `column`,
              gap: `10px`,
              position: `absolute`,
              top: 10,
              right: 10,
            }}
          >
            <Box
              sx={{
                backgroundColor: `white`,
                width: `36px`,
                height: `36px`,
                borderRadius: `100%`,
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`,
                '&:hover': {
                  cursor: `pointer`,
                },
              }}
              onClick={onClose}
            >
              <img src={cross} alt="pic" />
            </Box>
            <Box
              sx={{
                backgroundColor: `white`,
                width: `36px`,
                height: `36px`,
                borderRadius: `100%`,
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`,
                '&:hover': {
                  cursor: `pointer`,
                },
              }}
              onClick={() => downloadFile(imageUrl)}
            >
              <ArrowDownward color="black" />
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default PreviewImage
