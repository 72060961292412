import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Popover,
  Tab,
  Typography,
} from '@material-ui/core'
import PrimaryButton from 'app/Components/Common/PrimaryButton'
import PrependRs from 'app/Utils/PrependRs'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { SnackbarContext } from 'app/Components/SnackbarProvider'
import { useSelector } from 'react-redux'
import AddExpense from 'app/Components/Expenses/AddExpense'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import Transactions from '../Transactions'
import { styled } from '@mui/styles'
import AddTransaction from '../Transactions/AddTransaction'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { baseUrl } from 'app/Utils/Config'
import api from 'app/AxiosInstance'
import Expenses from '../Expenses'
import { getRole, isManagement } from 'app/Utils/helper'

const FundDetail = () => {
  const location = useLocation()
  const { state: fundsData } = location
  const { fundsId } = useParams()
  const TabLabel = styled(`span`)(({ selected }) => ({
    color: selected ? `#6039BB` : ``,
    textTransform: `none`,
  }))
  const [tabValue, setTabValue] = useState(`1`)
  const openSnackbar = useContext(SnackbarContext)
  const token = localStorage.getItem(`AuthToken`)
  const colors = useSelector((state) => state?.colors)

  const [anchorEl, setAnchorEl] = useState(null)

  const [addExpenseModal, setAddExpenseModal] = useState(false)
  const [addTransactionModal, setAddTransactionModal] = useState(false)
  const systemRole = getRole()

  const {
    isLoading,
    data: data,
    refetch,
    isFetching,
    // isError,
  } = useQuery(
    `getSingleFundsDataApi`,
    async () =>
      await api
        .get(`${baseUrl}/funds/${fundsId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          return res?.data?.data
        }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onError: (error) => {
        openSnackbar(
          error?.response ? error?.response?.data?.message : error?.message,
          `error`,
        )
      },
    },
  )

  const [expenseCount, setExpenseCount] = useState(0)

  useEffect(() => {
    const totalExpenseCount = data?.expensesOfSingleFund?.reduce(
      (accumulator, fund) => {
        const fundExpenseCount = fund?.expensesOfSingleAccount?.length || 0
        return accumulator + fundExpenseCount
      },
      0,
    )
    setExpenseCount(totalExpenseCount)
  }, [data])

  return (
    <Box
      sx={{
        display: `flex`,
        flexDirection: `column`,
        paddingX: { xs: `10px`, sm: `20px` },
        paddingBottom: `20px`,
        minHeight: `calc(100vh - 80px)`,
        backgroundColor: `rgba(40, 32, 61, 0.07)`,
      }}
    >
      {addExpenseModal && (
        <AddExpense
          open={addExpenseModal}
          onClose={() => setAddExpenseModal(!addExpenseModal)}
          fetchExpenses={() => refetch()}
        />
      )}

      {addTransactionModal && (
        <AddTransaction
          open={addTransactionModal}
          onClose={() => setAddTransactionModal(!addTransactionModal)}
          fundsData={fundsData}
          refetchTransaction={() => refetch()}
        />
      )}

      <Popover
        disableEnforceFocus
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        sx={{
          '& .MuiPaper-root': {
            width: `120px`,
            height: `max-content`,
            display: `flex`,
            justifyContent: `center`,
            alignItems: `center`,
          },
        }}
        anchorOrigin={{
          vertical: `bottom`,
          horizontal: `left`,
        }}
        transformOrigin={{
          vertical: `top`,
          horizontal: `center`,
        }}
      >
        <Box
          sx={{
            display: `flex`,
            flexDirection: `column`,
            width: `100%`,
          }}
        >
          <Box
            sx={{
              width: `100%`,
              height: `36px`,
              paddingLeft: `16px`,
              display: `flex`,
              alignItems: `center`,
              '&:hover': {
                cursor: `pointer`,
                backgroundColor: `rgba(40, 32, 61, 0.05)`,
              },
            }}
            onClick={(e) => {
              e?.stopPropagation()
              setIsUpdateFund(!isUpdateFund)
              setAnchorEl(null)
            }}
          >
            Edit
          </Box>

          <Box
            sx={{
              width: `100%`,
              height: `36px`,
              paddingLeft: `16px`,
              '&:hover': {
                cursor: `pointer`,
                backgroundColor: `rgba(40, 32, 61, 0.05)`,
              },
              display: `flex`,
              alignItems: `center`,
              backgroundColor: `white`,
              color: `black`,
            }}
            onClick={(e) => {
              e?.stopPropagation()
              setIsDeleteFund(!isDeleteFund)
              setAnchorEl(null)
            }}
          >
            Delete
          </Box>
        </Box>
      </Popover>

      {isLoading || isFetching ? (
        <Backdrop open={isLoading || isFetching}>
          <CircularProgress
            sx={{
              position: `absolute`,
              top: `50%`,
              color: colors?.appColor,
            }}
          />
        </Backdrop>
      ) : (
        <>
          <Box
            sx={{
              display: `flex`,
              flexDirection: { xs: `column`, sm: `row` },
              alignItems: `center`,
              justifyContent: { xs: `flex-start`, sm: `space-between` },
            }}
          >
            <Box
              sx={{
                display: `flex`,
                alignItems: `center`,
                justifyContent: `flex-start`,
                gap: `40px`,
              }}
            >
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: `14px`,
                  lineHeight: `16.94px`,
                  color: `rgba(40, 32, 61, 0.6)`,
                  display: `flex`,
                  alignItems: `center`,
                  gap: `5px`,
                }}
              >
                Allocated by:
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: `16px`,
                    lineHeight: `19.36px`,
                    color: `rgba(40, 32, 61, 1)`,
                  }}
                >
                  {`${data?.allocatedBy?.name || ``}
                  `}
                </Typography>
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: `14px`,
                  lineHeight: `16.94px`,
                  color: `rgba(40, 32, 61, 0.6)`,
                  display: `flex`,
                  alignItems: `center`,
                  gap: `5px`,
                }}
              >
                Allocated To:
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: `16px`,
                    lineHeight: `19.36px`,
                    color: `rgba(40, 32, 61, 1)`,
                  }}
                >
                  {`${data?.allocatedTo?.name || ``}
                  `}
                </Typography>
              </Typography>
            </Box>
            <Box
              sx={{
                display: `flex`,
                flexDirection: { xs: `column`, sm: `row` },
                alignItems: `center`,
                justifyContent: `flex-end`,
                gap: `10px`,
              }}
            >
              {systemRole !== `HRM` && isManagement() && (
                <Button
                  sx={{
                    color: `white`,
                    backgroundColor: `rgba(25, 201, 209, 1) `,
                    border: `solid rgba(25, 201, 209, 1) 1px`,
                    borderRadius: `10px`,
                    textTransform: `none`,
                    padding: `10px`,
                    width: { xs: `100%`, sm: `auto` },
                    marginBottom: { xs: `10px`, md: `0px` },
                    '&:hover': {
                      backgroundColor: `rgba(25, 201, 209, 1)`,
                      opacity: `0.8`,
                    },
                  }}
                  onClick={() => setAddTransactionModal(!addTransactionModal)}
                >
                  + Add Transaction
                </Button>
              )}

              <PrimaryButton
                title="+ Add Expense"
                onClick={() => setAddExpenseModal(!addExpenseModal)}
              />
            </Box>
          </Box>

          <Box
            sx={{
              backgroundColor: `white`,
              display: `flex`,
              justifyContent: { xs: `flex-start`, sm: `space-between` },
              alignItems: { xs: `flex-start`, sm: `center` },
              flexDirection: { xs: `column`, sm: `row` },
              gap: `10px`,
              borderRadius: `10px`,
              width: `100%`,
              paddingX: `10px`,
              marginY: `10px`,
            }}
          >
            <Box
              sx={{
                width: { xs: `100%`, sm: `50%` },
                paddingY: `20px`,
                paddingX: `10px`,
              }}
            >
              <Box
                sx={{
                  display: `flex`,
                  justifyContent: `space-between`,
                  alignItems: `center`,
                }}
              >
                <Typography
                  sx={{
                    width: `80px`,
                    height: `24px`,
                    fontWeight: 500,
                    fontSize: `12px`,
                    lineHeight: `14.52px`,
                    display: `flex`,
                    justifyContent: `center`,
                    alignItems: `center`,
                    borderRadius: `10px`,
                    backgroundColor:
                      data?.status === `BELOW`
                        ? `rgba(77, 198, 64, 0.12)`
                        : data?.status === `EXCEEDED`
                        ? `rgba(255, 72, 94, 0.12)`
                        : `rgba(37, 187, 251, 0.12)`,
                    color:
                      data?.status === `BELOW`
                        ? `rgba(77, 198, 64, 1)`
                        : data?.status === `EXCEEDED`
                        ? `rgba(255, 72, 94, 1)`
                        : `rgba(37, 187, 251, 1)`,
                  }}
                >
                  {data?.status === `BELOW`
                    ? `Below`
                    : data?.status === `EXCEEDED`
                    ? `Exceeded`
                    : `Utilized`}
                </Typography>
              </Box>

              <Typography
                sx={{
                  fontSize: `14px`,
                  fontWeight: 400,
                  lineHeight: `16.94px`,
                  color: `rgba(40, 32, 61, 0.6)`,
                  paddingY: `10px`,
                  textAlign: `justify`,
                }}
              >
                {data?.description ? data?.description : `No description added`}
              </Typography>
            </Box>
            <Box
              sx={{
                width: { xs: `100%`, sm: `50%` },
                paddingY: `20px`,
                paddingX: `10px`,
              }}
            >
              <Grid
                container
                spacing={2}
                sx={{
                  display: `flex`,
                  justifyContent: `space-between`,
                  alignItems: `center`,
                }}
              >
                <Grid item xs={12} sm={4}>
                  <Box
                    sx={{
                      borderRadius: `10px`,
                      backgroundColor: `rgba(3, 227, 252,0.06)`,
                      height: `113px`,
                      display: `flex`,
                      flexDirection: `column`,
                      alignItems: `center`,
                      justifyContent: `center`,
                      gap: `10px`,
                    }}
                  >
                    <Typography>
                      {PrependRs({
                        amount: data?.total?.toFixed(2) || 0,
                        sx: {
                          fontSize: { xs: `18px`, sm: `14px`, md: `18px` },
                          fontWeight: 600,
                          lineHeight: `21.78px`,
                          textAlign: `center`,
                        },
                      })}
                    </Typography>
                    <Typography
                      sx={{
                        color: `rgba(40, 32, 61, 0.6)`,
                        fontSize: { xs: `14px`, sm: `12px`, md: `14px` },
                        fontWeight: 400,
                        lineHeight: `16.94px`,
                        textAlign: `center`,
                      }}
                    >
                      Funds
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box
                    sx={{
                      borderRadius: `10px`,
                      backgroundColor: `
                      rgba(119, 3, 252, 0.06)`,
                      height: `113px`,
                      display: `flex`,
                      flexDirection: `column`,
                      alignItems: `center`,
                      justifyContent: `center`,
                      gap: `10px`,
                    }}
                  >
                    <Typography>
                      {PrependRs({
                        amount: data?.utilized?.toFixed(2) || 0,
                        sx: {
                          fontSize: { xs: `18px`, sm: `14px`, md: `18px` },
                          fontWeight: 600,
                          lineHeight: `21.78px`,
                          textAlign: `center`,
                        },
                      })}
                    </Typography>
                    <Typography
                      sx={{
                        color: `rgba(40, 32, 61, 0.6)`,
                        fontSize: { xs: `14px`, sm: `12px`, md: `14px` },
                        fontWeight: 400,
                        lineHeight: `16.94px`,
                        textAlign: `center`,
                      }}
                    >
                      Utilized
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box
                    sx={{
                      borderRadius: `10px`,
                      backgroundColor:
                        data?.utilized > data?.total
                          ? `rgba(255, 72, 94, 0.12)`
                          : `
                          rgba(3, 252, 73,0.06)`,
                      height: `113px`,
                      display: `flex`,
                      flexDirection: `column`,
                      alignItems: `center`,
                      justifyContent: `center`,
                      gap: `10px`,
                    }}
                  >
                    <Typography>
                      {PrependRs({
                        amount: data?.remaining?.toFixed(2) || 0,
                        sx: {
                          fontSize: { xs: `18px`, sm: `14px`, md: `18px` },
                          fontWeight: 600,
                          lineHeight: `21.78px`,
                          textAlign: `center`,
                          color:
                            data?.utilized > data?.total
                              ? `rgba(255, 72, 94, 1)`
                              : `rgba(40, 32, 61, 1)`,
                        },
                      })}
                    </Typography>
                    <Typography
                      sx={{
                        color: `rgba(40, 32, 61, 0.6)`,
                        fontSize: { xs: `14px`, sm: `12px`, md: `14px` },
                        fontWeight: 400,
                        lineHeight: `16.94px`,
                        textAlign: `center`,
                      }}
                    >
                      Balance
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box
            sx={{
              width: `100%`,
            }}
          >
            <TabContext value={tabValue}>
              <Box sx={{ borderBottom: 1, borderColor: `divider` }}>
                <TabList
                  variant="scrollable"
                  allowScrollButtonsMobile
                  onChange={(e, value) => setTabValue(value)}
                  aria-label="lab API tabs example"
                  TabIndicatorProps={{ style: { backgroundColor: `#19C9D1` } }}
                >
                  <Tab
                    label={
                      <TabLabel selected={tabValue === `1`}>
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: {
                              xs: `12px`,
                              sm: `12px`,
                              md: `14px`,
                            },
                          }}
                        >
                          {`Transactions (${
                            data?.transactions &&
                            data?.transactions[0]?.transactionBy?.name
                              ? data?.transactions?.length
                              : 0
                          })`}
                        </Typography>
                      </TabLabel>
                    }
                    value="1"
                  />
                  <Tab
                    label={
                      <TabLabel selected={tabValue === `2`}>
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: {
                              xs: `12px`,
                              sm: `12px`,
                              md: `14px`,
                            },
                          }}
                        >
                          {`Expenses (${
                            data?.expensesOfSingleFund &&
                            Object?.keys(
                              data?.expensesOfSingleFund[0]
                                ?.expensesOfSingleAccount[0],
                            )?.length === 0
                              ? 0
                              : expenseCount
                          })`}
                        </Typography>
                      </TabLabel>
                    }
                    value="2"
                  />
                </TabList>
              </Box>

              <TabPanel value="1" sx={{ p: 0 }}>
                <Transactions
                  singleFund={data}
                  fundsData={fundsData}
                  refetchTransaction={() => refetch()}
                />
              </TabPanel>

              <TabPanel value="2" sx={{ p: 0 }}>
                <Expenses fetchExpenses={refetch} />
              </TabPanel>
            </TabContext>
          </Box>
        </>
      )}
    </Box>
  )
}

export default FundDetail
