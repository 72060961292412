import React, { useState, useContext, useCallback } from 'react'
import { Box, Typography, Grid, Popover } from '@mui/material'
import { SnackbarContext } from 'app/Components/SnackbarProvider'
import api from 'app/AxiosInstance'

// Skeleton Loading
import SkeletonLoading from './SkeletonLoading'

// Common Components
import PrimaryButton from 'app/Components/Common/PrimaryButton'
import AddVendor from './AddVendor'
import UpdateVendor from './UpdateVendor'
import NotApplicable from 'app/Components/Common/NotApplicable'
import DeleteConfirmationDialog from 'app/Components/Common/DeleteConfirmationDialog'

// React Icons
import { HiEmojiSad } from 'react-icons/hi'
import { BsThreeDotsVertical } from 'react-icons/bs'

// Redux Hooks
import { useSelector } from 'react-redux'

// React Query Hooks
import { useQuery, useMutation } from 'react-query'

const Vendors = () => {
  const colors = useSelector((state) => state.colors)
  const openSnackbar = useContext(SnackbarContext)
  const [addVendorOpen, setAddVendorOpen] = useState(false)
  const [updateVendorOpen, setUpdateVendorOpen] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [vendorId, setVendorId] = useState()
  const [anchorEl, setAnchorEl] = useState(null)
  const [singleVendor, setSingleVendor] = useState(null)

  const {
    data: vendors,
    isFetching,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery(
    `allVendors`,
    async () => {
      const { data } = await api.get(`/vendors`)
      if (!data) undefined
      return data.data
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onError: (error) => {
        openSnackbar(
          error.response ? error.response.data.message : error.message,
          `error`,
        )
      },
    },
  )

  const deleteVendor = async () => {
    const response = await api.delete(`/vendors/${vendorId}`)
    return response
  }

  const { mutate: deleteVendorMutation, isLoading: isMutatingDeleteVendor } =
    useMutation(deleteVendor, {
      onSuccess: () => {
        refetch()
        setOpenDeleteDialog((prev) => !prev)
        openSnackbar(`Deleted Successfully!!`, `success`)
      },
      onError: (error) => {
        openSnackbar(
          error?.response ? error.response.data.message : error.message,
          `error`,
        )
      },
    })

  const closeAddVendor = useCallback(() => {
    setAddVendorOpen((prev) => !prev)
  }, [addVendorOpen])

  // delete callback
  const deleteVendorClick = (id) => {
    deleteVendorMutation(id)
  }

  const closeUpdateVendor = useCallback(() => {
    setUpdateVendorOpen((prev) => !prev)
  }, [updateVendorOpen])

  if (isFetching || isRefetching || isLoading) {
    return [1, 2, 3, 4].map(() => SkeletonLoading)
  }

  return (
    <>
      <DeleteConfirmationDialog
        open={openDeleteDialog}
        onClose={(prev) => setOpenDeleteDialog(!prev)}
        deleteFunc={deleteVendorClick}
        loading={isMutatingDeleteVendor}
      />

      <Popover
        disableEnforceFocus
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        sx={{
          '& .MuiPaper-root': {
            width: `120px`,
            height: `max-content`,
            display: `flex`,
            justifyContent: `center`,
            alignItems: `center`,
          },
        }}
        anchorOrigin={{
          vertical: `bottom`,
          horizontal: `left`,
        }}
        transformOrigin={{
          vertical: `top`,
          horizontal: `center`,
        }}
      >
        <Box
          sx={{
            display: `flex`,
            flexDirection: `column`,
            width: `100%`,
          }}
        >
          <Box
            sx={{
              width: `100%`,
              height: `36px`,
              paddingLeft: `16px`,
              display: `flex`,
              alignItems: `center`,
              '&:hover': {
                cursor: `pointer`,
                backgroundColor: `rgba(40, 32, 61, 0.05)`,
              },
            }}
            onClick={(e) => {
              e?.stopPropagation()
              setUpdateVendorOpen(!updateVendorOpen)
              setAnchorEl(null)
            }}
          >
            Edit
          </Box>

          <Box
            sx={{
              width: `100%`,
              height: `36px`,
              paddingLeft: `16px`,
              '&:hover': {
                cursor: `pointer`,
                backgroundColor: `rgba(40, 32, 61, 0.05)`,
              },
              display: `flex`,
              alignItems: `center`,
              backgroundColor: `white`,
              color: `black`,
            }}
            onClick={(e) => {
              e?.stopPropagation()
              setOpenDeleteDialog(!openDeleteDialog)
              setAnchorEl(null)
            }}
          >
            Delete
          </Box>
        </Box>
      </Popover>

      {addVendorOpen && (
        <AddVendor
          open={addVendorOpen}
          onClose={closeAddVendor}
          refetch={refetch}
        />
      )}

      {updateVendorOpen && (
        <UpdateVendor
          open={updateVendorOpen}
          onClose={closeUpdateVendor}
          vendorInitialData={singleVendor || {}}
          refetchAllVendors={refetch}
        />
      )}

      <Box
        sx={{
          display: `flex`,
          flexDirection: `column`,
          paddingX: { xs: `10px`, sm: `20px` },
          paddingBottom: `20px`,
          minHeight: `calc(100vh - 80px)`,
          backgroundColor: `rgba(40, 32, 61, 0.07)`,
        }}
      >
        <Box
          sx={{
            display: `flex`,
            justifyContent: `flex-end`,
          }}
        >
          <PrimaryButton
            title="+ Add Business"
            onClick={() => setAddVendorOpen((prev) => !prev)}
          />
        </Box>

        {!vendors ? (
          <Box
            sx={{
              display: `flex`,
              justifyContent: `center`,
              gap: `5px`,
              minHeight: `100vh`,
            }}
          >
            <Typography
              sx={{ fontFamily: `Inter`, fontWeight: 500, fontSize: `16px` }}
            >
              No Vendor Found!
            </Typography>
            <HiEmojiSad
              style={{ color: colors.appColor, width: `30px`, height: `30px` }}
            />
          </Box>
        ) : (
          vendors.map((vendor) => (
            <Box
              key={vendor._id}
              sx={{
                height: `100%`,
                width: `100%`,
                marginTop: `10px`,
                borderRadius: `10px`,
                backgroundColor: `white`,
                padding: `10px`,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Box
                    sx={{
                      display: `flex`,
                      flexDirection: `column`,
                      justifyContent: `center`,
                      width: `100%`,
                      height: `100%`,
                      borderRadius: `10px`,
                      padding: `20px`,
                      backgroundColor: `rgba(25, 201, 209, 0.1)`,
                    }}
                  >
                    {vendor.name ? (
                      <Typography
                        sx={{
                          fontFamily: `Inter`,
                          fontSize: 20,
                          fontWeight: 600,
                          color: `#28203D`,
                          whiteSpace: `wrap`,
                          wordBreak: `break-word`,
                        }}
                      >
                        {vendor.name}
                      </Typography>
                    ) : (
                      NotApplicable
                    )}

                    <Typography
                      sx={{
                        fontFamily: `Inter`,
                        fontSize: 14,
                        fontWeight: 400,
                        wordBreak: `break-word`,
                        whiteSpace: `wrap`,
                        color: `rgba(40, 32, 61, 1)`,
                      }}
                    >
                      {vendor?.email ? vendor.email : NotApplicable}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          display: `flex`,
                          flexDirection: `column`,
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: `Inter`,
                            fontWeight: 300,
                            fontSize: `14px`,
                            color: `rgba(40, 32, 61, 1)`,
                          }}
                        >
                          Phone
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: `Inter`,
                            fontWeight: 600,
                            fontSize: `14px`,
                          }}
                        >
                          {vendor?.phone ? vendor.phone : NotApplicable}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          display: `flex`,
                          flexDirection: `column`,
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: `Inter`,
                            fontWeight: 400,
                            fontSize: `14px`,
                            color: `rgba(40, 32, 61, 1)`,
                          }}
                        >
                          Country
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: `Inter`,
                            fontWeight: 600,
                            fontSize: `14px`,
                          }}
                        >
                          {vendor?.country ? vendor.country : NotApplicable}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          display: `flex`,
                          flexDirection: `column`,
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: `Inter`,
                            fontWeight: 400,
                            fontSize: `14px`,
                            color: `rgba(40, 32, 61, 1)`,
                          }}
                        >
                          State
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: `Inter`,
                            fontWeight: 600,
                            fontSize: `14px`,
                          }}
                        >
                          {vendor?.state ? vendor.state : NotApplicable}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          display: `flex`,
                          flexDirection: `column`,
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: `Inter`,
                            fontWeight: 400,
                            fontSize: `14px`,
                            color: `rgba(40, 32, 61, 1)`,
                          }}
                        >
                          Address
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: `Inter`,
                            fontWeight: 600,
                            fontSize: `14px`,
                          }}
                        >
                          {vendor?.address ? vendor.address : NotApplicable}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={2}>
                  <Box
                    sx={{
                      display: `flex`,
                      flexDirection: `row`,
                      justifyContent: `flex-end`,
                      alignItems: `center`,
                      gap: `10px`,
                      height: `max-content`,
                    }}
                  >
                    <Typography
                      sx={{
                        height: `max-content`,
                        backgroundColor:
                          vendor?.status === `INACTIVE`
                            ? `rgba(255, 72, 94, 0.1)`
                            : `rgba(77, 198, 64, 0.12)`,

                        color:
                          vendor?.status === `INACTIVE`
                            ? `rgba(255, 72, 94, 1)`
                            : `rgba(77, 198, 64, 1)`,

                        borderRadius: `20px`,
                        paddingX: `15px`,
                        fontFamily: `Inter`,
                        fontWeight: 500,
                        fontSize: `14px`,
                      }}
                    >
                      {vendor?.status === `INACTIVE`
                        ? `Inactive`
                        : vendor?.status === `ACTIVE`
                        ? `Active`
                        : ``}
                    </Typography>

                    <Box
                      sx={{
                        display: `flex`,
                        justifyContent: `center`,
                        alignItems: `center`,
                        borderRadius: `100%`,
                        padding: `10px`,
                        '&:hover': {
                          cursor: `pointer`,
                          backgroundColor: `rgba(40, 32, 61, 0.05)`,
                        },
                        width: `max-content`,
                        height: `max-content`,
                      }}
                      onClick={(e) => {
                        e?.stopPropagation()
                        setAnchorEl(e?.currentTarget)
                        setSingleVendor(vendor)
                        setVendorId(vendor?._id)
                      }}
                    >
                      <BsThreeDotsVertical
                        style={{ color: `rgba(40, 32, 61, 0.5)` }}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ))
        )}
      </Box>
    </>
  )
}

export default Vendors
