import React from 'react'
import { Select, MenuItem, FormControl, InputLabel, Box } from '@mui/material'

const DropdownMenu = ({
  options,
  label,
  onChange,
  selectedValue,
  setSelectedValue,
}) => {
  const handleChange = (event) => {
    setSelectedValue(event.target.value)
    if (onChange) {
      onChange(event.target.value)
    }
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width={{ md: `300px`, xs: `100%` }}
      mt={2}
    >
      <FormControl sx={{ width: `100%`, maxWidth: { md: 300, xs: `100%` } }}>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedValue}
          label="Age"
          onChange={handleChange}
          sx={{
            width: `100%`,
            height: `44px`,
            maxWidth: { md: 300, xs: `100%` },
            bgcolor: `#FFFFFF`,
            borderRadius: `10px`,
            border: 0,
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: `none`,
              },
              '&.Mui-focused fieldset': {
                border: `none`,
              },
              '& .MuiInputBase-input': {
                height: `44px`,
                padding: `0 8px`,
                display: `flex`,
                alignItems: `center`,
              },
            },
          }}
        >
          {options.map((opt) => {
            return (
              <MenuItem value={opt.value} key={opt.value}>
                {opt.label}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </Box>
  )
}

export default DropdownMenu
