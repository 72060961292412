import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  breadCrumbs: [], // Ensure the initial state is an array
}

const breadCrumb = createSlice({
  name: `breadCrumbs`,
  initialState,
  reducers: {
    setBreadCrumbs: (state, { payload }) => {
      // Use payload as an array directly
      state.breadCrumbs = payload
    },
    breadCrumbReset: (state) => {
      state.breadCrumbs = [] // Reset to an empty array
    },
    setSelectedBreadCrumbs: (state, { payload }) => {
      state.breadCrumbs.splice(payload + 1)
    },
  },
})

export const { setBreadCrumbs, breadCrumbReset, setSelectedBreadCrumbs } =
  breadCrumb.actions

export const breadCrumbs = breadCrumb.reducer
