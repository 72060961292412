import { Button } from '@material-ui/core'
import { ThemeProvider, createTheme } from '@mui/material'
import React from 'react'

const SecondaryButton = ({ onClick, title, minWidth }) => {
  const buttonTheme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: `none`,
          },
        },
      },
    },
  })
  return (
    <ThemeProvider theme={buttonTheme}>
      <Button
        sx={{
          bgcolor: `rgba(40, 32, 61, 0.07)`,
          color: `black`,
          padding: `10px`,
          width: { xs: `100%`, sm: `auto` },
          minWidth: minWidth,
          display: `flex`,
          justifyContent: `center`,
          alignItems: `center`,
          borderRadius: `10px`,
          '&:hover': { cursor: `pointer`, opacity: 0.8 },
        }}
        onClick={onClick}
      >
        {title}
      </Button>
    </ThemeProvider>
  )
}

export default SecondaryButton
