import { Box } from '@material-ui/core'
import SearchBar from 'app/Components/Common/SearchBar'
import React, { useState } from 'react'
import DropdownMenu from './DropDown'
import ApplicantsTable from './ApplicantsTable'
import { baseUrl } from 'app/Utils/Config'
import api from 'app/AxiosInstance'
import { useQuery } from 'react-query'
import { statusOptions } from 'app/Utils/data'

const AllApplicants = () => {
  const [query, setQuery] = useState([])
  const [isNoData, setIsNoData] = useState(false)
  const [selectedValue, setSelectedValue] = useState(`All`)

  const fetchData = async () => {
    const data = await api.get(
      `${baseUrl}/business-applicants?search=${query}&status=${
        selectedValue === `All` ? `` : selectedValue
      }`,
    )
  }

  const { data: applicantData, isLoading } = useQuery({
    queryKey: [`applicantData`, query, selectedValue],
    queryFn: fetchData,
    refetchOnMount: true,
    onSettled: (data) => {
      if (data === null || data === undefined || data.length === 0) {
        setIsNoData(true)
      } else setIsNoData(false)
    },
  })

  const handleChangeQuery = (e) => {
    setQuery(e.target.value)
  }

  return (
    <Box px={`1.5rem`}>
      <Box
        display={`flex`}
        flexDirection={{ md: `row`, xs: `column` }}
        gap={`0.5rem`}
        mt={`0`}
        mb={`1rem`}
      >
        <SearchBar
          value={query}
          onChange={handleChangeQuery}
          placeholder={`Search By Name`}
        />
        <DropdownMenu
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          defaultValue={`All`}
          label={`Status`}
          options={statusOptions}
        />
      </Box>
      <ApplicantsTable
        isNoData={isNoData}
        isLoading={isLoading}
        applicantData={applicantData}
      />
    </Box>
  )
}

export default AllApplicants
