import React from 'react'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min'
import Layout from 'app/Layout'

const ProtectedRoutes = ({ children }) => {
  const isLoggedIn = localStorage.getItem(`AuthToken`)

  return isLoggedIn ? <Layout>{children}</Layout> : <Redirect to="/" />
}

export default ProtectedRoutes
