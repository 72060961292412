import React, { createContext, useState } from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

// Create a context for the Snackbar
export const SnackbarContext = createContext()

// Define the SnackbarProvider component
const SnackbarProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [message, setMessage] = useState(``)
  const [severity, setSeverity] = useState(`success`)

  // Handler to open the Snackbar
  const openSnackbar = (message, severity) => {
    setMessage(message)
    setSeverity(severity)
    setIsOpen(true)
  }

  // Handler to close the Snackbar
  const closeSnackbar = () => {
    setIsOpen(false)
  }

  // Custom MUI Alert component
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} variant="filled" ref={ref} {...props} />
  })

  Alert.displayName = `Alert`

  return (
    <SnackbarContext.Provider value={openSnackbar}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: `top`, horizontal: `center` }}
        open={isOpen}
        autoHideDuration={6000}
        onClose={closeSnackbar}
      >
        <Alert onClose={closeSnackbar} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  )
}

export default SnackbarProvider
