import React from 'react'
import Navigator from 'app/Components/Navigator'
// import Header from 'app/Components/Header'
import { Box, Stack } from '@mui/material'
import { useSelector } from 'react-redux'
import TopBar from 'app/Components/Topbar'
// import { toggleDrawer } from 'app/Redux/slices/user/Drawer'

const Layout = ({ children }) => {
  // const dispatch = useDispatch()
  const drawerState = useSelector((state) => state.drawer)

  // const smallAndMediumScreen = useMediaQuery(`(max-width:900px)`)

  // const closeSideBar = (event) => {
  //   event.stopPropagation()
  //   if (drawerState.openDrawer && smallAndMediumScreen) {
  //     dispatch(toggleDrawer({ openDrawer: !drawerState.openDrawer }))
  //   }
  // }

  return (
    <Stack direction={`row`}>
      <Box
        sx={{
          flexGrow: 4,
          width: {
            xs: `0px`,

            md: drawerState.openDrawer ? `249px` : `0px`,
          },
        }}
      >
        <Navigator />
      </Box>

      <Box
        sx={{
          flexGrow: 8,
          width: {
            xs: `calc(100% - ${drawerState.openDrawer ? `249px` : `0px`})`,
          },
        }}
        // onClick={(event) => closeSideBar(event)}
      >
        <Box
          sx={{
            flexGrow: 1,
            overflowY: `auto`,
            overflowX: `hidden`,
            width: `100%`,
            maxHeight: `100vh`,
            position: `relative`,
            backgroundColor: `rgba(40, 32, 61, 0.07)`,
          }}
        >
          <TopBar />
          {children}
        </Box>
      </Box>
    </Stack>
  )
}

export default Layout
