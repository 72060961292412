import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import api from 'app/AxiosInstance'
import PrimaryButton from 'app/Components/Common/PrimaryButton'
import { SnackbarContext } from 'app/Components/SnackbarProvider'
import { getAllProjects } from 'app/Services'
import { getAllClients } from 'app/Services/General/getAllClients'
import { baseUrl } from 'app/Utils/Config'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import InvoiceStatusDropDown from './DropDown/index'
import { INVOICE_STATUS_OPTIONS } from 'app/Utils/data'

const tableColumns = [
  { label: `Sr. #`, value: `` },
  { label: `INVOICE #`, value: `invoice` },
  { label: `INVOICE DATE`, value: `invoiceDate` },
  { label: `DUE DATE`, value: `dueDate` },
  { label: `CLIENT`, value: `client` },
  { label: `PROJECT`, value: `project` },
  { label: `DESCRIPTION`, value: `description` },
  { label: `TOTAL AMOUNT`, value: `totalAmount` },

  { label: `STATUS`, value: `status` },
]

const Invoices = () => {
  const colors = useSelector((state) => state?.colors)
  const history = useHistory()
  const [clientsData, setClientsData] = useState([])
  const [projectData, setProjectData] = useState([])

  const queryClient = useQueryClient()

  const [isPaidAccordion, setIsPaidAccordion] = useState(false)
  const [isUnpaidAccordion, setIsUnpaidAccordion] = useState(false)

  const [isCancelledAccordion, setIsCancelledAccordion] = useState(false)

  useEffect(() => {
    getAllProjects().then((result) => {
      const newLoadProject = result?.options?.map((project) => {
        {
          return {
            label: project?.name,
            value: project?._id,
          }
        }
      })
      setProjectData(newLoadProject)
    })
    getAllClients().then((result) => {
      const newLoadClient = result?.options?.map((client) => {
        {
          return {
            label: client?.name,
            value: client?._id,
          }
        }
      })
      setClientsData(newLoadClient)
    })
  }, [])

  const {
    mutate: updateStatus,
    isLoading: isUpdating,
    // isError,
  } = useMutation(
    async ({ id, newStatus }) =>
      await api
        .patch(`${baseUrl}/invoices/${id}/status`, {
          status: newStatus,
        })
        .then((res) => {
          if (res?.data) {
            openSnackbar(`Status updated successfully`, `success`)
            res?.data
          }
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: () => {
        queryClient.invalidateQueries(`getAllInvoicesApi`)
      },
      onError: (error) => {
        openSnackbar(
          error?.response?.data?.transactions
            ? error?.response?.data?.transactions
            : error?.response
            ? error?.response?.data?.message
            : error?.message
            ? error?.message
            : `Something went wrong`,
          `error`,
        )
      },
    },
  )

  const openSnackbar = useContext(SnackbarContext)

  const token = localStorage.getItem(`AuthToken`)

  const [filteredData, setFilteredData] = useState({
    client: ``,
    project: ``,
    startDate: new Date(new Date().getFullYear(), 0, 1),
    endDate: new Date(new Date().getFullYear(), 11, 31),
    incomeMonth: null,
    isPaid: true,
    isUnpaid: true,
    isCancelled: true,
  })

  const {
    isLoading,
    data: invoicesData,
    refetch,
    isFetching,
  } = useQuery(
    `getAllInvoicesApi`,
    async () =>
      await api
        .get(
          `${baseUrl}/invoices?clientId=${
            filteredData?.client === `All` ? `` : filteredData?.client
          }&projectId=${
            filteredData?.project === `All` ? `` : filteredData?.project
          }&incomeMonth=${
            filteredData?.incomeMonth
              ? moment(filteredData?.incomeMonth).format(`YYYY-MM`)
              : ``
          }&startDate=${
            filteredData?.incomeMonth
              ? ``
              : filteredData?.startDate
              ? moment(filteredData?.startDate).format(`YYYY-MM-DD`)
              : ``
          }&endDate=${
            filteredData?.incomeMonth
              ? ``
              : filteredData?.endDate
              ? moment(filteredData?.endDate).format(`YYYY-MM-DD`)
              : ``
          }&paid=${filteredData?.isPaid}&unpaid=${
            filteredData?.isUnpaid
          }&cancelled=${filteredData?.isCancelled}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then((res) => {
          return res?.data?.data
        }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,

      onError: (error) => {
        openSnackbar(
          error?.response ? error?.response?.data?.message : error?.message,
          `error`,
        )
      },
    },
  )

  const handleFilteredData = (name, value) => {
    setFilteredData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  useEffect(() => {
    if (filteredData?.incomeMonth) {
      setFilteredData((prevState) => ({
        ...prevState,
        startDate: null,
        endDate: null,
      }))
    }
  }, [filteredData?.incomeMonth])
  useEffect(() => {
    if (filteredData?.startDate || filteredData?.endDate) {
      setFilteredData((prevState) => ({
        ...prevState,
        incomeMonth: null,
      }))
    }
  }, [filteredData?.startDate, filteredData?.endDate])
  return (
    <Box
      sx={{
        display: `flex`,
        flexDirection: `column`,
        paddingTop: `20px`,
      }}
    >
      <Box
        sx={{
          display: `flex`,
          flexDirection: { xs: `column`, sm: `row` },
          alignItems: `center`,
          justifyContent: `space-between`,
          gap: `10px`,
        }}
      >
        <Box
          sx={{
            display: `flex`,
            alignItems: `center`,
            gap: `10px`,
            flexDirection: { xs: `column`, sm: `row` },
          }}
        >
          <FormControl
            size="small"
            sx={{
              backgroundColor: `white`,
              borderRadius: `10px`,
              width: { xs: `350px`, sm: `300px` },
              '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                  border: `solid ${colors.appColor} 1px`,
                },
                '&.Mui-focused fieldset': {
                  border: `solid ${colors.appColor} 1px`,
                },
                '& fieldset': {
                  borderColor: `rgba(40, 32, 61, 0.25)`,
                  borderRadius: `10px`,
                },
              },
              '& .MuiInputLabel-root': {
                '&.Mui-focused': {
                  color: `rgba(40, 32, 61, 0.25)`,
                },
              },
            }}
          >
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                color: `rgba(40, 32, 61, 0.5)`,
              }}
            >
              Client *
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="client"
              label="Client *"
              value={filteredData?.client || `All`}
              onChange={(e) => handleFilteredData(`client`, e.target.value)}
            >
              <MenuItem value="All">All</MenuItem>
              {clientsData &&
                clientsData?.map((client) => (
                  <MenuItem key={client?.value} value={client?.value}>
                    {client?.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl
            size="small"
            sx={{
              backgroundColor: `white`,
              borderRadius: `10px`,
              width: { xs: `350px`, sm: `300px` },
              '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                  border: `solid ${colors.appColor} 1px`,
                },
                '&.Mui-focused fieldset': {
                  border: `solid ${colors.appColor} 1px`,
                },
                '& fieldset': {
                  borderColor: `rgba(40, 32, 61, 0.25)`,
                  borderRadius: `10px`,
                },
              },
              '& .MuiInputLabel-root': {
                '&.Mui-focused': {
                  color: `rgba(40, 32, 61, 0.25)`,
                },
              },
            }}
          >
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                color: `rgba(40, 32, 61, 0.5)`,
              }}
            >
              Project *
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="project"
              label="Project *"
              value={filteredData?.project || `All`}
              onChange={(e) => handleFilteredData(`project`, e.target.value)}
            >
              <MenuItem value="All">All</MenuItem>
              {projectData &&
                projectData?.map((project) => (
                  <MenuItem key={project?.value} value={project?.value}>
                    {project?.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: `flex`,
            alignItems: `center`,
            gap: `10px`,
            flexDirection: { xs: `column`, sm: `row` },
          }}
        >
          <Button
            sx={{
              width: { xs: `100%`, sm: `auto` },

              backgroundColor: `rgba(25, 201, 209, 1)`,
              border: `solid rgba(25, 201, 209, 1) 1px`,
              textTransform: `none`,
              borderRadius: `10px`,
              paddingY: `9px`,
              paddingX: `20px`,
              color: `white`,
              '&:hover': {
                backgroundColor: `rgba(25, 201, 209, 1)`,
                opacity: `0.8`,
              },
            }}
          >
            Reports
          </Button>
          <PrimaryButton
            title="+ Add Invoice"
            height="44px"
            width={`153px`}
            onClick={() => history.push(`/income/addInvoice`)}
          />
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: `10px`,
          backgroundColor: `white`,
          paddingY: `10px`,
          alignItems: `center`,
          justifyContent: `space-between`,
          display: `flex`,
          flexDirection: { xs: `column`, sm: `row` },
          gap: { xs: `10px`, sm: `0px` },
          borderRadius: `10px`,
        }}
      >
        <Grid container spacing={2} sx={{ width: `80%`, paddingX: `10px` }}>
          <Grid item xs={12} sm={2.5}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormControl fullWidth>
                <DatePicker
                  views={[`month`, `year`]}
                  inputFormat="MMM-YYYY"
                  label="Income Month *"
                  value={filteredData?.incomeMonth || null}
                  onChange={(e) => {
                    if (!e) {
                      handleFilteredData(`incomeMonth`, null)
                    } else {
                      handleFilteredData(`incomeMonth`, new Date(e))
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            border: `solid  ${colors.appColor} 1px`,
                          },
                          '&.Mui-focused fieldset': {
                            border: `solid ${colors.appColor} 1px`,
                          },
                          '& fieldset': {
                            borderColor: `rgba(40, 32, 61, 0.5)`,
                            borderRadius: `10px`,
                          },
                          '& svg': { color: `#19C9D1` },
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: `rgba(40, 32, 61, 0.5)`,
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={2.5}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormControl fullWidth>
                <DatePicker
                  inputFormat="YYYY-MM-DD"
                  label="Start Date *"
                  value={filteredData?.startDate || null}
                  onChange={(e) => {
                    if (!e) {
                      handleFilteredData(
                        `startDate`,
                        new Date(new Date().getFullYear(), 0, 1),
                      )
                    } else {
                      handleFilteredData(`startDate`, new Date(e))
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            border: `solid  ${colors.appColor} 1px`,
                          },
                          '&.Mui-focused fieldset': {
                            border: `solid ${colors.appColor} 1px`,
                          },
                          '& fieldset': {
                            borderColor: `rgba(40, 32, 61, 0.5)`,
                            borderRadius: `10px`,
                          },
                          '& svg': { color: `#19C9D1` },
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: `rgba(40, 32, 61, 0.5)`,
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} sm={2.5}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormControl fullWidth>
                <DatePicker
                  inputFormat="YYYY-MM-DD"
                  label="End Date *"
                  value={filteredData?.endDate || null}
                  onChange={(e) => {
                    if (!e) {
                      handleFilteredData(
                        `endDate`,
                        new Date(new Date().getFullYear(), 11, 31),
                      )
                    } else {
                      handleFilteredData(`endDate`, new Date(e))
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            border: `solid  ${colors.appColor} 1px`,
                          },
                          '&.Mui-focused fieldset': {
                            border: `solid ${colors.appColor} 1px`,
                          },
                          '& fieldset': {
                            borderColor: `rgba(40, 32, 61, 0.5)`,
                            borderRadius: `10px`,
                          },
                          '& svg': { color: `#19C9D1` },
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: `rgba(40, 32, 61, 0.5)`,
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={4.5}>
            <FormGroup sx={{ display: `flex`, flexDirection: `row` }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filteredData.isPaid}
                    onChange={(e) =>
                      handleFilteredData(`isPaid`, e.target.checked)
                    }
                    defaultChecked
                    sx={{
                      color: `rgba(40, 32, 61, 0.15)`,
                      '&.Mui-checked': {
                        color: colors?.appColor,
                      },
                    }}
                  />
                }
                label="Paid"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filteredData.isUnpaid}
                    onChange={(e) =>
                      handleFilteredData(`isUnpaid`, e.target.checked)
                    }
                    defaultChecked
                    sx={{
                      color: `rgba(40, 32, 61, 0.15)`,
                      '&.Mui-checked': {
                        color: colors?.appColor,
                      },
                    }}
                  />
                }
                label="Unpaid"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filteredData.isCancelled}
                    onChange={(e) =>
                      handleFilteredData(`isCancelled`, e.target.checked)
                    }
                    defaultChecked
                    sx={{
                      color: `rgba(40, 32, 61, 0.15)`,
                      '&.Mui-checked': {
                        color: colors?.appColor,
                      },
                    }}
                  />
                }
                label="Cancelled"
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: `flex`,
            gap: `10px`,
            alignItems: `center`,
            paddingX: `10px`,
          }}
        >
          <Typography
            sx={{
              color: `rgba(96, 57, 187, 1)`,
              fontSize: `14px`,
              fontWeight: 400,
              lineHeight: `16.94px`,
              cursor: `pointer`,
            }}
            onClick={() => {
              setFilteredData({
                client: ``,
                project: ``,
                startDate: new Date(new Date().getFullYear(), 0, 1),
                endDate: new Date(new Date().getFullYear(), 11, 31),
                incomeMonth: null,
                isPaid: true,
                isUnpaid: true,
                isCancelled: true,
              })
            }}
          >
            Clear Filter
          </Typography>
          <Button
            sx={{
              color: `white`,
              marginX: `3px`,
              backgroundColor: `rgba(25, 201, 209, 1) `,
              border: `solid rgba(25, 201, 209, 1) 1px`,
              borderRadius: `10px`,
              textTransform: `none`,
              width: `92px`,
              marginBottom: { xs: `10px`, md: `0px` },
              '&:hover': {
                backgroundColor: `rgba(25, 201, 209, 1)`,
                opacity: `0.8`,
              },
            }}
            onClick={() => refetch()}
          >
            Apply
          </Button>
        </Box>
      </Box>
      {isLoading || isFetching ? (
        <Backdrop open={isLoading || isFetching}>
          <CircularProgress
            sx={{
              position: `absolute`,
              top: `50%`,
              color: colors?.appColor,
            }}
          />
        </Backdrop>
      ) : !invoicesData || invoicesData?.length === 0 ? (
        <Typography
          sx={{
            display: `flex`,
            justifyContent: `center`,
            alignItems: `center`,
            height: `50vh`,
          }}
        >
          Invoices not found for given data
        </Typography>
      ) : (
        invoicesData?.map((data, index) => (
          <Box
            key={index}
            sx={{
              display: `flex`,
              flexDirection: `column`,
              marginTop: `10px`,
              borderRadius: `10px`,
            }}
          >
            {data?.status === `PAID` && (
              <Accordion
                expanded={isPaidAccordion}
                sx={{
                  bgcolor: `white`,
                  borderRadius: `10px`,
                }}
              >
                <AccordionSummary
                  sx={{
                    borderRadius: `10px`,
                  }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="table-content"
                  id="table-header"
                  onClick={() => setIsPaidAccordion(!isPaidAccordion)}
                >
                  <Box
                    sx={{
                      display: `flex`,
                      justifyContent: `space-between`,
                      alignItems: `center`,
                      // paddingY: `10px`,
                      flexDirection: { xs: `column`, sm: `row` },
                      gap: `10px`,
                      // paddingX: `15px`,
                      width: `100%`,
                      borderRadius: `10px`,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: `20px`,
                        fontWeight: 600,
                        lineHeight: `24.2px`,
                        color: `rgba(77, 198, 64, 1)`,
                        display: `flex`,
                        alignItems: `center`,
                        gap: `5px`,
                      }}
                    >
                      Paid Invoices
                      <Typography
                        sx={{
                          color: `rgba(77, 198, 64, 1)`,
                        }}
                      >{`(${data?.invoices?.length || 0})`}</Typography>
                    </Typography>
                    <Box
                      sx={{
                        display: `flex`,
                        alignItems: `center`,
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: `
                            rgba(96, 57, 187, 0.12)`,
                          width: `220px`,
                          height: `46px`,
                          alignItems: `center`,
                          justifyContent: `center`,
                          display: `flex`,
                          borderRadius: `10px`,
                          paddingX: `8px`,
                          marginX: `10px`,
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: `18px`,
                            color: `
                                
rgba(40, 32, 61, 1)`,
                            lineHeight: `21.78px`,
                          }}
                        >
                          {`${(data?.pkrAmount).toFixed(2)} PKR`}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: `
                            rgba(25, 201, 209, 0.2)`,
                          width: `220px`,
                          height: `46px`,
                          alignItems: `center`,
                          justifyContent: `center`,
                          display: `flex`,
                          borderRadius: `10px`,
                          paddingX: `8px`,
                          marginX: `10px`,
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: `18px`,
                            color: `
                              rgba(40, 32, 61, 1)`,
                            lineHeight: `21.78px`,
                          }}
                        >
                          {`${(data?.usdAmount).toFixed(2)} USD`}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid item xs={12}>
                    <TableContainer
                      className="scrollbar-hide"
                      sx={{
                        backgroundColor: `white`,
                        borderTop: `solid 1px rgba(40, 32, 61, 0.15)`,

                        height: `auto`,
                        maxHeight: `400px`,
                        whiteSpace: `nowrap`,
                        position: `relative`,
                        borderRadius: `10px`,
                        '& tbody': {
                          '& td, & th': {
                            borderColor: `rgba(40, 32, 61, 0.03)`,
                          },
                        },
                      }}
                    >
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead className="h-10 p-20">
                          <TableRow>
                            {tableColumns?.map((item, index) => {
                              return (
                                <TableCell
                                  key={index}
                                  sx={{ padding: `0px 16px` }}
                                >
                                  <Typography
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: `12px`,
                                      color: `#28203D`,
                                      opacity: 0.4,
                                    }}
                                  >
                                    {item?.label}
                                  </Typography>
                                </TableCell>
                              )
                            })}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {!data?.invoices && !data?.invoices?.length ? (
                            <TableRow>
                              <TableCell
                                colSpan={tableColumns.length + 1}
                                sx={{ border: 0 }}
                              >
                                <div
                                  style={{
                                    display: `flex`,
                                    justifyContent: `center`,
                                    alignItems: `center`,
                                  }}
                                >
                                  <Typography>No invoice Found</Typography>
                                </div>
                              </TableCell>
                            </TableRow>
                          ) : (
                            data?.invoices
                              ?.sort(
                                (a, b) =>
                                  new Date(b.invoiceDate) -
                                  new Date(a.invoiceDate),
                              )
                              ?.map((invoice, key) => (
                                <TableRow
                                  key={key}
                                  sx={{
                                    '&:last-child td, &:last-child th': {
                                      border: 0,
                                    },
                                    '&:hover': {
                                      cursor: `pointer`,
                                      background: `linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(250,249,253,1) 15%, rgba(250,249,253,1) 85%, rgba(255,255,255,1) 100%)`,
                                    },
                                    width: `100%`,
                                  }}
                                >
                                  <TableCell
                                    onClick={() =>
                                      history.push(`/income/${invoice?._id}`)
                                    }
                                    sx={{ width: `5%` }}
                                  >
                                    {key + 1}
                                  </TableCell>

                                  <TableCell
                                    onClick={() =>
                                      history.push(`/income/${invoice?._id}`)
                                    }
                                    sx={{ width: `10%` }}
                                  >
                                    {invoice?.invoiceNumber || ``}
                                  </TableCell>
                                  <TableCell
                                    onClick={() =>
                                      history.push(`/income/${invoice?._id}`)
                                    }
                                    sx={{ width: `10%` }}
                                  >
                                    {moment(invoice?.invoiceDate).format(
                                      `YYYY-MM-DD`,
                                    )}
                                  </TableCell>
                                  <TableCell
                                    onClick={() =>
                                      history.push(`/income/${invoice?._id}`)
                                    }
                                    sx={{ width: `10%` }}
                                  >
                                    {moment(invoice?.dueDate).format(
                                      `YYYY-MM-DD`,
                                    )}
                                  </TableCell>
                                  <TableCell
                                    onClick={() =>
                                      history.push(`/income/${invoice?._id}`)
                                    }
                                    sx={{ width: `10%` }}
                                  >
                                    {invoice?.billTo?.name}
                                  </TableCell>
                                  <TableCell
                                    onClick={() =>
                                      history.push(`/income/${invoice?._id}`)
                                    }
                                    sx={{ width: `10%` }}
                                  >
                                    {invoice?.projectId?.name}
                                  </TableCell>
                                  <TableCell
                                    onClick={() =>
                                      history.push(`/income/${invoice?._id}`)
                                    }
                                    sx={{
                                      width: `10%`,
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        width: `200px`,
                                        whiteSpace: `nowrap`,
                                        overflow: `hidden`,
                                        textOverflow: `ellipsis`,
                                      }}
                                    >
                                      {invoice?.description || `N / A`}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    onClick={() =>
                                      history.push(`/income/${invoice?._id}`)
                                    }
                                    sx={{ width: `10%` }}
                                  >{`${(invoice?.total).toFixed(2)} ${
                                    invoice?.currency || `USD`
                                  }`}</TableCell>
                                  {/* <TableCell sx={{ width: `10%` }}>
                                  {moment(invoice?.statusUpdatedDate).format(
                                    `YYYY-MM-DD`,
                                  )}
                                </TableCell> */}
                                  <TableCell sx={{ width: `15%` }}>
                                    <InvoiceStatusDropDown
                                      options={INVOICE_STATUS_OPTIONS}
                                      selectedValue={invoice?.status}
                                      isUpdating={isUpdating}
                                      onClick={(e) => {
                                        updateStatus({
                                          id: invoice?._id,
                                          newStatus: e,
                                        })
                                      }}
                                    />
                                    {/* <Typography
                                      sx={{
                                        width: `auto`,
                                        textAlign: `center`,
                                        backgroundColor:
                                          invoice?.status === `CANCELLED`
                                            ? `rgba(255, 72, 94, 0.1)`
                                            : invoice?.status === `PAID`
                                            ? `rgba(77, 198, 64, 0.12)`
                                            : invoice?.status === `UNPAID`
                                            ? `rgba(249, 171, 6, 0.12)`
                                            : `rgba(40, 32, 61, 0.1)`,
                                        color:
                                          invoice?.status === `CANCELLED`
                                            ? `rgba(255, 72, 94, 1)`
                                            : invoice?.status === `PAID`
                                            ? `
rgba(77, 198, 64, 1)`
                                            : invoice?.status === `UNPAID`
                                            ? `rgba(249, 171, 6, 1)`
                                            : `rgba(40, 32, 61, 1)`,
                                        borderRadius: `20px`,
                                        paddingY: `5px`,
                                        paddingX: `10px`,
                                      }}
                                    >
                                      {invoice?.status === `CANCELLED`
                                        ? `Cancelled`
                                        : invoice?.status === `PAID`
                                        ? `Paid`
                                        : invoice?.status === `UNPAID`
                                        ? `Unpaid`
                                        : ``}
                                    </Typography> */}
                                  </TableCell>
                                </TableRow>
                              ))
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )}
            {data?.status === `UNPAID` && (
              <Box
                sx={{
                  backgroundColor: `white`,
                  borderRadius: `10px`,
                }}
              >
                <Accordion expanded={isUnpaidAccordion}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="table-content"
                    id="table-header"
                    onClick={() => setIsUnpaidAccordion(!isUnpaidAccordion)}
                  >
                    <Box
                      sx={{
                        display: `flex`,
                        justifyContent: `space-between`,
                        alignItems: `center`,
                        // paddingY: `10px`,
                        flexDirection: { xs: `column`, sm: `row` },
                        gap: `10px`,
                        // paddingX: `15px`,
                        width: `100%`,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: `20px`,
                          fontWeight: 600,
                          lineHeight: `24.2px`,
                          color: `
                        rgba(249, 171, 6, 1)`,
                          display: `flex`,
                          alignItems: `center`,
                          gap: `5px`,
                        }}
                      >
                        Unpaid Invoices
                        <Typography
                          sx={{
                            color: `
                          rgba(249, 171, 6, 1)`,
                          }}
                        >{`(${data?.invoices?.length || 0})`}</Typography>
                      </Typography>

                      <Box
                        sx={{
                          display: `flex`,
                          alignItems: `center`,
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: `
                              rgba(96, 57, 187, 0.12)`,
                            width: `220px`,
                            height: `46px`,
                            alignItems: `center`,
                            justifyContent: `center`,
                            display: `flex`,
                            borderRadius: `10px`,
                            paddingX: `8px`,
                            marginX: `10px`,
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: `18px`,
                              color: `
                              
rgba(40, 32, 61, 1)`,
                              lineHeight: `21.78px`,
                            }}
                          >
                            {`${(data?.pkrAmount).toFixed(2)} PKR`}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: `
                            rgba(25, 201, 209, 0.2)`,
                            width: `220px`,
                            height: `46px`,
                            alignItems: `center`,
                            justifyContent: `center`,
                            display: `flex`,
                            borderRadius: `10px`,
                            paddingX: `8px`,
                            marginX: `10px`,
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: `18px`,
                              color: `
                                rgba(40, 32, 61, 1)`,
                              lineHeight: `21.78px`,
                            }}
                          >
                            {`${(data?.usdAmount).toFixed(2)} USD`}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid item xs={12}>
                      <TableContainer
                        className="scrollbar-hide"
                        sx={{
                          backgroundColor: `white`,
                          borderTop: `solid 1px rgba(40, 32, 61, 0.15)`,
                          height: `auto`,
                          maxHeight: `400px`,
                          whiteSpace: `nowrap`,
                          position: `relative`,
                          '& tbody': {
                            '& td, & th': {
                              borderColor: `rgba(40, 32, 61, 0.03)`,
                            },
                          },
                        }}
                      >
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead className="h-10 p-20">
                            <TableRow>
                              {tableColumns?.map((item, index) => {
                                return (
                                  <TableCell
                                    key={index}
                                    sx={{ padding: `0px 16px` }}
                                  >
                                    <Typography
                                      sx={{
                                        fontWeight: 600,
                                        fontSize: `12px`,
                                        color: `#28203D`,
                                        opacity: 0.4,
                                      }}
                                    >
                                      {item?.label}
                                    </Typography>
                                  </TableCell>
                                )
                              })}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {!data?.invoices && !data?.invoices?.length ? (
                              <TableRow>
                                <TableCell
                                  colSpan={tableColumns.length + 1}
                                  sx={{ border: 0 }}
                                >
                                  <div
                                    style={{
                                      display: `flex`,
                                      justifyContent: `center`,
                                      alignItems: `center`,
                                    }}
                                  >
                                    <Typography>No invoice Found</Typography>
                                  </div>
                                </TableCell>
                              </TableRow>
                            ) : (
                              data?.invoices
                                ?.sort(
                                  (a, b) =>
                                    new Date(b.invoiceDate) -
                                    new Date(a.invoiceDate),
                                )
                                ?.map((invoice, key) => (
                                  <TableRow
                                    key={key}
                                    sx={{
                                      '&:last-child td, &:last-child th': {
                                        border: 0,
                                      },
                                      '&:hover': {
                                        cursor: `pointer`,
                                        background: `linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(250,249,253,1) 15%, rgba(250,249,253,1) 85%, rgba(255,255,255,1) 100%)`,
                                      },
                                      width: `100%`,
                                    }}
                                  >
                                    <TableCell
                                      onClick={() =>
                                        history.push(`/income/${invoice?._id}`)
                                      }
                                      sx={{ width: `5%` }}
                                    >
                                      {key + 1}
                                    </TableCell>

                                    <TableCell
                                      onClick={() =>
                                        history.push(`/income/${invoice?._id}`)
                                      }
                                      sx={{ width: `10%` }}
                                    >
                                      {invoice?.invoiceNumber || ``}
                                    </TableCell>
                                    <TableCell
                                      onClick={() =>
                                        history.push(`/income/${invoice?._id}`)
                                      }
                                      sx={{ width: `10%` }}
                                    >
                                      {moment(invoice?.invoiceDate).format(
                                        `YYYY-MM-DD`,
                                      )}
                                    </TableCell>
                                    <TableCell
                                      onClick={() =>
                                        history.push(`/income/${invoice?._id}`)
                                      }
                                      sx={{ width: `10%` }}
                                    >
                                      {moment(invoice?.dueDate).format(
                                        `YYYY-MM-DD`,
                                      )}
                                    </TableCell>
                                    <TableCell
                                      onClick={() =>
                                        history.push(`/income/${invoice?._id}`)
                                      }
                                      sx={{ width: `10%` }}
                                    >
                                      {invoice?.billTo?.name}
                                    </TableCell>
                                    <TableCell
                                      onClick={() =>
                                        history.push(`/income/${invoice?._id}`)
                                      }
                                      sx={{ width: `10%` }}
                                    >
                                      {invoice?.projectId?.name}
                                    </TableCell>
                                    <TableCell
                                      onClick={() =>
                                        history.push(`/income/${invoice?._id}`)
                                      }
                                      sx={{ width: `10%` }}
                                    >
                                      <Typography
                                        sx={{
                                          width: `200px`,
                                          whiteSpace: `nowrap`,
                                          overflow: `hidden`,
                                          textOverflow: `ellipsis`,
                                        }}
                                      >
                                        {invoice?.description || `N / A`}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      onClick={() =>
                                        history.push(`/income/${invoice?._id}`)
                                      }
                                      sx={{ width: `10%` }}
                                    >{`${(invoice?.total).toFixed(2)} ${
                                      invoice?.currency || `USD`
                                    }`}</TableCell>
                                    {/* <TableCell sx={{ width: `10%` }}>
                                    {moment(invoice?.statusUpdatedDate).format(
                                      `YYYY-MM-DD`,
                                    )}
                                  </TableCell> */}

                                    <TableCell sx={{ width: `15%` }}>
                                      <InvoiceStatusDropDown
                                        options={INVOICE_STATUS_OPTIONS}
                                        selectedValue={invoice?.status}
                                        isUpdating={isUpdating}
                                        onClick={(e) => {
                                          updateStatus({
                                            id: invoice?._id,
                                            newStatus: e,
                                          })
                                        }}
                                      />
                                    </TableCell>
                                  </TableRow>
                                ))
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Box>
            )}
            {data?.status === `CANCELLED` && (
              <Box
                sx={{
                  backgroundColor: `white`,
                  borderRadius: `10px`,
                }}
              >
                <Accordion expanded={isCancelledAccordion}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="table-content"
                    id="table-header"
                    onClick={() =>
                      setIsCancelledAccordion(!isCancelledAccordion)
                    }
                  >
                    <Box
                      sx={{
                        display: `flex`,
                        justifyContent: `space-between`,
                        alignItems: `center`,
                        // paddingY: `10px`,
                        flexDirection: { xs: `column`, sm: `row` },
                        gap: `10px`,
                        // paddingX: `15px`,
                        width: `100%`,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: `20px`,
                          fontWeight: 600,
                          lineHeight: `24.2px`,
                          color: `
                          
                        rgba(255, 72, 94, 1)`,
                          display: `flex`,
                          alignItems: `center`,
                          gap: `5px`,
                        }}
                      >
                        Cancelled Invoices
                        <Typography
                          sx={{
                            color: `
                          
                          rgba(255, 72, 94, 1)`,
                          }}
                        >{`(${data?.invoices?.length || 0})`}</Typography>
                      </Typography>

                      <Box
                        sx={{
                          display: `flex`,
                          alignItems: `center`,
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: `
                              rgba(96, 57, 187, 0.12)`,
                            width: `220px`,
                            height: `46px`,
                            alignItems: `center`,
                            justifyContent: `center`,
                            display: `flex`,
                            borderRadius: `10px`,
                            paddingX: `8px`,
                            marginX: `10px`,
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: `18px`,
                              color: `
                              
rgba(40, 32, 61, 1)`,
                              lineHeight: `21.78px`,
                            }}
                          >
                            {`${(data?.pkrAmount).toFixed(2)} PKR`}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: `
                            rgba(25, 201, 209, 0.2)`,
                            width: `220px`,
                            height: `46px`,
                            alignItems: `center`,
                            justifyContent: `center`,
                            display: `flex`,
                            borderRadius: `10px`,
                            paddingX: `8px`,
                            marginX: `10px`,
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: `18px`,
                              color: `
                                rgba(40, 32, 61, 1)`,
                              lineHeight: `21.78px`,
                            }}
                          >
                            {`${(data?.usdAmount).toFixed(2)} USD`}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid item xs={12}>
                      <TableContainer
                        className="scrollbar-hide"
                        sx={{
                          backgroundColor: `white`,
                          borderTop: `solid 1px rgba(40, 32, 61, 0.15)`,
                          height: `auto`,
                          maxHeight: `400px`,
                          whiteSpace: `nowrap`,
                          position: `relative`,
                          '& tbody': {
                            '& td, & th': {
                              borderColor: `rgba(40, 32, 61, 0.03)`,
                            },
                          },
                        }}
                      >
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead className="h-10 p-20">
                            <TableRow>
                              {tableColumns?.map((item, index) => {
                                return (
                                  <TableCell
                                    key={index}
                                    sx={{ padding: `0px 16px` }}
                                  >
                                    <Typography
                                      sx={{
                                        fontWeight: 600,
                                        fontSize: `12px`,
                                        color: `#28203D`,
                                        opacity: 0.4,
                                      }}
                                    >
                                      {item?.label}
                                    </Typography>
                                  </TableCell>
                                )
                              })}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {!data?.invoices && !data?.invoices?.length ? (
                              <TableRow>
                                <TableCell
                                  colSpan={tableColumns.length + 1}
                                  sx={{ border: 0 }}
                                >
                                  <div
                                    style={{
                                      display: `flex`,
                                      justifyContent: `center`,
                                      alignItems: `center`,
                                    }}
                                  >
                                    <Typography>No invoice Found</Typography>
                                  </div>
                                </TableCell>
                              </TableRow>
                            ) : (
                              data?.invoices
                                ?.sort(
                                  (a, b) =>
                                    new Date(b.invoiceDate) -
                                    new Date(a.invoiceDate),
                                )
                                ?.map((invoice, key) => (
                                  <TableRow
                                    key={key}
                                    sx={{
                                      '&:last-child td, &:last-child th': {
                                        border: 0,
                                      },
                                      '&:hover': {
                                        cursor: `pointer`,
                                        background: `linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(250,249,253,1) 15%, rgba(250,249,253,1) 85%, rgba(255,255,255,1) 100%)`,
                                      },
                                      width: `100%`,
                                    }}
                                  >
                                    <TableCell
                                      onClick={() =>
                                        history.push(`/income/${invoice?._id}`)
                                      }
                                      sx={{ width: `5%` }}
                                    >
                                      {key + 1}
                                    </TableCell>

                                    <TableCell
                                      onClick={() =>
                                        history.push(`/income/${invoice?._id}`)
                                      }
                                      sx={{ width: `10%` }}
                                    >
                                      {invoice?.invoiceNumber || ``}
                                    </TableCell>
                                    <TableCell
                                      onClick={() =>
                                        history.push(`/income/${invoice?._id}`)
                                      }
                                      sx={{ width: `10%` }}
                                    >
                                      {moment(invoice?.invoiceDate).format(
                                        `YYYY-MM-DD`,
                                      )}
                                    </TableCell>
                                    <TableCell
                                      onClick={() =>
                                        history.push(`/income/${invoice?._id}`)
                                      }
                                      sx={{ width: `10%` }}
                                    >
                                      {moment(invoice?.dueDate).format(
                                        `YYYY-MM-DD`,
                                      )}
                                    </TableCell>
                                    <TableCell sx={{ width: `10%` }}>
                                      {invoice?.billTo?.name}
                                    </TableCell>
                                    <TableCell
                                      onClick={() =>
                                        history.push(`/income/${invoice?._id}`)
                                      }
                                      sx={{ width: `10%` }}
                                    >
                                      {invoice?.projectId?.name}
                                    </TableCell>
                                    <TableCell
                                      onClick={() =>
                                        history.push(`/income/${invoice?._id}`)
                                      }
                                      sx={{ width: `10%` }}
                                    >
                                      <Typography
                                        sx={{
                                          width: `200px`,
                                          whiteSpace: `nowrap`,
                                          overflow: `hidden`,
                                          textOverflow: `ellipsis`,
                                        }}
                                      >
                                        {invoice?.description || `N / A`}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      onClick={() =>
                                        history.push(`/income/${invoice?._id}`)
                                      }
                                      sx={{ width: `10%` }}
                                    >{`${(invoice?.total).toFixed(2)} ${
                                      invoice?.currency || `USD`
                                    }`}</TableCell>
                                    {/* <TableCell sx={{ width: `10%` }}>
                                    {moment(invoice?.statusUpdatedDate).format(
                                      `YYYY-MM-DD`,
                                    )}
                                  </TableCell> */}
                                    <TableCell sx={{ width: `15%` }}>
                                      <InvoiceStatusDropDown
                                        options={INVOICE_STATUS_OPTIONS}
                                        selectedValue={invoice?.status}
                                        isUpdating={isUpdating}
                                        onClick={(e) => {
                                          updateStatus({
                                            id: invoice?._id,
                                            newStatus: e,
                                          })
                                        }}
                                      />
                                    </TableCell>
                                  </TableRow>
                                ))
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Box>
            )}
          </Box>
        ))
      )}
    </Box>
  )
}

export default Invoices
