import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import PrimaryButton from 'app/Components/Common/PrimaryButton'
import SecondaryButton from 'app/Components/Common/SecondaryButton'
import React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useMutation } from 'react-query'
import { baseUrl } from 'app/Utils/Config'
import api from 'app/AxiosInstance'
import { useContext } from 'react'
import { SnackbarContext } from 'app/Components/SnackbarProvider'
import { useEffect } from 'react'
import crossIcon from '../../../../Assets/cross_ic.svg'
import ReactInputMask from 'react-input-mask'
import Image from 'mui-image'

const UpdateTemplate = ({ open, onClose, refetch, templateData }) => {
  const colors = useSelector((state) => state?.colors)
  const openSnackbar = useContext(SnackbarContext)
  const [description, setDescription] = useState(``)
  const [services, setServices] = useState([
    {
      description: ``,
      quantity: ``,
      rate: ``,
    },
  ])
  useEffect(() => {
    if (templateData) {
      setDescription(templateData?.name)
      const servicesList = templateData?.services?.map((service) => ({
        description: service?.detail ?? ``,
        quantity: service?.quantity ?? ``,
        rate: service?.rate ?? ``,
      }))
      setServices(servicesList)
    }
  }, [templateData])

  const [errors, setErrors] = useState({
    formSubmit: false,
    description: false,
    services: false,
  })

  const { mutate: addTemplate, isLoading } = useMutation(
    async (payload) =>
      await api
        .patch(`${baseUrl}/templates/${templateData?._id}`, {
          ...payload,
        })
        .then((res) => {
          if (res?.data) {
            onClose()
            openSnackbar(`Template updated successfully`, `success`)
            return res?.data
          }
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: () => {
        refetch()
      },
      onError: (error) => {
        openSnackbar(
          error?.response
            ? error?.response?.data?.message
            : error?.message
            ? error?.message
            : `Something went wrong`,
          `error`,
        )
      },
    },
  )

  const validateForm = () => {
    if (!description?.length) {
      setErrors((prevState) => ({
        ...prevState,
        description: true,
      }))
    } else {
      setErrors((prevState) => ({
        ...prevState,
        description: false,
      }))
    }
    const serviceError = services?.some(
      (service) =>
        !service?.description || !service?.quantity || !service?.rate,
    )
    if (serviceError) {
      setErrors((prevState) => ({
        ...prevState,
        services: true,
      }))
    } else {
      setErrors((prevState) => ({
        ...prevState,
        services: false,
      }))
    }
    setErrors((prevState) => ({
      ...prevState,
      formSubmit: true,
    }))
  }

  useEffect(() => {
    if (errors?.formSubmit && !errors?.description && !errors?.services) {
      addTemplate(handleTemplate())
      setErrors((prevState) => ({
        ...prevState,
        formSubmit: false,
      }))
    } else if (errors?.formSubmit) {
      openSnackbar(`Please fill out all required fields`, `error`)
    }
  }, [errors])

  const handleTemplate = () => {
    const data = {}
    const servicesData = []

    if (description?.length) {
      data.name = description
    }
    services?.forEach((data) => {
      servicesData?.push({
        detail: data?.description,
        quantity: parseInt(data?.quantity),
        rate: parseFloat(data?.rate),
      })
    })
    data.services = servicesData
    return data
  }

  const handleAddService = () => {
    setServices([
      ...services,
      {
        description: ``,
        quantity: ``,
        rate: ``,
      },
    ])
  }
  const handleRemoveService = (index) => {
    let newService = [...services]
    newService.splice(index, 1)
    setServices(newService)
  }

  const handleServices = (value, name, ind) => {
    if (ind !== undefined) {
      const newService = services?.map((service, index) => {
        if (index === ind) {
          if (name === `rate` && !/^[0-9.]*$/.test(value)) {
            return service
          }
          service[name] = value
        }
        return service
      })
      setServices(newService)
    }
  }
  return (
    <Dialog
      open={open}
      maxWidth="lg"
      PaperProps={{
        sx: {
          width: `700px`,
          height: `max-content`,
          borderRadius: `20px`,
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          height: `64px`,
          width: `100%`,
        }}
      >
        <Box
          sx={{
            display: `flex`,
            flexDirection: `row`,
            justifyContent: `space-between`,
            alignItems: `center`,
            backgroundColor: `grey`,
            marginX: `1rem`,
          }}
        >
          <Typography
            sx={{ fontSize: `20px`, fontWeight: 500, lineHeight: `24.2px` }}
          >
            Update Invoice Template
          </Typography>
          <Box
            sx={{
              width: `36px`,
              height: `36px`,
              backgroundColor: `white`,
              display: `flex`,
              justifyContent: `center`,
              alignItems: `center`,
            }}
          >
            <Button onClick={onClose}>
              <img src={crossIcon} alt="cross" />
            </Button>
          </Box>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      border: errors?.description
                        ? `solid #FF485E 1px`
                        : `solid ${colors.appColor} 1px`,
                    },
                    '&.Mui-focused fieldset': {
                      border: errors?.description
                        ? `solid #FF485E 1px`
                        : `solid ${colors.appColor} 1px`,
                    },
                    '& fieldset': {
                      borderColor: errors?.description
                        ? ` #FF485E`
                        : `rgba(40, 32, 61, 0.25)`,
                      borderRadius: `10px`,
                    },
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: errors?.description
                      ? `#FF485E`
                      : `rgba(40, 32, 61, 0.5)`,
                  },
                }}
                id="outlined-basic"
                label="Template Name"
                name="description"
                value={description}
                variant="outlined"
                size="small"
                onChange={(e) => setDescription(e?.target?.value)}
                multiline={true}
                // minRows={4}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Box
          sx={{
            backgroundColor: errors?.services
              ? `rgba(255, 72, 94, 0.1)`
              : `rgba(25, 201, 209, 0.15)`,
            marginY: `10px`,
            alignItems: `center`,
            display: `flex`,
            paddingY: `8px`,
            paddingX: `10px`,
            borderRadius: `10px`,
          }}
        >
          <Grid spacing={1} container>
            <Grid
              item
              xs={12}
              sm={6.5}
              sx={{
                color: errors?.services ? `#FF485E` : `rgba(40, 32, 61, 0.4)`,
              }}
            >
              Services Description
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              sx={{
                color: errors?.services ? `#FF485E` : `rgba(40, 32, 61, 0.4)`,
              }}
            >
              Qty
            </Grid>
            <Grid
              item
              xs={12}
              sm={2.5}
              sx={{
                color: errors?.services ? `#FF485E` : `rgba(40, 32, 61, 0.4)`,
              }}
            >
              Rate
            </Grid>
          </Grid>
        </Box>

        {services?.length > 0 ? (
          services?.map((service, index) => (
            <>
              <Box
                sx={{
                  display: `flex`,
                  flexDirection: `row`,
                  justifyContent: `space-between`,
                  alignItems: `center`,
                  marginY: `10px`,
                }}
                key={index}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6.5}>
                    <FormControl fullWidth>
                      <TextField
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              border: `solid ${colors.appColor} 1px`,
                            },
                            '&.Mui-focused fieldset': {
                              border: `solid ${colors.appColor} 1px`,
                            },
                            '& fieldset': {
                              borderColor: `rgba(40, 32, 61, 0.25)`,
                              borderRadius: `10px`,
                            },
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            color: `rgba(40, 32, 61, 0.5)`,
                          },
                        }}
                        id="outlined-basic"
                        label="Description"
                        name="description"
                        value={service?.description}
                        onChange={(e) =>
                          handleServices(e?.target?.value, `description`, index)
                        }
                        variant="outlined"
                        size="small"
                        multiline={true}
                        // minRows={4}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <FormControl
                      fullWidth
                      sx={{
                        // marginY: `10px`,
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            border: `solid ${colors?.appColor} 2px`,
                          },
                          '&.Mui-focused fieldset': {
                            border: `solid ${colors?.appColor} 2px`,
                          },
                          '& fieldset': {
                            borderRadius: `10px`,
                          },
                        },
                      }}
                    >
                      <ReactInputMask
                        fullWidth
                        mask="9999999999999"
                        maskChar=""
                        value={service?.quantity}
                        onChange={(e) =>
                          handleServices(e?.target?.value, `quantity`, index)
                        }
                      >
                        {(inputProps) => (
                          <TextField
                            id="outlined-basic"
                            label="Qty *"
                            name="quantity"
                            variant="outlined"
                            size="small"
                            InputLabelProps={{
                              style: {
                                color: `rgba(40, 32, 61, 0.5)`,
                              },
                            }}
                            {...inputProps}
                          />
                        )}
                      </ReactInputMask>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={2.5}>
                    <FormControl
                      fullWidth
                      sx={{
                        // marginY: `10px`,
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            border: `solid ${colors?.appColor} 2px`,
                          },
                          '&.Mui-focused fieldset': {
                            border: `solid ${colors?.appColor} 2px`,
                          },
                          '& fieldset': {
                            borderRadius: `10px`,
                          },
                        },
                      }}
                    >
                      <TextField
                        id="outlined-basic"
                        label="Rate * "
                        name="rate"
                        value={service?.rate}
                        variant="outlined"
                        size="small"
                        onChange={(e) =>
                          handleServices(e?.target?.value, `rate`, index)
                        }
                        InputLabelProps={{
                          style: {
                            color: `rgba(40, 32, 61, 0.5)`,
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Box
                    sx={{
                      marginY: `10px`,
                    }}
                  >
                    <Image
                      src={crossIcon}
                      alt="cross icon"
                      width={24}
                      height={24}
                      onClick={() => handleRemoveService(index)}
                      sx={{
                        marginTop: `15px`,
                        marginX: `5px`,
                        '&:hover': {
                          cursor: `pointer`,
                        },
                      }}
                    />
                  </Box>
                </Grid>
              </Box>
            </>
          ))
        ) : (
          <>
            <Box
              sx={{
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`,
                paddingX: `30px`,
                marginX: { xs: `50px`, sm: `200px` },
              }}
            >
              <Typography>No Service added yet</Typography>
            </Box>
          </>
        )}
        <Button
          onClick={handleAddService}
          sx={{
            width: { xs: `100%`, sm: `auto` },
            marginY: `10px`,
            backgroundColor: `rgba(25, 201, 209, 1)`,
            border: `solid rgba(25, 201, 209, 1) 1px`,
            textTransform: `none`,
            borderRadius: `6px`,
            paddingY: `2px`,
            paddingX: `10px`,
            color: `white`,
            '&:hover': {
              backgroundColor: `rgba(25, 201, 209, 1)`,
              opacity: `0.8`,
            },
          }}
        >
          + Add Items
        </Button>

        <Box
          sx={{
            display: `flex`,
            flexDirection: {
              xs: `column-reverse`,
              sm: `row-reverse`,
            },
            gap: `10px`,
            marginY: `10px`,
          }}
        >
          <PrimaryButton
            title="Update"
            onClick={validateForm}
            isLoading={isLoading}
          />
          <SecondaryButton title="Cancel" onClick={onClose} />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default UpdateTemplate
