import {
  Box,
  Button,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import { Dialog, DialogTitle, Divider, MenuItem } from '@mui/material'
import React, { useContext } from 'react'
import crossIcon from '../../../Assets/cross_ic.svg'
import { useState } from 'react'
import PrimaryButton from 'app/Components/Common/PrimaryButton'
import SecondaryButton from 'app/Components/Common/SecondaryButton'
import { useSelector } from 'react-redux'
import { useMutation } from 'react-query'
import { baseUrl } from 'app/Utils/Config'
import api from 'app/AxiosInstance'
import { SnackbarContext } from 'app/Components/SnackbarProvider'
import { useEffect } from 'react'
import ReactInputMask from 'react-input-mask'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { getAllAccounts } from 'app/Services/General/getAllAccounts'

const AddAllocateBudget = ({ onClose, open, refetchAccount, title }) => {
  const colors = useSelector((state) => state?.colors)
  const { accountId } = useParams()
  const openSnackbar = useContext(SnackbarContext)
  const [accountData, setAccountData] = useState([])

  useEffect(() => {
    getAllAccounts().then((result) => {
      const newLoadAccounts = result?.options
        ?.map((account) => {
          if (
            account?.name &&
            !account.name.toLowerCase().includes(`payroll`)
          ) {
            return {
              label: account?.name,
              value: account?._id,
            }
          }
          return null // Filter out accounts with "payroll" in their name
        })
        .filter(Boolean) // Remove null entries from the array
      setAccountData(newLoadAccounts)
    })
  }, [])

  const [allocateBudget, setAllocateBudget] = useState({
    account: accountId ? accountId : ``,
    amount: ``,
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
  })
  const [errors, setErrors] = useState({
    formSubmit: false,
    account: false,
    amount: false,
    startDate: false,
    endDate: false,
  })

  const { mutate: addAllocatedBudget, isLoading } = useMutation(
    async (payload) =>
      await api
        .post(`${baseUrl}/accounts/${allocateBudget?.account}/budget`, {
          ...payload,
        })
        .then((res) => {
          if (res?.data) {
            onClose()
            openSnackbar(`Budget added successfully`, `success`)
            res?.data
          }
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: () => {
        refetchAccount()
      },
      onError: (error) => {
        openSnackbar(
          error?.response
            ? error?.response?.data?.message
            : error?.message
            ? error?.message
            : `Something went wrong`,
          `error`,
        )
      },
    },
  )

  const validateForm = () => {
    if (!allocateBudget?.account?.length) {
      setErrors((prevState) => ({
        ...prevState,
        account: true,
      }))
    } else {
      setErrors((prevState) => ({
        ...prevState,
        account: false,
      }))
    }
    if (!allocateBudget?.amount?.length) {
      setErrors((prevState) => ({
        ...prevState,
        amount: true,
      }))
    } else {
      setErrors((prevState) => ({
        ...prevState,
        amount: false,
      }))
    }
    if (!allocateBudget?.startDate) {
      setErrors((prevState) => ({
        ...prevState,
        startDate: true,
      }))
    } else if (
      moment(allocateBudget?.startDate).format(`YYYY-MM-DD`) === `Invalid date`
    ) {
      setErrors((prevState) => ({
        ...prevState,
        startDate: true,
      }))
    } else {
      setErrors((prevState) => ({
        ...prevState,
        startDate: false,
      }))
    }
    if (!allocateBudget?.endDate) {
      setErrors((prevState) => ({
        ...prevState,
        endDate: true,
      }))
    } else if (
      moment(allocateBudget?.endDate).format(`YYYY-MM-DD`) === `Invalid date`
    ) {
      setErrors((prevState) => ({
        ...prevState,
        endDate: true,
      }))
    } else {
      setErrors((prevState) => ({
        ...prevState,
        endDate: false,
      }))
    }

    setErrors((prevState) => ({
      ...prevState,
      formSubmit: true,
    }))
  }

  useEffect(() => {
    if (
      errors?.formSubmit &&
      !errors?.account &&
      !errors?.amount &&
      !errors?.startDate &&
      !errors?.endDate
    ) {
      addAllocatedBudget(handleAllocatedBudget())
      setErrors((prevState) => ({
        ...prevState,
        formSubmit: false,
      }))
    } else if (errors?.formSubmit) {
      openSnackbar(
        `Please ensure all required fields are filled and valid`,
        `error`,
      )
    }
  }, [errors])

  const handleAllocatedBudget = () => {
    const data = {}

    if (allocateBudget?.amount?.length) {
      data.total = parseInt(allocateBudget?.amount)
    }
    if (allocateBudget?.startDate) {
      data.startDate = moment(allocateBudget?.startDate)
        .format(`YYYY-MM-DD`)
        .toString()
    }

    if (allocateBudget?.endDate) {
      data.endDate = moment(allocateBudget?.endDate)
        .format(`YYYY-MM-DD`)
        .toString()
    }
    if (allocateBudget?.description?.length) {
      data.details = allocateBudget?.description
    }

    return data
  }

  const handleBudgetChanges = (name, value) => {
    setAllocateBudget((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      PaperProps={{
        sx: {
          height: `max-content`,
          borderRadius: `20px`,
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          height: `64px`,
          width: `100%`,
        }}
      >
        <Box
          sx={{
            display: `flex`,
            flexDirection: `row`,
            justifyContent: `space-between`,
            alignItems: `center`,
            backgroundColor: `grey`,
            marginLeft: `1rem`,
            marginRight: `1rem`,
          }}
        >
          <Typography>Allocate Budget</Typography>
          <Box
            sx={{
              width: `36px`,
              height: `36px`,
              backgroundColor: `white`,
              display: `flex`,
              justifyContent: `center`,
              alignItems: `center`,
            }}
          >
            <Button onClick={onClose}>
              <img src={crossIcon} alt="cross" />
            </Button>
          </Box>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl
              size="small"
              fullWidth
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    border: errors?.account
                      ? `solid #FF485E 1px`
                      : `solid ${colors.appColor} 1px`,
                  },
                  '&.Mui-focused fieldset': {
                    border: errors?.account
                      ? `solid #FF485E 1px`
                      : `solid ${colors.appColor} 1px`,
                  },
                  '& fieldset': {
                    borderColor: errors?.account
                      ? `#FF485E`
                      : `rgba(40, 32, 61, 0.25)`,
                    borderRadius: `10px`,
                  },
                },
                '& .MuiInputLabel-root': {
                  '&.Mui-focused': {
                    color: errors?.account
                      ? `#FF485E`
                      : `rgba(40, 32, 61, 0.25)`,
                  },
                },
              }}
            >
              <InputLabel
                id="demo-simple-select-label"
                sx={{
                  color: errors?.account ? `#FF485E` : `rgba(40, 32, 61, 0.5)`,
                }}
              >
                Account *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="account"
                label="Account *"
                value={allocateBudget?.account}
                disabled={title !== `generalBudget`}
                onChange={(e) => handleBudgetChanges(`account`, e.target.value)}
              >
                {accountData?.map((account) => (
                  <MenuItem key={account?.value} value={account?.value}>
                    {account?.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl
              sx={{
                display: `flex`,
                flexDirection: `row`,
                alignItems: `center`,
                justifyContent: `center`,
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    border: errors?.amount
                      ? `solid #FF485E 1px`
                      : `solid ${colors.appColor} 1px`,
                    borderLeft: `none`,
                  },
                  '&.Mui-focused fieldset': {
                    border: errors?.amount
                      ? `solid #FF485E 1px`
                      : `solid ${colors.appColor} 1px`,
                    borderLeft: `none`,
                  },
                  '& fieldset': {
                    borderLeft: `none`,
                    borderColor: errors?.amount
                      ? `#FF485E`
                      : `rgba(40, 32, 61, 0.25)`,
                    borderRadius: `10px`,
                    borderTopLeftRadius: `0px`,
                    borderBottomLeftRadius: `0px`,
                  },
                },
              }}
            >
              <Typography
                sx={{
                  backgroundColor: `rgba(40, 32, 61, 0.12)`,
                  padding: `10px`,
                  paddingTop: `11px`,

                  fontSize: `14px`,
                  fontWeight: 400,
                  lineHeight: `16.94px`,
                  borderRadius: `10px`,
                  borderTopRightRadius: `0px`,
                  borderBottomRightRadius: `0px`,
                  border: errors?.amount
                    ? `solid #FF485E 1px`
                    : `solid rgba(40, 32, 61, 0.12) 1px`,
                  borderRight: `none`,
                }}
              >
                Rs
              </Typography>
              <ReactInputMask
                fullWidth
                mask="9999999999999"
                maskChar=""
                value={allocateBudget?.amount}
                onChange={(e) => handleBudgetChanges(`amount`, e.target.value)}
              >
                {(inputProps) => (
                  <TextField
                    id="outlined-basic"
                    label="Budget Amount *"
                    name="Budget Amount"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      style: {
                        color: errors?.amount
                          ? `#FF485E`
                          : `rgba(40, 32, 61, 0.5)`,
                      },
                    }}
                    {...inputProps}
                  />
                )}
              </ReactInputMask>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormControl fullWidth>
                <DatePicker
                  inputFormat="YYYY-MM-DD"
                  label="Start Date *"
                  value={allocateBudget?.startDate}
                  onChange={(e) => {
                    if (!e) {
                      handleBudgetChanges(
                        `startDate`,
                        new Date(
                          new Date().getFullYear(),
                          new Date().getMonth(),
                          1,
                        ),
                      )
                    } else {
                      handleBudgetChanges(`startDate`, new Date(e))
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            border: `solid  ${colors.appColor} 1px`,
                          },
                          '&.Mui-focused fieldset': {
                            border: `solid ${colors.appColor} 1px`,
                          },
                          '& fieldset': {
                            borderColor: `rgba(40, 32, 61, 0.5)`,
                            borderRadius: `10px`,
                          },
                          '& svg': { color: `#19C9D1` },
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: `rgba(40, 32, 61, 0.5)`,
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormControl fullWidth>
                <DatePicker
                  inputFormat="YYYY-MM-DD"
                  label="End Date *"
                  value={
                    allocateBudget?.endDate ||
                    new Date(
                      new Date().getFullYear(),
                      new Date().getMonth() + 1,
                      0,
                    )
                  }
                  onChange={(e) => {
                    if (!e) {
                      handleBudgetChanges(
                        `endDate`,
                        new Date(
                          new Date().getFullYear(),
                          new Date().getMonth() + 1,
                          0,
                        ),
                      )
                    } else {
                      handleBudgetChanges(`endDate`, new Date(e))
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            border: `solid  ${colors.appColor} 1px`,
                          },
                          '&.Mui-focused fieldset': {
                            border: `solid ${colors.appColor} 1px`,
                          },
                          '& fieldset': {
                            borderColor: `rgba(40, 32, 61, 0.5)`,
                            borderRadius: `10px`,
                          },
                          '& svg': { color: `#19C9D1` },
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: `rgba(40, 32, 61, 0.5)`,
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                sx={{
                  marginY: `10px`,
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      border: `solid ${colors?.appColor} 2px`,
                    },
                    '&.Mui-focused fieldset': {
                      border: `solid ${colors?.appColor} 2px`,
                    },
                    '& fieldset': {
                      borderRadius: `10px`,
                    },
                  },
                }}
                rows={4}
                multiline={true}
                id="outlined-basic"
                label="Description "
                name="description"
                value={allocateBudget?.description}
                variant="outlined"
                size="small"
                onChange={(e) =>
                  handleBudgetChanges(`description`, e?.target?.value)
                }
              />
            </FormControl>
          </Grid>
        </Grid>

        <Box
          sx={{
            display: `flex`,
            flexDirection: {
              xs: `column-reverse`,
              sm: `row-reverse`,
            },
            gap: `10px`,
            marginY: `10px`,
          }}
        >
          <PrimaryButton
            title="Allocate"
            onClick={validateForm}
            isLoading={isLoading}
          />
          <SecondaryButton title="Cancel" onClick={onClose} />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default AddAllocateBudget
