import React, { useState, useContext, useCallback } from 'react'
import { Box, Typography, Grid } from '@mui/material'
import { SnackbarContext } from 'app/Components/SnackbarProvider'
import api from 'app/AxiosInstance'

// Skeleton Loading
import SkeletonLoading from './SkeletonLoading'

// Common Components
import PrimaryButton from 'app/Components/Common/PrimaryButton'
import AddClient from './AddClient'
import NotApplicable from 'app/Components/Common/NotApplicable'

// Images
import userAvatar from 'app/Assets/userAvatar.jpg'

// Icons
import { HiEmojiSad } from 'react-icons/hi'

//Client Detail
import ClientDetail from './ClientDetail'

// Redux Hooks
import { useSelector } from 'react-redux'

// Third Party Api's
import { useQuery } from 'react-query'

const Clients = () => {
  const colors = useSelector((state) => state.colors)
  const openSnackbar = useContext(SnackbarContext)
  const [addClientOpen, setAddClientOpen] = useState(false)
  const closeAddClient = useCallback(() => {
    setAddClientOpen((prev) => !prev)
  }, [addClientOpen])

  const {
    data: clients,
    isFetching,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery(
    `allClients`,
    async () => {
      const { data } = await api.get(`/clients`)
      if (!data) undefined
      return data.data
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onError: (error) => {
        openSnackbar(
          error.response ? error.response.data.message : error.message,
          `error`,
        )
      },
    },
  )

  const [clientDetailOpen, setClientDetailOpen] = useState(false)
  const [idToGetSingleClient, setIdToGetSingleClient] = useState()

  const closeClientDetail = useCallback(() => {
    setClientDetailOpen((prev) => !prev)
  }, [clientDetailOpen])

  const refetchCallback = useCallback(() => {
    refetch()
  }, [clients])

  const handleClientClick = (id) => {
    setIdToGetSingleClient(id)
    setClientDetailOpen((prev) => !prev)
  }

  if (isFetching || isRefetching || isLoading) {
    return [1, 2, 3, 4, 5].map(() => SkeletonLoading)
  }

  return (
    <Box
      sx={{
        display: `flex`,
        flexDirection: `column`,
        gap: `10px`,
        paddingLeft: `20px`,
        paddingRight: `20px`,
        paddingBottom: `20px`,
        minHeight: `100vh`,
        backgroundColor: `rgba(40, 32, 61, 0.07)`,
      }}
    >
      {addClientOpen && (
        <AddClient
          open={addClientOpen}
          onClose={closeAddClient}
          refetch={refetch}
        />
      )}

      {clientDetailOpen && (
        <ClientDetail
          open={clientDetailOpen}
          onClose={closeClientDetail}
          id={idToGetSingleClient}
          refetchCallback={refetchCallback}
        />
      )}

      <Box
        sx={{
          display: `flex`,
          justifyContent: `flex-end`,
        }}
      >
        <PrimaryButton
          title="+ Add Client"
          onClick={() => setAddClientOpen((prev) => !prev)}
        />
      </Box>

      {!clients ? (
        <Box
          sx={{
            display: `flex`,
            justifyContent: `center`,
            alignItems: `center`,
            gap: `5px`,
          }}
        >
          <Typography
            sx={{ fontFamily: `Inter`, fontWeight: 500, fontSize: `16px` }}
          >
            No Data Found!
          </Typography>
          <HiEmojiSad
            style={{ color: colors.appColor, width: `30px`, height: `30px` }}
          />
        </Box>
      ) : (
        clients.map((client) => (
          <Box
            key={client._id}
            sx={{
              marginTop: `5px`,
              borderRadius: `10px`,
              backgroundColor: `white`,
              padding: `10px`,
              '&: hover': {
                cursor: `pointer`,
              },
            }}
            onClick={() => handleClientClick(client._id)}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    width: `100%`,
                    height: `100%`,
                    display: `flex`,
                    alignItems: `center`,
                    gap: `10px`,
                    borderRadius: `10px`,
                    padding: `20px`,
                    backgroundColor: `rgba(25, 201, 209, 0.2)`,
                  }}
                >
                  <img
                    src={
                      client?.profilePicture?.url
                        ? client.profilePicture.url
                        : userAvatar
                    }
                    style={{
                      width: `70px`,
                      height: `70px`,
                      borderRadius: `50%`,
                    }}
                  />
                  <Box
                    sx={{
                      display: `flex`,
                      flexDirection: `column`,
                    }}
                  >
                    <Box
                      sx={{
                        display: `flex`,
                        gap: `5px`,
                        alignItems: `center`,
                      }}
                    >
                      {client.name ? (
                        <Typography
                          sx={{
                            fontFamily: `Inter`,
                            fontSize: 20,
                            fontWeight: 600,
                            color: `#28203D`,
                            whiteSpace: `wrap`,
                            wordBreak: `break-word`,
                          }}
                        >
                          {client.name}
                        </Typography>
                      ) : (
                        NotApplicable
                      )}
                    </Box>
                    <Typography
                      sx={{
                        fontFamily: `Inter`,
                        fontSize: 14,
                        fontWeight: 400,
                        wordBreak: `break-word`,
                        whiteSpace: `wrap`,
                      }}
                    >
                      {client?.email ? client.email : NotApplicable}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={8}>
                <Box
                  sx={{
                    width: `100%`,
                    height: `100%`,
                    display: `flex`,
                    flexDirection: `column`,
                    gap: `20px`,
                  }}
                >
                  <Box sx={{ display: `flex`, flexDirection: `column` }}>
                    <Typography
                      sx={{
                        fontFamily: `Inter`,
                        fontSize: 14,
                        fontWeight: 400,
                        color: `rgba(40, 32, 61, 0.7)`,
                      }}
                    >
                      Address
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: `Inter`,
                        fontSize: 14,
                        fontWeight: 600,
                        whiteSpace: `wrap`,
                        wordBreak: `break-word`,
                      }}
                    >
                      {client?.address
                        ? `${client.address}${
                            client?.country ? `, ${client.country}, ` : ``
                          }${client?.state ? `${client.state}` : ``}`
                        : NotApplicable}
                    </Typography>
                  </Box>
                  <Box sx={{ display: `flex`, flexDirection: `column` }}>
                    <Typography
                      sx={{
                        fontFamily: `Inter`,
                        fontSize: 14,
                        fontWeight: 400,
                        color: `rgba(40, 32, 61, 0.7)`,
                      }}
                    >
                      {client?.projects?.length > 1 ? `Project(s)` : `Project`}
                    </Typography>

                    <Grid container spacing={2}>
                      {client?.projects?.length > 0 ? (
                        client.projects.map((project) => {
                          return (
                            <Grid key={project._id} item xs={12} md={6}>
                              <Box
                                sx={{
                                  display: `flex`,
                                  gap: `5px`,
                                  alignItems: `center`,
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: `Inter`,
                                    fontWeight: 600,
                                    fontSize: `12px`,
                                  }}
                                >
                                  {project?.name ? project.name : NotApplicable}
                                </Typography>

                                <Box
                                  sx={{
                                    height: `max-content`,
                                    borderRadius: `20px`,
                                    paddingY: `2px`,
                                    paddingX: `10px`,
                                    display: `flex`,
                                    justifyContent: `center`,
                                    alignItems: `center`,
                                    backgroundColor:
                                      project.status ===
                                      `Gathering Requirements`
                                        ? `rgba(125, 77, 236, 0.1)`
                                        : project.status === `Planning`
                                        ? `rgba(37, 187, 251, 0.12)`
                                        : project.status === `In-Progress`
                                        ? `rgba(249, 171, 6, 0.12)`
                                        : project.status === `Testing`
                                        ? `rgba(198, 71, 242, 0.1)`
                                        : project.status === `Completed`
                                        ? `rgba(77, 198, 64, 0.12)`
                                        : project.status === `On-Hold`
                                        ? `rgba(255, 114, 54, 0.12)`
                                        : project.status === `Canceled`
                                        ? `rgba(255, 72, 94, 0.1)`
                                        : project.status === `Maintenance` &&
                                          ` rgba(25, 201, 209, 0.12)`,
                                    color:
                                      project.status ===
                                      `Gathering Requirements`
                                        ? `#7D4DEC`
                                        : project.status === `Planning`
                                        ? `#25BBFB`
                                        : project.status === `In-Progress`
                                        ? `#F9AB06`
                                        : project.status === `Testing`
                                        ? `#C647F2`
                                        : project.status === `Completed`
                                        ? `#4DC640`
                                        : project.status === `On-Hold`
                                        ? `#FF7236`
                                        : project.status === `Canceled`
                                        ? `#FF485E`
                                        : project.status === `Maintenance` &&
                                          `#19C9D1`,
                                  }}
                                >
                                  <Typography
                                    noWrap
                                    sx={{
                                      textAlign: `center`,
                                      whiteSpace: `wrap`,
                                      fontFamily: `Inter`,
                                      fontWeight: 500,
                                      fontSize: `12px`,
                                    }}
                                  >
                                    {project?.status
                                      ? project.status
                                      : NotApplicable}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                          )
                        })
                      ) : (
                        <Grid item xs={12} sm={6}>
                          {NotApplicable}
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        ))
      )}
    </Box>
  )
}

export default Clients
