import React from 'react'
import {
  Box,
  Grid,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material'
import Image from 'mui-image'
import userAvatar from 'app/Assets/userAvatar.jpg'
import { withStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableRow: {
    height: 80,
  },
  tableHead: {
    height: 30,
  },
  tableCell: {
    padding: `0px 16px`,
  },
})

const StyledTableRow = withStyles(() => ({
  root: {
    height: 30,
  },
}))(TableRow)

const StyledTableCell = withStyles(() => ({
  root: {
    padding: `0px 16px`,
  },
}))(TableCell)

const AttendanceByDate = () => {
  const classes = useStyles()

  return (
    <Box
      sx={{
        display: `flex`,
        minHeight: `100vh`,
        backgroundColor: `rgba(40, 32, 61, 0.07)`,
        padding: { xs: `10px`, md: `20px` },
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} md={7}>
          <Box
            sx={{
              width: `100%`,
              height: `100%`,
              backgroundColor: `white`,
              borderRadius: `10px`,
              display: `flex`,
              flexDirection: `column`,
            }}
          >
            <Box sx={{ display: `flex`, padding: `10px` }}>
              <Typography
                sx={{
                  fontFamily: `Inter`,
                  fontSize: `16px`,
                  fontWeight: 600,
                }}
              >
                Present
              </Typography>
            </Box>
            <TableContainer
              className="scrollbar-hide"
              sx={{
                backgroundColor: `white`,
                borderTop: `solid 1px rgba(40, 32, 61, 0.15)`,
                height: `100%`,
              }}
            >
              <Table
                sx={{ minWidth: 650 }}
                aria-label="a dense table"
                className={classes.table}
                stickyHeader
              >
                <TableHead>
                  <TableRow className={classes.tableHead}>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography
                        sx={{
                          fontFamily: `Inter`,
                          fontWeight: 600,
                          fontSize: `12px`,
                          color: `#28203D`,
                          opacity: 0.4,
                        }}
                      >
                        NAME
                      </Typography>
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <Box
                        sx={{
                          display: `flex`,
                          flexDirection: `row`,
                          gap: `5px`,
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: `Inter`,
                            fontWeight: 600,
                            fontSize: `12px`,
                            color: `#28203D`,
                            opacity: 0.4,
                          }}
                        >
                          TIME IN
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: `12px`,
                          color: `#28203D`,
                          opacity: 0.4,
                        }}
                      >
                        TIME OUT
                      </Typography>
                    </TableCell>

                    <TableCell align="right" className={classes.tableCell}>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: `12px`,
                          color: `#28203D`,
                          opacity: 0.4,
                        }}
                      >
                        DURATION
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <StyledTableRow
                    className={classes.tableRow}
                    sx={{
                      '&:hover': {
                        cursor: `pointer`,
                        background: `linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(250,249,253,1) 15%, rgba(250,249,253,1) 85%, rgba(255,255,255,1) 100%)`,
                      },
                    }}
                  >
                    <StyledTableCell align="left">
                      <Typography sx={{ whiteSpace: `nowrap` }}>
                        Name
                      </Typography>
                    </StyledTableCell>

                    <StyledTableCell align="left">
                      <Box
                        sx={{
                          display: `flex`,
                          flexDirection: `row`,
                          alignItems: `center`,
                        }}
                      >
                        <Box
                          sx={{
                            width: `40px`,
                            height: `40px`,
                            borderRadius: `25px`,
                            display: `flex`,
                            justifyContent: `center`,
                            marginLeft: `-10px`,
                            borderWidth: `3px`,
                            borderStyle: `solid`,
                            borderColor: `white`,
                          }}
                        >
                          <Image
                            src={userAvatar}
                            alt="Profile Picture"
                            style={{ borderRadius: `100%` }}
                          />
                        </Box>
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>

        <Grid item xs={12} md={5}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box
                sx={{
                  width: `100%`,
                  minHeight: `300px`,
                  height: `max-content`,
                  backgroundColor: `white`,
                  borderRadius: `10px`,
                  display: `flex`,
                  flexDirection: `column`,
                }}
              >
                <Box sx={{ display: `flex`, padding: `10px` }}>
                  <Typography
                    sx={{
                      fontFamily: `Inter`,
                      fontSize: `16px`,
                      fontWeight: 600,
                    }}
                  >
                    Leave
                  </Typography>
                </Box>
                <TableContainer
                  className="scrollbar-hide"
                  sx={{
                    backgroundColor: `white`,
                    borderTop: `solid 1px rgba(40, 32, 61, 0.15)`,
                    height: `100%`,
                  }}
                >
                  <Table
                    sx={{ minWidth: 650 }}
                    aria-label="a dense table"
                    className={classes.table}
                    stickyHeader
                  >
                    <TableHead>
                      <TableRow className={classes.tableHead}>
                        <TableCell align="left" className={classes.tableCell}>
                          <Typography
                            sx={{
                              fontFamily: `Inter`,
                              fontWeight: 600,
                              fontSize: `12px`,
                              color: `#28203D`,
                              opacity: 0.4,
                            }}
                          >
                            NAME
                          </Typography>
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                          <Box
                            sx={{
                              display: `flex`,
                              flexDirection: `row`,
                              gap: `5px`,
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: `Inter`,
                                fontWeight: 600,
                                fontSize: `12px`,
                                color: `#28203D`,
                                opacity: 0.4,
                              }}
                            >
                              TYPE
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: `12px`,
                              color: `#28203D`,
                              opacity: 0.4,
                            }}
                          >
                            ACTION
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      <StyledTableRow
                        className={classes.tableRow}
                        sx={{
                          '&:hover': {
                            cursor: `pointer`,
                            background: `linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(250,249,253,1) 15%, rgba(250,249,253,1) 85%, rgba(255,255,255,1) 100%)`,
                          },
                        }}
                      >
                        <StyledTableCell align="left">
                          <Typography sx={{ whiteSpace: `nowrap` }}>
                            Name
                          </Typography>
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          <Box
                            sx={{
                              display: `flex`,
                              flexDirection: `row`,
                              alignItems: `center`,
                            }}
                          >
                            <Box
                              sx={{
                                width: `40px`,
                                height: `40px`,
                                borderRadius: `25px`,
                                display: `flex`,
                                justifyContent: `center`,
                                marginLeft: `-10px`,
                                borderWidth: `3px`,
                                borderStyle: `solid`,
                                borderColor: `white`,
                              }}
                            >
                              <Image
                                src={userAvatar}
                                alt="Profile Picture"
                                style={{ borderRadius: `100%` }}
                              />
                            </Box>
                          </Box>
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  width: `100%`,
                  minHeight: `300px`,
                  height: `max-content`,
                  backgroundColor: `white`,
                  borderRadius: `10px`,
                  display: `flex`,
                  flexDirection: `column`,
                }}
              >
                <Box sx={{ display: `flex`, padding: `10px` }}>
                  <Typography
                    sx={{
                      fontFamily: `Inter`,
                      fontSize: `16px`,
                      fontWeight: 600,
                    }}
                  >
                    Work From Home
                  </Typography>
                </Box>
                <TableContainer
                  className="scrollbar-hide"
                  sx={{
                    backgroundColor: `white`,
                    borderTop: `solid 1px rgba(40, 32, 61, 0.15)`,
                    height: `100%`,
                  }}
                >
                  <Table
                    sx={{ minWidth: 650 }}
                    aria-label="a dense table"
                    className={classes.table}
                    stickyHeader
                  >
                    <TableHead>
                      <TableRow className={classes.tableHead}>
                        <TableCell align="left" className={classes.tableCell}>
                          <Typography
                            sx={{
                              fontFamily: `Inter`,
                              fontWeight: 600,
                              fontSize: `12px`,
                              color: `#28203D`,
                              opacity: 0.4,
                            }}
                          >
                            NAME
                          </Typography>
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                          <Box
                            sx={{
                              display: `flex`,
                              flexDirection: `row`,
                              gap: `5px`,
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: `Inter`,
                                fontWeight: 600,
                                fontSize: `12px`,
                                color: `#28203D`,
                                opacity: 0.4,
                              }}
                            >
                              TYPE
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: `12px`,
                              color: `#28203D`,
                              opacity: 0.4,
                            }}
                          >
                            ACTION
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      <StyledTableRow
                        className={classes.tableRow}
                        sx={{
                          '&:hover': {
                            cursor: `pointer`,
                            background: `linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(250,249,253,1) 15%, rgba(250,249,253,1) 85%, rgba(255,255,255,1) 100%)`,
                          },
                        }}
                      >
                        <StyledTableCell align="left">
                          <Typography sx={{ whiteSpace: `nowrap` }}>
                            Name
                          </Typography>
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          <Box
                            sx={{
                              display: `flex`,
                              flexDirection: `row`,
                              alignItems: `center`,
                            }}
                          >
                            <Box
                              sx={{
                                width: `40px`,
                                height: `40px`,
                                borderRadius: `25px`,
                                display: `flex`,
                                justifyContent: `center`,
                                marginLeft: `-10px`,
                                borderWidth: `3px`,
                                borderStyle: `solid`,
                                borderColor: `white`,
                              }}
                            >
                              <Image
                                src={userAvatar}
                                alt="Profile Picture"
                                style={{ borderRadius: `100%` }}
                              />
                            </Box>
                          </Box>
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  width: `100%`,
                  minHeight: `300px`,
                  height: `max-content`,
                  backgroundColor: `white`,
                  borderRadius: `10px`,
                  display: `flex`,
                  flexDirection: `column`,
                }}
              >
                <Box sx={{ display: `flex`, padding: `10px` }}>
                  <Typography
                    sx={{
                      fontFamily: `Inter`,
                      fontSize: `16px`,
                      fontWeight: 600,
                    }}
                  >
                    Absent
                  </Typography>
                </Box>
                <TableContainer
                  className="scrollbar-hide"
                  sx={{
                    backgroundColor: `white`,
                    borderTop: `solid 1px rgba(40, 32, 61, 0.15)`,
                    height: `100%`,
                  }}
                >
                  <Table
                    sx={{ minWidth: 650 }}
                    aria-label="a dense table"
                    className={classes.table}
                    stickyHeader
                  >
                    <TableHead>
                      <TableRow className={classes.tableHead}>
                        <TableCell align="left" className={classes.tableCell}>
                          <Typography
                            sx={{
                              fontFamily: `Inter`,
                              fontWeight: 600,
                              fontSize: `12px`,
                              color: `#28203D`,
                              opacity: 0.4,
                            }}
                          >
                            NAME
                          </Typography>
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                          <Box
                            sx={{
                              display: `flex`,
                              flexDirection: `row`,
                              gap: `5px`,
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: `Inter`,
                                fontWeight: 600,
                                fontSize: `12px`,
                                color: `#28203D`,
                                opacity: 0.4,
                              }}
                            >
                              PHONE
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      <StyledTableRow
                        className={classes.tableRow}
                        sx={{
                          '&:hover': {
                            cursor: `pointer`,
                            background: `linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(250,249,253,1) 15%, rgba(250,249,253,1) 85%, rgba(255,255,255,1) 100%)`,
                          },
                        }}
                      >
                        <StyledTableCell align="left">
                          <Typography sx={{ whiteSpace: `nowrap` }}>
                            Name
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Typography sx={{ whiteSpace: `nowrap` }}>
                            Name
                          </Typography>
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default AttendanceByDate
