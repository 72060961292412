import React, { useState, useContext, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  Divider,
  Grid,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core'
import { SnackbarContext } from 'app/Components/SnackbarProvider'
import { useMutation } from 'react-query'
import api from 'app/AxiosInstance'

// Import Images
import cross from 'app/Assets/cross.svg'

// Common Components
import InputField from '../../../Common/InputField'
import PrimaryButton from 'app/Components/Common/PrimaryButton'
import SecondaryButton from 'app/Components/Common/SecondaryButton'
import MySelectionField from 'app/Components/Common/MySelectionField'

// Third party Api's
import { Country, State } from 'country-state-city'
import { motion } from 'framer-motion'

const statusOptions = [
  {
    id: `ACTIVE`,
    name: `Active`,
  },
  {
    id: `INACTIVE`,
    name: `Inactive`,
  },
]

const AddVendor = (props) => {
  const { open, onClose, refetch } = props

  const openSnackbar = useContext(SnackbarContext)

  const [addVendorState, setAddVendorState] = useState({
    name: null,
    phone: null,
    email: null,
    country: `Pakistan`,
    state: null,
    address: null,
    status: `ACTIVE`,
  })

  const addVendor = async (payload) => {
    const response = await api.post(`/vendors`, payload)
    return response
  }

  const { mutate: addVendorMutation, isLoading: isMutatingAddVendor } =
    useMutation(addVendor, {
      onSuccess: () => {
        onClose()
        refetch()
        openSnackbar(`Added Successfull`, `success`)
      },
      onError: (error) => {
        openSnackbar(
          error?.response ? error.response.data.message : error.message,
          `error`,
        )
      },
    })

  const handleSubmitForm = () => {
    if (isMutatingAddVendor) {
      return // Do nothing if either mutation is in progress
    }

    if (Object.keys(addVendorState).length === 0) {
      openSnackbar(`Ops!! Empty Form.`, `error`)
      return
    }

    const payload = Object.fromEntries(
      Object.entries(addVendorState).filter(
        (arr) => arr[1] !== `` && arr[1] !== null,
      ),
    )

    addVendorMutation(payload)
  }

  const handleInputChange = (key, val) => {
    if (isMutatingAddVendor) return
    setAddVendorState((prev) => ({ ...prev, [key]: val }))
  }

  const getSxProps = () => {
    return {
      '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
          border: `solid rgba(40, 32, 61, 0.25) 1px`,
        },
        '&.Mui-focused fieldset': {
          border: `solid rgba(40, 32, 61, 0.25) 1px`,
        },
        '& fieldset': {
          borderColor: `rgba(40, 32, 61, 0.25)`,
          borderRadius: `10px`,
        },
      },
      '& .MuiInputLabel-root': {
        '&.Mui-focused': {
          color: `rgba(40, 32, 61, 0.5)`,
        },
      },
    }
  }

  const getInputLabelProps = () => {
    return {
      style: {
        color: `rgba(40, 32, 61, 0.5)`,
      },
    }
  }

  const getSelectionFieldLabelColor = () => {
    return {
      color: `rgba(40, 32, 61, 0.5)`,
    }
  }

  const allCountries = Country.getAllCountries()
  const countriesArray = allCountries.map((country) => country.name)

  const [statesArray, setStatesArray] = useState([])

  useEffect(() => {
    // Make State NULL if exist :(because)----> "If user select country then select state against that country and then what if he or she select the other country and but does not select state against new selected"
    if (addVendorState?.country) {
      const foundSelectedCountry = allCountries.find(
        (country) => country.name === addVendorState.country,
      )

      if (addVendorState?.state) {
        setAddVendorState((prev) => ({ ...prev, state: null }))
      }

      const statesArray = State.getStatesOfCountry(foundSelectedCountry.isoCode)

      const statesNames = statesArray.map((state) => state.name)

      setStatesArray(statesNames)
    }
  }, [addVendorState.country])

  const [status, setStatus] = useState(`ACTIVE`)

  return (
    <motion.div
      initial={{ opacity: 0, y: -80 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5, delay: 0.1 }}
    >
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        PaperProps={{
          sx: {
            borderRadius: `20px 20px 20px 20px`,
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            height: `max-content`,
            display: `flex`,
            flexDirection: `column`,
          }}
        >
          <Box
            sx={{
              width: `100%`,
              display: `flex`,
              justifyContent: `space-between`,
              alignItems: `center`,
              flexDirection: `row`,
              paddingBottom: `10px`,
            }}
          >
            <Typography
              sx={{
                fontFamily: `Inter`,
                fontSize: {
                  lg: 15,
                  md: 13,
                  sm: 10,
                  xs: 8,
                },
              }}
            >
              Add Business
            </Typography>
            <Box
              sx={{
                width: `36px`,
                height: `36px`,
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`,
                padding: `11px`,
                '&:hover': {
                  cursor: `pointer`,
                  opacity: 0.8,
                },
              }}
              onClick={onClose}
            >
              <img src={cross} alt="cross" width="13.87px" height="14px" />
            </Box>
          </Box>
          <Divider sx={{ width: `100%` }} />
          {/* <AnimatePresence>
            <motion.div
              initial={{ opacity: 0, x: -500, rotate: -360 }}
              animate={{ opacity: 1, x: 0, rotate: 0 }}
              exit={{ opacity: 0, x: -100, rotate: -360 }}
              transition={{ duration: 1, delay: 0.1, ease: `easeInOut` }}
            >
              <Box
                sx={{
                  display: `flex`,
                  justifyContent: `center`,
                  marginTop: `10px`,
                }}
              >
                <Box
                  sx={{
                    width: `100px`,
                    height: `100px`,
                    alignSelf: `center`,
                    borderRadius: `50%`,
                    display: `flex`,
                    justifyContent: `center`,
                    alignItems: `center`,
                    backgroundColor: `rgba(40, 32, 61, 0.07)`,
                    position: `relative`,
                    // border: profilePicture ? `solid 1px red` : `white`,
                  }}
                >
                  <img
                    src={profilePic ? profilePic : userIcon}
                    alt="user"
                    style={{
                      width: profilePic ? `100%` : `50px`,
                      height: profilePic ? `100%` : `50px`,
                      borderRadius: `100%`,
                    }}
                  />
                  {profilePic ? (
                    <Box
                      sx={{
                        position: `absolute`,
                        bottom: 0,
                        right: 0,
                        backgroundColor: `#FF485E`,
                        color: `white`,
                        width: `30px`,
                        height: `30px`,
                        display: `flex`,
                        justifyContent: `center`,
                        alignItems: `center`,
                        borderRadius: `50%`,
                        border: `solid white 3px`,
                        zIndex: 2,
                        '&:hover': {
                          cursor: !isMutatingPicture && `pointer`,
                          width: `35px`,
                          height: `35px`,
                        },
                      }}
                      onClick={handleDeleteImage}
                    >
                      x
                    </Box>
                  ) : (
                    <label htmlFor="add-vendor-image-picker">
                      <Box
                        sx={{
                          position: `absolute`,
                          bottom: 0,
                          right: 0,
                          backgroundColor: `#19C9D1`,
                          color: `white`,
                          width: `30px`,
                          height: `30px`,
                          display: `flex`,
                          justifyContent: `center`,
                          alignItems: `center`,
                          borderRadius: `50%`,
                          border: `solid white 3px`,
                          zIndex: 2,
                          '&:hover': {
                            cursor: `pointer`,
                            width: `35px`,
                            height: `35px`,
                          },
                        }}
                      >
                        <input
                          accept=".jpg,.jpeg,.png,.gif,.JPG,.JPEG,.PNG,.GIF"
                          id="add-vendor-image-picker"
                          type="file"
                          style={{ display: `none` }}
                          onChange={handleImagePick}
                        />
                        +
                      </Box>
                    </label>
                  )}
                </Box>
              </Box>
            </motion.div>
          </AnimatePresence> */}
        </DialogTitle>

        <DialogContent
          sx={{
            display: `flex`,
            flexDirection: `column`,
            gap: `20px`,
            overflowY: `auto`,
          }}
        >
          <Grid
            container
            rowSpacing={2}
            columnSpacing={4}
            sx={{ paddingTop: `10px` }}
          >
            <Grid item xs={12} sm={6}>
              <motion.div
                initial={{ opacity: 0, y: -40 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.5, delay: 0.1 }}
              >
                <InputField
                  type="text"
                  label="Name"
                  defaultValue=""
                  value={addVendorState.name}
                  onChange={(evt) =>
                    handleInputChange(`name`, evt.target.value)
                  }
                  readOnly={false}
                  inputLabelProps={getInputLabelProps()}
                  sxProps={getSxProps()}
                />
              </motion.div>
            </Grid>

            <Grid item xs={12} sm={6}>
              <motion.div
                initial={{ opacity: 0, y: -40 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.5, delay: 0.1 }}
              >
                <InputField
                  label="Phone Number"
                  inputLabelProps={getInputLabelProps()}
                  sxProps={getSxProps()}
                  onChange={(evt) =>
                    handleInputChange(`phone`, evt.target.value)
                  }
                />
              </motion.div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <motion.div
                initial={{ opacity: 0, y: -40 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.5, delay: 0.1 }}
              >
                <InputField
                  type="text"
                  label="Email"
                  defaultValue=""
                  onChange={(evt) =>
                    handleInputChange(`email`, evt.target.value)
                  }
                  readOnly={false}
                  inputLabelProps={getInputLabelProps()}
                  sxProps={getSxProps()}
                />
              </motion.div>
            </Grid>

            <Grid item xs={12} sm={6}>
              <motion.div
                initial={{ opacity: 0, y: -40 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.5, delay: 0.1 }}
              >
                <MySelectionField
                  label="Country"
                  options={countriesArray}
                  value={addVendorState.country}
                  disabled={false}
                  onChange={(evt) =>
                    handleInputChange(`country`, evt.target.value)
                  }
                  labelColor={getSelectionFieldLabelColor()}
                  sxProps={getSxProps()}
                />
              </motion.div>
            </Grid>

            <Grid item xs={12} sm={6}>
              <motion.div
                initial={{ opacity: 0, y: -40 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.5, delay: 0.1 }}
              >
                <MySelectionField
                  label="State"
                  options={statesArray}
                  value={addVendorState.state}
                  disabled={false}
                  onChange={(evt) =>
                    handleInputChange(`state`, evt.target.value)
                  }
                  labelColor={getSelectionFieldLabelColor()}
                  sxProps={getSxProps()}
                />
              </motion.div>
            </Grid>

            <Grid item xs={12} sm={6}>
              <motion.div
                initial={{ opacity: 0, y: -40 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.5, delay: 0.1 }}
              >
                <InputField
                  type="text"
                  label="Address"
                  defaultValue=""
                  onChange={(evt) =>
                    handleInputChange(`address`, evt.target.value)
                  }
                  readOnly={false}
                  inputLabelProps={getInputLabelProps()}
                  sxProps={getSxProps()}
                />
              </motion.div>
            </Grid>

            <Grid item xs={12} sm={6}>
              <motion.div
                initial={{ opacity: 0, y: -40 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.5, delay: 0.1 }}
              >
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    backgroundColor: `white`,
                    borderRadius: `10px`,
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        border: `solid rgba(40, 32, 61, 0.5), 1px`,
                      },
                      '&.Mui-focused fieldset': {
                        border: `solid rgba(40, 32, 61, 0.5) 1px`,
                      },
                      '& fieldset': {
                        borderColor: `rgba(40, 32, 61, 0.25)`,
                        borderRadius: `10px`,
                      },
                    },
                    '& .MuiInputLabel-root': {
                      '&.Mui-focused': {
                        color: `rgba(40, 32, 61, 0.25)`,
                      },
                    },
                  }}
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{
                      color: `rgba(40, 32, 61, 0.5)`,
                    }}
                  >
                    Status *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="status"
                    label="Status *"
                    value={status || `ACTIVE`}
                    onChange={(e) => setStatus(e?.target?.value)}
                  >
                    {statusOptions &&
                      statusOptions?.map((status) => (
                        <MenuItem value={status?.id} key={status?.id}>
                          {status?.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </motion.div>
            </Grid>
          </Grid>
        </DialogContent>

        <motion.div
          initial={{ opacity: 0, x: -1000 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -20 }}
          transition={{ duration: 1, delay: 0.1 }}
        >
          <Box
            sx={{
              display: `flex`,
              flexDirection: { xs: `column`, sm: `row` },
              justifyContent: `flex-end`,
              gap: `10px`,
              padding: `20px`,
            }}
          >
            <SecondaryButton title="Cancel" onClick={() => onClose()} />
            <PrimaryButton
              title={isMutatingAddVendor ? `Adding...` : `Add Business`}
              isLoading={isMutatingAddVendor}
              onClick={() => handleSubmitForm()}
            />
          </Box>
        </motion.div>
        <Divider />
      </Dialog>
    </motion.div>
  )
}

export default React.memo(AddVendor)
