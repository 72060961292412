import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Popover,
  TextField,
  Typography,
} from '@material-ui/core'
import api from 'app/AxiosInstance'
import PrimaryButton from 'app/Components/Common/PrimaryButton'
import { SnackbarContext } from 'app/Components/SnackbarProvider'
import { baseUrl } from 'app/Utils/Config'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import AddAllocateBudget from '../AddAllocateBudget'
import moment from 'moment'
import Image from 'mui-image'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import actionIcon from '../.../../../../Assets/optionIcon.svg'
import UpdateBudget from '../UpdateBudget'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import PrependRs from 'app/Utils/PrependRs'
import { setCurrentAccount } from 'app/Redux/actions'

const AccountDetail = () => {
  const token = localStorage.getItem(`AuthToken`)
  const dispatch = useDispatch()
  const { accountId } = useParams()
  const openSnackbar = useContext(SnackbarContext)
  const history = useHistory()
  const colors = useSelector((state) => state?.colors)

  const accountsData = useSelector((state) => state?.account?.accountsList)

  const [addBudgetModal, setAddBudgetModal] = useState()

  const [isDeleteBudget, setIsDeleteBudget] = useState(false)
  const [budgetId, setBudgetId] = useState(``)

  const [updateBudgetModal, setUpdateBudgetModal] = useState(false)
  const [updateBudgetId, setUpdateBudgetId] = useState(``)
  const [anchorEl, setAnchorEl] = useState(null)

  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), 0, 1),
  )
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), 11, 31),
  )
  const [hasPayrollBudget, setHasPayrollBudget] = useState(false)

  const {
    isLoading,
    data: accountDetailData,
    refetch,
    isFetching,
    isError,
  } = useQuery(
    `getAccountDetailApi`,
    async () =>
      await api
        .get(
          `${baseUrl}/accounts/${accountId}?startDate=${moment(
            startDate,
          ).format(`YYYY-MM-DD`)}&endDate=${moment(endDate).format(
            `YYYY-MM-DD`,
          )}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then((res) => {
          return res?.data?.data
        }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        dispatch(setCurrentAccount(res))
      },
      onError: (error) => {
        openSnackbar(
          error?.response ? error?.response?.data?.message : error?.message,
          `error`,
        )
      },
    },
  )

  const { mutate: deleteBudget, isLoading: deleteBudgetLoader } = useMutation(
    async () =>
      await api
        .delete(`${baseUrl}/accounts/${accountId}/budget/${budgetId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data) {
            openSnackbar(`Budget deleted successfully`, `success`)
            setIsDeleteBudget(false)
          }
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onError: (error) => {
        openSnackbar(
          error?.response ? error?.response?.data?.message : error?.message,
          `error`,
        )
        setIsDeleteBudget(false)
      },
      onSuccess: () => {
        refetch()
      },
    },
  )

  const { mutate: payrollBudget, isLoading: payrollBudgetLoader } = useMutation(
    async () =>
      await api
        .post(
          `${baseUrl}/accounts/payroll/createBudget`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then((res) => {
          if (res.data) {
            openSnackbar(`Budget added successfully`, `success`)
          }
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onError: (error) => {
        openSnackbar(
          error?.response ? error?.response?.data?.message : error?.message,
          `error`,
        )
        setIsDeleteBudget(false)
      },
      onSuccess: () => {
        refetch()
      },
    },
  )

  useEffect(() => {
    if (
      accountDetailData?.budget?.some((data) => {
        return moment()
          .subtract(1, `months`)
          .isBetween(
            moment(data?.startDate),
            moment(data?.endDate),
            `days`,
            `[]`,
          )
      })
    ) {
      setHasPayrollBudget(!hasPayrollBudget)
    }
  }, [accountDetailData, isFetching, isLoading])
  return (
    <>
      {addBudgetModal && (
        <AddAllocateBudget
          open={addBudgetModal}
          onClose={() => setAddBudgetModal(!addBudgetModal)}
          refetchAccount={() => {
            refetch()
          }}
          accountData={accountsData}
        />
      )}

      <Popover
        disableEnforceFocus
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        sx={{
          '& .MuiPaper-root': {
            width: `120px`,
            height: `max-content`,
            display: `flex`,
            justifyContent: `center`,
            alignItems: `center`,
          },
        }}
        anchorOrigin={{
          vertical: `bottom`,
          horizontal: `left`,
        }}
        transformOrigin={{
          vertical: `top`,
          horizontal: `center`,
        }}
      >
        <Box sx={{ display: `flex`, flexDirection: `column`, width: `100%` }}>
          <Box
            sx={{
              width: `100%`,
              height: `36px`,
              paddingLeft: `16px`,
              display: `flex`,
              alignItems: `center`,
              '&:hover': {
                cursor: `pointer`,
                backgroundColor: `rgba(40, 32, 61, 0.05)`,
              },
            }}
            onClick={(e) => {
              e?.stopPropagation()
              setUpdateBudgetModal(!updateBudgetModal)
              setUpdateBudgetId(budgetId)
              setAnchorEl(null)
            }}
          >
            Edit
          </Box>

          <Box
            sx={{
              width: `100%`,
              height: `36px`,
              paddingLeft: `16px`,
              '&:hover': {
                cursor: `pointer`,
                backgroundColor: `rgba(40, 32, 61, 0.05)`,
              },
              display: `flex`,
              alignItems: `center`,
              backgroundColor: `white`,
              color: `black`,
            }}
            onClick={(e) => {
              e?.stopPropagation()
              setIsDeleteBudget(!isDeleteBudget)
              setAnchorEl(null)
            }}
          >
            Delete
          </Box>
        </Box>
      </Popover>

      {isDeleteBudget && (
        <Dialog open={isDeleteBudget}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this budget?
          </DialogContent>
          <DialogActions>
            <Box
              sx={{
                display: `flex`,
                flexDirection: `row`,
                gap: `10px`,
                marginBottom: `10px`,
              }}
            >
              <Box
                sx={{
                  bgcolor: `rgba(40, 32, 61, 0.07)`,
                  color: `black`,
                  width: `85px`,
                  height: `36px`,
                  display: `flex`,
                  justifyContent: `center`,
                  alignItems: `center`,
                  '&:hover': { cursor: `pointer`, opacity: 0.8 },
                }}
                onClick={() => setIsDeleteBudget(false)}
              >
                No
              </Box>
              <Box
                sx={{
                  bgcolor: `#FF485E`,
                  color: `white`,
                  width: `85px`,
                  height: `36px`,
                  display: `flex`,
                  justifyContent: `center`,
                  alignItems: `center`,
                  '&:hover': { cursor: `pointer`, opacity: 0.8 },
                }}
                onClick={() => deleteBudget()}
              >
                {deleteBudgetLoader ? (
                  <CircularProgress
                    size={25}
                    color="secondary"
                    style={{ position: `absolute`, color: `white` }}
                  />
                ) : (
                  `Yes`
                )}
              </Box>
            </Box>
          </DialogActions>
        </Dialog>
      )}

      {updateBudgetModal && (
        <UpdateBudget
          open={updateBudgetModal}
          onClose={() => setUpdateBudgetModal(!updateBudgetModal)}
          budgetData={accountDetailData?.budget}
          updateBudgetId={updateBudgetId}
          accountData={accountsData}
          refetchAccount={() => refetch()}
        />
      )}

      {!isError && (
        <Box
          sx={{
            display: `flex`,
            flexDirection: `column`,
            gap: `10px`,
            paddingX: { xs: `10px`, sm: `20px` },
            paddingBottom: `20px`,
            minHeight: `calc(100vh - 80px)`,
            backgroundColor: `rgba(40, 32, 61, 0.07)`,
          }}
        >
          <Box
            sx={{
              display: `flex`,
              flexDirection: { xs: `column`, sm: `row` },
              alignItems: `center`,
              justifyContent: `space-between`,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6} md={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <FormControl fullWidth>
                    <DatePicker
                      inputFormat="YYYY-MM-DD"
                      label="Start Date *"
                      value={
                        startDate || new Date(new Date().getFullYear(), 0, 1)
                      }
                      onChange={(e) => {
                        if (!e) {
                          // If date is removed, set startDate to the first date of the current year
                          setStartDate(new Date(new Date().getFullYear(), 0, 1))
                        } else {
                          setStartDate(new Date(e))
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          sx={{
                            // backgroundColor: `white`,
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                border: `solid  ${colors.appColor} 1px`,
                              },
                              '&.Mui-focused fieldset': {
                                border: `solid ${colors.appColor} 1px`,
                              },
                              '& fieldset': {
                                borderColor: `rgba(40, 32, 61, 0.12)`,
                                borderRadius: `10px`,
                              },
                              '& svg': { color: `#19C9D1` },
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: `rgba(40, 32, 61, 0.5)`,
                            },
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6} md={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <FormControl fullWidth>
                    <DatePicker
                      inputFormat="YYYY-MM-DD"
                      label="End Date *"
                      value={
                        endDate || new Date(new Date().getFullYear(), 11, 31)
                      }
                      onChange={(e) => {
                        if (!e) {
                          // If date is removed, set startDate to the first date of the current year
                          setEndDate(new Date(new Date().getFullYear(), 11, 31))
                        } else {
                          setEndDate(new Date(e))
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                border: `solid  ${colors.appColor} 1px`,
                              },
                              '&.Mui-focused fieldset': {
                                border: `solid ${colors.appColor} 1px`,
                              },
                              '& fieldset': {
                                borderColor: `rgba(40, 32, 61, 0.12)`,
                                borderRadius: `10px`,
                              },
                              '& svg': { color: `#19C9D1` },
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: `rgba(40, 32, 61, 0.5)`,
                            },
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6} md={4}>
                <Button
                  sx={{
                    color: `rgba(25, 201, 209, 1)`,
                    border: `solid rgba(25, 201, 209, 1) 1px`,
                    borderRadius: `10px`,
                    textTransform: `none`,
                    width: `92px`,
                    marginBottom: { xs: `10px`, md: `0px` },
                  }}
                  onClick={() => refetch()}
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: `flex`,
                justifyContent: `flex-end`,
                alignItems: `center`,
                width: `100%`,
                paddingY: { xs: `10px`, md: `0px` },
                gap: `10px`,
              }}
            >
              {accountDetailData?.name &&
              accountDetailData?.name.toLowerCase().includes(`payroll`) ? (
                <PrimaryButton
                  title="+ Payroll Budget"
                  hasPayrollBudget={hasPayrollBudget}
                  isLoading={payrollBudgetLoader}
                  onClick={() => payrollBudget()}
                />
              ) : (
                <PrimaryButton
                  title="Allocate Budget"
                  onClick={() => setAddBudgetModal(!addBudgetModal)}
                />
              )}
            </Box>
          </Box>

          <Box
            sx={{
              backgroundColor: `white`,
              display: `flex`,
              justifyContent: { xs: `flex-start`, sm: `space-between` },
              alignItems: { xs: `flex-start`, sm: `center` },
              flexDirection: { xs: `column`, sm: `row` },
              gap: `10px`,
              borderRadius: `10px`,
              width: `100%`,
              paddingX: `10px`,
            }}
          >
            <Box
              sx={{
                width: { xs: `100%`, sm: `50%` },
                paddingY: `20px`,
                paddingX: `10px`,
              }}
            >
              <Typography
                sx={{
                  fontSize: `24px`,
                  fontWeight: 600,
                  lineHeight: `29.05px`,
                  color: `rgba(40, 32, 61, 1)`,
                }}
              >
                {accountDetailData?.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: `14px`,
                  fontWeight: 400,
                  lineHeight: `16.94px`,
                  color: `rgba(40, 32, 61, 0.6)`,
                  paddingY: `10px`,
                  textAlign: `justify`,
                }}
              >
                {accountDetailData?.description
                  ? accountDetailData?.description
                  : `No description added`}
              </Typography>
            </Box>
            <Box
              sx={{
                width: { xs: `100%`, sm: `50%` },
                paddingY: `20px`,
                paddingX: `10px`,
              }}
            >
              <Grid
                container
                spacing={2}
                sx={{
                  display: `flex`,
                  justifyContent: `space-between`,
                  alignItems: `center`,
                }}
              >
                <Grid item xs={12} sm={4}>
                  <Box
                    sx={{
                      borderRadius: `10px`,
                      backgroundColor: `rgba(3, 227, 252,0.06)`,
                      height: `113px`,
                      display: `flex`,
                      flexDirection: `column`,
                      alignItems: `center`,
                      justifyContent: `center`,
                      gap: `10px`,
                    }}
                  >
                    <Typography>
                      {PrependRs({
                        amount: accountDetailData?.budgetBudget || 0,
                        sx: {
                          fontSize: { xs: `18px`, sm: `14px`, md: `18px` },
                          fontWeight: 600,
                          lineHeight: `21.78px`,
                          textAlign: `center`,
                        },
                      })}
                    </Typography>
                    <Typography
                      sx={{
                        color: `rgba(40, 32, 61, 0.6)`,
                        fontSize: { xs: `14px`, sm: `12px`, md: `14px` },
                        fontWeight: 400,
                        lineHeight: `16.94px`,
                        textAlign: `center`,
                      }}
                    >
                      Budget
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box
                    sx={{
                      borderRadius: `10px`,
                      backgroundColor: `
                      rgba(119, 3, 252, 0.06)`,
                      height: `113px`,
                      display: `flex`,
                      flexDirection: `column`,
                      alignItems: `center`,
                      justifyContent: `center`,
                      gap: `10px`,
                    }}
                  >
                    <Typography>
                      {PrependRs({
                        amount: accountDetailData?.utilized || 0,
                        sx: {
                          fontSize: { xs: `18px`, sm: `14px`, md: `18px` },
                          fontWeight: 600,
                          lineHeight: `21.78px`,
                          textAlign: `center`,
                        },
                      })}
                    </Typography>
                    <Typography
                      sx={{
                        color: `rgba(40, 32, 61, 0.6)`,
                        fontSize: { xs: `14px`, sm: `12px`, md: `14px` },
                        fontWeight: 400,
                        lineHeight: `16.94px`,
                        textAlign: `center`,
                      }}
                    >
                      Utilized
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box
                    sx={{
                      borderRadius: `10px`,
                      backgroundColor:
                        accountDetailData?.utilized >
                        accountDetailData?.budgetBudget
                          ? `rgba(255, 72, 94, 0.1)`
                          : `rgba(3, 252, 73,0.06)`,
                      height: `113px`,
                      display: `flex`,
                      flexDirection: `column`,
                      alignItems: `center`,
                      justifyContent: `center`,
                      gap: `10px`,
                    }}
                  >
                    <Typography>
                      {PrependRs({
                        amount: accountDetailData?.remaining || 0,
                        sx: {
                          fontSize: { xs: `18px`, sm: `14px`, md: `18px` },
                          fontWeight: 600,
                          lineHeight: `21.78px`,
                          textAlign: `center`,
                          color:
                            accountDetailData?.utilized >
                            accountDetailData?.budgetBudget
                              ? `rgba(255, 72, 94, 1)`
                              : `rgba(40, 32, 61, 1)`,
                        },
                      })}
                    </Typography>
                    <Typography
                      sx={{
                        color: `rgba(40, 32, 61, 0.6)`,
                        fontSize: { xs: `14px`, sm: `12px`, md: `14px` },
                        fontWeight: 400,
                        lineHeight: `16.94px`,
                        textAlign: `center`,
                      }}
                    >
                      Balance
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>

          {isLoading || isFetching ? (
            <Backdrop open={isLoading || isFetching}>
              <CircularProgress
                sx={{
                  position: `absolute`,
                  top: `50%`,
                  color: colors?.appColor,
                }}
              />
            </Backdrop>
          ) : !accountDetailData || accountDetailData?.budget?.length === 0 ? (
            <Typography
              sx={{
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`,
                height: `30vh`,
              }}
            >
              Budget(s) not found for given dates
            </Typography>
          ) : (
            <Grid container spacing={2}>
              {accountDetailData?.budget?.map((data, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Box
                    sx={{
                      width: `100%`,
                      // maxWidth: `700px`,
                      height: `200px`,
                      borderRadius: `10px`,
                      paddingY: `10px`,
                      paddingX: `20px`,
                      backgroundColor: `white`,
                      overflow: `hidden`,
                      '&:hover': {
                        cursor: `pointer`,
                      },
                    }}
                    onClick={(e) => {
                      e?.stopPropagation()
                      history?.push({
                        pathname: `/accounts/${accountId}/budget/${data?._id}`,
                        state: {
                          accountName: {
                            name: accountDetailData?.name,
                            id: accountId,
                          },
                        },
                      })
                    }}
                  >
                    <Box
                      sx={{
                        display: `flex`,
                        flexDirection: `row`,
                        alignItems: `center`,
                        justifyContent: `space-between`,
                      }}
                    >
                      <Typography
                        sx={{
                          width: `80px`,
                          height: `24px`,
                          fontWeight: 500,
                          fontSize: `12px`,
                          lineHeight: `14.52px`,
                          display: `flex`,
                          justifyContent: `center`,
                          alignItems: `center`,
                          borderRadius: `10px`,
                          backgroundColor:
                            data?.limitStatus === `BELOW`
                              ? `rgba(77, 198, 64, 0.12)`
                              : data?.limitStatus === `EXCEEDED`
                              ? `rgba(255, 72, 94, 0.12)`
                              : `rgba(37, 187, 251, 0.12)`,
                          color:
                            data?.limitStatus === `BELOW`
                              ? `rgba(77, 198, 64, 1)`
                              : data?.limitStatus === `EXCEEDED`
                              ? `rgba(255, 72, 94, 1)`
                              : `rgba(37, 187, 251, 1)`,
                        }}
                      >
                        {data?.limitStatus === `BELOW`
                          ? `Below`
                          : data?.limitStatus === `EXCEEDED`
                          ? `Exceeded`
                          : `Utilized`}
                      </Typography>
                      <Image
                        src={actionIcon}
                        width={36}
                        height={36}
                        onClick={(e) => {
                          e?.stopPropagation()
                          setAnchorEl(e.currentTarget)
                          setBudgetId(data?._id)
                        }}
                        sx={{
                          '&:hover': {
                            cursor: `pointer`,
                            backgroundColor: `rgba(40, 32, 61, 0.05)`,
                            borderRadius: `20px`,
                          },
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: `flex`,
                        flexDirection: `row`,
                        alignItems: `center`,
                        justifyContent: `space-between`,
                        marginY: `10px`,
                      }}
                    >
                      <Typography
                        sx={{
                          display: `flex`,
                          alignItems: `center`,
                          gap: `10px`,
                          fontWeight: 400,
                          fontSize: `14px`,
                          lineHeight: `16.94px`,
                          color: `rgba(40, 32, 61, 1)`,
                        }}
                      >
                        <Typography
                          sx={{
                            color: `rgba(40, 32, 61, 0.6)`,
                            fontSize: `12px`,
                          }}
                        >
                          Duration
                        </Typography>
                        {`${moment(data?.startDate).format(
                          `DD MMM YYYY`,
                        )} - ${moment(data?.endDate).format(`DD MMM YYYY`)}`}
                      </Typography>

                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: `14px`,
                          lineHeight: `16.94px`,
                          paddingX: `15px`,
                          color:
                            data?.validityStatus === `VALID`
                              ? `rgba(77, 198, 64, 1)`
                              : data?.validityStatus === `EXPIRE`
                              ? `rgba(255, 72, 94, 1)`
                              : `rgba(37, 187, 251, 1)`,
                        }}
                      >
                        {data?.validityStatus === `VALID`
                          ? `Valid`
                          : data?.validityStatus === `EXPIRE`
                          ? `Expired`
                          : ``}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: `flex`,
                        flexDirection: `row`,
                        justifyContent: `space-between`,
                        marginY: `10px`,
                        gap: `10px`,
                      }}
                    >
                      <Box
                        sx={{
                          borderRadius: `10px`,
                          backgroundColor: `rgba(3, 227, 252,0.06)`,
                          height: `65px`,
                          display: `flex`,
                          flexDirection: `column`,
                          alignItems: `flex-start`,
                          justifyContent: `center`,
                          gap: `5px`,
                          width: `100%`,
                          minWidth: { xs: `90px`, sm: `110px` },
                          padding: `10px`,
                        }}
                      >
                        <Typography
                          sx={{
                            color: `rgba(40, 32, 61, 0.6)`,
                            fontSize: `12px`,
                            fontWeight: 400,
                            lineHeight: `14.52px`,
                            textAlign: `left`,
                          }}
                        >
                          Budget
                        </Typography>
                        <Typography>
                          {PrependRs({
                            amount: data?.total || 0,
                            sx: {
                              fontSize: `14px`,
                              fontWeight: 600,
                              lineHeight: `16.94px`,
                              textAlign: `left`,
                            },
                          })}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          borderRadius: `10px`,
                          backgroundColor: `
                      rgba(119, 3, 252, 0.06)`,
                          height: `65px`,
                          display: `flex`,
                          flexDirection: `column`,
                          alignItems: `flex-start`,
                          justifyContent: `center`,
                          gap: `5px`,
                          width: `100%`,
                          minWidth: { xs: `90px`, sm: `110px` },
                          padding: `10px`,
                        }}
                      >
                        <Typography
                          sx={{
                            color: `rgba(40, 32, 61, 0.6)`,
                            fontSize: `12px`,
                            fontWeight: 400,
                            lineHeight: `14.52px`,
                            textAlign: `left`,
                          }}
                        >
                          Utilized
                        </Typography>
                        <Typography>
                          {PrependRs({
                            amount: data?.utilized || 0,
                            sx: {
                              fontSize: `14px`,
                              fontWeight: 600,
                              lineHeight: `16.94px`,
                              textAlign: `left`,
                            },
                          })}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          borderRadius: `10px`,
                          backgroundColor:
                            data?.utilized > data?.total
                              ? `rgba(255, 72, 94, 0.1)`
                              : `rgba(3, 252, 73,0.06)`,
                          height: `65px`,
                          display: `flex`,
                          flexDirection: `column`,
                          alignItems: `flex-start`,
                          justifyContent: `center`,
                          gap: `5px`,
                          width: `100%`,

                          minWidth: { xs: `90px`, sm: `110px` },
                          padding: `10px`,
                        }}
                      >
                        <Typography
                          sx={{
                            color: `rgba(40, 32, 61, 0.6)`,
                            fontSize: `12px`,
                            fontWeight: 400,
                            lineHeight: `14.52px`,
                            textAlign: `left`,
                          }}
                        >
                          Balance
                        </Typography>
                        <Typography>
                          {PrependRs({
                            amount: data?.remaining || 0,
                            sx: {
                              color:
                                data?.utilized > data?.total
                                  ? `rgba(255, 72, 94, 1)`
                                  : `rgba(40, 32, 61, 1)`,

                              fontSize: `14px`,
                              fontWeight: 600,
                              lineHeight: `16.94px`,
                              textAlign: `left`,
                            },
                          })}
                        </Typography>
                      </Box>
                    </Box>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: `12px`,
                        lineHeight: `14.52px`,
                        color: `rgba(40, 32, 61, 0.6)`,
                        textAlign: `justify`,
                        overflow: `hidden`,
                        WebkitLineClamp: 2, // Number of lines to show
                        WebkitBoxOrient: `vertical`,
                        display: `-webkit-box`,
                      }}
                    >
                      {data?.details}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      )}
    </>
  )
}

export default AccountDetail
