import React from 'react'
import { Typography } from '@mui/material'
import formatAmountWithCommas from './formatAmountWithCommas'

const PrependRs = ({ amount, sx }) => {
  return (
    <Typography sx={sx}>{`Rs. ${formatAmountWithCommas(amount)}`}</Typography>
  )
}

export default PrependRs
