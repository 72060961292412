import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Button,
  DialogContentText,
  LinearProgress,
  Typography,
  Box,
  Snackbar,
  IconButton,
  CircularProgress,
} from '@material-ui/core'
import cross from 'app/Assets/cross.svg'
import error from 'app/Assets/error_red.svg'
import file from 'app/Assets/file.svg'
import uploadcsv from 'app/Assets/uploadcsv.svg'
import { makeStyles } from '@mui/styles'
import { baseUrl } from 'app/Utils/Config'
import api from 'app/AxiosInstance'
import CloseIcon from '@mui/icons-material/Close'
import { useSelector } from 'react-redux'

const useStyles = makeStyles({
  progressColor: {
    color: `rgba(96, 57, 187, 1)`,
  },
  button: {
    textTransform: `none`,
    '&:first-letter': {
      textTransform: `capitalize`,
    },
  },
  progresscolor: {
    color: `rgba(96, 57, 187, 1)`,
  },
})

const Error = ({ open, onClose, Error }) => {
  const colors = useSelector((state) => state.colors)
  const classes = useStyles()
  // const [loading, setLoading] = useState(false)
  const [uploadCSVOpen, setuploadCSVOpen] = useState(true)
  const [progress, setProgress] = useState(0)
  const [fileUploaded, setFileUploaded] = useState()
  const [fileName, setFileName] = useState(``)
  const [displayProgress, setDisplayProgress] = useState(false)
  const [dataKB, setDataKB] = useState(0)
  const [dataMB, setDataMB] = useState(0)
  // const [dataLoaded, setDataLoaded] = useState()
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const token = localStorage.getItem(`AuthToken`)
  let KB = 1024 // 1 kb = 1024 bytes
  let MB = KB * 1024 // 1 mb = 1024 kilo bytes

  // LogOut if Authentication failed
  // const handleLogOutClick = () => {
  //   localStorage.removeItem(`userID`)
  //   window.location.reload()
  // }

  // close upload csv modal
  const handleuploadCSVClose = () => {
    setuploadCSVOpen(false)
    setFileUploaded()
    setFileName(``)
    setDisplayProgress(false)
    setDataKB(0)
    setDataMB(0)
  }

  const hiddenFileInput = React.useRef(null) // creating a reference variable

  // upload the file
  const handleUploadCSVClick = () => {
    hiddenFileInput.current.click()
  }

  // handle upload CSV change
  const handleUploadCSVChange = (event) => {
    setFileUploaded(event.target.files[0])
    const file = event.target.files[0]
    setFileName(file.name)
    setDisplayProgress(true)
  }

  // upload CSV file Api
  const uploadEmployeesAPI = (path, body) => {
    // setLoading(true)
    api
      .post(path, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: (data) => {
          setProgress(Math.round((data.loaded / data.total) * 100))
          // setDataLoaded(data.loaded)
          setDataKB((data.loaded / KB).toFixed(1))
          setDataMB((data.loaded / MB).toFixed(1))
        },
      })
      .then(() => {
        // setLoading(false)
        handleuploadCSVClose()
      })
      .catch((error) => {
        // setLoading(false)
        error.response
          ? setErrorMessage(error.response.data.message)
          : setErrorMessage(error.message)
        setOpenSnackBar(true)
      })
  }

  // handle save button click
  const handleSave = () => {
    if (displayProgress) {
      const formData = new FormData()
      formData.append(`file`, fileUploaded)
      fileUploaded &&
        uploadEmployeesAPI(`${baseUrl}/users/uploadEmployeeFile`, formData)
    }
  }

  const handleSnackBarClose = () => {
    setOpenSnackBar(false)
  }

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleSnackBarClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackBarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  )

  return Error === `Employees not exist!!` ? ( // if error = Employees not exist!! then render upload csv modal
    <>
      {openSnackBar && (
        <Snackbar
          open={openSnackBar}
          autoHideDuration={6000}
          onClose={handleSnackBarClose}
          message={errorMessage}
          action={action}
        />
      )}
      <Dialog
        open={uploadCSVOpen}
        onClose={handleuploadCSVClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            width: `600px`,
            height: `443px`,
            borderRadius: `20px 20px 20px 20px`,
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            height: `64px`,
            width: `100%`,
          }}
        >
          <Box
            sx={{
              display: `flex`,
              flexDirection: `row`,
              justifyContent: `space-between`,
              alignItems: `center`,
              backgroundColor: `grey`,

              marginLeft: `1rem`,
              marginRight: `1rem`,
            }}
          >
            <Typography>Upload Employee CSV File</Typography>
            <Box
              sx={{
                width: `36px`,
                height: `36px`,
                backgroundColor: `white`,
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`,
              }}
            >
              <Button onClick={handleuploadCSVClose}>
                <img src={cross} alt="cross" />
              </Button>
            </Box>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box
            sx={{
              display: `flex`,
              flexDirection: `column`,
              marginLeft: `12px`,
              marginRight: `12px`,
            }}
          >
            <div>
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description"
                  border="dashed"
                  borderColor="rgba(40, 32, 61, 0.2)"
                  sx={{
                    px: `1rem`,
                    py: `2rem`,
                    fontSize: 12,
                    display: `flex`,
                    flexDirection: `column`,
                    justifyContent: `center`,
                    alignItems: `center`,
                    gap: 2,
                  }}
                >
                  <Button onClick={handleUploadCSVClick}>
                    <img
                      src={uploadcsv}
                      alt="Upload the image"
                      className="m-auto"
                    />
                  </Button>
                  <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleUploadCSVChange}
                    style={{ display: `none` }}
                  />
                  Upload CSV file
                </DialogContentText>
              </DialogContent>
            </div>

            {displayProgress && (
              <Box
                sx={{
                  display: `flex`,
                  flexDirection: `row`,
                  alignItems: `center`,
                  marginLeft: `1.5rem`,
                  marginRight: `1.5rem`,
                }}
              >
                <img src={file} alt="file" />
                <Box
                  sx={{
                    display: `flex`,
                    flexDirection: `column`,
                    marginLeft: `1rem`,
                    width: `100%`,
                  }}
                >
                  <Box
                    sx={{
                      display: `flex`,
                      flexDirection: `row`,
                      justifyContent: `space-between`,
                      alignItems: `center`,
                    }}
                  >
                    <Typography>{fileName}</Typography>
                    <Box onClick={() => setDisplayProgress(false)}>
                      <img src={cross} alt="cross" />
                    </Box>
                  </Box>
                  <Box sx={{ width: `100%` }}>
                    <LinearProgress
                      variant="determinate"
                      value={progress}
                      className={classes.progressColor}
                    >
                      {`${progress}%`}
                    </LinearProgress>
                  </Box>
                  <Box
                    sx={{
                      display: `flex`,
                      flexDirection: `row`,
                      justifyContent: `space-between`,
                    }}
                  >
                    <Typography sx={{ fontSize: `12px` }}>
                      {dataKB} of {dataMB}
                    </Typography>
                    <Typography
                      sx={{ fontSize: `12px` }}
                    >{`${progress}%`}</Typography>
                  </Box>
                </Box>
              </Box>
            )}

            <Box
              variant="contained"
              sx={{
                display: `flex`,
                flexDirection: `row-reverse`,
                marginTop: `2rem`,
                marginRight: `1.5rem`,
              }}
            >
              <Box sx={{ display: `flex`, flexDirection: `row` }}>
                <Button
                  sx={{
                    display: `flex`,
                    backgroundColor: `rgba(40, 32, 61, 0.07)`,
                    color: `rgba(40, 32, 61, 1)`,
                    borderRadius: `10px`,
                    width: `110px`,
                    height: `44px`,
                  }}
                  onClick={handleuploadCSVClose}
                  className={classes.button}
                >
                  Cancel
                </Button>

                <Box
                  sx={{
                    backgroundColor: colors.appColor,
                    color: `white`,
                    marginLeft: `1rem`,
                    display: `flex`,
                    justifyContent: `center`,
                    alignItems: `center`,
                    borderRadius: `10px`,
                    width: `110px`,
                    height: `44px`,
                    '&:hover': {
                      cursor: `pointer`,
                      opacity: 0.8,
                    },
                  }}
                  className={classes.button}
                  onClick={handleSave}
                >
                  {isMutating ? (
                    <CircularProgress
                      size={25}
                      color="secondary"
                      style={{ position: `absolute`, color: `white` }}
                    />
                  ) : (
                    `Save`
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <Divider />
      </Dialog>
    </>
  ) : (
    <Dialog // otherwise render error modal
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          width: `386px`,
          height: `280px`,
          borderRadius: `20px 20px 20px 20px`,
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          height: `48px`,
        }}
      >
        <Box sx={{ display: `flex`, flexDirection: `row-reverse` }}>
          <Box
            sx={{
              width: `36px`,
              height: `36px`,
              display: `flex`,
              justifyContent: `center`,
              alignItems: `centrer`,
              padding: `11px`,
              '&:hover': {
                cursor: `pointer`,
                opacity: 0.8,
              },
            }}
            onClick={onClose}
          >
            <img src={cross} alt="cross" width="13.87px" height="14px" />
          </Box>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box
          sx={{
            display: `flex`,
            flexDirection: `column`,
            justifyContent: `center`,
            alignItems: `center`,
          }}
        >
          <Box
            sx={{
              width: `60px`,
              height: `60px`,
            }}
          >
            <img src={error} alt="Pic" />
          </Box>
          {/* <Typography
            sx={{
              fontWeight: 500,
              fontSize: `20px`,
              marginTop: `29.5px`,
              lineHeight: `24.2px`,
            }}
          >
            Something went wrong
          </Typography> */}
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: `18px`,
              marginTop: `14px`,
              lineHeight: `16.94px`,
              textAlign: `center`,
            }}
          >
            {Error}
          </Typography>
          <Box
            variant="contained"
            sx={{
              backgroundColor: colors.appColor,
              color: `white`,
              marginTop: `30px`,
              width: `173px`,
              height: `44px`,
              display: `flex`,
              justifyContent: `center`,
              alignItems: `center`,
              borderRadius: `10px`,
              '&:hover': {
                cursor: `pointer`,
                opacity: 0.8,
              },
            }}
            onClick={onClose}
          >
            OK
          </Box>
        </Box>
      </DialogContent>
      <Divider />
    </Dialog>
  )
}

export default Error
