import React, { useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import api from 'app/AxiosInstance'
import { useHistory } from 'react-router-dom'
import Error from 'app/Components/Error'
import { baseUrl } from 'app/Utils/Config'
import { Box } from '@material-ui/core'
import { Typography } from '@mui/material'
import { Skeleton } from '@material-ui/core'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'

const AllEmployees = () => {
  const colors = useSelector((state) => state.colors)
  const history = useHistory()
  const [errorDialogOpen, setErrorDialogOpen] = useState(true)
  const token = localStorage.getItem(`AuthToken`)

  // Close Error Modal
  const CloseErrorModal = () => {
    setErrorDialogOpen(false)
  }

  // get All Employees
  const { isLoading, data, isError, error } = useQuery(
    `allEmployees`,
    async () => {
      const { data } = await api.get(`${baseUrl}/users/getAllEmployees`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      setErrorDialogOpen(true)
      return data.employees
    },
    {
      refetchOnMount: true,
      refetchInterval: false,
    },
  )

  // Navigate to Employee details
  const NavigateToEmployeeDetail = (ID) => {
    history.push(`/employeeDetails/${ID}`)
  }

  return (
    <>
      {/* Error Modal */}
      {isError && (
        <Error
          open={errorDialogOpen}
          onClose={CloseErrorModal}
          Error={error?.response ? error.response.data.message : error.message}
        />
      )}

      <Box
        sx={{
          width: `100%`,
          height: `100vh`,
          padding: `20px`,
          display: `flex`,
          justifyContent: `center`,
          alignItems: `center`,
          backgroundColor: `rgba(40, 32, 61, 0.07)`,
        }}
      >
        <Box
          sx={{
            height: `100%`,
            overflowY: `scroll`,
            margin: `20px`,
            boxShadow: `0 3px 5px 2px rgba(0, 0, 0, .3)`,
            borderRadius: `10px`,
          }}
        >
          {isLoading ? (
            <Skeleton variant="wave" width={650} height="100vh" />
          ) : (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <Typography
                        sx={{
                          fontFamily: `Inter`,
                          fontWeight: 600,
                          fontSize: `18px`,
                          color: colors.appColor,
                        }}
                      >
                        {isLoading ? `Loading...` : `Attendance Id`}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        sx={{
                          fontFamily: `Inter`,
                          fontWeight: 600,
                          fontSize: `18px`,
                          color: colors.appColor,
                        }}
                      >
                        {isLoading ? `Loading...` : `Name`}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        sx={{
                          fontFamily: `Inter`,
                          fontWeight: 600,
                          fontSize: `18px`,
                          color: colors.appColor,
                        }}
                      >
                        {isLoading ? `Loading...` : `Designation`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {data &&
                    data?.map((item, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          '&:hover': {
                            cursor: `pointer`,
                            opacity: 0.8,
                            backgroundColor: `rgba(96, 57, 187, 0.5)`,
                          },
                        }}
                        onClick={() =>
                          NavigateToEmployeeDetail(item.attendanceId)
                        }
                      >
                        <TableCell component="th" scope="row" align="center">
                          <Typography
                            sx={{
                              fontFamily: `Inter`,
                              fontSize: `16px`,
                              fontWeight: 600,
                            }}
                          >
                            {item.attendanceId}
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          <Typography
                            sx={{
                              fontFamily: `Inter`,
                              fontSize: `16px`,
                              fontWeight: 600,
                            }}
                          >
                            {item.name}
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          <Typography
                            sx={{
                              fontFamily: `Inter`,
                              fontSize: `16px`,
                              fontWeight: 600,
                            }}
                          >
                            {item.designation}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Box>
    </>
  )
}

export default AllEmployees
