import React from 'react'
import Popover from '@mui/material/Popover'
import { Box } from '@material-ui/core'
import menuProfile from 'app/Assets/menuProfile.svg'
import menuChangePassword from 'app/Assets/menuChangePassword.svg'
import menuLogout from 'app/Assets/menuLogout.svg'

const Menu = (props) => {
  const {
    open,
    onClose,
    anchorEl,
    OpenProfile,
    OpenChangePassword,
    OpenLogout,
  } = props // Destructuring the props

  // close the popover
  const closePopover = () => {
    onClose()
  }

  return (
    <>
      <Popover
        open={open}
        onClose={closePopover}
        anchorEl={anchorEl}
        sx={{
          '& .MuiPaper-root': {
            width: `196px`,
            height: `max-content`,
            borderRadius: `15px`,
            display: `flex`,
            justifyContent: `center`,
            alignItems: `center`,
          },
        }}
        anchorOrigin={{
          vertical: `bottom`,
          horizontal: `center`,
        }}
        transformOrigin={{
          vertical: `top`,
          horizontal: `center`,
        }}
      >
        <Box
          sx={{
            display: `flex`,
            flexDirection: `column`,
            width: `100%`,
            padding: `20px`,
          }}
        >
          <Box
            sx={{
              display: `flex`,
              flexDirection: `row`,
              justifyContent: `space-between`,
              '&:hover': {
                cursor: `pointer`,
              },
              whiteSpace: `no-wrap`,
            }}
          >
            <img src={menuProfile} alt="profile" />
            <Box
              sx={{
                width: `100%`,
                height: `36px`,
                paddingLeft: `16px`,

                display: `flex`,
                alignItems: `center`,
              }}
              onClick={() => OpenProfile()}
            >
              Profile
            </Box>
          </Box>

          <Box
            sx={{
              display: `flex`,
              flexDirection: `row`,
              justifyContent: `space-between`,
              '&:hover': {
                cursor: `pointer`,
              },
              whiteSpace: `no-wrap`,
            }}
          >
            <img src={menuChangePassword} alt="Change Password" />
            <Box
              sx={{
                width: `100%`,
                height: `36px`,
                paddingLeft: `16px`,

                display: `flex`,
                alignItems: `center`,
              }}
              onClick={() => OpenChangePassword()}
            >
              Change Password
            </Box>
          </Box>

          <Box
            sx={{
              display: `flex`,
              flexDirection: `row`,
              justifyContent: `space-between`,
              '&:hover': {
                cursor: `pointer`,
              },
              whiteSpace: `no-wrap`,
            }}
          >
            <img src={menuLogout} alt="Logout" />
            <Box
              sx={{
                width: `100%`,
                height: `36px`,
                paddingLeft: `16px`,

                display: `flex`,
                alignItems: `center`,
                backgroundColor: `white`,
                color: `black`,
              }}
              onClick={() => OpenLogout()}
            >
              Logout
            </Box>
          </Box>
        </Box>
      </Popover>
    </>
  )
}

export default Menu
