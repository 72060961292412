import React from 'react'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import Box from '@mui/material/Box'
import ClearIcon from '@mui/icons-material/Clear'

const SearchBar = ({ query, setQuery, onChange, placeholder }) => {
  const handleInputChange = (event) => {
    const newQuery = event.target.value
    if (newQuery.length === 0 || !/^\s/.test(newQuery)) {
      if (setQuery) {
        setQuery(newQuery)
      }
      if (onChange) {
        onChange(event)
      }
    }
  }

  const handleClearInput = () => {
    setQuery(``)
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width={{ md: `300px`, xs: `100%` }}
      mt={2}
    >
      <TextField
        variant="outlined"
        placeholder={placeholder}
        value={query}
        onChange={handleInputChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {query !== `` ? (
                <ClearIcon
                  sx={{ color: `#19C9D1`, cursor: `pointer` }}
                  onClick={handleClearInput}
                />
              ) : (
                <SearchIcon sx={{ color: `#19C9D1` }} />
              )}
            </InputAdornment>
          ),
        }}
        sx={{
          width: `100%`,
          height: `44px`,
          maxWidth: { md: 300, xs: `100%` },
          bgcolor: `#FFFFFF`,
          borderRadius: `10px`,
          border: 0,
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: `none`,
            },
            '&.Mui-focused fieldset': {
              border: `none`,
            },
            '& .MuiInputBase-input': {
              height: `44px`,
              padding: `0 15px`,
            },
          },
        }}
      />
    </Box>
  )
}

export default SearchBar
