const { createTheme } = require(`@material-ui/core`)

const theme = createTheme({
  typography: {
    fontFamily: [
      `Inter`, // Use Inter as the primary font
      `-apple-system`, // Backup font for iOS
      `BlinkMacSystemFont`, // Backup font for macOS
      `"Segoe UI"`, // Backup font for Windows
      `Roboto`, // Backup font for Android
      `"Helvetica Neue"`, // Backup font for older iOS
      `Arial`, // Backup font for older Windows
      `sans-serif`, // Generic fallback font
    ].join(`,`),
    heading36px: {
      fontSize: `36px`,
      fontWeight: `700`,
      color: `#28203D`,
    },
    heading1: {
      fontSize: `24px`,
      fontWeight: `600`,
      color: `#28203D`,
    },
    heading2: {
      fontSize: `20px`,
      fontWeight: `600`,
      color: `#28203D`,
    },
    heading2Purple: {
      fontSize: `20px`,
      fontWeight: `600`,
      color: `#6039bb`,
    },
    heading3: {
      fontSize: `18px`,
      fontWeight: `600`,
      color: `#28203D`,
    },
    heading3Medium: {
      fontSize: `18px`,
      fontWeight: `500`,
      color: `#28203D`,
    },
    heading4: {
      fontSize: `16px`,
      fontWeight: `600`,
      color: `#28203D`,
    },
    heading4Medium: {
      fontSize: `16px`,
      fontWeight: `500`,
      color: `#28203D`,
    },
    heading4Thin: {
      fontSize: `16px`,
      fontWeight: `400`,
      color: `#28203D`,
    },
    bodyText: {
      fontSize: `14px`,
      fontWeight: `400`,
      color: `#a9a6b1`,
    },
    bodyTextBlack: {
      fontSize: `14px`,
      fontWeight: `400`,
      color: `#28203D`,
    },
    bodyText2: {
      fontSize: `12px`,
      fontWeight: `600`,
      color: `#a9a6b1`,
    },
    bodyText3: {
      fontSize: `12px`,
      fontWeight: `400`,
      color: `#FFFFFF`,
    },
  },
})

export const customTheme = theme
