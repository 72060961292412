import { Redirect } from 'react-router-dom'
import React from 'react'

export const RoleGuard = ({ userRole, allowedRoles, children }) => {
  if (allowedRoles?.includes(userRole)) {
    return children
  } else {
    return <Redirect to="*" />
  }
}
