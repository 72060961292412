import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import PrimaryButton from 'app/Components/Common/PrimaryButton'
import React, { useEffect, useRef, useState } from 'react'
import bracketsLogo from '../../../Assets/brackets_logo_colorful.svg'
import moment from 'moment'
import { useLocation } from 'react-router-dom'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { getAllAccounts } from 'app/Services/General/getAllAccounts'

const tableColumns = [
  { label: `Sr.#`, value: `sr.no` },
  { label: `ACCOUNTS AND BUDGET`, value: `account` },
  { label: `EXPENSE BY`, value: `name` },
  { label: `PURCHASE BY`, value: `name` },
  { label: `EXPENSE DESCRIPTION`, value: `description` },
  { label: `EXPENSE MONTH`, value: `month` },
  { label: `EXPENSE DATE`, value: `date` },
  { label: `PKR AMOUNT`, value: `amount` },
  { label: `USD AMOUNT`, value: `usdAmount` },
]

const ExpenseReports = () => {
  const location = useLocation()

  const [accountsData, setAccountsData] = useState([])

  useEffect(() => {
    getAllAccounts().then((result) => {
      const newLoadAccounts = result?.options?.map((account) => ({
        label: account?.name,
        value: account?._id,
      }))
      setAccountsData(newLoadAccounts)
    })
  }, [])
  const { state: reports } = location
  const account =
    accountsData &&
    accountsData?.find(
      (account) => account.value === reports?.reports?.filteredData?.account,
    )

  let rowNumber = 0
  const [loading, setLoading] = useState(false)
  const reportRef = useRef()

  const exportToPdf = () => {
    setLoading(true)
    const inputPage1 = reportRef.current

    html2canvas(inputPage1, { scale: 2 }).then((canvasPage1) => {
      const imgDataPage1 = canvasPage1.toDataURL(`image/jpeg`, {
        quality: 0.8,
        progressive: true,
      })

      const pdf = new jsPDF({
        orientation: `portrait`,
        unit: `mm`,
        format: `a4`,
      })

      const pdfWidth = pdf.internal.pageSize.getWidth()

      pdf.addImage(imgDataPage1, `JPEG`, 0, 0, pdfWidth, 0)

      pdf.save(`expenseReport.pdf`)
      setLoading(false)
    })
  }

  const sortByDate = (data) => {
    // Flatten the nested structure and extract currencyWiseExpenses
    const currencyWiseExpenses = data?.flatMap((account) =>
      account?.expensesOfSingleAccount?.flatMap(
        (expense) => expense?.currencyWiseExpenses,
      ),
    )

    // Sort the flattened array based on the date property in descending order
    const sortedData = currencyWiseExpenses.sort(
      (a, b) => new Date(b.date) - new Date(a.date),
    )

    const getAccountObject = (accountId) => {
      const targetAccount = data?.filter(
        (account) => account.account._id === accountId,
      )
      return targetAccount?.[0]?.account?.name
    }

    const sortedDataWithAccountName = sortedData?.map((expense) => {
      return { ...expense, account: getAccountObject(expense?.account) }
    })

    return sortedDataWithAccountName
  }

  return (
    <Box
      sx={{
        display: `flex`,
        flexDirection: `column`,
        gap: `10px`,
        padding: { xs: `10px`, sm: `20px` },
        minHeight: `calc(100vh - 80px)`,
        backgroundColor: `rgba(40, 32, 61, 0.07)`,
      }}
    >
      <Box
        sx={{
          display: `flex`,
          justifyContent: `flex-end`,
          alignItems: `center`,
          gap: `20px`,
          flexDirection: { xs: `column`, sm: `row` },
        }}
      >
        <Button
          variant="contained"
          sx={{
            width: { xs: `100%`, sm: `auto` },
            backgroundColor: `rgba(25, 201, 209, 1)`,
            border: `solid rgba(25, 201, 209, 1) 1px`,
            textTransform: `none`,
            borderRadius: `10px`,
            paddingY: `9px`,
            paddingX: `20px`,
            color: `white`,
            '&:hover': {
              backgroundColor: `rgba(25, 201, 209, 1)`,
              opacity: `0.8`,
            },
          }}
          onClick={exportToPdf}
          className="button"
          disabled={loading} // Disable the button while loading
        >
          {loading ? (
            <>
              <Typography sx={{ textTransform: `none` }}>
                Exporting...
              </Typography>

              <CircularProgress
                size={20}
                color="inherit"
                style={{ marginRight: `10px` }}
              />
            </>
          ) : (
            `Export PDF`
          )}
        </Button>

        <PrimaryButton title="Print" onClick={() => window.print()} />
      </Box>
      <Box
        ref={reportRef}
        sx={{
          backgroundColor: `white`,
          marginX: { xs: `20px`, sm: `1%` },
          borderRadius: `10px`,
          padding: `20px`,
        }}
      >
        <Box
          sx={{
            display: `flex`,
            justifyContent: `space-between`,
            alignItems: `center`,
          }}
        >
          <img src={bracketsLogo} width={`143px`} height={`36px`} />
          <Typography
            sx={{
              fontSize: `16px`,
              fontWeight: 500,
              lineHeight: `12.1px`,
            }}
          >
            {`Date: ${moment(new Date().toLocaleDateString()).format(
              `YYYY-MM-DD`,
            )}`}
          </Typography>
        </Box>

        <Typography
          sx={{
            fontWeight: 600,
            fontSize: `24px`,
            lineHeight: `19.36px`,
            display: `flex`,
            alignItems: `center`,
            justifyContent: `center`,
            marginTop: `10px`,
          }}
        >
          Expenses Reports
        </Typography>
        <Box
          sx={{
            backgroundColor: `rgba(25, 201, 209, 0.07)`,
            border: `solid rgba(40, 32, 61, 0.2) 1px`,
            marginY: `20px`,
          }}
        >
          <Box
            sx={{
              display: `flex`,
              alignItems: { xs: `flex-start`, sm: `center` },
              justifyContent: `space-between`,
              paddingY: `10px`,
              paddingX: `15px`,
              flexDirection: { xs: `column`, sm: `row` },
            }}
          >
            <Box>
              <Typography
                sx={{
                  display: `flex`,
                  alignItems: `center`,
                  gap: `20px`,
                  fontSize: `14px`,
                  fontWeight: 400,
                  color: `rgba(40, 32, 61, 0.6)`,
                }}
              >
                Account :
                <Typography
                  sx={{
                    fontWeight: 600,
                    alignItems: `center`,
                    color: `rgba(40, 32, 61, 1)`,
                  }}
                >
                  {account?.label || `All`}
                </Typography>
              </Typography>
              {reports?.reports?.filteredData?.incomeMonth ? (
                <Typography
                  sx={{
                    display: `flex`,
                    alignItems: `center`,
                    gap: `30px`,
                    fontSize: `14px`,
                    color: `rgba(40, 32, 61, 0.6)`,
                  }}
                >
                  Month :
                  <Typography
                    sx={{ fontWeight: 600, color: `rgba(40, 32, 61, 1)` }}
                  >
                    {reports?.reports?.filteredData?.incomeMonth
                      ? `${moment(
                          reports?.reports?.filteredData?.incomeMonth,
                        ).format(`MMM YYYY`)} `
                      : `N / A`}
                  </Typography>
                </Typography>
              ) : (
                <Box
                  sx={{
                    display: `flex`,
                    gap: `10px`,
                  }}
                >
                  <Typography
                    sx={{
                      display: `flex`,
                      alignItems: `center`,
                      gap: `40px`,
                      fontSize: `14px`,
                      color: `rgba(40, 32, 61, 0.6)`,
                    }}
                  >
                    Date :
                    <Typography
                      sx={{ fontWeight: 600, color: `rgba(40, 32, 61, 1)` }}
                    >
                      {reports?.reports?.filteredData?.startDate
                        ? `${moment(
                            reports?.reports?.filteredData?.startDate,
                          ).format(`DD MMM YYYY`)} `
                        : `N / A`}
                    </Typography>
                  </Typography>
                  <Typography
                    sx={{
                      display: `flex`,
                      alignItems: `center`,
                      gap: `10px`,
                      fontSize: `14px`,
                      color: `rgba(40, 32, 61, 0.6)`,
                    }}
                  >
                    to
                    <Typography
                      sx={{ fontWeight: 600, color: `rgba(40, 32, 61, 1)` }}
                    >
                      {reports?.reports?.filteredData?.endDate
                        ? `${moment(
                            reports?.reports?.filteredData?.endDate,
                          ).format(`DD MMM YYYY`)} `
                        : `N / A`}
                    </Typography>
                  </Typography>
                </Box>
              )}
            </Box>

            <Box sx={{ marginX: `30px` }}>
              <Typography
                sx={{
                  display: `flex`,
                  alignItems: `center`,
                  fontSize: `14px`,
                  fontWeight: 400,
                  gap: { xs: `80px`, sm: `20px` },
                  color: `rgba(40, 32, 61, 0.6)`,
                }}
              >
                PKR Amount :
                <Typography
                  sx={{ fontWeight: 600, color: `rgba(40, 32, 61, 1)` }}
                >
                  {`${
                    reports?.reports?.budgetReports?.totalConvertedAmountOfAllAccounts.toFixed(
                      2,
                    ) || 0
                  } `}
                </Typography>
              </Typography>
              <Typography
                sx={{
                  display: `flex`,
                  alignItems: `center`,
                  gap: { xs: `80px`, sm: `20px` },
                  fontSize: `14px`,
                  fontWeight: 400,
                  color: `rgba(40, 32, 61, 0.6)`,
                }}
              >
                USD Amount :
                <Typography
                  sx={{ fontWeight: 600, color: `rgba(40, 32, 61, 1)` }}
                >
                  {`${
                    reports?.reports?.budgetReports?.totalUSDAmountOfAllAccounts.toFixed(
                      2,
                    ) || 0
                  } `}
                </Typography>
              </Typography>
            </Box>
          </Box>
        </Box>

        <Grid item xs={12}>
          <TableContainer
            className="scrollbar-hide"
            sx={{
              backgroundColor: `white`,
              border: `solid 1px rgba(40, 32, 61, 0.15)`,
              marginTop: `20px`,
              height: {
                xs: `360px`,
                sm: `auto`,
              },
              whiteSpace: `nowrap`,
              position: `relative`,
            }}
          >
            <Table size="small">
              <TableHead className="h-10">
                <TableRow
                  sx={{
                    backgroundColor: `rgba(96, 57, 187, 1)`,
                  }}
                >
                  {tableColumns?.map((item) => {
                    return (
                      <TableCell key={item.label} sx={{ padding: `0px 16px` }}>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: `10px`,
                            color: `white`,
                          }}
                        >
                          {item?.label}
                        </Typography>
                      </TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {!reports?.reports?.budgetReports?.accountAndExpenses ||
                !reports?.reports?.budgetReports?.accountAndExpenses ? (
                  <TableRow>
                    <TableCell
                      colSpan={tableColumns.length + 1}
                      sx={{ border: 0 }}
                    >
                      <div
                        style={{
                          display: `flex`,
                          justifyContent: `center`,
                          alignItems: `center`,
                        }}
                      >
                        <Typography>No expense Found</Typography>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  sortByDate(
                    reports?.reports?.budgetReports?.accountAndExpenses,
                  )?.map((expense, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        // '&:last-child td, &:last-child th': {
                        //   border: 0,
                        // },
                        '&:hover': {
                          cursor: `pointer`,
                          background: `linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(250,249,253,1) 15%, rgba(250,249,253,1) 85%, rgba(255,255,255,1) 100%)`,
                        },
                        width: `100%`,
                      }}
                    >
                      <TableCell
                        width="2%"
                        sx={{
                          fontWeight: 400,
                          fontSize: `14px`,
                          lineHeight: `12.1px`,
                          borderRight: `solid rgba(40, 32, 61, 0.15) 1px`,
                        }}
                      >
                        {++rowNumber}
                      </TableCell>

                      <TableCell
                        width="18%"
                        sx={{
                          width: `100px`,

                          borderRight: `solid rgba(40, 32, 61, 0.15) 1px`,
                          wordWrap: `break-word`,
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: `12px`,
                            lineHeight: `12.1px`,
                            marginBottom: `5px`,
                            display: `block`,
                            width: `100%`,
                            whiteSpace: `normal`,
                            textAlign: `justify`,
                          }}
                        >
                          {expense?.account}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: `12px`,
                            lineHeight: `9.68px`,
                            color: `rgba(40, 32, 61, 0.6)`,
                          }}
                        >{`${moment(
                          expense?.budget?.startDate,
                          `YYYY-MM-DD`,
                        ).format(`MMM DD, YYYY`)} to ${moment(
                          expense?.budget?.endDate,
                          `YYYY-MM-DD`,
                        ).format(`MMM DD, YYYY`)}`}</Typography>
                      </TableCell>
                      <TableCell
                        width="12.5%"
                        sx={{
                          borderRight: `solid rgba(40, 32, 61, 0.15) 1px`,
                          textAlign: `justify`,
                          fontWeight: 400,
                          fontSize: `14px`,
                          lineHeight: `12.1px`,
                        }}
                      >
                        {expense?.expenseBy?.name}
                      </TableCell>
                      <TableCell
                        width="12.5%"
                        sx={{
                          borderRight: `solid rgba(40, 32, 61, 0.15) 1px`,
                          textAlign: `justify`,
                          fontWeight: 400,
                          fontSize: `14px`,
                          lineHeight: `12.1px`,
                        }}
                      >
                        {expense?.name}
                      </TableCell>

                      <TableCell
                        width="25%"
                        sx={{
                          wordWrap: `break-word`,
                          borderRight: `solid rgba(40, 32, 61, 0.15) 1px`,
                        }}
                      >
                        <Typography
                          sx={{
                            display: `block`,
                            width: `100%`,
                            whiteSpace: `normal`,
                            textAlign: `left`,
                            fontSize: `14px`,
                          }}
                        >
                          {expense?.description
                            ? expense?.description
                            : `No description added`}
                        </Typography>
                      </TableCell>
                      <TableCell
                        width="5%"
                        sx={{
                          fontWeight: 400,
                          fontSize: `14px`,
                          lineHeight: `12.1px`,
                          borderRight: `solid rgba(40, 32, 61, 0.15) 1px`,
                        }}
                      >
                        {moment(new Date(expense?.expenseMonth))?.format(
                          `MMM YYYY `,
                        )}
                      </TableCell>
                      <TableCell
                        width="5%"
                        sx={{
                          fontWeight: 400,
                          fontSize: `14px`,
                          lineHeight: `12.1px`,
                          borderRight: `solid rgba(40, 32, 61, 0.15) 1px`,
                        }}
                      >
                        {expense?.date}
                      </TableCell>
                      <TableCell
                        width="10%"
                        sx={{
                          fontSize: `14px`,
                          fontWeight: 400,
                          lineHeight: `12.1px`,
                          borderRight: `solid rgba(40, 32, 61, 0.15) 1px`,
                        }}
                      >{`${expense?.amount.toFixed(2)} `}</TableCell>

                      <TableCell
                        width="10%"
                        sx={{
                          wordWrap: `break-word`,
                          fontSize: `14px`,
                          fontWeight: 400,
                          lineHeight: `12.1px`,
                        }}
                      >{`${expense?.usdAmount.toFixed(2)} `}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Box>
    </Box>
  )
}

export default ExpenseReports
