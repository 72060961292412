const formatAmountWithCommas = (amount) => {
  // Convert the amount to a string
  let amountString = amount.toString()

  // Check if the amount contains a decimal point
  if (amountString.includes(`.`)) {
    // Split the amount into integer and decimal parts
    let [integerPart, decimalPart] = amountString.split(`.`)

    // Format the integer part with commas
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, `,`)

    // Combine the integer and decimal parts back
    return decimalPart ? `${integerPart}.${decimalPart}` : integerPart
  } else {
    // Format the integer part with commas
    return amountString.replace(/\B(?=(\d{3})+(?!\d))/g, `,`)
  }
}

export default formatAmountWithCommas
