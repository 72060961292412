import api from 'app/AxiosInstance'
import { baseUrl } from 'app/Utils/Config'

const token = localStorage.getItem(`AuthToken`)

export const getAllClients = async () => {
  try {
    const {
      data: { data },
    } = await api.get(`${baseUrl}/clients`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (data) {
      return {
        options: data,
      }
    }
  } catch (error) {
    if (error.response) {
      return {
        options: [],
      }
    }
  }
}
