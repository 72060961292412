import React from 'react'
import { Select, MenuItem, FormControl, Box } from '@mui/material'
import { getBgColor, getTextColor } from 'app/Utils/MenuColors'

const CustomDropdown = ({
  options,
  label,
  onChange,
  selectedValue,
  setSelectedValue,
  disabled,
}) => {
  const handleChange = (event) => {
    setSelectedValue(event.target.value)
    if (onChange) {
      onChange(event.target.value)
    }
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="start"
      minWidth="100px"
    >
      <FormControl sx={{ width: `100%`, minWidth: 100 }}>
        <Select
          disabled={disabled}
          placeholder={label}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedValue}
          onChange={handleChange}
          sx={{
            width: `100%`,
            height: `36px`,
            maxWidth: 300,
            bgcolor: getBgColor(selectedValue),
            borderRadius: `20px`,
            border: `none`,
            padding: 0,
            color: getTextColor(selectedValue),
            fontWeight: `500`,
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: `none`,
                padding: `0`,
              },
              '&:hover fieldset': {
                border: `none`,
              },
              '&.Mui-focused fieldset': {
                border: `none`,
              },
              '& .MuiInputBase-input': {
                height: `36px`,
                padding: `0`,
                display: `flex`,
                alignItems: `center`,
                justifyContent: `center`,
                border: `none`,
              },
            },
            '& .MuiSelect-select': {
              display: `flex`,
              alignItems: `center`,
              justifyContent: `center`,
              border: `none`,
              padding: `0 10px`,
              height: `36px`,
            },
            '& .MuiSelect-icon': {
              top: `calc(50% - 12px)`,
              right: `10px`,
            },
          }}
        >
          {options.map((opt) => {
            return (
              <MenuItem value={opt.value} key={opt.value}>
                {opt.label}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </Box>
  )
}

export default CustomDropdown
