import { Box, Typography } from '@material-ui/core'
import React from 'react'
import StyledTextField from '../../Common/StyledTextField'
import PrimaryButton from '../../Common/PrimaryButton'

export default function BusinessDeveloperInvite() {
  const handleCopy = () => {
    const textFieldValue = `https://tms.bracketsltd.com/businessDeveloperForm` // Get the value of the text field
    navigator.clipboard.writeText(textFieldValue) // Copy the value to clipboard
  }
  return (
    <Box
      p={`2rem`}
      minHeight={`100vh`}
      display={`flex`}
      alignItems={`center`}
      flexDirection={`column`}
      gap={`1rem`}
      bgcolor={`#e2e0e4`}
      borderRadius={`10px`}
    >
      <Box
        sx={{
          width: `100%`,
          minHeight: `10rem`,
          bgcolor: `white`,
          display: `flex`,
          flexDirection: `column`,
          justifyContent: `center`,
          alignItems: `center`,
          gap: `1rem`,
          borderRadius: `10px`,
          p: `1rem`,
        }}
      >
        <Typography
          variant="heading2"
          textAlign={{ xs: `center`, md: `initial` }}
        >
          Invite Business Developer Applicant
        </Typography>
        <Box
          display={`flex`}
          flexDirection={{ xs: `column`, md: `row` }}
          gap={`1rem`}
          justifyContent={`center`}
          alignItems={`center`}
          width={{ md: `60%`, xs: `100%` }}
        >
          <StyledTextField
            fullWidth
            readOnly={true}
            value={`https://tms.bracketsltd.com/businessDeveloperForm`}
          />
          <PrimaryButton height="40px" title={`Copy`} onClick={handleCopy} />
        </Box>
      </Box>
    </Box>
  )
}
