import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { SnackbarContext } from '../SnackbarProvider'
import { useHistory } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import api from 'app/AxiosInstance'
import { baseUrl } from 'app/Utils/Config'
import {
  Backdrop,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Popover,
  Typography,
} from '@material-ui/core'
import AddFunds from './AddFunds'
import PrimaryButton from '../Common/PrimaryButton'
import actionIcon from '../../Assets/optionIcon.svg'
import PrependRs from 'app/Utils/PrependRs'
import UpdateFund from './UpdateFund'
import { getRole, isManagement } from 'app/Utils/helper'

const Funds = () => {
  const colors = useSelector((state) => state?.colors)
  const openSnackbar = useContext(SnackbarContext)
  const token = localStorage.getItem(`AuthToken`)
  const history = useHistory()
  const systemRole = getRole()

  const [addFundsModal, setAddFundsModal] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [fund, setFund] = useState(``)
  const [isDeleteFund, setIsDeleteFund] = useState(false)
  const [isUpdateFund, setIsUpdateFund] = useState(false)

  const {
    isLoading,
    data: fundsData,
    refetch,
    isFetching,
  } = useQuery(
    `getFundsDataApi`,
    async () =>
      await api
        .get(
          `${baseUrl}/funds?allocatedBy=&allocatedTo=&startDate=&endDate=&below=&utilized=&exceeded=`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then((res) => {
          return res?.data?.data
        }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onError: (error) => {
        openSnackbar(
          error?.response ? error?.response?.data?.message : error?.message,
          `error`,
        )
      },
    },
  )

  const { mutate: deleteFund, isLoading: deleteLoader } = useMutation(
    async () =>
      await api
        .delete(`${baseUrl}/funds/${fund?._id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data) {
            openSnackbar(`Fund deleted successfully`, `success`)
            setIsDeleteFund(false)
          }
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: () => {
        refetch()
      },

      onError: (error) => {
        openSnackbar(
          error?.response ? error?.response?.data?.message : error?.message,
          `error`,
        )
        setIsDeleteFund(false)
      },
    },
  )

  return (
    <Box
      sx={{
        display: `flex`,
        flexDirection: `column`,
        gap: `10px`,
        paddingX: { xs: `10px`, sm: `20px` },
        paddingBottom: `20px`,
        minHeight: `calc(100vh - 80px)`,
        backgroundColor: `rgba(40, 32, 61, 0.07)`,
      }}
    >
      {addFundsModal && (
        <AddFunds
          open={addFundsModal}
          onClose={() => setAddFundsModal(!addFundsModal)}
          refetchFunds={() => refetch()}
        />
      )}

      <Popover
        disableEnforceFocus
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        sx={{
          '& .MuiPaper-root': {
            width: `120px`,
            height: `max-content`,
            display: `flex`,
            justifyContent: `center`,
            alignItems: `center`,
          },
        }}
        anchorOrigin={{
          vertical: `bottom`,
          horizontal: `left`,
        }}
        transformOrigin={{
          vertical: `top`,
          horizontal: `center`,
        }}
      >
        <Box sx={{ display: `flex`, flexDirection: `column`, width: `100%` }}>
          <Box
            sx={{
              width: `100%`,
              height: `36px`,
              paddingLeft: `16px`,
              display: `flex`,
              alignItems: `center`,
              '&:hover': {
                cursor: `pointer`,
                backgroundColor: `rgba(40, 32, 61, 0.05)`,
              },
            }}
            onClick={(e) => {
              e?.stopPropagation()
              setIsUpdateFund(!isUpdateFund)
              setAnchorEl(null)
            }}
          >
            Edit
          </Box>

          <Box
            sx={{
              width: `100%`,
              height: `36px`,
              paddingLeft: `16px`,
              '&:hover': {
                cursor: `pointer`,
                backgroundColor: `rgba(40, 32, 61, 0.05)`,
              },
              display: `flex`,
              alignItems: `center`,
              backgroundColor: `white`,
              color: `black`,
            }}
            onClick={(e) => {
              e?.stopPropagation()
              setIsDeleteFund(!isDeleteFund)
              setAnchorEl(null)
            }}
          >
            Delete
          </Box>
        </Box>
      </Popover>

      {isUpdateFund && (
        <UpdateFund
          open={isUpdateFund}
          onClose={() => setIsUpdateFund(!isUpdateFund)}
          fundData={fund}
          refetchFunds={() => refetch()}
        />
      )}

      {isDeleteFund && (
        <Dialog open={isDeleteFund}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this fund?
          </DialogContent>
          <DialogActions>
            <Box
              sx={{
                display: `flex`,
                flexDirection: `row`,
                gap: `10px`,
                marginBottom: `10px`,
              }}
            >
              <Box
                sx={{
                  bgcolor: `rgba(40, 32, 61, 0.07)`,
                  color: `black`,
                  width: `85px`,
                  height: `36px`,
                  display: `flex`,
                  justifyContent: `center`,
                  alignItems: `center`,
                  '&:hover': { cursor: `pointer`, opacity: 0.8 },
                }}
                onClick={() => setIsDeleteFund(false)}
              >
                No
              </Box>
              <Box
                sx={{
                  bgcolor: `#FF485E`,
                  color: `white`,
                  width: `85px`,
                  height: `36px`,
                  display: `flex`,
                  justifyContent: `center`,
                  alignItems: `center`,
                  '&:hover': { cursor: `pointer`, opacity: 0.8 },
                }}
                onClick={() => deleteFund()}
              >
                {deleteLoader ? (
                  <CircularProgress
                    size={25}
                    color="secondary"
                    style={{ position: `absolute`, color: `white` }}
                  />
                ) : (
                  `Yes`
                )}
              </Box>
            </Box>
          </DialogActions>
        </Dialog>
      )}

      {systemRole !== `HRM` && isManagement() && (
        <Box
          sx={{
            display: `flex`,
            flexDirection: { xs: `column`, sm: `row` },
            alignItems: `center`,
            justifyContent: `flex-end`,
            gap: `10px`,
          }}
        >
          <PrimaryButton
            title="+ Add Funds"
            onClick={() => setAddFundsModal(!addFundsModal)}
          />
        </Box>
      )}

      {isLoading || isFetching ? (
        <Backdrop open={isLoading || isFetching}>
          <CircularProgress
            sx={{
              position: `absolute`,
              top: `50%`,
              color: colors?.appColor,
            }}
          />
        </Backdrop>
      ) : !fundsData || fundsData?.length === 0 ? (
        <Typography
          sx={{
            display: `flex`,
            justifyContent: `center`,
            alignItems: `center`,
            height: `30vh`,
          }}
        >
          Fund(s) not found for given dates
        </Typography>
      ) : (
        <Grid container spacing={1}>
          {fundsData?.map((data, index) => (
            <Grid item xs={12} key={index}>
              <Box
                sx={{
                  backgroundColor: `white`,
                  display: `flex`,
                  justifyContent: { xs: `flex-start`, sm: `space-between` },
                  alignItems: `flex-start`,
                  flexDirection: { xs: `column`, sm: `row` },
                  gap: `10px`,
                  borderRadius: `10px`,
                  width: `100%`,
                  paddingX: `10px`,
                  cursor: `pointer`,
                }}
                onClick={() => history?.push(`/funds/${data?._id}`, fundsData)}
              >
                <Box
                  sx={{
                    width: { xs: `100%`, sm: `50%` },
                    paddingY: `15px`,
                    paddingX: `10px`,
                  }}
                >
                  <Box
                    sx={{
                      display: `flex`,
                      justifyContent: `space-between`,
                      alignItems: `center`,
                    }}
                  >
                    <Typography
                      sx={{
                        width: `90px`,
                        height: `28px`,
                        fontWeight: 500,
                        fontSize: `14px`,
                        lineHeight: `14.52px`,
                        display: `flex`,
                        justifyContent: `center`,
                        alignItems: `center`,
                        borderRadius: `20px`,
                        backgroundColor:
                          data?.status === `BELOW`
                            ? `rgba(77, 198, 64, 0.12)`
                            : data?.status === `EXCEEDED`
                            ? `rgba(255, 72, 94, 0.12)`
                            : `rgba(37, 187, 251, 0.12)`,
                        color:
                          data?.status === `BELOW`
                            ? `rgba(77, 198, 64, 1)`
                            : data?.status === `EXCEEDED`
                            ? `rgba(255, 72, 94, 1)`
                            : `rgba(37, 187, 251, 1)`,
                      }}
                    >
                      {data?.status === `BELOW`
                        ? `Below`
                        : data?.status === `EXCEEDED`
                        ? `Exceeded`
                        : `Utilized`}
                    </Typography>

                    <Box
                      sx={{
                        display: `flex`,
                        flexDirection: `row`,
                        gap: `10px`,
                        marginX: `5px`,
                        justifyContent: `center`,
                        alignItems: `center`,
                      }}
                    >
                      {systemRole !== `HRM` && isManagement() && (
                        <Box
                          sx={{
                            display: `flex`,
                            justifyContent: `center`,
                            alignItems: `center`,
                            width: `36px`,
                            height: `36px`,
                            '&:hover': {
                              backgroundColor: `rgba(40, 32, 61, 0.07)`,
                              borderRadius: 25,
                              cursor: `pointer`,
                            },
                          }}
                          onClick={(e) => {
                            e?.stopPropagation()
                            setAnchorEl(e?.currentTarget)
                            setFund(data)
                          }}
                        >
                          <img src={actionIcon} alt="actions" />
                        </Box>
                      )}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: `flex`,
                      alignItems: `center`,
                      justifyContent: `space-between`,
                      paddingTop: `10px`,
                      // paddingRight: { xs: `10px`, sm: `40px` },
                      width: { xs: `100%`, sm: `100%` },
                    }}
                  >
                    <Box
                      sx={{
                        display: `flex`,
                        alignItems: `center`,
                        flex: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: `12px`,
                          lineHeight: `14.52px`,
                          color: `rgba(40, 32, 61, 0.6)`,
                        }}
                      >
                        Allocated by:
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: `16px`,
                            lineHeight: `16.94px`,
                            color: `rgba(40, 32, 61, 1)`,
                            paddingTop: `2px`,
                          }}
                        >
                          {`${data?.allocatedBy?.name || ``}`}
                        </Typography>
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: `flex`,
                        alignItems: `center`,
                        flex: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: `12px`,
                          lineHeight: `14.52px`,
                          color: `rgba(40, 32, 61, 0.6)`,
                        }}
                      >
                        Allocated To:
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: `16px`,
                            lineHeight: `16.94px`,
                            color: `rgba(40, 32, 61, 1)`,
                            paddingTop: `2px`,
                          }}
                        >
                          {`${data?.allocatedTo?.name || ``} `}
                        </Typography>
                      </Typography>
                    </Box>
                  </Box>

                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: `12px`,
                      lineHeight: `14.52px`,
                      color: `rgba(40, 32, 61, 0.6)`,
                      textAlign: `justify`,
                      overflow: `hidden`,
                      WebkitLineClamp: 2, // Number of lines to show
                      WebkitBoxOrient: `vertical`,
                      display: `-webkit-box`,
                      marginY: `10px`,
                      width: { xs: `100%`, sm: `90%` },
                    }}
                  >
                    {data?.description
                      ? data?.description
                      : `No description added`}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: { xs: `100%`, sm: `50%` },
                    paddingY: `20px`,
                    paddingX: `10px`,
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      display: `flex`,
                      justifyContent: `space-between`,
                      alignItems: `center`,
                    }}
                  >
                    <Grid item xs={12} sm={4}>
                      <Box
                        sx={{
                          borderRadius: `10px`,
                          backgroundColor: `rgba(3, 227, 252,0.06)`,
                          height: `113px`,
                          display: `flex`,
                          flexDirection: `column`,
                          alignItems: `center`,
                          justifyContent: `center`,
                          gap: `10px`,
                        }}
                      >
                        <Typography>
                          {PrependRs({
                            amount: (data?.total).toFixed(2) || 0,
                            sx: {
                              fontSize: { xs: `18px`, sm: `14px`, md: `18px` },
                              fontWeight: 600,
                              lineHeight: `21.78px`,
                              textAlign: `center`,
                            },
                          })}
                        </Typography>
                        <Typography
                          sx={{
                            color: `rgba(40, 32, 61, 0.6)`,
                            fontSize: { xs: `14px`, sm: `12px`, md: `14px` },
                            fontWeight: 400,
                            lineHeight: `16.94px`,
                            textAlign: `center`,
                          }}
                        >
                          Funds
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Box
                        sx={{
                          borderRadius: `10px`,
                          backgroundColor: `
                          rgba(119, 3, 252, 0.06)`,
                          height: `113px`,
                          display: `flex`,
                          flexDirection: `column`,
                          alignItems: `center`,
                          justifyContent: `center`,
                          gap: `10px`,
                        }}
                      >
                        <Typography>
                          {PrependRs({
                            amount: (data?.utilized).toFixed(2) || 0,
                            sx: {
                              fontSize: { xs: `18px`, sm: `14px`, md: `18px` },
                              fontWeight: 600,
                              lineHeight: `21.78px`,
                              textAlign: `center`,
                            },
                          })}
                        </Typography>
                        <Typography
                          sx={{
                            color: `rgba(40, 32, 61, 0.6)`,
                            fontSize: { xs: `14px`, sm: `12px`, md: `14px` },
                            fontWeight: 400,
                            lineHeight: `16.94px`,
                            textAlign: `center`,
                          }}
                        >
                          Utilized
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Box
                        sx={{
                          borderRadius: `10px`,
                          backgroundColor:
                            data?.utilized > data?.total
                              ? `rgba(255, 72, 94, 0.12)`
                              : `
                              rgba(3, 252, 73,0.06)`,
                          height: `113px`,
                          display: `flex`,
                          flexDirection: `column`,
                          alignItems: `center`,
                          justifyContent: `center`,
                          gap: `10px`,
                        }}
                      >
                        <Typography>
                          {PrependRs({
                            amount: (data?.remaining).toFixed(2) || 0,
                            sx: {
                              fontSize: { xs: `18px`, sm: `14px`, md: `18px` },
                              fontWeight: 600,
                              lineHeight: `21.78px`,
                              textAlign: `center`,
                              color:
                                data?.utilized > data?.total
                                  ? `rgba(255, 72, 94, 1)`
                                  : `rgba(40, 32, 61, 1)`,
                            },
                          })}
                        </Typography>
                        <Typography
                          sx={{
                            color: `rgba(40, 32, 61, 0.6)`,
                            fontSize: { xs: `14px`, sm: `12px`, md: `14px` },
                            fontWeight: 400,
                            lineHeight: `16.94px`,
                            textAlign: `center`,
                          }}
                        >
                          Balance
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  )
}

export default Funds
