import axios from 'axios'
import { baseUrl } from 'app/Utils/Config'

const api = axios.create({
  baseURL: baseUrl, // Replace with your API base URL
})

// Add request interceptor
api.interceptors.request.use(
  (config) => {
    // Add any necessary headers or modify the request config
    // before sending the request
    const token = localStorage.getItem(`AuthToken`)
    if (token) {
      config.headers[`Authorization`] = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    // Handle request error
    return Promise.reject(error)
  },
)

// Add response interceptor
api.interceptors.response.use(
  (response) => {
    // Return the response if it's successful
    return response
  },
  (error) => {
    // Handle response error
    if (error.response) {
      const { status } = error.response

      if (status === 401) {
        // Unauthorized, redirect to login page
        localStorage.removeItem(`AuthToken`)
        window.location.assign(`/`)
      }
      //   else if (status === 403) {
      //     // Forbidden, session expired, redirect to login page or show appropriate message
      //     localStorage.removeItem(`AuthToken`)
      //     window.location.assign(`/`)
      //   }
    }
    return Promise.reject(error)
  },
)

export default api
