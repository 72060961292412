import { Button, CircularProgress } from '@mui/material'
import { ThemeProvider, createTheme } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'

const PrimaryButton = ({
  onClick,
  title,
  type,
  isLoading,
  width,
  hasPayrollBudget,
  sxProps,
  height = `44px`,
  disabled,
}) => {
  const colors = useSelector((state) => state?.colors)
  const buttonTheme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: `none`,
          },
        },
      },
    },
  })

  return (
    <ThemeProvider theme={buttonTheme}>
      <Button
        sx={{
          ...sxProps,
          backgroundColor:
            isLoading || hasPayrollBudget || disabled
              ? `rgba(40, 32, 61, 0.07)`
              : colors?.appColor,
          color: isLoading || hasPayrollBudget ? `black` : `white`,
          borderRadius: `10px`,
          height: height,
          paddingY: `10px`,
          paddingX: `20px`,
          width: { xs: `100%`, md: `${width ? width : `auto`}` },
          '&:hover': {
            backgroundColor: colors?.appColor,
            opacity: `0.8`,
          },
        }}
        onClick={onClick}
        disabled={isLoading || disabled}
        type={type}
      >
        {isLoading ? (
          <React.Fragment>
            <span style={{ marginRight: `5px` }}>{title}</span>
            <CircularProgress
              sx={{ color: `black` }}
              style={{ width: `16px`, height: `16px` }}
            />
          </React.Fragment>
        ) : (
          title
        )}
      </Button>
    </ThemeProvider>
  )
}

export default PrimaryButton
