import { Box, Tab, Typography } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import React, { useState } from 'react'
import Invoices from './Invoices'
import { styled } from '@mui/styles'
import InvoiceTemplates from './InvoiceTemplates'

const Income = () => {
  const TabLabel = styled(`span`)(({ selected }) => ({
    color: selected ? `#6039BB` : ``,
    textTransform: `none`,
  }))
  const [tabValue, setTabValue] = useState(`1`)
  return (
    <Box
      sx={{
        minHeight: `calc(100vh - 80px)`,
        backgroundColor: `rgba(40, 32, 61, 0.07)`,
        paddingX: { xs: `10px`, sm: `20px` },
        paddingBottom: `20px`,
      }}
    >
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: `divider` }}>
          <TabList
            variant="scrollable"
            allowScrollButtonsMobile
            onChange={(e, value) => setTabValue(value)}
            aria-label="lab API tabs example"
            TabIndicatorProps={{ style: { backgroundColor: `#19C9D1` } }}
          >
            <Tab
              label={
                <TabLabel selected={tabValue === `1`}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: {
                        xs: `12px`,
                        sm: `14px`,
                        md: `16px`,
                      },
                    }}
                  >
                    Invoices
                  </Typography>
                </TabLabel>
              }
              value="1"
            />
            <Tab
              label={
                <TabLabel selected={tabValue === `2`}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: {
                        xs: `12px`,
                        sm: `14px`,
                        md: `16px`,
                      },
                    }}
                  >
                    Templates
                  </Typography>
                </TabLabel>
              }
              value="2"
            />
          </TabList>
        </Box>

        <TabPanel value="1" sx={{ p: 0 }}>
          <Invoices />
        </TabPanel>

        <TabPanel value="2" sx={{ p: 0 }}>
          <InvoiceTemplates />
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default Income
