import {
  Box,
  Button,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import { Dialog, DialogTitle, Divider } from '@mui/material'
import React, { useContext } from 'react'
import crossIcon from '../../../Assets/cross_ic.svg'
import { useState } from 'react'
import PrimaryButton from 'app/Components/Common/PrimaryButton'
import SecondaryButton from 'app/Components/Common/SecondaryButton'
import { useSelector } from 'react-redux'
import { useMutation } from 'react-query'
import { baseUrl } from 'app/Utils/Config'
import api from 'app/AxiosInstance'
import { SnackbarContext } from 'app/Components/SnackbarProvider'
import { useEffect } from 'react'
import { getAllUsers } from 'app/Services'

const AddFunds = ({ onClose, open, refetchFunds }) => {
  const colors = useSelector((state) => state?.colors)
  const openSnackbar = useContext(SnackbarContext)
  const loggedInUserId = localStorage.getItem(`loggedInUserId`)

  const [loadAllUsers, setLoadAllUsers] = useState([])
  useEffect(() => {
    getAllUsers().then((result) => {
      const newLoadAllUsers = result?.options
        ?.filter(
          (user) =>
            user?.systemRole !== `EMPLOYEE` && user?.systemRole !== `TR`,
        )
        .map((user) => ({
          label: `${user?.firstName} ${user?.lastName}`,
          value: user?._id,
        }))
      setLoadAllUsers(newLoadAllUsers)
    })
  }, [])

  const [fund, setFund] = useState({
    allocatedBy: ``,
    allocatedTo: ``,
    description: ``,
  })
  const [errors, setErrors] = useState({
    formSubmit: false,
    allocatedTo: false,
    description: false,
  })

  const { mutate: addFund, isLoading } = useMutation(
    async (payload) =>
      await api
        .post(`${baseUrl}/funds`, {
          ...payload,
        })
        .then((res) => {
          if (res?.data) {
            onClose()
            openSnackbar(`Fund added successfully`, `success`)
            res?.data
          }
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: () => {
        refetchFunds()
      },
      onError: (error) => {
        openSnackbar(
          error?.response
            ? error?.response?.data?.message
            : error?.message
            ? error?.message
            : `Something went wrong`,
          `error`,
        )
      },
    },
  )

  const validateForm = () => {
    if (!fund?.allocatedTo?.length) {
      setErrors((prevState) => ({
        ...prevState,
        allocatedTo: true,
      }))
    } else {
      setErrors((prevState) => ({
        ...prevState,
        allocatedTo: false,
      }))
    }
    // if (!fund?.paymentMode?.length) {
    //   setErrors((prevState) => ({
    //     ...prevState,
    //     paymentMode: true,
    //   }))
    // } else {
    //   setErrors((prevState) => ({
    //     ...prevState,
    //     paymentMode: false,
    //   }))
    // }
    // if (!fund?.amount?.length) {
    //   setErrors((prevState) => ({
    //     ...prevState,
    //     amount: true,
    //   }))
    // } else {
    //   setErrors((prevState) => ({
    //     ...prevState,
    //     amount: false,
    //   }))
    // }
    // if (!fund?.date) {
    //   setErrors((prevState) => ({
    //     ...prevState,
    //     date: true,
    //   }))
    // } else {
    //   setErrors((prevState) => ({
    //     ...prevState,
    //     date: false,
    //   }))
    // }
    if (!fund?.description?.length) {
      setErrors((prevState) => ({
        ...prevState,
        description: true,
      }))
    } else {
      setErrors((prevState) => ({
        ...prevState,
        description: false,
      }))
    }
    // if (fund?.paymentMode && fund?.paymentMode === `BANK_TRANSFER`) {
    //   if (fund?.receipt?.length <= 0) {
    //     setErrors((prevState) => ({
    //       ...prevState,
    //       receipt: true,
    //     }))
    //   } else {
    //     setErrors((prevState) => ({
    //       ...prevState,
    //       receipt: false,
    //     }))
    //   }
    // } else if (fund?.paymentMode !== `BANK_TRANSFER`) {
    //   setErrors((prevState) => ({
    //     ...prevState,
    //     receipt: false,
    //   }))
    // }

    setErrors((prevState) => ({
      ...prevState,
      formSubmit: true,
    }))
  }

  useEffect(() => {
    if (
      errors?.formSubmit &&
      // !errors?.amount &&
      // !errors?.date &&
      !errors?.description &&
      // !errors?.paymentMode &&
      // !errors?.receipt &&
      !errors?.allocatedTo
    ) {
      addFund(handleAllocatedBudget())

      setErrors((prevState) => ({
        ...prevState,
        formSubmit: false,
      }))
    } else if (errors?.formSubmit) {
      openSnackbar(`Please fill out all required fields`, `error`)
    }
  }, [errors])

  const handleAllocatedBudget = () => {
    const data = {}
    if (loggedInUserId?.length) {
      data.allocatedBy = loggedInUserId
    }

    if (fund?.allocatedTo?.length) {
      data.allocatedTo = fund?.allocatedTo
    }

    // if (fund?.amount) {
    //   data.total = parseInt(fund?.amount)
    // }
    // if (fund?.date) {
    //   data.date = moment(fund?.date).format(`YYYY-MM-DD`).toString()
    // }

    if (fund?.description?.length) {
      data.description = fund?.description
    }
    // if (fund?.receipt) {
    //   data.receipt = fund?.receipt
    // }

    return data
  }

  const handleFundChange = (name, value) => {
    setFund((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      PaperProps={{
        sx: {
          height: `max-content`,
          borderRadius: `20px`,
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          height: `64px`,
          width: `100%`,
        }}
      >
        <Box
          sx={{
            display: `flex`,
            flexDirection: `row`,
            justifyContent: `space-between`,
            alignItems: `center`,
            backgroundColor: `grey`,
            marginLeft: `1rem`,
            marginRight: `1rem`,
          }}
        >
          <Typography>Add Fund</Typography>
          <Box
            sx={{
              width: `36px`,
              height: `36px`,
              backgroundColor: `white`,
              display: `flex`,
              justifyContent: `center`,
              alignItems: `center`,
            }}
          >
            <Button onClick={onClose}>
              <img src={crossIcon} alt="cross" />
            </Button>
          </Box>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      border: `solid ${colors?.appColor} 2px`,
                    },
                    '&.Mui-focused fieldset': {
                      border: `solid ${colors?.appColor} 2px`,
                    },
                    '& fieldset': {
                      borderRadius: `10px`,
                    },
                  },
                }}
                id="outlined-basic"
                label="Allocated By *"
                name="allocatedBy"
                value={localStorage.getItem(`loggedInEmployeeName`)}
                variant="outlined"
                size="small"
                disabled={true}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              size="small"
              fullWidth
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    border: errors?.allocatedTo
                      ? `solid #FF485E 1px`
                      : `solid ${colors.appColor} 1px`,
                  },
                  '&.Mui-focused fieldset': {
                    border: errors?.allocatedTo
                      ? `solid #FF485E 1px`
                      : `solid ${colors.appColor} 1px`,
                  },
                  '& fieldset': {
                    borderColor: errors?.allocatedTo
                      ? `#FF485E`
                      : `rgba(40, 32, 61, 0.25)`,
                    borderRadius: `10px`,
                  },
                },
                '& .MuiInputLabel-root': {
                  '&.Mui-focused': {
                    color: errors?.allocatedTo
                      ? `#FF485E`
                      : `rgba(40, 32, 61, 0.25)`,
                  },
                },
              }}
            >
              <InputLabel
                id="demo-simple-select-label"
                sx={{
                  color: errors?.allocatedTo
                    ? `#FF485E`
                    : `rgba(40, 32, 61, 0.5)`,
                }}
              >
                Allocated To *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="allocatedTo"
                label="Allocated To *"
                value={fund?.allocatedTo}
                onChange={(e) =>
                  handleFundChange(`allocatedTo`, e.target.value)
                }
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: `300px`,
                    },
                  },
                }}
              >
                {loadAllUsers?.map((user) => (
                  <MenuItem key={user?.value} value={user?.value}>
                    {user?.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl
              fullWidth
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    border: errors?.description
                      ? `solid #FF485E 1px`
                      : `solid ${colors.appColor} 1px`,
                  },
                  '&.Mui-focused fieldset': {
                    border: errors?.description
                      ? `solid #FF485E 1px`
                      : `solid ${colors.appColor} 1px`,
                  },
                  '& fieldset': {
                    borderColor: errors?.description
                      ? `#FF485E`
                      : `rgba(40, 32, 61, 0.25)`,
                    borderRadius: `10px`,
                  },
                },
                '& .MuiInputLabel-root': {
                  '&.Mui-focused': {
                    color: errors?.description
                      ? `#FF485E`
                      : `rgba(40, 32, 61, 0.25)`,
                  },
                },
              }}
            >
              <TextField
                rows={4}
                multiline={true}
                id="outlined-basic"
                label="Description * "
                name="description"
                value={fund?.description}
                variant="outlined"
                size="small"
                onChange={(e) =>
                  handleFundChange(`description`, e?.target?.value)
                }
                InputLabelProps={{
                  style: {
                    color: errors?.description
                      ? `#FF485E`
                      : `rgba(40, 32, 61, 0.5)`,
                  },
                }}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Box
          sx={{
            display: `flex`,
            flexDirection: {
              xs: `column-reverse`,
              sm: `row-reverse`,
            },
            gap: `10px`,
            marginY: `10px`,
          }}
        >
          <PrimaryButton
            title={`Submit`}
            onClick={validateForm}
            isLoading={isLoading}
          />
          <SecondaryButton title="Cancel" onClick={onClose} />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default AddFunds
