import { FormControl, MenuItem, Select, InputLabel } from '@material-ui/core'
import React from 'react'

const MySelectionField = ({
  label,
  options,
  value,
  disabled,
  onChange = () => {},
  labelColor,
  sxProps,
}) => {
  return (
    <>
      <FormControl size="small" fullWidth sx={sxProps}>
        <InputLabel id="demo-simple-select-label" sx={labelColor}>
          {label}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label={label}
          value={value}
          disabled={disabled}
          onChange={onChange}
        >
          {options?.map((option) => (
            <MenuItem
              key={option}
              value={option}
              // sx={{ color: option?.optionColor }}
            >
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}

export default MySelectionField
