import { TextField } from '@material-ui/core'
import React from 'react'

export default function StyledTextField({
  id,
  type,
  name,
  label,
  placeholder,
  defaultValue,
  value,
  onChange,
  readOnly,
  onBlur,
  inputLabelProps,
  fullWidth,
  variant,
  sxProps,
}) {
  return (
    <>
      <TextField
        sx={sxProps}
        id={id}
        fullWidth={fullWidth}
        type={type}
        name={name}
        label={label}
        placeholder={placeholder}
        variant={variant}
        size={`small`}
        defaultValue={defaultValue}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        InputProps={{
          readOnly: readOnly,
        }}
        InputLabelProps={inputLabelProps}
      />
    </>
  )
}
