import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  CircularProgress,
} from '@mui/material'

import { motion } from 'framer-motion'

const DeleteConfirmationDialog = ({ open, onClose, deleteFunc, loading }) => {
  return (
    <Dialog open={open}>
      <motion.div
        initial={{ opacity: 0, x: -10 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -20 }}
        transition={{ duration: 0.5, delay: 0.1 }}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, x: -10 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -20 }}
        transition={{ duration: 0.5, delay: 0.1 }}
      >
        <DialogContent>
          Are you sure you want to delete this client?
        </DialogContent>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, x: -80 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -20 }}
        transition={{ duration: 0.5, delay: 0.1 }}
      >
        <DialogActions>
          <Box
            sx={{
              display: `flex`,
              flexDirection: `row`,
              gap: `10px`,
              marginBottom: `10px`,
            }}
          >
            <Box
              sx={{
                bgcolor: `rgba(40, 32, 61, 0.07)`,
                color: `black`,
                width: `85px`,
                height: `36px`,
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`,
                '&:hover': { cursor: `pointer`, opacity: 0.8 },
              }}
              onClick={onClose}
            >
              No
            </Box>
            <Box
              sx={{
                bgcolor: `#FF485E`,
                color: `white`,
                width: `85px`,
                height: `36px`,
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`,
                '&:hover': { cursor: `pointer`, opacity: 0.8 },
              }}
              onClick={deleteFunc}
            >
              {loading ? (
                <CircularProgress
                  size={25}
                  color="secondary"
                  style={{ position: `absolute`, color: `white` }}
                />
              ) : (
                `yes`
              )}
            </Box>
          </Box>
        </DialogActions>
      </motion.div>
    </Dialog>
  )
}

export default DeleteConfirmationDialog
