import React, { useState } from 'react'
import {
  Select,
  MenuItem,
  FormControl,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  CircularProgress,
} from '@mui/material'
import { getBgColor, getTextColor } from 'app/Utils/MenuColors'

const InvoiceStatusDropDown = ({
  options,
  label,
  onChange,
  onClick,
  selectedValue,
  isUpdating,

  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(``)
  const [value, setValue] = useState(``)

  const handleChange = (event) => {
    setIsOpen(true)
    setValue(event.target.value)
    if (onChange) {
      onChange(event.target.value)
    }
  }

  return (
    <>
      <Dialog open={isOpen}>
        <DialogTitle>Confirm Status Update</DialogTitle>
        <DialogContent>
          Are you sure you want to update the status of this invoice?
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              display: `flex`,
              flexDirection: `row`,
              gap: `10px`,
              marginBottom: `10px`,
            }}
          >
            <Box
              sx={{
                bgcolor: `#FF485E`,
                color: `white`,
                width: `85px`,
                height: `36px`,
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`,
                '&:hover': { cursor: `pointer`, opacity: 0.8 },
              }}
              onClick={() => setIsOpen(false)}
            >
              No
            </Box>
            <Box
              sx={{
                bgcolor: `#31BD3F`,
                color: `white`,
                width: `85px`,
                height: `36px`,
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`,
                '&:hover': { cursor: `pointer`, opacity: 0.8 },
              }}
              onClick={() => onClick(value)}
            >
              {isUpdating ? (
                <CircularProgress
                  size={25}
                  color="secondary"
                  style={{ position: `absolute`, color: `white` }}
                />
              ) : (
                `Yes`
              )}
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="start"
        minWidth="100px"
      >
        <FormControl sx={{ width: `100%`, minWidth: 100 }}>
          <Select
            disabled={disabled}
            placeholder={label}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedValue}
            onChange={handleChange}
            sx={{
              width: `100%`,
              height: `36px`,
              maxWidth: 300,
              bgcolor: getBgColor(selectedValue),
              borderRadius: `20px`,
              border: `none`,
              padding: 0,
              color: getTextColor(selectedValue),
              fontWeight: `500`,
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: `none`,
                  padding: `0`,
                },
                '&:hover fieldset': {
                  border: `none`,
                },
                '&.Mui-focused fieldset': {
                  border: `none`,
                },
                '& .MuiInputBase-input': {
                  height: `36px`,
                  padding: `0`,
                  display: `flex`,
                  alignItems: `center`,
                  justifyContent: `center`,
                  border: `none`,
                },
              },
              '& .MuiSelect-select': {
                display: `flex`,
                alignItems: `center`,
                justifyContent: `center`,
                border: `none`,
                padding: `0 10px`,
                height: `36px`,
              },
              '& .MuiSelect-icon': {
                top: `calc(50% - 12px)`,
                right: `10px`,
              },
            }}
          >
            {options.map((opt) => {
              return (
                <MenuItem value={opt.value} key={opt.value}>
                  {opt.label}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Box>
    </>
  )
}

export default InvoiceStatusDropDown
