import React from 'react'
import { Skeleton, Box, Grid } from '@mui/material'

const SkeletonLoading = (
  <Box
    sx={{
      marginTop: `10px`,
      display: `flex`,
      flexDirection: { xs: `column`, md: `row` },
      alignItems: `center`,
      gap: `20px`,
      borderRadius: `10px`,
      backgroundColor: `white`,
      padding: `10px`,
      position: `relative`,
      '&:hover': {
        cursor: `pointer`,
      },
    }}
  >
    <Box sx={{ flex: 1, width: `100%` }}>
      <Box
        sx={{
          display: `flex`,
          flexDirection: `row`,
          alignItems: `center`,
          gap: `10px`,
          borderRadius: `10px`,
          padding: `20px`,
          backgroundColor: `rgba(25, 201, 209, 0.2)`,
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Skeleton
            variant="circular"
            width={70}
            height={70}
            animation="wave"
          />
        </Box>

        <Box
          sx={{
            display: `flex`,
            flexDirection: `column`,
            width: `100%`,
          }}
        >
          <Skeleton variant="text" width="70%" height={20} animation="wave" />

          <Skeleton variant="text" width="50%" height={20} animation="wave" />
        </Box>
      </Box>
    </Box>

    <Box sx={{ flex: 2, width: `100%` }}>
      <Box
        sx={{
          display: `flex`,
          flexDirection: `column`,
          gap: `20px`,
        }}
      >
        <Box sx={{ display: `flex`, flexDirection: `column` }}>
          <Skeleton variant="text" width="20%" height={20} animation="wave" />
          <Skeleton variant="text" width="70%" height={20} animation="wave" />
        </Box>

        <Box sx={{ display: `flex`, flexDirection: `column` }}>
          <Skeleton variant="text" width="20%" height={20} animation="wave" />

          <Grid container spacing={2}>
            <Grid item xs={12} md={3.5}>
              <Skeleton
                variant="text"
                width="90%"
                height={20}
                animation="wave"
              />
            </Grid>

            <Grid item xs={12} md={3.5}>
              <Skeleton
                variant="text"
                width="90%"
                height={20}
                animation="wave"
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  </Box>
)

export default SkeletonLoading
