import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import reportWebVitals from './reportWebVitals'
import App from './app'
import SnackbarProvider from 'app/Components/SnackbarProvider'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from 'app/Redux/store'
import { ThemeProvider } from '@material-ui/core'
import { customTheme } from 'theme/customTheme'
import { BrowserRouter } from 'react-router-dom/cjs/react-router-dom.min'

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={customTheme}>
      <SnackbarProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </SnackbarProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById(`root`),
)
reportWebVitals()
