import api from 'app/AxiosInstance'
import { baseUrl } from 'app/Utils/Config'

const token = localStorage.getItem(`AuthToken`)

export const getConfiguration = async () => {
  try {
    const {
      data: { data },
    } = await api.get(`${baseUrl}/configurations`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (data) {
      return data[0]
    }
  } catch (error) {
    if (error.response) {
      return 1
    }
  }
}
