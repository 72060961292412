import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentAttendance: {
    firstName: ``,
    lastName: ``,
  },
}

const attendanceSlice = createSlice({
  name: `attendance`,
  initialState,
  reducers: {
    setCurrentAttendance: (state, { payload }) => {
      state.currentAttendance = payload
    },
  },
})

export const { setCurrentAttendance } = attendanceSlice.actions
export const attendance = attendanceSlice.reducer
