import { createSlice } from '@reduxjs/toolkit'

const currentDate = new Date()
const currentMonth = currentDate.getMonth() + 1
const currentYear = currentDate.getFullYear()

const expenseFilterSlice = createSlice({
  name: `expenseFilter`,
  initialState: {
    startDate:
      currentMonth <= 9
        ? `${currentYear}-0${currentMonth}-01`
        : `${currentYear}-${currentMonth}-01`,
    endDate:
      currentMonth <= 9
        ? `${currentYear}-0${currentMonth}-31`
        : `${currentYear}-${currentMonth}-31`,
    account: `All`,
  },
  reducers: {
    applyExpenseFilter: (state, action) => {
      return { ...action.payload }
    },
  },
})

export const { applyExpenseFilter } = expenseFilterSlice.actions
export const expenseFilterReducer = expenseFilterSlice.reducer
