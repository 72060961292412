import api from 'app/AxiosInstance'
import { baseUrl } from 'app/Utils/Config'

const token = localStorage.getItem(`AuthToken`)

export const getAllUsers = async () => {
  try {
    const {
      data: { data },
    } = await api.get(
      `${baseUrl}/users/?search=&sort=&page=1&limit=1000&status=ACTIVE`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    if (data) {
      return {
        options: data?.users,
      }
    }
  } catch (error) {
    if (error.response) {
      return {
        options: [],
      }
    }
  }
}
