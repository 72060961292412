import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  id: null,
  teamMember: ``,
  profilePicture: ``,
  projectManager: ``,
  teamLead: ``,
  hr: ``,
}

const teamMemberSlice = createSlice({
  name: `assessment`,
  initialState,
  reducers: {
    setTeamMember: (state, { payload }) => {
      state.id = payload.id
      state.teamMember = payload.teamMember
      state.profilePicture = payload.profilePicture
      state.projectManager = payload.projectManager
      state.teamLead = payload.teamLead
      state.hr = payload.hr
    },
  },
})

export const { setTeamMember } = teamMemberSlice.actions
export const assessment = teamMemberSlice.reducer
