import React, { useContext, useState } from 'react'
import {
  Box,
  Typography,
  Drawer,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Skeleton,
} from '@material-ui/core'
import filtercross from 'app/Assets/cross.svg'
import editIcon from 'app/Assets/editIcon.svg'
import trash from 'app/Assets/trash.svg'
import file from 'app/Assets/file_ic.svg'
import downloadIcon from 'app/Assets/downloadIcon.svg'
import { useSelector } from 'react-redux'
import { useQuery, useMutation } from 'react-query'
import { SnackbarContext } from 'app/Components/SnackbarProvider'
import api from 'app/AxiosInstance'
import UpdateExpense from '../UpdateExpense'
// import PrependRs from 'app/Utils/PrependRs'
import { Link } from '@material-ui/core'
import PreviewImage from 'app/Components/Gallery/PreviewImage'
import moment from 'moment'

const ExpenseDetail = ({ open, onClose, id, fetchExpenses }) => {
  const colors = useSelector((state) => state.colors)
  const openSnackbar = useContext(SnackbarContext)
  const [deleteExpenseOpen, setDeleteExpenseOpen] = useState(false)
  const [updateExpenseOpen, setUpdateExpenseOpen] = useState(false)
  const loggedInUserId = localStorage.getItem(`loggedInUserId`)
  const {
    data: singleExpense,
    refetch,
    isLoading,
    isFetching,
  } = useQuery(
    `singleExpense`,
    async () => {
      const { data } = await api.get(`/expenses/${id}`)
      return data?.data
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onError: (error) => {
        openSnackbar(
          error?.response ? error?.response?.data?.message : error?.message,
          `error`,
        )
      },
    },
  )

  const deleteExpense = async () => {
    const response = await api.delete(`/expenses/${singleExpense?._id}`)
    return response
  }

  const { mutate: deleteExpenseMutation, isLoading: deleteExpenseMutating } =
    useMutation(deleteExpense, {
      onSuccess: () => {
        setDeleteExpenseOpen(false)
        onClose()
        fetchExpenses()
        openSnackbar(`Expense deleted successfully`, `success`)
      },
      onError: (error) => {
        openSnackbar(
          error.response ? error.response.data.message : error.message,
          `error`,
        )
      },
    })

  const confirmDeleteExpense = () => {
    if (singleExpense?._id) {
      deleteExpenseMutation()
    }
  }

  const closeDeleteExpense = () => {
    if (!deleteExpenseMutating) {
      setDeleteExpenseOpen(false)
    }
  }

  const [previewImage, setPreviewImage] = useState(false)
  const [imageUrl, setImageUrl] = useState()
  const handlePreviewImage = (url) => {
    setImageUrl(url)
    setPreviewImage(true)
  }
  return (
    <>
      {deleteExpenseOpen && (
        <Dialog open={deleteExpenseOpen}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this expense?
          </DialogContent>
          <DialogActions>
            <Box
              sx={{
                display: `flex`,
                flexDirection: `row`,
                gap: `10px`,
                marginBottom: `10px`,
              }}
            >
              <Box
                sx={{
                  bgcolor: `rgba(40, 32, 61, 0.07)`,
                  color: `black`,
                  width: `85px`,
                  height: `36px`,
                  display: `flex`,
                  justifyContent: `center`,
                  alignItems: `center`,
                  '&:hover': { cursor: `pointer`, opacity: 0.8 },
                }}
                onClick={() => closeDeleteExpense()}
              >
                No
              </Box>
              <Box
                sx={{
                  bgcolor: `#FF485E`,
                  color: `white`,
                  width: `85px`,
                  height: `36px`,
                  display: `flex`,
                  justifyContent: `center`,
                  alignItems: `center`,
                  '&:hover': { cursor: `pointer`, opacity: 0.8 },
                }}
                onClick={confirmDeleteExpense}
              >
                {deleteExpenseMutating ? (
                  <CircularProgress
                    size={25}
                    color="secondary"
                    style={{ position: `absolute`, color: `white` }}
                  />
                ) : (
                  `Yes`
                )}
              </Box>
            </Box>
          </DialogActions>
        </Dialog>
      )}
      {updateExpenseOpen && (
        <UpdateExpense
          open={updateExpenseOpen}
          onClose={() => setUpdateExpenseOpen(false)}
          expenseDetail={singleExpense}
          fetchExpenses={() => {
            fetchExpenses(), refetch()
          }}
        />
      )}
      {previewImage && (
        <PreviewImage
          open={previewImage}
          onClose={() => setPreviewImage(false)}
          imageUrl={imageUrl}
        />
      )}
      <Drawer
        disableEnforceFocus
        anchor="right"
        open={open}
        onClose={onClose}
        sx={{
          '& .MuiDrawer-paper': {
            width: { xs: `90%`, sm: `50%`, md: `30%` },
          },
        }}
      >
        {isLoading || isFetching ? (
          <Box sx={{ margin: `20px` }}>
            {[...Array(3)].map((_, index) => (
              <Box key={index}>
                <Box
                  sx={{
                    display: `flex`,
                    gap: `10px`,
                  }}
                >
                  <Skeleton
                    sx={{ bgcolor: `rgba(40, 32, 61, 0.05)` }}
                    animation="wave"
                    variant="circular"
                    width={40}
                    height={40}
                  />
                  <Skeleton
                    animation="wave"
                    height={30}
                    sx={{ bgcolor: `rgba(40, 32, 61, 0.05)`, width: `100%` }}
                  />
                </Box>

                <Skeleton
                  sx={{ bgcolor: `rgba(40, 32, 61, 0.05)`, marginY: `10px` }}
                  animation="wave"
                  variant="rectangular"
                  height={120}
                />
                <Skeleton
                  animation="wave"
                  height={20}
                  sx={{ bgcolor: `rgba(40, 32, 61, 0.05)`, width: `70%` }}
                />
                <Skeleton
                  animation="wave"
                  height={20}
                  sx={{
                    bgcolor: `rgba(40, 32, 61, 0.05)`,
                    width: `50%`,
                    marginBottom: `5px`,
                  }}
                />
              </Box>
            ))}
          </Box>
        ) : (
          <Box
            sx={{
              display: `flex`,
              flexDirection: `column`,
              padding: `30px`,
              gap: `10px`,
            }}
          >
            <img
              src={filtercross}
              alt="cross"
              style={{
                cursor: `pointer`,
                color: `#28203D`,
                opacity: 0.7,
                width: `14px`,
                height: `14px`,
              }}
              onClick={onClose}
            />

            <Box
              sx={{
                display: `flex`,
                flexDirection: `row`,
                justifyContent: `space-between`,
                marginTop: `20px`,
              }}
            >
              <Typography
                sx={{ fontFamily: `Inter`, fontWeight: 600, fontSize: `22px` }}
              >
                Expense
              </Typography>
              {loggedInUserId === singleExpense?.expenseBy?._id && (
                <Box
                  sx={{
                    display: `flex`,
                    flexDirection: `row`,
                    justifyContent: `flex-end`,
                    gap: `10px`,
                  }}
                >
                  <Box
                    sx={{
                      width: `28px`,
                      height: `28px`,
                      display: `flex`,
                      justifyContent: `center`,
                      alignItems: `center`,
                      borderRadius: `30%`,
                      backgroundColor: `rgba(255, 72, 94, 1)`,
                      '&:hover': {
                        cursor: `pointer`,
                        opacity: 0.8,
                      },
                    }}
                    onClick={() => setDeleteExpenseOpen(true)}
                  >
                    <img src={trash} alt="trash" />
                  </Box>

                  <Box
                    sx={{
                      width: `28px`,
                      height: `28px`,
                      display: `flex`,
                      justifyContent: `center`,
                      alignItems: `center`,
                      borderRadius: `30%`,
                      backgroundColor: colors.appColor,
                      '&:hover': {
                        cursor: `pointer`,
                        opacity: 0.8,
                      },
                    }}
                    onClick={() => setUpdateExpenseOpen(true)}
                  >
                    <img src={editIcon} alt="Edit" />
                  </Box>
                </Box>
              )}
            </Box>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: `14px`,
                color: `
rgba(40, 32, 61, 1)`,
                lineHeight: `16.94px`,
              }}
            >
              Business
            </Typography>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: `14px`,
                lineHeight: `16.94px`,
                color: `rgba(96, 57, 187, 1)`,
              }}
            >
              {singleExpense?.business?.name || ``}
            </Typography>

            <Typography
              sx={{
                marginTop: `20px`,
                fontWeight: 600,
                fontSize: `16px`,
                color: `rgba(40, 32, 61, 1)`,
              }}
            >
              Account Detail
            </Typography>
            <Box
              sx={{
                display: `flex`,
                flexDirection: `row`,
                justifyContent: `space-between`,
              }}
            >
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: `14px`,
                  lineHeight: `19.36px`,
                  color: `#28203D`,
                  opacity: 0.4,
                }}
              >
                Account
              </Typography>

              <Typography sx={{ fontWeight: 400, fontSize: `14px` }}>
                {singleExpense?.account?.name || `N/A`}
              </Typography>
            </Box>
            <Box
              sx={{
                display: `flex`,
                flexDirection: `row`,
                justifyContent: `space-between`,
              }}
            >
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: `14px`,
                  lineHeight: `19.36px`,
                  color: `#28203D`,
                  opacity: 0.4,
                }}
              >
                Budget Duration
              </Typography>

              <Typography sx={{ fontWeight: 400, fontSize: `14px` }} noWrap>
                {singleExpense?.accountsBudget
                  ? `${moment(singleExpense.accountsBudget.startDate).format(
                      `MMM DD, YYYY`,
                    )} - ${moment(singleExpense.accountsBudget.endDate).format(
                      `MMM DD, YYYY`,
                    )}`
                  : `N/A`}
              </Typography>
            </Box>

            <Typography
              sx={{
                marginTop: `22px`,
                fontWeight: 600,
                fontSize: `16px`,
              }}
            >
              Expense Detail
            </Typography>
            <Box
              sx={{
                display: `flex`,
                flexDirection: `row`,
                justifyContent: `space-between`,
              }}
            >
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: `14px`,
                  lineHeight: `19.36px`,
                  color: `#28203D`,
                  opacity: 0.4,
                }}
              >
                Expense By
              </Typography>

              <Typography flexWrap sx={{ fontWeight: 400, fontSize: `14px` }}>
                {singleExpense?.expenseBy?.name || `N/A`}
              </Typography>
            </Box>
            <Box
              sx={{
                display: `flex`,
                flexDirection: `row`,
                justifyContent: `space-between`,
              }}
            >
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: `14px`,
                  lineHeight: `19.36px`,
                  color: `#28203D`,
                  opacity: 0.4,
                }}
              >
                {singleExpense?.account?.name
                  ?.toLowerCase()
                  ?.includes(`payroll`)
                  ? `Employee Name`
                  : `Purchased By`}
              </Typography>

              <Typography flexWrap sx={{ fontWeight: 400, fontSize: `14px` }}>
                {singleExpense?.name || `N/A`}
              </Typography>
            </Box>
            <Box
              sx={{
                display: `flex`,
                flexDirection: `row`,
                justifyContent: `space-between`,
              }}
            >
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: `14px`,
                  lineHeight: `19.36px`,
                  color: `#28203D`,
                  opacity: 0.4,
                }}
              >
                Date
              </Typography>

              <Typography sx={{ fontWeight: 400, fontSize: `14px` }}>
                {singleExpense?.date || `N/A`}
              </Typography>
            </Box>

            <Box
              sx={{
                display: `flex`,
                flexDirection: `row`,
                justifyContent: `space-between`,
              }}
            >
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: `14px`,
                  lineHeight: `19.36px`,
                  color: `#28203D`,
                  opacity: 0.4,
                }}
              >
                Expense Month
              </Typography>

              <Typography
                sx={{
                  fontSize: `14px`,
                  fontWeight: 400,
                }}
              >
                {moment(singleExpense?.expenseMonth).format(`MMMM YYYY`)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: `flex`,
                flexDirection: `row`,
                justifyContent: `space-between`,
              }}
            >
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: `14px`,
                  lineHeight: `19.36px`,
                  color: `#28203D`,
                  opacity: 0.4,
                }}
              >
                Currency
              </Typography>

              <Typography
                sx={{
                  fontSize: `14px`,
                  fontWeight: 400,
                }}
              >
                {singleExpense?.currency}
              </Typography>
            </Box>
            <Box
              sx={{
                display: `flex`,
                flexDirection: `row`,
                justifyContent: `space-between`,
              }}
            >
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: `14px`,
                  lineHeight: `19.36px`,
                  color: `#28203D`,
                  opacity: 0.4,
                }}
              >
                Expense Amount
              </Typography>

              <Typography
                sx={{
                  fontSize: `14px`,
                  fontWeight: 400,
                }}
              >
                {`${singleExpense?.originalAmount || 0} ${
                  singleExpense?.currency
                }`}
              </Typography>
            </Box>

            <Box
              sx={{
                padding: `10px`,
                backgroundColor: `rgba(25, 201, 209, 0.1)`,
                borderRadius: `10px`,
                gap: `5px`,
                display: `flex`,
                flexDirection: `column`,
              }}
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: `14px`,
                  lineHeight: `16.94px`,
                }}
              >
                PKR
              </Typography>
              <Box
                sx={{
                  display: `flex`,
                  justifyContent: `space-between`,
                  alignItems: `center`,
                }}
              >
                <Typography
                  sx={{
                    color: `rgba(40, 32, 61, 0.4)`,
                    fontWeight: 400,
                    fontSize: `14px`,
                    lineHeight: `16.94px`,
                  }}
                >
                  Conversion Rate
                </Typography>
                <Typography
                  sx={{
                    color: `rgba(40, 32, 61, 1)`,
                    fontWeight: 400,
                    fontSize: `14px`,
                    lineHeight: `16.94px`,
                  }}
                >{`${singleExpense?.conversionRate} PKR`}</Typography>
              </Box>
              <Box
                sx={{
                  display: `flex`,
                  justifyContent: `space-between`,
                  alignItems: `center`,
                }}
              >
                <Typography
                  sx={{
                    color: `rgba(40, 32, 61, 0.4)`,
                    fontWeight: 400,
                    fontSize: `14px`,
                    lineHeight: `16.94px`,
                  }}
                >
                  Amount
                </Typography>
                <Typography
                  sx={{
                    color: `rgba(40, 32, 61, 1)`,
                    fontWeight: 600,
                    fontSize: `14px`,
                    lineHeight: `16.94px`,
                  }}
                >{`${singleExpense?.amount || 0} PKR`}</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                padding: `10px`,
                backgroundColor: `rgba(25, 201, 209, 0.1)`,
                borderRadius: `10px`,
                gap: `5px`,
                display: `flex`,
                flexDirection: `column`,
              }}
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: `14px`,
                  lineHeight: `16.94px`,
                }}
              >
                PKR - USD
              </Typography>
              <Box
                sx={{
                  display: `flex`,
                  justifyContent: `space-between`,
                  alignItems: `center`,
                }}
              >
                <Typography
                  sx={{
                    color: `rgba(40, 32, 61, 0.4)`,
                    fontWeight: 400,
                    fontSize: `14px`,
                    lineHeight: `16.94px`,
                  }}
                >
                  Conversion Rate
                </Typography>
                <Typography
                  sx={{
                    color: `rgba(40, 32, 61, 1)`,
                    fontWeight: 400,
                    fontSize: `14px`,
                    lineHeight: `16.94px`,
                  }}
                >{`${singleExpense?.usdConversionRate} PKR`}</Typography>
              </Box>
              <Box
                sx={{
                  display: `flex`,
                  justifyContent: `space-between`,
                  alignItems: `center`,
                }}
              >
                <Typography
                  sx={{
                    color: `rgba(40, 32, 61, 0.4)`,
                    fontWeight: 400,
                    fontSize: `14px`,
                    lineHeight: `16.94px`,
                  }}
                >
                  Amount
                </Typography>
                <Typography
                  sx={{
                    color: `rgba(40, 32, 61, 1)`,
                    fontWeight: 600,
                    fontSize: `14px`,
                    lineHeight: `16.94px`,
                  }}
                >{`${
                  singleExpense?.usdAmount?.toFixed(2) || 0
                } USD`}</Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: `flex`,
                flexDirection: `column`,
                gap: `10px`,
              }}
            >
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: `14px`,
                  lineHeight: `19.36px`,
                  color: `#28203D`,
                  opacity: 0.4,
                }}
              >
                Description
              </Typography>

              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: `14px`,
                  textAlign: `justify`,
                }}
              >
                {singleExpense?.description || `N/A`}
              </Typography>
            </Box>
            {singleExpense?.receipts?.length > 0 && (
              <>
                <Typography
                  sx={{
                    marginTop: `22px`,
                    fontWeight: 600,
                    fontSize: `16px`,
                  }}
                >
                  Receipts
                </Typography>

                {singleExpense?.receipts?.map((item, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        display: `flex`,
                        flexDirection: `row`,
                        justifyContent: `space-between`,
                        alignItems: `center`,
                        padding: `10px`,
                        backgroundColor: `rgba(25, 201, 209, 0.3)`,
                        borderRadius: `10px`,
                      }}
                    >
                      <Box
                        sx={{
                          width: `80%`,
                          display: `flex`,
                          flexDirection: `row`,
                          alignItems: `center`,
                          gap: `10px`,
                        }}
                      >
                        <img src={file} alt="file" />
                        <Typography
                          noWrap
                          sx={{
                            fontFamily: `Inter`,
                            fontSize: `14px`,
                            fontWeight: 400,
                          }}
                        >
                          {item.key}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: `flex`,
                          flexDirection: `row`,
                          gap: `10px`,
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: `Inter`,
                            fontSize: `12px`,
                            fontWeight: `bold`,
                            color: colors.appColor,
                            '&:hover': { cursor: `pointer` },
                          }}
                          onClick={() => handlePreviewImage(item.url)}
                        >
                          View
                        </Typography>
                        <Link href={item?.url}>
                          <img
                            width={15}
                            height={15}
                            src={downloadIcon}
                            alt="download"
                          />
                        </Link>
                      </Box>
                    </Box>
                  )
                })}
              </>
            )}
          </Box>
        )}
      </Drawer>
    </>
  )
}

export default ExpenseDetail
