import React, { useState, useContext, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  Divider,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@material-ui/core'
import { SnackbarContext } from 'app/Components/SnackbarProvider'
import { useMutation } from 'react-query'
import api from 'app/AxiosInstance'

// Import Images
import cross from 'app/Assets/cross.svg'

// Common Components
import InputField from '../../../Common/InputField'
import PrimaryButton from 'app/Components/Common/PrimaryButton'
import SecondaryButton from 'app/Components/Common/SecondaryButton'
import MySelectionField from 'app/Components/Common/MySelectionField'

// Third Party
import { Country, State } from 'country-state-city'
import { motion } from 'framer-motion'

const statusOptions = [
  {
    id: `ACTIVE`,
    name: `Active`,
  },
  {
    id: `INACTIVE`,
    name: `Inactive`,
  },
]

const UpdateVendor = (props) => {
  const { open, onClose, vendorInitialData, refetchAllVendors } = props

  const openSnackbar = useContext(SnackbarContext)
  const [addVendorState, setAddVendorState] = useState({})
  const [status, setStatus] = useState(`ACTIVE`)

  useEffect(() => {
    if (Object.keys(vendorInitialData)?.length > 0) {
      const filterObject = () => {
        const filteredObject = {}
        Object.entries(vendorInitialData).forEach(([key, value]) => {
          if (value !== null && !(Array.isArray(value) && value.length === 0)) {
            filteredObject[key] = value
          }
        })
        return filteredObject
      }

      setAddVendorState(filterObject())
      setStatus(vendorInitialData?.status)
    }
  }, [vendorInitialData])

  const updateVendor = async ({ payload, _id }) => {
    const response = await api.patch(`/vendors/${_id}`, payload)
    return response
  }

  const { mutate: updateVendorMutation, isLoading: isMutatingUpdateVendor } =
    useMutation(updateVendor, {
      onSuccess: () => {
        onClose()

        refetchAllVendors()
        openSnackbar(`Updated Successfull`, `success`)
      },
      onError: (error) => {
        openSnackbar(
          error?.response ? error.response.data.message : error.message,
          `error`,
        )
      },
    })

  const handleSubmitForm = () => {
    if (isMutatingUpdateVendor) {
      return // Do nothing if either mutation is in progress
    }

    if (Object.keys(addVendorState).length === 0) {
      openSnackbar(`Ops!! Empty Form.`)
      return
    }

    const { createdAt, updatedAt, __v, _id, ...rest } = addVendorState

    const payload = Object.fromEntries(
      Object.entries(rest).filter((arr) => arr[1] !== `` && arr[1] !== null),
    )

    updateVendorMutation({ payload, _id })
  }

  const handleInputChange = (key, val) => {
    setAddVendorState((prev) => ({ ...prev, [key]: val }))
  }

  const getSxProps = () => {
    return {
      '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
          border: `solid rgba(40, 32, 61, 0.25) 1px`,
        },
        '&.Mui-focused fieldset': {
          border: `solid rgba(40, 32, 61, 0.25) 1px`,
        },
        '& fieldset': {
          borderColor: `rgba(40, 32, 61, 0.25)`,
          borderRadius: `10px`,
        },
      },
      '& .MuiInputLabel-root': {
        '&.Mui-focused': {
          color: `rgba(40, 32, 61, 0.5)`,
        },
      },
    }
  }

  const getInputLabelProps = () => {
    return {
      style: {
        color: `rgba(40, 32, 61, 0.5)`,
      },
    }
  }

  const getSelectionFieldLabelColor = () => {
    return {
      color: `rgba(40, 32, 61, 0.5)`,
    }
  }

  const allCountries = Country.getAllCountries()
  const countriesArray = allCountries.map((country) => country.name)
  const [statesArray, setStatesArray] = useState([])

  useEffect(() => {
    if (addVendorState?.country) {
      const foundSelectedCountry = allCountries.find(
        (country) => country.name === addVendorState.country,
      )

      const statesArray = State.getStatesOfCountry(foundSelectedCountry.isoCode)

      const statesNames = statesArray.map((state) => state.name)

      setStatesArray(statesNames)
    }
  }, [addVendorState.country])

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      PaperProps={{
        sx: {
          borderRadius: `20px 20px 20px 20px`,
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          height: `max-content`,
          display: `flex`,
          flexDirection: `column`,
        }}
      >
        <Box
          sx={{
            display: `flex`,
            justifyContent: `space-between`,
            alignItems: `center`,
            flexDirection: `row`,
            paddingBottom: `10px`,
          }}
        >
          <Typography
            sx={{
              fontFamily: `Inter`,
              fontSize: {
                lg: 15,
                md: 13,
                sm: 10,
                xs: 8,
              },
            }}
          >
            Update Business
          </Typography>
          <Box
            sx={{
              width: `36px`,
              height: `36px`,
              display: `flex`,
              justifyContent: `center`,
              alignItems: `centrer`,
              padding: `11px`,
              '&:hover': {
                cursor: `pointer`,
                opacity: 0.8,
              },
            }}
            onClick={onClose}
          >
            <img src={cross} alt="cross" width="13.87px" height="14px" />
          </Box>
        </Box>
        <Divider sx={{ width: `100%` }} />
      </DialogTitle>

      <DialogContent
        sx={{
          display: `flex`,
          flexDirection: `column`,
          gap: `20px`,
          overflowY: `auto`,
        }}
      >
        <Grid
          container
          rowSpacing={2}
          columnSpacing={4}
          sx={{ paddingTop: `10px` }}
        >
          <Grid item xs={12} sm={6}>
            <motion.div
              initial={{ opacity: 0, y: -40 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <InputField
                type="text"
                label="Name"
                defaultValue=""
                value={addVendorState.name}
                onChange={(evt) => handleInputChange(`name`, evt.target.value)}
                readOnly={false}
                inputLabelProps={getInputLabelProps()}
                sxProps={getSxProps()}
              />
            </motion.div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <motion.div
              initial={{ opacity: 0, y: -40 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <InputField
                label="Phone Number"
                value={addVendorState.phone}
                onChange={(evt) => handleInputChange(`phone`, evt.target.value)}
                inputLabelProps={getInputLabelProps()}
                sxProps={getSxProps()}
              />
            </motion.div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <motion.div
              initial={{ opacity: 0, y: -40 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <InputField
                type="text"
                label="Email"
                defaultValue=""
                value={addVendorState.email}
                onChange={(evt) => handleInputChange(`email`, evt.target.value)}
                readOnly={false}
                inputLabelProps={getInputLabelProps()}
                sxProps={getSxProps()}
              />
            </motion.div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <motion.div
              initial={{ opacity: 0, y: -40 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <MySelectionField
                label="Country"
                options={countriesArray}
                value={addVendorState.country}
                disabled={false}
                onChange={(evt) =>
                  handleInputChange(`country`, evt.target.value)
                }
                labelColor={getSelectionFieldLabelColor()}
                sxProps={getSxProps()}
              />
            </motion.div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <motion.div
              initial={{ opacity: 0, y: -40 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <MySelectionField
                label="State"
                options={statesArray}
                value={addVendorState.state}
                disabled={false}
                onChange={(evt) => handleInputChange(`state`, evt.target.value)}
                labelColor={getSelectionFieldLabelColor()}
                sxProps={getSxProps()}
              />
            </motion.div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <motion.div
              initial={{ opacity: 0, y: -40 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <InputField
                type="text"
                label="Address"
                defaultValue=""
                value={addVendorState.address}
                onChange={(evt) =>
                  handleInputChange(`address`, evt.target.value)
                }
                readOnly={false}
                inputLabelProps={getInputLabelProps()}
                sxProps={getSxProps()}
              />
            </motion.div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <motion.div
              initial={{ opacity: 0, y: -40 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <FormControl
                fullWidth
                size="small"
                sx={{
                  backgroundColor: `white`,
                  borderRadius: `10px`,
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      border: `solid rgba(40, 32, 61, 0.5), 1px`,
                    },
                    '&.Mui-focused fieldset': {
                      border: `solid rgba(40, 32, 61, 0.5) 1px`,
                    },
                    '& fieldset': {
                      borderColor: `rgba(40, 32, 61, 0.25)`,
                      borderRadius: `10px`,
                    },
                  },
                  '& .MuiInputLabel-root': {
                    '&.Mui-focused': {
                      color: `rgba(40, 32, 61, 0.25)`,
                    },
                  },
                }}
              >
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{
                    color: `rgba(40, 32, 61, 0.5)`,
                  }}
                >
                  Status *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  label="Status *"
                  value={status || `ACTIVE`}
                  onChange={(e) => setStatus(e?.target?.value)}
                >
                  {statusOptions &&
                    statusOptions?.map((status) => (
                      <MenuItem value={status?.id} key={status?.id}>
                        {status?.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </motion.div>
          </Grid>
        </Grid>
      </DialogContent>

      <motion.div
        initial={{ opacity: 0, x: -1000 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -20 }}
        transition={{ duration: 1, delay: 0.1 }}
      >
        <Box
          sx={{
            display: `flex`,
            flexDirection: { xs: `column`, sm: `row` },
            justifyContent: `flex-end`,
            gap: `10px`,
            padding: `20px`,
          }}
        >
          <SecondaryButton title="Cancel" onClick={() => onClose()} />

          <PrimaryButton
            title={isMutatingUpdateVendor ? `Updating...` : `Update Business`}
            isLoading={isMutatingUpdateVendor}
            onClick={() => handleSubmitForm()}
          />
        </Box>
      </motion.div>
      <Divider />
    </Dialog>
  )
}

export default UpdateVendor
