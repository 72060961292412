import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import React from 'react'
import ApplicantBgTableCell from './ApplicantBGTableCell'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { APPLICANT_TABLE_HEAD_DATA } from 'app/Utils/data'

export default function ApplicantsTable({
  applicantData,
  isLoading,
  isNoData,
}) {
  const history = useHistory()

  return (
    <Box
      sx={{
        bgcolor: `white`,
        pt: `10px`,
        borderRadius: `10px`,
        minHeight: `70vh`,
      }}
    >
      <TableContainer component={Box}>
        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {APPLICANT_TABLE_HEAD_DATA.map((cellName, index) => {
                return (
                  <TableCell
                    sx={{ p: `0 16px`, pb: `5px`, maxWidth: `80px` }}
                    key={index}
                  >
                    <Typography variant="bodyText">{cellName}</Typography>
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {isLoading ? (
              <TableRow rowSpan={7}>
                <TableCell colSpan={7} align="center">
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="500px"
                    width="100%"
                  >
                    <CircularProgress />
                  </Box>
                </TableCell>
              </TableRow>
            ) : isNoData ? (
              <TableRow rowSpan={7}>
                <TableCell colSpan={7} align="center">
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="500px"
                    width="100%"
                  >
                    <Typography variant="heading1">No Data Found!</Typography>
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              applicantData?.map((applicant, index) => (
                <TableRow
                  key={index}
                  onClick={() =>
                    history.push(
                      `/businessDeveloperApplicant/ApplicationDetail/${applicant._id}`,
                    )
                  }
                >
                  <TableCell
                    colSpan={2}
                    sx={{
                      minWidth: `120px`,
                      maxWidth: `150px`,
                      overflowWrap: `break-word`,
                      py: `10px`,
                      pr: `10px`,
                    }}
                  >
                    {applicant.firstName} {applicant.lastName}
                  </TableCell>
                  <TableCell
                    sx={{
                      minWidth: `80px`,
                      maxWidth: `80px`,
                      py: `10px`,
                      pr: `10px`,
                      overflowWrap: `break-word`,
                    }}
                  >
                    {applicant.date}
                  </TableCell>
                  <TableCell
                    sx={{
                      minWidth: `80px`,
                      maxWidth: `80px`,
                      overflowWrap: `break-word`,
                      py: `10px`,
                      pr: `10px`,
                    }}
                  >
                    {applicant.experience} years
                  </TableCell>
                  <TableCell
                    sx={{
                      minWidth: `100px`,
                      maxWidth: `100px`,
                      overflowWrap: `break-word`,
                      py: `10px`,
                      pr: `10px`,
                    }}
                  >
                    {applicant.phone}
                  </TableCell>
                  <TableCell
                    sx={{
                      minWidth: `100px`,
                      maxWidth: `140px`,
                      overflowWrap: `break-word`,
                      py: `10px`,
                      pr: `10px`,
                    }}
                  >
                    {applicant.email}
                  </TableCell>

                  <ApplicantBgTableCell status={applicant.status} />
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
