import {
  Typography,
  DialogTitle,
  Box,
  DialogContent,
  Divider,
  Dialog,
  FormControl,
  CircularProgress,
  Grid,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@material-ui/core'
import React from 'react'
import { useState, useContext, useRef } from 'react'
import api from 'app/AxiosInstance'
import { useMutation } from 'react-query'
import cross from 'app/Assets/cross.svg'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { SnackbarContext } from 'app/Components/SnackbarProvider'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useSelector } from 'react-redux'
// import { useDispatch } from 'react-redux'
// import { applyUser } from 'app/Redux/slices/user'

const ChangePassword = ({ open, onClose }) => {
  //   const addRef = useRef()
  //   const dispatch = useDispatch()
  const history = useHistory()
  const colors = useSelector((state) => state.colors)
  const openSnackbar = useContext(SnackbarContext)
  const [showOldPassword, setShowOldPassword] = React.useState(false)
  const [showNewPassword, setShowNewPassword] = React.useState(false)
  const [showNewPasswordAgain, setShowNewPasswordAgain] = React.useState(false)

  // show password

  const handleClickShowOldPassword = () => setShowOldPassword((show) => !show)

  const handleMouseDownOldPassword = (event) => {
    event.preventDefault()
  }

  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show)

  const handleMouseDownNewPassword = (event) => {
    event.preventDefault()
  }

  const handleClickShowNewPasswordAgain = () =>
    setShowNewPasswordAgain((show) => !show)

  const handleMouseDownNewPasswordAgain = (event) => {
    event.preventDefault()
  }

  const [oldPassword, setOldPassword] = useState()
  const [newPassword, setNewPassword] = useState()
  const [newPasswordAgain, setNewPasswordAgain] = useState()

  const handleOldPasswordChange = (value) => {
    setOldPassword(value)
  }

  const handleNewPasswordChange = (value) => {
    setNewPassword(value)
  }

  const handleNewPasswordAgainChange = (value) => {
    setNewPasswordAgain(value)
  }

  const changePasswordRequest = async (payLoad) => {
    const response = await api.patch(`/users/change/password`, payLoad)
    return response
  }

  const { mutate: changePasswordMutation, isLoading: isMutating } = useMutation(
    changePasswordRequest,
    {
      onSuccess: async (response) => {
        openSnackbar(response.data.data, `success`)
        history.push(`/`)
      },
      onError: (error) => {
        openSnackbar(
          error.response ? error.response.data.message : error.message,
          `error`,
        )
      },
    },
  )

  const [formErrors, setFormErrors] = useState({})

  const validateForm = () => {
    let hasErrors = false
    const formErrors = {}

    if (!oldPassword) {
      formErrors.oldPassword = true
      hasErrors = true
    }

    if (!newPassword) {
      formErrors.newPassword = true
      hasErrors = true
    }

    if (!newPasswordAgain) {
      formErrors.newPasswordAgain = true
      hasErrors = true
    }

    setFormErrors(formErrors)
    return hasErrors
  }

  const handleChangePasswordClick = () => {
    const hasErrors = validateForm()

    if (hasErrors) {
      openSnackbar(
        `Please ensure all mandatory fields are completed with the necessary information.`,
        `error`,
      )
      return
    }

    if (oldPassword.length < 3) {
      openSnackbar(
        `Your previous password must be at least 3 characters long.`,
        `error`,
      )
      return
    }

    if (newPassword.length < 3 || newPasswordAgain.length < 3) {
      openSnackbar(`Password must be atleast 3 characters long`, `error`)
      return
    }

    if (newPassword !== newPasswordAgain) {
      openSnackbar(`Make sure your password is same`, `error`)
      return
    }

    if (!isMutating) {
      const payLoad = {
        oldPassword: oldPassword,
        newPassword: newPassword,
        newPasswordAgain: newPasswordAgain,
      }
      changePasswordMutation(payLoad)
    }
  }

  const newPasswordAgainRef = useRef(null)
  // handle enter key press
  const handleKeyDown = (event) => {
    if (event.key === `Enter`) {
      let hasErrors = validate()
      if (!hasErrors) {
        newPasswordAgainRef.current.click()
      } else {
        openSnackbar(
          `Please ensure all mandatory fields are completed with the necessary information.`,
          `error`,
        )
      }
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={!isMutating && onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            width: `350px`,
            height: `max-content`,
            borderRadius: `20px 20px 20px 20px`,
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            height: `48px`,
            display: `flex`,
            flexDirection: `row-reverse`,
            justifyContent: `space-between`,
            alignItems: `center`,
          }}
        >
          <Box
            sx={{
              width: `36px`,
              height: `36px`,
              display: `flex`,
              justifyContent: `center`,
              alignItems: `centrer`,
              padding: `11px`,
              '&:hover': {
                cursor: `pointer`,
                opacity: 0.8,
              },
            }}
            onClick={!isMutating && onClose}
          >
            <img src={cross} alt="cross" width="13.87px" height="14px" />
          </Box>
          <Typography
            sx={{ fontFamily: `Inter`, fontWeight: 600, fontSize: `16px` }}
          >
            Change Password
          </Typography>
        </DialogTitle>
        <Divider sx={{ width: `100%` }} />

        <DialogContent
          sx={{
            display: `flex`,
            flexDirection: `column`,
            gap: `40px`,
            alignItems: `center`,
          }}
        >
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <FormControl
                size="small"
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      border: formErrors.oldPassword
                        ? `solid #FF485E 1px`
                        : `solid ${colors.appColor} 1px`,
                    },
                    '&.Mui-focused fieldset': {
                      border: formErrors.oldPassword
                        ? `solid #FF485E 1px`
                        : `solid ${colors.appColor} 1px`,
                    },
                    '& fieldset': {
                      borderColor: formErrors.oldPassword
                        ? `#FF485E`
                        : oldPassword
                        ? colors.appColor
                        : `rgba(40, 32, 61, 0.25)`,
                      borderRadius: `10px`,
                    },
                  },
                }}
              >
                <InputLabel
                  // htmlFor="outlined-adornment-password"
                  InputLabelProps={{
                    style: {
                      color: formErrors.oldPassword
                        ? `#FF485E`
                        : oldPassword
                        ? colors.appColor
                        : `rgba(40, 32, 61, 0.5)`,
                    },
                  }}
                >
                  Old *
                </InputLabel>
                <OutlinedInput
                  //   id="outlined-adornment-password"
                  type={showOldPassword ? `text` : `password`}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowOldPassword}
                        onMouseDown={handleMouseDownOldPassword}
                        edge="end"
                      >
                        {showOldPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  variant="outlined"
                  label="Old Password *"
                  value={oldPassword}
                  onChange={(event) =>
                    handleOldPasswordChange(event.target.value)
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                size="small"
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      border: formErrors.newPassword
                        ? `solid #FF485E 1px`
                        : `solid ${colors.appColor} 1px`,
                    },
                    '&.Mui-focused fieldset': {
                      border: formErrors.newPassword
                        ? `solid #FF485E 1px`
                        : `solid ${colors.appColor} 1px`,
                    },
                    '& fieldset': {
                      borderColor: formErrors.newPassword
                        ? `#FF485E`
                        : newPassword
                        ? colors.appColor
                        : `rgba(40, 32, 61, 0.25)`,
                      borderRadius: `10px`,
                    },
                  },
                }}
              >
                <InputLabel
                  // htmlFor="outlined-adornment-password"
                  InputLabelProps={{
                    style: {
                      color: formErrors.newPassword
                        ? `#FF485E`
                        : newPassword
                        ? colors.appColor
                        : `rgba(40, 32, 61, 0.5)`,
                      fontSize: `8px`,
                    },
                  }}
                >
                  New *
                </InputLabel>
                <OutlinedInput
                  // id="outlined-adornment-password"
                  type={showNewPassword ? `text` : `password`}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowNewPassword}
                        onMouseDown={handleMouseDownNewPassword}
                        edge="end"
                      >
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  variant="outlined"
                  label="New Password *"
                  value={newPassword}
                  onChange={(event) =>
                    handleNewPasswordChange(event.target.value)
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                size="small"
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      border: formErrors.newPasswordAgain
                        ? `solid #FF485E 1px`
                        : `solid ${colors.appColor} 1px`,
                    },
                    '&.Mui-focused fieldset': {
                      border: formErrors.newPasswordAgain
                        ? `solid #FF485E 1px`
                        : `solid ${colors.appColor} 1px`,
                    },
                    '& fieldset': {
                      borderColor: formErrors.newPasswordAgain
                        ? `#FF485E`
                        : newPasswordAgain
                        ? colors.appColor
                        : `rgba(40, 32, 61, 0.25)`,
                      borderRadius: `10px`,
                    },
                  },
                }}
              >
                <InputLabel
                  // htmlFor="outlined-adornment-password"
                  InputLabelProps={{
                    style: {
                      color: formErrors.newPasswordAgain
                        ? `#FF485E`
                        : newPasswordAgain
                        ? colors.appColor
                        : `rgba(40, 32, 61, 0.5)`,
                      fontSize: `8px`,
                    },
                  }}
                >
                  New Again *
                </InputLabel>
                <OutlinedInput
                  // id="outlined-adornment-password"
                  type={showNewPasswordAgain ? `text` : `password`}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowNewPasswordAgain}
                        onMouseDown={handleMouseDownNewPasswordAgain}
                        edge="end"
                      >
                        {showNewPasswordAgain ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  variant="outlined"
                  label="New Password Again *"
                  value={newPasswordAgain}
                  onKeyDown={handleKeyDown}
                  onChange={(event) =>
                    handleNewPasswordAgainChange(event.target.value)
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Box
            sx={{
              width: `100%`,
              display: `flex`,
              flexDirection: `row`,
              justifyContent: `flex-end`,
              gap: `10px`,
            }}
          >
            <Box
              sx={{
                bgcolor: `rgba(40, 32, 61, 0.07)`,
                color: `black`,
                width: `85px`,
                height: `36px`,
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`,
                borderRadius: `10px`,
                '&:hover': { cursor: `pointer`, opacity: 0.8 },
              }}
              onClick={!isMutating && onClose}
            >
              Cancel
            </Box>
            <Box
              ref={newPasswordAgainRef}
              sx={{
                bgcolor: colors.appColor,
                color: `white`,
                width: `85px`,
                height: `36px`,
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`,
                borderRadius: `10px`,
                '&:hover': { cursor: `pointer`, opacity: 0.8 },
              }}
              onClick={handleChangePasswordClick}
              //   ref={addRef}
            >
              {isMutating ? (
                <CircularProgress
                  size={25}
                  color="secondary"
                  style={{ position: `absolute`, color: `white` }}
                />
              ) : (
                `Change`
              )}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ChangePassword
