import React, { useState, useContext, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  Divider,
  Grid,
  Checkbox,
  Autocomplete,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@material-ui/core'
import { SnackbarContext } from 'app/Components/SnackbarProvider'
import { useQuery, useMutation } from 'react-query'
import api from 'app/AxiosInstance'

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

// Mui Icons
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

// Import Images
import cross from 'app/Assets/cross.svg'
import userIcon from 'app/Assets/user_ic.svg'

// Common Components
import InputField from '../../../Common/InputField'
import PrimaryButton from 'app/Components/Common/PrimaryButton'
import SecondaryButton from 'app/Components/Common/SecondaryButton'
import MultilineInputField from 'app/Components/Common/MultilineInputField'
import MySelectionField from 'app/Components/Common/MySelectionField'

// Third Party
import { Country, State } from 'country-state-city'
import { motion, AnimatePresence } from 'framer-motion'

const statusOptions = [
  {
    id: `ACTIVE`,
    name: `Active`,
  },
  {
    id: `INACTIVE`,
    name: `Inactive`,
  },
]

const UpdateClient = (props) => {
  const { open, onClose, clientInitialData, refetchAllClients } = props

  const [projects, setProjects] = useState([
    { id: 1, name: `veronica` },
    { id: 2, name: `monitair` },
  ])

  const [projectsArray, setProjectsArray] = useState([])

  useEffect(() => {
    if (clientInitialData?.profilePicture) {
      setProfilePic(clientInitialData.profilePicture.url)
    }
  }, [clientInitialData?.profilePicture])

  useEffect(() => {
    if (clientInitialData?.projects?.length > 0) {
      setProjectsArray(clientInitialData?.projects)
    }
  }, [clientInitialData?.projects])

  useQuery(
    `allprojects`,
    async () => {
      const { data } = await api.get(`/projects/?search=&sort=&page=&limit=`)

      if (data) {
        setProjects(data.data.projects)
      }
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onError: (error) => {
        openSnackbar(
          error?.response ? error?.response?.data?.message : error?.message,
          `error`,
        )
      },
    },
  )

  const openSnackbar = useContext(SnackbarContext)
  const [addClientState, setAddClientState] = useState({})
  const [status, setStatus] = useState(`ACTIVE`)

  useEffect(() => {
    if (Object.keys(clientInitialData)?.length > 0) {
      const filterObject = () => {
        const filteredObject = {}
        Object.entries(clientInitialData).forEach(([key, value]) => {
          if (value !== null && !(Array.isArray(value) && value.length === 0)) {
            filteredObject[key] = value
          }
        })
        return filteredObject
      }

      setAddClientState(filterObject())
      setStatus(clientInitialData?.status)
    }
  }, [clientInitialData])

  const updateClient = async ({ payload, _id }) => {
    const response = await api.patch(`/clients/${_id}`, payload)
    return response
  }

  const { mutate: updateClientMutation, isLoading: isMutatingUpdateClient } =
    useMutation(updateClient, {
      onSuccess: () => {
        onClose()

        refetchAllClients()
        openSnackbar(`Updated Successfull`, `success`)
      },
      onError: (error) => {
        openSnackbar(
          error?.response ? error.response.data.message : error.message,
          `error`,
        )
      },
    })

  const handleSubmitForm = () => {
    if (isMutatingUpdateClient || isMutatingPicture) {
      return // Do nothing if either mutation is in progress
    }

    if (
      Object.keys(addClientState).length === 0 &&
      projectsArray.length === 0
    ) {
      openSnackbar(`Ops!! Empty Form.`)
      return
    }

    const { _id, ...rest } = addClientState

    const projectsIds = projectsArray.map((project) => project._id)
    const addClientStateWithProjects = {
      ...rest,
      // ...(projectsIds.length > 0 && { projects: projectsIds }),
      projects: projectsIds,
      status: status,
    }

    const payload = Object.fromEntries(
      Object.entries(addClientStateWithProjects).filter(
        (arr) => arr[1] !== `` && arr[1] !== null,
      ),
    )

    updateClientMutation({ payload, _id })
  }

  const handleInputChange = (key, val) => {
    setAddClientState((prev) => ({ ...prev, [key]: val }))
  }

  const getSxProps = () => {
    return {
      '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
          border: `solid rgba(40, 32, 61, 0.25) 1px`,
        },
        '&.Mui-focused fieldset': {
          border: `solid rgba(40, 32, 61, 0.25) 1px`,
        },
        '& fieldset': {
          borderColor: `rgba(40, 32, 61, 0.25)`,
          borderRadius: `10px`,
        },
      },
      '& .MuiInputLabel-root': {
        '&.Mui-focused': {
          color: `rgba(40, 32, 61, 0.5)`,
        },
      },
    }
  }

  const getInputLabelProps = () => {
    return {
      style: {
        color: `rgba(40, 32, 61, 0.5)`,
      },
    }
  }

  const getSelectionFieldLabelColor = () => {
    return {
      color: `rgba(40, 32, 61, 0.5)`,
    }
  }

  const [profilePic, setProfilePic] = useState()

  const uploadPicture = async (file) => {
    const formData = new FormData()
    formData.append(`document`, file)
    formData.append(`folderName`, `profile/`)
    const response = await api.post(`/users/upload-document`, formData)
    return response.data.data
  }

  const { mutate: uploadPictureMutation, isLoading: isMutatingPicture } =
    useMutation(uploadPicture, {
      onSuccess: (data) => {
        openSnackbar(`Picture Uploaded Successfully!!`, `success`)
        const updatedAddClientData = {
          ...addClientState,
          profilePicture: {
            url: data.attachment,
            key: data.key,
          },
        }
        setProfilePic(data.temporaryLink)
        setAddClientState(updatedAddClientData)
      },
      onError: (error) => {
        openSnackbar(
          error.response ? error.response.data.message : error.message,
          `error`,
        )
      },
    })

  const handleImagePick = (event) => {
    const file = event.target.files[0]
    setProfilePic(URL.createObjectURL(file))
    uploadPictureMutation(file)
  }

  const handleDeleteImage = () => {
    if (!isMutatingPicture) {
      setProfilePic(null)
    }
  }

  // const handleProjectsChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event
  //   setProjectsArray(
  //     // On autofill we get a stringified value.
  //     typeof value === `string` ? value.split(`,`) : value,
  //   )
  // }

  const allCountries = Country.getAllCountries()
  const countriesArray = allCountries.map((country) => country.name)
  const [statesArray, setStatesArray] = useState([])

  useEffect(() => {
    if (addClientState?.country) {
      const foundSelectedCountry = allCountries.find(
        (country) => country.name === addClientState.country,
      )

      // if country gets change make state null first
      // if (addClientState?.state) {
      //   setAddClientState((prev) => ({ ...prev, state: null }))
      // }

      const statesArray = State.getStatesOfCountry(foundSelectedCountry.isoCode)

      const statesNames = statesArray.map((state) => state.name)

      setStatesArray(statesNames)
    }
  }, [addClientState.country])

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      PaperProps={{
        sx: {
          borderRadius: `20px 20px 20px 20px`,
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          height: `max-content`,
          display: `flex`,
          flexDirection: `column`,
        }}
      >
        <Box
          sx={{
            display: `flex`,
            justifyContent: `space-between`,
            alignItems: `center`,
            flexDirection: `row`,
            paddingBottom: `10px`,
          }}
        >
          <Typography
            sx={{
              fontFamily: `Inter`,
              fontSize: {
                lg: 15,
                md: 13,
                sm: 10,
                xs: 8,
              },
            }}
          >
            Update Client
          </Typography>
          <Box
            sx={{
              width: `36px`,
              height: `36px`,
              display: `flex`,
              justifyContent: `center`,
              alignItems: `centrer`,
              padding: `11px`,
              '&:hover': {
                cursor: `pointer`,
                opacity: 0.8,
              },
            }}
            onClick={onClose}
          >
            <img src={cross} alt="cross" width="13.87px" height="14px" />
          </Box>
        </Box>
        <Divider sx={{ width: `100%` }} />
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0, x: -500, rotate: -360 }}
            animate={{ opacity: 1, x: 0, rotate: 0 }}
            exit={{ opacity: 0, x: -100, rotate: -360 }}
            transition={{ duration: 1, delay: 0.1, ease: `easeInOut` }}
          >
            <Box
              sx={{
                display: `flex`,
                justifyContent: `center`,
                marginTop: `10px`,
              }}
            >
              <Box
                sx={{
                  width: `100px`,
                  height: `100px`,
                  alignSelf: `center`,
                  borderRadius: `50%`,
                  display: `flex`,
                  justifyContent: `center`,
                  alignItems: `center`,
                  backgroundColor: `rgba(40, 32, 61, 0.07)`,
                  position: `relative`,
                  // border: profilePicture ? `solid 1px red` : `white`,
                }}
              >
                <img
                  src={profilePic ? profilePic : userIcon}
                  alt="user"
                  style={{
                    width: profilePic ? `100%` : `50px`,
                    height: profilePic ? `100%` : `50px`,
                    borderRadius: `100%`,
                  }}
                />
                {profilePic ? (
                  <Box
                    sx={{
                      position: `absolute`,
                      bottom: 0,
                      right: 0,
                      backgroundColor: `#FF485E`,
                      color: `white`,
                      width: `30px`,
                      height: `30px`,
                      display: `flex`,
                      justifyContent: `center`,
                      alignItems: `center`,
                      borderRadius: `50%`,
                      border: `solid white 3px`,
                      zIndex: 2,
                      '&:hover': {
                        cursor: !isMutatingPicture && `pointer`,
                        width: `35px`,
                        height: `35px`,
                      },
                    }}
                    onClick={handleDeleteImage}
                  >
                    x
                  </Box>
                ) : (
                  <label htmlFor="update-client-image-picker">
                    <Box
                      sx={{
                        position: `absolute`,
                        bottom: 0,
                        right: 0,
                        backgroundColor: `#19C9D1`,
                        color: `white`,
                        width: `30px`,
                        height: `30px`,
                        display: `flex`,
                        justifyContent: `center`,
                        alignItems: `center`,
                        borderRadius: `50%`,
                        border: `solid white 3px`,
                        zIndex: 2,
                        '&:hover': {
                          cursor: `pointer`,
                          width: `35px`,
                          height: `35px`,
                        },
                      }}
                    >
                      <input
                        accept=".jpg,.jpeg,.png,.gif,.JPG,.JPEG,.PNG,.GIF"
                        id="update-client-image-picker"
                        type="file"
                        style={{ display: `none` }}
                        onChange={handleImagePick}
                      />
                      +
                    </Box>
                  </label>
                )}
              </Box>
            </Box>
          </motion.div>
        </AnimatePresence>
      </DialogTitle>

      <DialogContent
        sx={{
          display: `flex`,
          flexDirection: `column`,
          gap: `20px`,
          overflowY: `auto`,
        }}
      >
        <Grid
          container
          rowSpacing={2}
          columnSpacing={4}
          sx={{ paddingTop: `10px` }}
        >
          <Grid item xs={12} sm={6}>
            <motion.div
              initial={{ opacity: 0, y: -40 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <InputField
                type="text"
                label="Name"
                defaultValue=""
                value={addClientState.name}
                onChange={(evt) => handleInputChange(`name`, evt.target.value)}
                readOnly={false}
                inputLabelProps={getInputLabelProps()}
                sxProps={getSxProps()}
              />
            </motion.div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <motion.div
              initial={{ opacity: 0, y: -40 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <InputField
                label="Phone Number"
                value={addClientState.phone}
                onChange={(evt) => handleInputChange(`phone`, evt.target.value)}
                inputLabelProps={getInputLabelProps()}
                sxProps={getSxProps()}
              />
            </motion.div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <motion.div
              initial={{ opacity: 0, y: -40 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <InputField
                type="text"
                label="Email"
                defaultValue=""
                value={addClientState.email}
                onChange={(evt) => handleInputChange(`email`, evt.target.value)}
                readOnly={false}
                inputLabelProps={getInputLabelProps()}
                sxProps={getSxProps()}
              />
            </motion.div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <motion.div
              initial={{ opacity: 0, y: -40 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <InputField
                type="text"
                label="Company"
                defaultValue=""
                value={addClientState.company}
                onChange={(evt) =>
                  handleInputChange(`company`, evt.target.value)
                }
                readOnly={false}
                inputLabelProps={getInputLabelProps()}
                sxProps={getSxProps()}
              />
            </motion.div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <motion.div
              initial={{ opacity: 0, y: -40 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <InputField
                type="text"
                label="URL"
                defaultValue=""
                value={addClientState.url}
                onChange={(evt) => handleInputChange(`url`, evt.target.value)}
                readOnly={false}
                inputLabelProps={getInputLabelProps()}
                sxProps={getSxProps()}
              />
            </motion.div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <motion.div
              initial={{ opacity: 0, y: -40 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <MySelectionField
                label="Country"
                options={countriesArray}
                value={addClientState.country}
                disabled={false}
                onChange={(evt) =>
                  handleInputChange(`country`, evt.target.value)
                }
                labelColor={getSelectionFieldLabelColor()}
                sxProps={getSxProps()}
              />
            </motion.div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <motion.div
              initial={{ opacity: 0, y: -40 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <MySelectionField
                label="State"
                options={statesArray}
                value={addClientState.state}
                disabled={false}
                onChange={(evt) => handleInputChange(`state`, evt.target.value)}
                labelColor={getSelectionFieldLabelColor()}
                sxProps={getSxProps()}
              />
            </motion.div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <motion.div
              initial={{ opacity: 0, y: -40 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <InputField
                type="text"
                label="Address"
                defaultValue=""
                value={addClientState.address}
                onChange={(evt) =>
                  handleInputChange(`address`, evt.target.value)
                }
                readOnly={false}
                inputLabelProps={getInputLabelProps()}
                sxProps={getSxProps()}
              />
            </motion.div>
          </Grid>

          {/* <Grid item xs={12} sm={6}>
            <motion.div
              initial={{ opacity: 0, y: -40 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <FormControl
                size="small"
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      border: `solid rgba(40, 32, 61, 0.25) 1px`,
                    },
                    '&.Mui-focused fieldset': {
                      border: `solid rgba(40, 32, 61, 0.25) 1px`,
                    },
                    '& fieldset': {
                      borderColor: `rgba(40, 32, 61, 0.25)`,
                      borderRadius: `10px`,
                    },
                  },
                  '& .MuiInputLabel-root': {
                    '&.Mui-focused': {
                      color: `rgba(40, 32, 61, 0.5)`,
                    },
                  },
                }}
              >
                <InputLabel
                  id="demo-mutiple-chip-checkbox-label"
                  sx={{ color: `rgba(40, 32, 61, 0.5)` }}
                >
                  Projects
                </InputLabel>
                <Select
                  labelId="demo-mutiple-chip-checkbox-label"
                  id="demo-mutiple-chip-checkbox"
                  multiple
                  value={projectsArray}
                  onChange={handleProjectsChange}
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Projects" />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: `flex`, flexWrap: `wrap`, gap: 0.5 }}>
                      {selected.map((selected) => (
                        <Chip
                          sx={{
                            backgroundColor: `rgba(25, 201, 209, 0.15)`,
                            color: `black`,
                            borderRadius: `5px`,
                          }}
                          clickable
                          key={selected.id}
                          label={selected.name}
                        />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {projects?.map((project) => (
                    <MenuItem
                      key={project._id}
                      value={project}
                      style={getStyles(project.name, projectsArray, theme)}
                    >
                      <Checkbox
                        checked={projectsArray.some(
                          (eachProject) => project.name === eachProject.name,
                        )}
                      />
                      <ListItemText primary={project.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </motion.div>
          </Grid> */}

          <Grid item xs={12} sm={6}>
            <motion.div
              initial={{ opacity: 0, y: -40 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <Autocomplete
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      border: `solid rgba(40, 32, 61, 0.25) 1px`,
                    },
                    '&.Mui-focused fieldset': {
                      border: `solid rgba(40, 32, 61, 0.25) 1px`,
                    },
                    '& fieldset': {
                      borderColor: `rgba(40, 32, 61, 0.25)`,
                      borderRadius: `10px`,
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: `rgba(40, 32, 61, 0.5)`,
                  },
                }}
                size="small"
                fullWidth
                multiple
                id="checkboxes-tags-demo"
                options={projects}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                value={projectsArray}
                onChange={(event, newValue) => {
                  setProjectsArray(newValue)
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected.name}
                    />
                    {option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Projects" />
                )}
              />
            </motion.div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <motion.div
              initial={{ opacity: 0, y: -40 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <FormControl
                fullWidth
                size="small"
                sx={{
                  backgroundColor: `white`,
                  borderRadius: `10px`,
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      border: `solid rgba(40, 32, 61, 0.5), 1px`,
                    },
                    '&.Mui-focused fieldset': {
                      border: `solid rgba(40, 32, 61, 0.5) 1px`,
                    },
                    '& fieldset': {
                      borderColor: `rgba(40, 32, 61, 0.25)`,
                      borderRadius: `10px`,
                    },
                  },
                  '& .MuiInputLabel-root': {
                    '&.Mui-focused': {
                      color: `rgba(40, 32, 61, 0.25)`,
                    },
                  },
                }}
              >
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{
                    color: `rgba(40, 32, 61, 0.5)`,
                  }}
                >
                  Status *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  label="Status *"
                  value={status || `ACTIVE`}
                  onChange={(e) => setStatus(e?.target?.value)}
                >
                  {statusOptions &&
                    statusOptions?.map((status) => (
                      <MenuItem value={status?.id} key={status?.id}>
                        {status?.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </motion.div>
          </Grid>

          <Grid item xs={12}>
            <motion.div
              initial={{ opacity: 0, y: -40 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <MultilineInputField
                type="text"
                label="Description"
                defaultValue=""
                value={addClientState.description}
                onChange={(evt) =>
                  handleInputChange(`description`, evt.target.value)
                }
                readOnly={false}
                inputLabelProps={getInputLabelProps()}
                sxProps={getSxProps()}
              />
            </motion.div>
          </Grid>
        </Grid>
      </DialogContent>

      <motion.div
        initial={{ opacity: 0, x: -1000 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -20 }}
        transition={{ duration: 1, delay: 0.1 }}
      >
        <Box
          sx={{
            display: `flex`,
            flexDirection: { xs: `column`, sm: `row` },
            justifyContent: `flex-end`,
            gap: `10px`,
            padding: `20px`,
          }}
        >
          <SecondaryButton title="Cancel" onClick={() => onClose()} />

          <PrimaryButton
            title={
              isMutatingPicture
                ? `Uploading...`
                : isMutatingUpdateClient
                ? `Updating...`
                : `Update Client`
            }
            isLoading={isMutatingUpdateClient || isMutatingPicture}
            onClick={() => handleSubmitForm()}
          />
        </Box>
      </motion.div>
      <Divider />
    </Dialog>
  )
}

export default UpdateClient
