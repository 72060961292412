import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  id: null,
}
const assessmentIdSlice = createSlice({
  name: `assessmentId`,
  initialState,
  reducers: {
    setAssessmentId: (state, action) => {
      return { ...action.payload }
    },
  },
})

export const { setAssessmentId } = assessmentIdSlice.actions
export const assessmentId = assessmentIdSlice.reducer
