import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import PrimaryButton from 'app/Components/Common/PrimaryButton'
import React, { useRef, useState } from 'react'
import bracketsLogo from '../../../../Assets/brackets_logo_colorful.svg'
import moment from 'moment'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PrependRs from 'app/Utils/PrependRs'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

const tableColumns = [
  { label: `Sr. No.`, value: `sr.no` },
  { label: `ACCOUNT`, value: `account` },
  { label: `BUDGET DESCRIPTION`, value: `description` },
  { label: `BUDGET`, value: `budget` },
  { label: `UTILIZED`, value: `utilized` },
  { label: `BALANCE`, value: `balance` },
  { label: `LIMIT STATUS`, value: `limitStatus` },
]

const GenerateReports = () => {
  const location = useLocation()
  const accountsData = useSelector((state) => state?.account?.accountsList)

  const { state: reports } = location
  const account =
    accountsData &&
    accountsData?.find(
      (account) => account._id === reports?.reports?.filteredData?.account,
    )

  let rowNumber = 0
  const [loading, setLoading] = useState(false)
  const reportRef = useRef()

  const exportToPdf = () => {
    setLoading(true)
    const inputPage1 = reportRef.current

    html2canvas(inputPage1, { scale: 2 }).then((canvasPage1) => {
      const imgDataPage1 = canvasPage1.toDataURL(`image/jpeg`, {
        quality: 0.8,
        progressive: true,
      })

      const pdf = new jsPDF({
        orientation: `portrait`,
        unit: `mm`,
        format: `a4`,
      })

      const pdfWidth = pdf.internal.pageSize.getWidth()

      pdf.addImage(imgDataPage1, `JPEG`, 0, 0, pdfWidth, 0)

      pdf.save(`budgetReport.pdf`)
      setLoading(false)
    })
  }

  return (
    <Box
      sx={{
        display: `flex`,
        flexDirection: `column`,
        paddingX: { xs: `10px`, sm: `20px` },
        paddingBottom: `20px`,
        minHeight: `calc(100vh - 80px)`,
        backgroundColor: `rgba(40, 32, 61, 0.07)`,
      }}
    >
      <Box
        sx={{
          display: `flex`,
          justifyContent: `flex-end`,
          alignItems: `center`,
          gap: `20px`,
          flexDirection: { xs: `column`, sm: `row` },
        }}
      >
        <Button
          variant="contained"
          sx={{
            width: { xs: `100%`, sm: `auto` },
            backgroundColor: `rgba(25, 201, 209, 1)`,
            border: `solid rgba(25, 201, 209, 1) 1px`,
            textTransform: `none`,
            borderRadius: `10px`,
            paddingY: `9px`,
            paddingX: `20px`,
            color: `white`,
            '&:hover': {
              backgroundColor: `rgba(25, 201, 209, 1)`,
              opacity: `0.8`,
            },
          }}
          onClick={exportToPdf}
          className="button"
          disabled={loading} // Disable the button while loading
        >
          {loading ? (
            <>
              <Typography sx={{ textTransform: `none` }}>
                Exporting...
              </Typography>

              <CircularProgress
                size={20}
                color="inherit"
                style={{ marginRight: `10px` }}
              />
            </>
          ) : (
            `Export PDF`
          )}
        </Button>

        <PrimaryButton title="Print" onClick={() => window.print()} />
      </Box>
      <Box
        ref={reportRef}
        sx={{
          backgroundColor: `white`,
          marginY: `20px`,
          marginX: { xs: `20px`, sm: `5%` },
          borderRadius: `10px`,
          padding: `20px`,
        }}
      >
        <img src={bracketsLogo} width={`143px`} height={`36px`} />
        <Typography
          sx={{
            display: `flex`,
            alignItems: `center`,
            justifyContent: `flex-end`,
            fontSize: `12px`,
            fontWeight: 500,
            lineHeight: `12.1px`,
          }}
        >
          {`Dated: ${moment(new Date().toLocaleDateString()).format(
            `YYYY-MM-DD`,
          )}`}
        </Typography>
        <Box
          sx={{
            backgroundColor: `rgba(25, 201, 209, 0.07)`,
            border: `solid rgba(40, 32, 61, 0.2) 1px`,
            marginY: `20px`,
          }}
        >
          <Box
            sx={{
              display: `flex`,
              alignItems: { xs: `flex-start`, sm: `center` },
              justifyContent: `space-between`,
              paddingY: `10px`,
              paddingX: `15px`,
              flexDirection: { xs: `column`, sm: `row` },
              gap: `10px`,
            }}
          >
            <Typography
              sx={{
                display: `flex`,
                alignItems: `center`,
                gap: `10px`,
                fontSize: `14px`,
                fontWeight: 400,
              }}
            >
              Account:
              <Typography sx={{ fontWeight: 600, alignItems: `center` }}>
                {account?.name || `All`}
              </Typography>
            </Typography>
            <Typography
              sx={{
                display: `flex`,
                alignItems: `center`,
                gap: `10px`,
                fontSize: `14px`,
                fontWeight: 400,
              }}
            >
              Budget(s) Duration:
              <Typography sx={{ fontWeight: 600 }}>{`${moment(
                reports?.reports?.filteredData?.startDate,
              ).format(`MMM DD, YYYY`)} to ${moment(
                reports?.reports?.filteredData?.endDate,
              ).format(`MMM DD, YYYY`)}`}</Typography>
            </Typography>
          </Box>

          <Grid item xs={12}>
            <TableContainer
              className="scrollbar-hide"
              sx={{
                width: `100%`,
                position: `relative`,
                marginTop: `10px`,
                '& thead th': {
                  borderTop: `solid rgba(40, 32, 61, 0.2) 1px`,
                  borderBottom: `none`,
                },
                '& tbody': {
                  '& td, & th': {
                    border: `none`,
                  },
                },
              }}
            >
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: `40%` }}>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: `14px`,
                          color: `rgba(40, 32, 61, 0.6)`,
                          lineHeight: `16.94px`,
                        }}
                      >
                        Limit Status
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: `40%` }}>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: `14px`,
                          color: `rgba(40, 32, 61, 0.6)`,
                          lineHeight: `16.94px`,
                        }}
                      >
                        Total Budgets
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: `20%` }}>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: `14px`,
                          color: `rgba(40, 32, 61, 0.6)`,
                          lineHeight: `16.94px`,
                        }}
                      >
                        Balance
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ margin: `0px`, padding: `0px` }}>
                  {!reports?.reports?.budgetReports &&
                  !reports?.reports?.budgetReports?.length ? (
                    <TableRow>
                      <TableCell
                        colSpan={tableColumns.length + 1}
                        sx={{ border: 0 }}
                      >
                        <div
                          style={{
                            display: `flex`,
                            justifyContent: `center`,
                            alignItems: `center`,
                          }}
                        >
                          <Typography>No data Found</Typography>
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : (
                    reports?.reports?.budgetReports?.map((budget, key) => (
                      <TableRow
                        key={key}
                        sx={{
                          '&:hover': {
                            cursor: `pointer`,
                          },
                          width: `100%`,
                        }}
                      >
                        <TableCell
                          sx={{
                            fontSize: `14px`,
                            fontWeight: `600`,
                            lineHeight: `16.94px`,
                            width: `40%`,
                          }}
                        >
                          {budget?.limitStatus === `BELOW`
                            ? `Below`
                            : budget?.limitStatus === `UTILIZED`
                            ? `Utilized`
                            : budget?.limitStatus === `EXCEEDED`
                            ? `Exceeded`
                            : ``}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: `14px`,
                            fontWeight: `600`,
                            lineHeight: `16.94px`,
                            width: `40%`,
                          }}
                        >
                          {budget?.total}
                        </TableCell>

                        <TableCell sx={{ width: `20%` }}>
                          {PrependRs({
                            amount: budget?.balance || 0,
                            sx: {
                              fontSize: `14px`,
                              fontWeight: `600`,
                              lineHeight: `16.94px`,
                            },
                          })}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Box>

        <Grid item xs={12}>
          <TableContainer
            className="scrollbar-hide"
            sx={{
              backgroundColor: `white`,
              border: `solid 1px rgba(40, 32, 61, 0.15)`,
              // borderRadius: `10px`,
              marginTop: `20px`,
              height: {
                xs: `360px`,
                sm: `auto`,
              },
              whiteSpace: `nowrap`,
              position: `relative`,
            }}
          >
            <Table size="small">
              <TableHead className="h-10">
                <TableRow
                  sx={{
                    backgroundColor: `rgba(96, 57, 187, 1)`,
                  }}
                >
                  {tableColumns?.map((item, index) => {
                    return (
                      <TableCell key={index} sx={{ padding: `0px 16px` }}>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: `10px`,
                            color: `white`,
                          }}
                        >
                          {item?.label}
                        </Typography>
                      </TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {!reports?.reports?.budgetReports ||
                !reports?.reports?.budgetReports ? (
                  <TableRow>
                    <TableCell
                      colSpan={tableColumns.length + 1}
                      sx={{ border: 0 }}
                    >
                      <div
                        style={{
                          display: `flex`,
                          justifyContent: `center`,
                          alignItems: `center`,
                        }}
                      >
                        <Typography>No budget Found</Typography>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  reports?.reports?.budgetReports?.map((budget, index) =>
                    budget?.accountsAndBudgets?.map((data) => (
                      <TableRow
                        key={index}
                        sx={{
                          // '&:last-child td, &:last-child th': {
                          //   border: 0,
                          // },
                          '&:hover': {
                            cursor: `pointer`,
                            background: `linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(250,249,253,1) 15%, rgba(250,249,253,1) 85%, rgba(255,255,255,1) 100%)`,
                          },
                          width: `100%`,
                        }}
                      >
                        <TableCell
                          sx={{
                            fontWeight: 400,
                            fontSize: `14px`,
                            lineHeight: `12.1px`,
                            width: `1%`,
                            borderRight: `solid rgba(40, 32, 61, 0.15) 1px`,
                          }}
                        >
                          {++rowNumber}
                        </TableCell>
                        <TableCell
                          sx={{
                            width: `9%`,
                            fontWeight: 600,
                            fontSize: `14px`,
                            lineHeight: `12.1px`,
                            borderRight: `solid rgba(40, 32, 61, 0.15) 1px`,
                          }}
                        >
                          {data?.name}
                        </TableCell>
                        <TableCell
                          sx={{
                            width: `35%`,
                            maxWidth: `280px`,
                            borderRight: `solid rgba(40, 32, 61, 0.15) 1px`,
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: `14px`,
                              lineHeight: `21.1px`,
                              overflow: `hidden`,
                              textOverflow: `ellipsis`,
                              whiteSpace: `nowrap`,
                            }}
                          >
                            {`${moment(
                              data?.budget?.startDate,
                              `YYYY-MM-DD`,
                            ).format(`MMM DD, YYYY`)} to ${moment(
                              data?.budget?.endDate,
                              `YYYY-MM-DD`,
                            ).format(`MMM DD, YYYY`)}`}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: `12px`,
                              overflow: `hidden`,
                              textOverflow: `ellipsis`,
                              whiteSpace: `nowrap`,
                            }}
                          >
                            {data?.budget?.details
                              ? data?.budget?.details
                              : `No description added`}
                          </Typography>
                        </TableCell>

                        <TableCell
                          sx={{
                            borderRight: `solid rgba(40, 32, 61, 0.15) 1px`,
                            width: `15%`,
                          }}
                        >
                          {PrependRs({
                            amount: data?.budget?.total || 0,
                            sx: {
                              wordWrap: `break-word`,
                              fontSize: `14px`,
                              fontWeight: 400,
                              lineHeight: `12.1px`,
                            },
                          })}
                        </TableCell>
                        <TableCell
                          sx={{
                            borderRight: `solid rgba(40, 32, 61, 0.15) 1px`,
                            width: `15%`,
                          }}
                        >
                          {PrependRs({
                            amount: data?.budget?.utilized || 0,
                            sx: {
                              wordWrap: `break-word`,
                              fontSize: `14px`,
                              fontWeight: 400,
                              lineHeight: `12.1px`,
                            },
                          })}
                        </TableCell>
                        <TableCell
                          sx={{
                            borderRight: `solid rgba(40, 32, 61, 0.15) 1px`,
                            width: `15%`,
                          }}
                        >
                          {PrependRs({
                            amount: data?.budget?.remaining || 0,
                            sx: {
                              wordWrap: `break-word`,
                              fontSize: `14px`,
                              fontWeight: 400,
                              lineHeight: `12.1px`,
                            },
                          })}
                        </TableCell>
                        <TableCell
                          sx={{
                            width: `10%`,
                            fontSize: `14px`,
                            fontWeight: 600,
                            lineHeight: `12.1px`,
                            color:
                              data?.budget?.limitStatus === `BELOW`
                                ? `rgba(77, 198, 64, 1)`
                                : data?.budget?.limitStatus === `UTILIZED`
                                ? `rgba(37, 187, 251, 1)`
                                : data?.budget?.limitStatus === `EXCEEDED`
                                ? `rgba(255, 72, 94, 1)
                              `
                                : `
                              rgba(96, 57, 187, 1)`,
                          }}
                        >
                          {data?.budget?.limitStatus === `BELOW`
                            ? `Below`
                            : data?.budget?.limitStatus === `UTILIZED`
                            ? `Utilized`
                            : data?.budget?.limitStatus === `EXCEEDED`
                            ? `Exceeded`
                            : ``}
                        </TableCell>
                      </TableRow>
                    )),
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Box>
    </Box>
  )
}

export default GenerateReports
