import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Collapse,
} from '@material-ui/core'
import { Divider } from '@mui/material'
import api from 'app/AxiosInstance'
import PrimaryButton from 'app/Components/Common/PrimaryButton'
import { SnackbarContext } from 'app/Components/SnackbarProvider'
import { baseUrl } from 'app/Utils/Config'
import React from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import AddExpense from 'app/Components/Expenses/AddExpense'
import PrependRs from 'app/Utils/PrependRs'
import ExpenseDetail from 'app/Components/Expenses/ExpenseDetail'

// React Icons
import { MdExpandMore } from 'react-icons/md'
import { MdExpandLess } from 'react-icons/md'

// Utilities
import formatAmountWithCommas from 'app/Utils/formatAmountWithCommas'

const tableColumns = [
  { label: `Sr #`, value: `date` },
  { label: `DATE`, value: `date` },
  { label: `EXPENSE BY`, value: `name` },
  { label: `PURCHASED BY`, value: `name` },
  { label: `BUDGET DURATION`, value: `duration` },
  { label: `DETAILS`, value: `detail` },
  { label: `AMOUNT`, value: `amount` },
  { label: `CURRENCY`, value: `amount` },
]

const ViewExpense = () => {
  const token = localStorage.getItem(`AuthToken`)
  const { id, budgetId } = useParams()
  const openSnackbar = useContext(SnackbarContext)
  const colors = useSelector((state) => state?.colors)

  const [expenseId, setExpenseId] = useState(``)
  const [expenseDetailModal, setExpenseDetailModal] = useState(false)
  const [addExpenseModal, setAddExpenseModal] = useState(false)

  const location = useLocation()
  const { state: accountName } = location

  const [expenses, setExpenses] = useState()
  const [currencies, setCurrencies] = useState()

  const {
    isLoading: expenseLoader,
    data: expenseData,
    isFetching: fetchExpense,
    refetch: refetchExpense,
  } = useQuery(
    `getSingleExpenseApi`,
    async () =>
      await api
        .get(`${baseUrl}/expenses/${id}/budget/${budgetId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const tempCurrencies = res?.data?.data?.accountAndExpenses?.reduce(
            (acc, obj) => {
              acc[obj.account.name] = obj.expensesOfSingleAccount.map(
                (item, index) => {
                  return { ...item, isSelected: index === 0 ? true : false }
                },
              )
              return acc
            },
            {},
          )

          setCurrencies(tempCurrencies)

          const updated = res?.data?.data?.accountAndExpenses.map((item) => {
            return {
              expensesOfSingleAccount: item.expensesOfSingleAccount[0],
              sumOfUSDAmountAccountWise: item.sumOfUSDAmountAccountWise,
              sumOfConvertedAmountsAccountWise:
                item.sumOfConvertedAmountsAccountWise,
              account: item.account,

              isCollapse: false,
            }
          })

          setExpenses({
            accountAndExpenses: updated,
            totalConvertedAmountOfAllAccounts:
              res?.data?.data?.totalConvertedAmountOfAllAccounts,
          })
          return res?.data?.data
        }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onError: (error) => {
        openSnackbar(
          error?.response ? error?.response?.data?.message : error?.message,
          `error`,
        )
      },
    },
  )

  const toggleTable = () => {
    const target = expenses?.accountAndExpenses?.map((item) => {
      return {
        expensesOfSingleAccount: item.expensesOfSingleAccount,
        sumOfConvertedAmountsAccountWise: item.sumOfConvertedAmountsAccountWise,
        sumOfUSDAmountAccountWise: item.sumOfUSDAmountAccountWise,
        account: item.account,
        isCollapse: !item.isCollapse,
      }
    })

    setExpenses({
      accountAndExpenses: target,
      totalConvertedAmountOfAllAccounts:
        expenses?.totalConvertedAmountOfAllAccounts,
    })
  }

  const handleFilteredExpensesAgainstCurrency = (
    currency,
    accountId,
    accountName,
  ) => {
    const targetExpensesOfSingleAccount = expenseData?.accountAndExpenses
      .filter((item) => item.account._id === accountId)
      .map((item) => item.expensesOfSingleAccount)

    const foundCurrencyWiseExpense = targetExpensesOfSingleAccount[0].filter(
      (item) => item.currencyAndAmount.currency === currency,
    )

    const accountAndExpenses = expenseData?.accountAndExpenses?.map(
      (item, index) => {
        if (item.account._id === accountId) {
          return {
            expensesOfSingleAccount: foundCurrencyWiseExpense[0],
            sumOfConvertedAmountsAccountWise:
              item.sumOfConvertedAmountsAccountWise,
            account: item.account,
            isCollapse: expenses?.accountAndExpenses[index]?.isCollapse,
          }
        }

        return {
          ...item,
          expensesOfSingleAccount: item.expensesOfSingleAccount[0],
          isCollapse: expenses?.accountAndExpenses[index]?.isCollapse,
        }
      },
    )

    const updatedBudget = {
      accountAndExpenses: accountAndExpenses,
      totalConvertedAmountOfAllAccounts:
        expenseData?.totalConvertedAmountOfAllAccounts,
    }

    setExpenses(updatedBudget)

    const updatedCurrency = currencies[accountName].map((item) => {
      if (item.currencyAndAmount.currency === currency) {
        return { ...item, isSelected: true }
      }
      return { ...item, isSelected: false }
    })

    setCurrencies({ ...currencies, [accountName]: updatedCurrency })
  }

  const {
    isLoading,
    data: budgetDetailData,
    refetch,
    isFetching,
  } = useQuery(
    `getSingleBudgetApi`,
    async () =>
      await api
        .get(`${baseUrl}/accounts/${id}/budget/${budgetId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          return res?.data?.data
        }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onError: (error) => {
        openSnackbar(
          error?.response ? error?.response?.data?.message : error?.message,
          `error`,
        )
      },
    },
  )

  return (
    <>
      {addExpenseModal && (
        <AddExpense
          open={addExpenseModal}
          onClose={() => setAddExpenseModal(!addExpenseModal)}
          fetchExpenses={() => {
            refetchExpense(), refetch()
          }}
          accountName={accountName?.accountName}
        />
      )}
      {expenseDetailModal && (
        <ExpenseDetail
          open={expenseDetailModal}
          onClose={() => setExpenseDetailModal(!expenseDetailModal)}
          id={expenseId}
          fetchExpenses={() => {
            refetchExpense(), refetch()
          }}
        />
      )}

      <Box
        sx={{
          display: `flex`,
          flexDirection: `column`,
          gap: `10px`,
          paddingX: { xs: `10px`, sm: `20px` },
          paddingBottom: `20px`,
          minHeight: `calc(100vh - 80px)`,
          backgroundColor: `rgba(40, 32, 61, 0.07)`,
        }}
      >
        {accountName?.accountName &&
          !accountName?.accountName?.name
            ?.toLowerCase()
            ?.includes(`payroll`) && (
            <Box
              sx={{
                display: `flex`,
                justifyContent: `flex-end`,
                alignItems: `center`,
                width: `100%`,
                paddingY: { xs: `10px`, md: `0px` },
                gap: `10px`,
              }}
            >
              <PrimaryButton
                title="+ Add Expense"
                onClick={() => setAddExpenseModal(!addExpenseModal)}
              />
            </Box>
          )}

        <Box
          sx={{
            backgroundColor: `white`,
            display: `flex`,
            justifyContent: { xs: `flex-start`, sm: `space-between` },
            alignItems: { xs: `flex-start`, sm: `center` },
            flexDirection: { xs: `column`, sm: `row` },
            gap: `10px`,
            borderRadius: `10px`,
            width: `100%`,
            paddingX: `10px`,
          }}
        >
          <Box
            sx={{
              width: { xs: `100%`, sm: `50%` },
              paddingY: `20px`,
              paddingX: `10px`,
            }}
          >
            <Typography
              sx={{
                width: `80px`,
                height: `24px`,
                fontWeight: 500,
                fontSize: `12px`,
                lineHeight: `14.52px`,
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`,
                borderRadius: `10px`,
                backgroundColor:
                  budgetDetailData?.limitStatus === `BELOW`
                    ? `rgba(77, 198, 64, 0.12)`
                    : budgetDetailData?.limitStatus === `EXCEEDED`
                    ? `rgba(255, 72, 94, 0.12)`
                    : `rgba(37, 187, 251, 0.12)`,
                color:
                  budgetDetailData?.limitStatus === `BELOW`
                    ? `rgba(77, 198, 64, 1)`
                    : budgetDetailData?.limitStatus === `EXCEEDED`
                    ? `rgba(255, 72, 94, 1)`
                    : `rgba(37, 187, 251, 1)`,
              }}
            >
              {budgetDetailData?.limitStatus === `BELOW`
                ? `Below`
                : budgetDetailData?.limitStatus === `EXCEEDED`
                ? `Exceeded`
                : `Utilized`}
            </Typography>
            <Box
              sx={{
                display: `flex`,
                flexDirection: `row`,
                alignItems: `center`,
                justifyContent: `flex-start`,
                gap: `20px`,
                marginTop: `10px`,
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: `15px`, sm: `18px` },
                  fontWeight: 600,
                  lineHeight: `29.05px`,
                  color: `rgba(40, 32, 61, 1)`,
                  display: `flex`,
                  gap: `10px`,
                }}
              >
                {`${moment(budgetDetailData?.startDate).format(`DD MMM YYYY`)}`}
                <Typography sx={{ color: `rgba(40, 32, 61, 0.6)` }}>
                  to
                </Typography>
                {`${moment(budgetDetailData?.endDate).format(`DD MMM YYYY`)}`}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: `14px`,
                  lineHeight: `16.94px`,
                  paddingX: `15px`,
                  color:
                    budgetDetailData?.validityStatus === `VALID`
                      ? `rgba(77, 198, 64, 1)`
                      : budgetDetailData?.validityStatus === `EXPIRE`
                      ? `rgba(255, 72, 94, 1)`
                      : `rgba(37, 187, 251, 1)`,
                }}
              >
                {budgetDetailData?.validityStatus === `VALID`
                  ? `Valid`
                  : budgetDetailData?.validityStatus === `EXPIRE`
                  ? `Expired`
                  : ``}
              </Typography>
            </Box>

            <Typography
              sx={{
                fontSize: `14px`,
                fontWeight: 400,
                lineHeight: `16.94px`,
                color: `rgba(40, 32, 61, 0.6)`,
                paddingY: `10px`,
                textAlign: `justify`,
              }}
            >
              {budgetDetailData?.details
                ? budgetDetailData?.details
                : `No description added`}
            </Typography>
          </Box>
          <Box
            sx={{
              width: { xs: `100%`, sm: `50%` },
              paddingY: `20px`,
              paddingX: `10px`,
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{
                display: `flex`,
                justifyContent: `space-between`,
                alignItems: `center`,
              }}
            >
              <Grid item xs={12} sm={4}>
                <Box
                  sx={{
                    borderRadius: `10px`,
                    backgroundColor: `rgba(3, 227, 252,0.06)`,
                    height: `113px`,
                    display: `flex`,
                    flexDirection: `column`,
                    alignItems: `center`,
                    justifyContent: `center`,
                    gap: `10px`,
                  }}
                >
                  <Typography>
                    {PrependRs({
                      amount: budgetDetailData?.total || 0,
                      sx: {
                        fontSize: { xs: `18px`, sm: `14px`, md: `18px` },
                        fontWeight: 600,
                        lineHeight: `21.78px`,
                        textAlign: `center`,
                      },
                    })}
                  </Typography>
                  <Typography
                    sx={{
                      color: `rgba(40, 32, 61, 0.6)`,
                      fontSize: { xs: `14px`, sm: `12px`, md: `14px` },
                      fontWeight: 400,
                      lineHeight: `16.94px`,
                      textAlign: `center`,
                    }}
                  >
                    Budget
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box
                  sx={{
                    borderRadius: `10px`,
                    backgroundColor: `
                      rgba(119, 3, 252, 0.06)`,
                    height: `113px`,
                    display: `flex`,
                    flexDirection: `column`,
                    alignItems: `center`,
                    justifyContent: `center`,
                    gap: `10px`,
                  }}
                >
                  <Typography>
                    {PrependRs({
                      amount: budgetDetailData?.utilized || 0,
                      sx: {
                        fontSize: { xs: `18px`, sm: `14px`, md: `18px` },
                        fontWeight: 600,
                        lineHeight: `21.78px`,
                        textAlign: `center`,
                      },
                    })}
                  </Typography>
                  <Typography
                    sx={{
                      color: `rgba(40, 32, 61, 0.6)`,
                      fontSize: { xs: `14px`, sm: `12px`, md: `14px` },
                      fontWeight: 400,
                      lineHeight: `16.94px`,
                      textAlign: `center`,
                    }}
                  >
                    Utilized
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box
                  sx={{
                    borderRadius: `10px`,
                    backgroundColor:
                      budgetDetailData?.utilized > budgetDetailData?.total
                        ? `rgba(255, 72, 94, 0.12)`
                        : `rgba(3, 252, 73,0.06)`,
                    height: `113px`,
                    display: `flex`,
                    flexDirection: `column`,
                    alignItems: `center`,
                    justifyContent: `center`,
                    gap: `10px`,
                  }}
                >
                  <Typography>
                    {PrependRs({
                      amount: budgetDetailData?.remaining || 0,
                      sx: {
                        fontSize: { xs: `18px`, sm: `14px`, md: `18px` },
                        fontWeight: 600,
                        lineHeight: `21.78px`,
                        textAlign: `center`,
                        color:
                          budgetDetailData?.utilized > budgetDetailData?.total
                            ? `rgba(255, 72, 94, 1)`
                            : `rgba(40, 32, 61, 1)`,
                      },
                    })}
                  </Typography>
                  <Typography
                    sx={{
                      color: `rgba(40, 32, 61, 0.6)`,
                      fontSize: { xs: `14px`, sm: `12px`, md: `14px` },
                      fontWeight: 400,
                      lineHeight: `16.94px`,
                      textAlign: `center`,
                    }}
                  >
                    Balance
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>

        {expenseLoader || fetchExpense ? (
          <Backdrop open={isLoading || isFetching}>
            <CircularProgress
              sx={{
                position: `absolute`,
                top: `50%`,
                color: colors?.appColor,
              }}
            />
          </Backdrop>
        ) : !expenseData?.accountAndExpenses ||
          expenseData?.accountAndExpenses?.length === 0 ? (
          <Typography
            sx={{
              display: `flex`,
              justifyContent: `center`,
              alignItems: `center`,
              height: `50vh`,
            }}
          >
            Expense(s) not found for given data
          </Typography>
        ) : (
          expenses &&
          expenses?.accountAndExpenses?.map((data, index) => (
            <Box key={index} sx={{ display: `flex`, flexDirection: `column` }}>
              <Box
                sx={{
                  backgroundColor: `white`,
                  paddingY: `10px`,
                  borderRadius: `10px`,
                }}
              >
                <Box
                  sx={{
                    display: `flex`,
                    alignItems: { xs: `flex-start`, sm: `center` },
                    justifyContent: `space-between`,
                    paddingX: `20px`,
                    flexDirection: { xs: `column`, sm: `row` },
                    gap: `20%`,
                    '&:hover': { cursor: `pointer` },
                  }}
                  onClick={(event) => {
                    event.stopPropagation()
                    toggleTable(index)
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: `18px`,
                        lineHeight: `24.2px`,
                        color: `rgba(40, 32, 61, 1)`,
                        display: `flex`,
                        alignItems: `center`,
                        gap: `5px`,
                      }}
                    >
                      {data?.account?.name}
                      <Typography
                        sx={{ color: `rgba(40, 32, 61, 0.6)` }}
                      >{` (${data?.expensesOfSingleAccount?.currencyWiseExpenses?.length})`}</Typography>
                    </Typography>
                  </Box>

                  <Box
                    sx={{ display: `flex`, gap: `10px`, alignItems: `center` }}
                  >
                    <Box
                      sx={{
                        display: `flex`,
                        flexDirection: `row`,
                      }}
                    >
                      {currencies[data.account.name]?.map((item, index) => {
                        return (
                          <Box
                            onClick={(event) => {
                              event.stopPropagation(),
                                handleFilteredExpensesAgainstCurrency(
                                  item.currencyAndAmount.currency,
                                  data.account._id,
                                  data.account.name,
                                )
                            }}
                            key={index}
                            sx={{
                              display: `flex`,
                              justifyContent: `center`,
                              alignItems: `center`,
                              borderTopLeftRadius: index === 0 && `10px`,
                              borderBottomLeftRadius: index === 0 && `10px`,

                              borderTopRightRadius:
                                index ===
                                  currencies[data.account.name].length - 1 &&
                                `10px`,
                              borderBottomRightRadius:
                                index ===
                                  currencies[data.account.name].length - 1 &&
                                `10px`,

                              border: item.isSelected
                                ? `solid 1px rgba(25, 201, 209, 1)`
                                : `solid 1px rgba(40, 32, 61, 0.2)`,

                              '&: hover': {
                                cursor: `pointer`,
                              },
                              backgroundColor: item.isSelected
                                ? `rgba(25, 201, 209, 0.05)`
                                : `white`,
                              paddingY: `5px`,
                              paddingX: `10px`,
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: `Inter`,
                                fontWeight: 700,
                                fontSize: `14px`,
                                color: item.isSelected
                                  ? `rgba(25, 201, 209, 1)`
                                  : `black`,
                              }}
                            >
                              {`${formatAmountWithCommas(
                                item?.currencyAndAmount?.originalAmount || 0,
                              )} ${item?.currencyAndAmount?.currency}`}
                            </Typography>
                          </Box>
                        )
                      })}
                    </Box>
                    <Divider
                      sx={{ height: `30px`, borderRightWidth: 2 }}
                      orientation="vertical"
                      variant="middle"
                      flexItem
                    />

                    <Typography
                      sx={{
                        backgroundColor: `rgba(96, 57, 187, 0.12)`,
                        fontWeight: 600,
                        fontSize: `18px`,
                        color: `rgba(40, 32, 61, 1)`,
                        lineHeight: `21.78px`,
                        paddingY: `12px`,
                        paddingX: `14px`,
                        borderRadius: `10px`,
                        minWidth: `145px`,
                        textAlign: `center`,
                      }}
                    >
                      {`${formatAmountWithCommas(
                        data?.sumOfConvertedAmountsAccountWise || 0,
                      )} PKR`}
                    </Typography>

                    <Typography
                      sx={{
                        backgroundColor: `rgba(25, 201, 209, 0.2)`,
                        fontWeight: 600,
                        fontSize: `18px`,
                        color: `rgba(40, 32, 61, 1)`,
                        lineHeight: `21.78px`,
                        paddingY: `12px`,
                        paddingX: `14px`,
                        borderRadius: `10px`,
                        minWidth: `145px`,
                        textAlign: `center`,
                      }}
                    >
                      {`${formatAmountWithCommas(
                        data?.sumOfUSDAmountAccountWise?.toFixed(2) || 0,
                      )} USD`}
                    </Typography>
                    <Box sx={{ '&: hover': { cursor: `pointer` } }}>
                      {data.isCollapse ? (
                        <MdExpandLess
                          style={{
                            width: `30px`,
                            height: `30px`,
                            color: `grey`,
                          }}
                        />
                      ) : (
                        <MdExpandMore
                          style={{
                            width: `30px`,
                            height: `30px`,
                            color: `grey`,
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
                <Grid item xs={12}>
                  <Collapse in={data.isCollapse}>
                    <TableContainer
                      className="scrollbar-hide"
                      sx={{
                        backgroundColor: `white`,
                        borderTop: `solid 1px rgba(40, 32, 61, 0.15)`,

                        marginTop: `20px`,
                        height: {
                          xs: `360px`,
                          sm: `${isLoading || isFetching ? `400px` : `auto`}`,
                        },
                        maxHeight: `400px`,
                        whiteSpace: `nowrap`,
                        position: `relative`,
                        '& tbody': {
                          '& td, & th': {
                            borderColor: `rgba(40, 32, 61, 0.03)`,
                          },
                        },
                      }}
                    >
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead className="h-10 ">
                          <TableRow>
                            {tableColumns?.map((item, index) => {
                              return (
                                <TableCell
                                  key={index}
                                  sx={{ padding: `0px 16px` }}
                                >
                                  <Typography
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: `12px`,
                                      color: `#28203D`,
                                      opacity: 0.4,
                                    }}
                                  >
                                    {item?.label}
                                  </Typography>
                                </TableCell>
                              )
                            })}
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {!data?.expensesOfSingleAccount &&
                          !data?.expensesOfSingleAccount?.length ? (
                            <TableRow>
                              <TableCell
                                colSpan={tableColumns.length + 1}
                                sx={{ border: 0 }}
                              >
                                <div
                                  style={{
                                    display: `flex`,
                                    justifyContent: `center`,
                                    alignItems: `center`,
                                  }}
                                >
                                  <Typography>No Expense Found</Typography>
                                </div>
                              </TableCell>
                            </TableRow>
                          ) : (
                            data?.expensesOfSingleAccount?.currencyWiseExpenses?.map(
                              (budget, key) => (
                                <TableRow
                                  key={key}
                                  sx={{
                                    '&:last-child td, &:last-child th': {
                                      border: 0,
                                    },
                                    '&:hover': {
                                      cursor: `pointer`,
                                      background: `linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(250,249,253,1) 15%, rgba(250,249,253,1) 85%, rgba(255,255,255,1) 100%)`,
                                    },
                                    width: `100%`,
                                  }}
                                  onClick={(e) => {
                                    e?.stopPropagation(),
                                      setExpenseId(budget?._id),
                                      setExpenseDetailModal(!expenseDetailModal)
                                  }}
                                >
                                  <TableCell
                                    sx={{
                                      width: `5%`,
                                      fontWeight: 400,
                                      fontSize: `14px`,
                                      lineHeight: `16.94px`,
                                    }}
                                  >
                                    {key + 1}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      width: `10%`,
                                      fontWeight: 400,
                                      fontSize: `14px`,
                                      lineHeight: `16.94px`,
                                    }}
                                  >
                                    {moment(budget?.date).format(`YYYY-MM-DD`)}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      width: `15%`,
                                      fontWeight: 400,
                                      fontSize: `14px`,
                                      lineHeight: `16.94px`,
                                      whiteSpace: `wrap`,
                                    }}
                                  >
                                    {budget?.expenseBy?.name}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      width: `10%`,
                                      fontWeight: 400,
                                      fontSize: `14px`,
                                      lineHeight: `16.94px`,
                                    }}
                                  >
                                    {budget?.name}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      fontWeight: 400,
                                      fontSize: `14px`,
                                      lineHeight: `16.94px`,
                                      width: `10%`,
                                      whiteSpace: `wrap`,
                                    }}
                                  >{`${moment(budget?.budget?.startDate).format(
                                    `MMM DD, YYYY`,
                                  )} to ${moment(
                                    budget?.budget?.endDate,
                                  ).format(`MMM DD, YYYY`)}`}</TableCell>
                                  <TableCell
                                    sx={{
                                      width: `35%`,
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        display: `block`,
                                        whiteSpace: `wrap`,
                                        textAlign: `left`,
                                        fontSize: `14px`,
                                      }}
                                    >
                                      {budget?.description
                                        ? budget?.description
                                        : `No details added`}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      sx={{
                                        width: `10%`,
                                        wordWrap: `break-word`,
                                        fontSize: `14px`,
                                      }}
                                    >
                                      {budget?.amount || 0}
                                    </Typography>
                                  </TableCell>

                                  <TableCell sx={{ width: `5px` }}>
                                    {budget?.currency || 0}
                                  </TableCell>
                                </TableRow>
                              ),
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Collapse>
                </Grid>
              </Box>
            </Box>
          ))
        )}
      </Box>
    </>
  )
}

export default ViewExpense
