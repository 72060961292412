import { createSlice } from '@reduxjs/toolkit'

const drawerSlice = createSlice({
  name: `drawer`,
  initialState: { openDrawer: true },
  reducers: {
    toggleDrawer: (state, action) => {
      return { ...action.payload }
    },
  },
})

const selectedItemSlice = createSlice({
  name: `selectedItem`,
  initialState: { selectedItem: `Dashboard` },
  reducers: {
    changeSelectedItem: (state, action) => {
      return { ...action.payload }
    },
  },
})

export const { toggleDrawer } = drawerSlice.actions
export const { changeSelectedItem } = selectedItemSlice.actions

const drawerReducer = drawerSlice.reducer
const selectedItemReducer = selectedItemSlice.reducer

export { drawerReducer, selectedItemReducer }
