import React from 'react'
import { Typography } from '@mui/material'

const NotApplicable = (
  <Typography sx={{ fontFamily: `Inter`, fontWeight: 600, fontSize: `12px` }}>
    N/A
  </Typography>
)

export default NotApplicable
