import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Backdrop,
  CircularProgress,
} from '@material-ui/core'
import ExpenseDetail from 'app/Components/Expenses/ExpenseDetail'
import moment from 'moment'
import React, { useState, useContext } from 'react'
import api from 'app/AxiosInstance'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { SnackbarContext } from 'app/Components/SnackbarProvider'

// Redux Hooks
import { useSelector } from 'react-redux'

const tableColumns = [
  { label: `Sr #`, value: `date` },
  { label: `EXPENSE DATE`, value: `date` },
  { label: `EXPENSE MONTH`, value: `month` },
  { label: `EXPENSE BY`, value: `name` },
  { label: `PURCHASED BY`, value: `name` },
  { label: `BUDGET DURATION`, value: `duration` },
  { label: `DETAILS`, value: `detail` },
  { label: `AMOUNT`, value: `amount` },
  { label: `CURRENCY`, value: `amount` },
]

const Expenses = ({ fetchExpenses }) => {
  const openSnackbar = useContext(SnackbarContext)
  const colors = useSelector((state) => state.colors)
  const [isExpenseDetail, setIsExpenseDetail] = useState(false)
  const [expenseId, setExpenseId] = useState(``)
  const { fundsId } = useParams()

  const {
    isLoading,
    data: budgetReports,
    isFetching,
  } = useQuery(
    `getSingleFundDataApi`,
    async () =>
      await api.get(`expenses/${fundsId}/expenses`).then((res) => {
        // Flatten the nested structure and extract currencyWiseExpenses
        const flatData = res?.data?.data?.accountAndExpenses?.flatMap(
          (accAndExp) =>
            accAndExp?.expensesOfSingleAccount?.flatMap(
              (expense) => expense?.currencyWiseExpenses,
            ),
        )

        // Sort the flattened array based on the date property in descending order
        const sortedData = flatData?.sort(
          (a, b) => new Date(b.date) - new Date(a.date),
        )

        return sortedData
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onError: (error) => {
        openSnackbar(
          error?.response ? error?.response?.data?.message : error?.message,
          `error`,
        )
      },
    },
  )

  return (
    <>
      {isExpenseDetail && (
        <ExpenseDetail
          open={isExpenseDetail}
          onClose={() => setIsExpenseDetail(!isExpenseDetail)}
          id={expenseId}
          fetchExpenses={() => fetchExpenses}
        />
      )}
      {isLoading || isFetching ? (
        <Backdrop open={isLoading || isFetching}>
          <CircularProgress
            sx={{
              position: `absolute`,
              top: `50%`,
              color: colors?.appColor,
            }}
          />
        </Backdrop>
      ) : !budgetReports?.length ? (
        <Typography
          sx={{
            display: `flex`,
            justifyContent: `center`,
            alignItems: `center`,
            height: `50vh`,
          }}
        >
          Expense(s) not found!!
        </Typography>
      ) : (
        <Box sx={{ display: `flex`, flexDirection: `column`, marginY: `10px` }}>
          <Box
            sx={{
              backgroundColor: `white`,
              borderRadius: `10px`,
            }}
          >
            <TableContainer
              className="scrollbar-hide"
              sx={{
                backgroundColor: `white`,
                borderRadius: `10px`,
                borderTop: `solid 1px rgba(40, 32, 61, 0.15)`,
                height: {
                  xs: `360px`,
                  sm: `${isLoading || isFetching ? `400px` : `auto`}`,
                },
                maxHeight: `400px`,
                whiteSpace: `nowrap`,
                position: `relative`,
                '& tbody': {
                  '& td, & th': {
                    borderColor: `rgba(40, 32, 61, 0.03)`,
                  },
                },
              }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead className="h-10 ">
                  <TableRow>
                    {tableColumns?.map((item, index) => {
                      return (
                        <TableCell key={index} sx={{ padding: `0px 16px` }}>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: `12px`,
                              color: `#28203D`,
                              opacity: 0.4,
                            }}
                          >
                            {item?.label}
                          </Typography>
                        </TableCell>
                      )
                    })}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {!budgetReports && !budgetReports?.length ? (
                    <TableRow>
                      <TableCell
                        colSpan={tableColumns.length + 1}
                        sx={{ border: 0 }}
                      >
                        <div
                          style={{
                            display: `flex`,
                            justifyContent: `center`,
                            alignItems: `center`,
                          }}
                        >
                          <Typography>No Expense Found</Typography>
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : (
                    budgetReports?.map((currWiseExp, index) => (
                      <TableRow
                        key={currWiseExp._id}
                        sx={{
                          '&:last-child td, &:last-child th': {
                            border: 0,
                          },
                          '&:hover': {
                            cursor: `pointer`,
                            background: `linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(250,249,253,1) 15%, rgba(250,249,253,1) 85%, rgba(255,255,255,1) 100%)`,
                          },
                          width: `100%`,
                        }}
                        onClick={(e) => {
                          e?.stopPropagation(), setExpenseId(currWiseExp?._id)
                          setIsExpenseDetail(!isExpenseDetail)
                        }}
                      >
                        <TableCell
                          sx={{
                            width: `5%`,
                            fontWeight: 400,
                            fontSize: `14px`,
                            lineHeight: `16.94px`,
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          sx={{
                            width: `10%`,
                            fontWeight: 400,
                            fontSize: `14px`,
                            lineHeight: `16.94px`,
                          }}
                        >
                          {moment(currWiseExp?.date).format(`YYYY-MM-DD`)}
                        </TableCell>
                        <TableCell
                          sx={{
                            width: `10%`,
                            fontWeight: 400,
                            fontSize: `14px`,
                            lineHeight: `16.94px`,
                          }}
                        >
                          {moment(currWiseExp?.expenseMonth).format(`MMM YYYY`)}
                        </TableCell>
                        <TableCell
                          sx={{
                            width: `15%`,
                            fontWeight: 400,
                            fontSize: `14px`,
                            lineHeight: `16.94px`,
                            whiteSpace: `wrap`,
                          }}
                        >
                          {currWiseExp?.expenseBy?.name}
                        </TableCell>
                        <TableCell
                          sx={{
                            width: `10%`,
                            fontWeight: 400,
                            fontSize: `14px`,
                            lineHeight: `16.94px`,
                          }}
                        >
                          {currWiseExp?.name}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 400,
                            fontSize: `14px`,
                            lineHeight: `16.94px`,
                            width: `10%`,
                            whiteSpace: `wrap`,
                          }}
                        >{`${moment(currWiseExp?.budget?.startDate).format(
                          `MMM DD, YYYY`,
                        )} to ${moment(currWiseExp?.budget?.endDate).format(
                          `MMM DD, YYYY`,
                        )}`}</TableCell>
                        <TableCell
                          sx={{
                            width: `25%`,
                          }}
                        >
                          <Typography
                            sx={{
                              display: `block`,
                              whiteSpace: `wrap`,
                              textAlign: `left`,
                              fontSize: `14px`,
                            }}
                          >
                            {currWiseExp?.description
                              ? currWiseExp?.description
                              : `No details added`}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              width: `10%`,
                              wordWrap: `break-word`,
                              fontSize: `14px`,
                            }}
                          >
                            {currWiseExp?.amount || 0}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ width: `5px` }}>
                          {currWiseExp?.currency || 0}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      )}
    </>
  )
}

export default Expenses
