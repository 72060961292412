import React from 'react'
import { Box, Skeleton } from '@mui/material'

export const profitLossSkeleton = (
  <Box
    sx={{
      width: `100%`,
      height: `100%`,
      backgroundColor: `white`,
      borderRadius: `10px`,
      padding: `15px`,
      display: `flex`,
      flexDirection: `column`,
      gap: `10px`,
    }}
  >
    <Box
      sx={{
        display: `flex`,
        flexDirection: `row`,
        justifyContent: `space-between`,
      }}
    >
      <Skeleton width={120} height={24} />

      <Box
        sx={{
          display: `flex`,
          flexDirection: `row`,
          alignItems: `center`,
          gap: `10px`,
        }}
      >
        <Skeleton variant="circular" width={24} height={24} />
        <Skeleton width={120} height={24} />
        <Skeleton variant="circular" width={24} height={24} />
      </Box>
    </Box>

    <Box
      sx={{
        height: `100%`,
        flex: 1,
        display: `flex`,
        flexDirection: `column`,
        gap: `5px`,
      }}
    >
      <Skeleton variant="rectangular" height="100%" />
    </Box>
  </Box>
)
