import React from 'react'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min'
import Login from 'app/Pages/Login'

const ProtectedLogin = () => {
  const isLoggedIn = localStorage.getItem(`AuthToken`)
  return isLoggedIn ? <Redirect to="/dashboard" /> : <Login />
}

export default ProtectedLogin
