import React from 'react'
import { FormControl, TextField } from '@mui/material'

const InputField = (props) => {
  const {
    type,
    name,
    label,
    defaultValue,
    value,
    onChange,
    readOnly,
    inputLabelProps,
    sxProps,
  } = props

  return (
    <FormControl fullWidth>
      <TextField
        id="outlined-basic"
        type={type}
        name={name}
        label={label}
        variant="outlined"
        size="small"
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        InputProps={{
          readOnly: readOnly,
        }}
        InputLabelProps={inputLabelProps}
        sx={sxProps}
      />
    </FormControl>
  )
}

export default InputField
