import {
  Backdrop,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import api from 'app/AxiosInstance'
import PrimaryButton from 'app/Components/Common/PrimaryButton'
import { SnackbarContext } from 'app/Components/SnackbarProvider'
import { baseUrl } from 'app/Utils/Config'
import React, { useContext, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import actionIcon from '../../../Assets/optionIcon.svg'
import UpdateTemplate from './UpdateTemplate'
import AddTemplate from './AddTemplate'

const tableColumns = [
  { label: `Sr. No.`, value: `` },
  { label: `DESCRIPTION`, value: `description` },
  { label: `QUANTITY`, value: `quantity` },
  { label: `RATE`, value: `rate` },
]

const InvoiceTemplates = () => {
  const colors = useSelector((state) => state?.colors)
  const openSnackbar = useContext(SnackbarContext)
  const token = localStorage.getItem(`AuthToken`)

  const [anchorEl, setAnchorEl] = useState(null)
  const [addTemplateModal, setAddTemplateModal] = useState(false)
  const [updateTemplateModal, setUpdateTemplateModal] = useState(false)
  const [isDeleteTemplate, setIsDeleteTemplate] = useState(false)
  const [updateTemplateData, setUpdateTemplateData] = useState(``)

  const {
    isLoading,
    data: templateData,
    refetch,
    isFetching,
  } = useQuery(
    `getAllInvoiceTemplates`,
    async () =>
      await api
        .get(`${baseUrl}/templates`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          return res?.data?.data
        }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,

      onError: (error) => {
        openSnackbar(
          error?.response ? error?.response?.data?.message : error?.message,
          `error`,
        )
      },
    },
  )

  const { mutate: deleteTemplate, isLoading: deleteLoader } = useMutation(
    async () =>
      await api
        .delete(`${baseUrl}/templates/${updateTemplateData?._id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data) {
            openSnackbar(`Template deleted successfully`, `success`)
            setIsDeleteTemplate(false)
          }
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: () => {
        refetch()
      },
      onError: (error) => {
        openSnackbar(
          error?.response ? error?.response?.data?.message : error?.message,
          `error`,
        )
        setIsDeleteTemplate(false)
      },
    },
  )

  return (
    <Box
      sx={{
        display: `flex`,
        flexDirection: `column`,
        paddingY: `10px`,
        minHeight: `100vh`,
      }}
    >
      <Popover
        disableEnforceFocus
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        sx={{
          '& .MuiPaper-root': {
            width: `120px`,
            height: `max-content`,
            display: `flex`,
            justifyContent: `center`,
            alignItems: `center`,
          },
        }}
        anchorOrigin={{
          vertical: `bottom`,
          horizontal: `left`,
        }}
        transformOrigin={{
          vertical: `top`,
          horizontal: `center`,
        }}
      >
        <Box sx={{ display: `flex`, flexDirection: `column`, width: `100%` }}>
          <Box
            sx={{
              width: `100%`,
              height: `36px`,
              paddingLeft: `16px`,
              display: `flex`,
              alignItems: `center`,
              '&:hover': {
                cursor: `pointer`,
                backgroundColor: `rgba(40, 32, 61, 0.05)`,
              },
            }}
            onClick={(e) => {
              e?.stopPropagation()
              setUpdateTemplateModal(!updateTemplateModal)
              setAnchorEl(null)
            }}
          >
            Edit
          </Box>

          <Box
            sx={{
              width: `100%`,
              height: `36px`,
              paddingLeft: `16px`,
              '&:hover': {
                cursor: `pointer`,
                backgroundColor: `rgba(40, 32, 61, 0.05)`,
              },
              display: `flex`,
              alignItems: `center`,
              backgroundColor: `white`,
              color: `black`,
            }}
            onClick={(e) => {
              e?.stopPropagation()
              setIsDeleteTemplate(!isDeleteTemplate)
              setAnchorEl(null)
            }}
          >
            Delete
          </Box>
        </Box>
      </Popover>
      {addTemplateModal && (
        <AddTemplate
          open={addTemplateModal}
          onClose={() => setAddTemplateModal(!addTemplateModal)}
          refetch={() => refetch()}
        />
      )}
      {updateTemplateModal && (
        <UpdateTemplate
          open={updateTemplateModal}
          onClose={() => setUpdateTemplateModal(!updateTemplateModal)}
          refetch={() => refetch()}
          templateData={updateTemplateData}
        />
      )}
      {isDeleteTemplate && (
        <Dialog open={isDeleteTemplate}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this template?
          </DialogContent>
          <DialogActions>
            <Box
              sx={{
                display: `flex`,
                flexDirection: `row`,
                gap: `10px`,
                marginBottom: `10px`,
              }}
            >
              <Box
                sx={{
                  bgcolor: `rgba(40, 32, 61, 0.07)`,
                  color: `black`,
                  width: `85px`,
                  height: `36px`,
                  display: `flex`,
                  justifyContent: `center`,
                  alignItems: `center`,
                  '&:hover': { cursor: `pointer`, opacity: 0.8 },
                }}
                onClick={() => setIsDeleteTemplate(false)}
              >
                No
              </Box>
              <Box
                sx={{
                  bgcolor: `#FF485E`,
                  color: `white`,
                  width: `85px`,
                  height: `36px`,
                  display: `flex`,
                  justifyContent: `center`,
                  alignItems: `center`,
                  '&:hover': { cursor: `pointer`, opacity: 0.8 },
                }}
                onClick={() => deleteTemplate()}
              >
                {deleteLoader ? (
                  <CircularProgress
                    size={25}
                    color="secondary"
                    style={{ position: `absolute`, color: `white` }}
                  />
                ) : (
                  `Yes`
                )}
              </Box>
            </Box>
          </DialogActions>
        </Dialog>
      )}
      <Box
        sx={{
          display: `flex`,
          justifyContent: `flex-end`,
          alignItems: `flex-end`,
          marginBottom: `10px`,
        }}
      >
        <PrimaryButton
          title="+ Add Template"
          onClick={() => setAddTemplateModal(!addTemplateModal)}
        />
      </Box>
      {isLoading || isFetching ? (
        <Backdrop open={isLoading || isFetching}>
          <CircularProgress
            sx={{
              position: `absolute`,
              top: `50%`,
              color: colors?.appColor,
            }}
          />
        </Backdrop>
      ) : !templateData || templateData?.length === 0 ? (
        <Typography
          sx={{
            display: `flex`,
            justifyContent: `center`,
            alignItems: `center`,
            height: `50vh`,
          }}
        >
          No template found
        </Typography>
      ) : (
        <Grid item xs={12}>
          {templateData &&
            templateData?.map((data, index) => (
              <Box
                key={index}
                sx={{
                  backgroundColor: `white`,
                  borderRadius: `10px`,
                  paddingX: `15px`,
                  paddingY: `15px`,
                  marginBottom: `10px`,
                }}
              >
                <Box
                  sx={{
                    display: `flex`,
                    justifyContent: `space-between`,
                    alignItems: `center`,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: `20px`,
                      fontWeight: 600,
                      lineHeight: `24.2px`,
                    }}
                  >
                    {data?.name}
                  </Typography>
                  <Box
                    sx={{
                      display: `flex`,
                      flexDirection: `row`,
                      gap: `10px`,
                      //   marginX: `5px`,
                      justifyContent: `center`,
                      alignItems: `center`,
                    }}
                  >
                    <Box
                      sx={{
                        display: `flex`,
                        justifyContent: `flex-end`,
                        alignItems: `flex-end`,
                        width: `36px`,
                        height: `36px`,
                        '&:hover': {
                          backgroundColor: `rgba(40, 32, 61, 0.07)`,
                          borderRadius: 25,
                          cursor: `pointer`,
                        },
                      }}
                      onClick={(e) => {
                        e?.stopPropagation()
                        setAnchorEl(e?.currentTarget)
                        setUpdateTemplateData(data)
                      }}
                    >
                      <img src={actionIcon} alt="actions" />
                    </Box>
                  </Box>
                </Box>
                <TableContainer
                  className="scrollbar-hide"
                  sx={{
                    backgroundColor: `white`,
                    border: `solid 1px rgba(40, 32, 61, 0.15)`,
                    marginTop: `10px`,
                    height: `auto`,
                    maxHeight: `400px`,
                    whiteSpace: `nowrap`,
                    position: `relative`,
                    borderRadius: `10px`,
                    '& tbody': {
                      '& td, & th': {
                        borderColor: `rgba(40, 32, 61, 0.03)`,
                      },
                    },
                  }}
                >
                  <Table
                    stickyHeader
                    aria-label="sticky table a dense table"
                    size="small"
                  >
                    <TableHead className="h-10 p-20 ">
                      <TableRow>
                        {tableColumns?.map((item, index) => {
                          return (
                            <TableCell
                              key={index}
                              sx={{
                                backgroundColor: `rgba(25, 201, 209, 0.15)`,
                              }}
                            >
                              <Typography
                                sx={{
                                  fontWeight: 600,
                                  fontSize: `12px`,
                                  color: `
                                  rgba(40, 32, 61, 0.7)
                                  `,
                                  opacity: 0.4,
                                }}
                              >
                                {item?.label}
                              </Typography>
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!data?.services && !data?.services?.length ? (
                        <TableRow>
                          <TableCell
                            colSpan={tableColumns.length + 1}
                            sx={{ border: 0 }}
                          >
                            <div
                              style={{
                                display: `flex`,
                                justifyContent: `center`,
                                alignItems: `center`,
                              }}
                            >
                              <Typography>No service Found</Typography>
                            </div>
                          </TableCell>
                        </TableRow>
                      ) : (
                        data?.services?.map((service, key) => (
                          <TableRow
                            key={key}
                            sx={{
                              '&:last-child td, &:last-child th': {
                                border: 0,
                              },
                              '&:hover': {
                                cursor: `pointer`,
                                background: `linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(250,249,253,1) 15%, rgba(250,249,253,1) 85%, rgba(255,255,255,1) 100%)`,
                              },
                              width: `100%`,
                            }}
                          >
                            <TableCell sx={{ width: `5%` }}>
                              {key + 1}
                            </TableCell>
                            <TableCell
                              sx={{
                                width: `75%`,
                                wordWrap: `break-word`,
                                minWidth: `300px`,
                              }}
                            >
                              <Typography
                                sx={{
                                  display: `block`,
                                  width: `100%`,
                                  whiteSpace: `normal`,
                                  textAlign: `justify`,
                                  fontSize: `16px`,
                                }}
                              >
                                {service?.detail
                                  ? service?.detail
                                  : `No description added`}
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ width: `10%` }}>
                              {service?.quantity}
                            </TableCell>
                            <TableCell sx={{ width: `10%` }}>
                              {service?.rate}
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            ))}
        </Grid>
      )}
    </Box>
  )
}

export default InvoiceTemplates
