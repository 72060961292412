import React, { useState, useContext, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  Divider,
  Grid,
  Checkbox,
  Autocomplete,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core'
import { SnackbarContext } from 'app/Components/SnackbarProvider'
import { useQuery, useMutation } from 'react-query'
import api from 'app/AxiosInstance'

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

// Mui Icons
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

// Import Images
import cross from 'app/Assets/cross.svg'
import userIcon from 'app/Assets/user_ic.svg'

// Common Components
import InputField from '../../../Common/InputField'
import PrimaryButton from 'app/Components/Common/PrimaryButton'
import SecondaryButton from 'app/Components/Common/SecondaryButton'
import MultilineInputField from 'app/Components/Common/MultilineInputField'
import MySelectionField from 'app/Components/Common/MySelectionField'

// Third party Api's
import { Country, State } from 'country-state-city'
import { AnimatePresence, motion } from 'framer-motion'
const statusOptions = [
  {
    id: `ACTIVE`,
    name: `Active`,
  },
  {
    id: `INACTIVE`,
    name: `Inactive`,
  },
]

const AddClient = (props) => {
  const { open, onClose, refetch } = props

  const openSnackbar = useContext(SnackbarContext)

  const [projects, setProjects] = useState([
    { id: 1, name: `veronica` },
    { id: 2, name: `monitair` },
  ])

  const [projectsArray, setProjectsArray] = useState([])

  useQuery(
    `allprojects`,
    async () => {
      const { data } = await api.get(`/projects/?search=&sort=&page=&limit=`)

      if (data) {
        setProjects(data.data.projects)
      }
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onError: (error) => {
        openSnackbar(
          error?.response ? error?.response?.data?.message : error?.message,
          `error`,
        )
      },
    },
  )

  const [addClientState, setAddClientState] = useState({})

  const addClient = async (payload) => {
    const response = await api.post(`/clients`, payload)
    return response
  }

  const { mutate: addClientMutation, isLoading: isMutatingAddClient } =
    useMutation(addClient, {
      onSuccess: () => {
        onClose()
        refetch()
        openSnackbar(`Added Successfull`, `success`)
      },
      onError: (error) => {
        openSnackbar(
          error?.response ? error.response.data.message : error.message,
          `error`,
        )
      },
    })

  const handleSubmitForm = () => {
    if (isMutatingAddClient || isMutatingPicture) {
      return // Do nothing if either mutation is in progress
    }

    if (
      Object.keys(addClientState).length === 0 &&
      projectsArray.length === 0
    ) {
      openSnackbar(`Ops!! Empty Form.`, `error`)
      return
    }

    const projectsIds = projectsArray.map((project) => project._id)
    const addClientStateWithProjects = {
      ...addClientState,
      ...(projectsIds.length > 0 && { projects: projectsIds }),
      status,
    }

    const payload = Object.fromEntries(
      Object.entries(addClientStateWithProjects).filter(
        (arr) => arr[1] !== `` && arr[1] !== null,
      ),
    )

    addClientMutation(payload)
  }

  const handleInputChange = (key, val) => {
    if (isMutatingAddClient || isMutatingPicture) return
    setAddClientState((prev) => ({ ...prev, [key]: val }))
  }

  const getSxProps = () => {
    return {
      '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
          border: `solid rgba(40, 32, 61, 0.25) 1px`,
        },
        '&.Mui-focused fieldset': {
          border: `solid rgba(40, 32, 61, 0.25) 1px`,
        },
        '& fieldset': {
          borderColor: `rgba(40, 32, 61, 0.25)`,
          borderRadius: `10px`,
        },
      },
      '& .MuiInputLabel-root': {
        '&.Mui-focused': {
          color: `rgba(40, 32, 61, 0.5)`,
        },
      },
    }
  }

  const getInputLabelProps = () => {
    return {
      style: {
        color: `rgba(40, 32, 61, 0.5)`,
      },
    }
  }

  const getSelectionFieldLabelColor = () => {
    return {
      color: `rgba(40, 32, 61, 0.5)`,
    }
  }

  const [profilePic, setProfilePic] = useState(null)

  const uploadPicture = async (file) => {
    const formData = new FormData()
    formData.append(`document`, file)
    formData.append(`folderName`, `profile/`)
    const response = await api.post(`/users/upload-document`, formData)
    return response.data.data
  }

  const { mutate: uploadPictureMutation, isLoading: isMutatingPicture } =
    useMutation(uploadPicture, {
      onSuccess: (data) => {
        openSnackbar(`Picture Uploaded Successfully!!`, `success`)
        const updatedAddClientData = {
          ...addClientState,
          profilePicture: {
            url: data.attachment,
            key: data.key,
          },
        }
        setProfilePic(data.temporaryLink)
        setAddClientState(updatedAddClientData)
      },
      onError: (error) => {
        openSnackbar(
          error.response ? error.response.data.message : error.message,
          `error`,
        )
      },
    })

  const handleImagePick = (event) => {
    const file = event.target.files[0]
    setProfilePic(URL.createObjectURL(file))
    uploadPictureMutation(file)
  }

  const handleDeleteImage = () => {
    if (!isMutatingPicture) {
      setProfilePic(null)
    }
  }

  const allCountries = Country.getAllCountries()
  const countriesArray = allCountries.map((country) => country.name)

  const [statesArray, setStatesArray] = useState([])

  useEffect(() => {
    // Make State NULL if exist :(because)----> "If user select country then select state against that country and then what if he or she select the other country and but does not select state against new selected"
    if (addClientState?.country) {
      const foundSelectedCountry = allCountries.find(
        (country) => country.name === addClientState.country,
      )

      if (addClientState?.state) {
        setAddClientState((prev) => ({ ...prev, state: null }))
      }

      const statesArray = State.getStatesOfCountry(foundSelectedCountry.isoCode)

      const statesNames = statesArray.map((state) => state.name)

      setStatesArray(statesNames)
    }
  }, [addClientState.country])

  const [status, setStatus] = useState(`ACTIVE`)

  return (
    <motion.div
      initial={{ opacity: 0, y: -80 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5, delay: 0.1 }}
    >
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        PaperProps={{
          sx: {
            borderRadius: `20px 20px 20px 20px`,
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            height: `max-content`,
            display: `flex`,
            flexDirection: `column`,
          }}
        >
          <Box
            sx={{
              width: `100%`,
              display: `flex`,
              justifyContent: `space-between`,
              alignItems: `center`,
              flexDirection: `row`,
              paddingBottom: `10px`,
            }}
          >
            <Typography
              sx={{
                fontFamily: `Inter`,
                fontSize: {
                  lg: 15,
                  md: 13,
                  sm: 10,
                  xs: 8,
                },
              }}
            >
              Add Client
            </Typography>
            <Box
              sx={{
                width: `36px`,
                height: `36px`,
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`,
                padding: `11px`,
                '&:hover': {
                  cursor: `pointer`,
                  opacity: 0.8,
                },
              }}
              onClick={onClose}
            >
              <img src={cross} alt="cross" width="13.87px" height="14px" />
            </Box>
          </Box>
          <Divider sx={{ width: `100%` }} />
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0, x: -500, rotate: -360 }}
              animate={{ opacity: 1, x: 0, rotate: 0 }}
              exit={{ opacity: 0, x: -100, rotate: -360 }}
              transition={{ duration: 1, delay: 0.1, ease: `easeInOut` }}
            >
              <Box
                sx={{
                  display: `flex`,
                  justifyContent: `center`,
                  marginTop: `10px`,
                }}
              >
                <Box
                  sx={{
                    width: `100px`,
                    height: `100px`,
                    alignSelf: `center`,
                    borderRadius: `50%`,
                    display: `flex`,
                    justifyContent: `center`,
                    alignItems: `center`,
                    backgroundColor: `rgba(40, 32, 61, 0.07)`,
                    position: `relative`,
                    // border: profilePicture ? `solid 1px red` : `white`,
                  }}
                >
                  <img
                    src={profilePic ? profilePic : userIcon}
                    alt="user"
                    style={{
                      width: profilePic ? `100%` : `50px`,
                      height: profilePic ? `100%` : `50px`,
                      borderRadius: `100%`,
                    }}
                  />
                  {profilePic ? (
                    <Box
                      sx={{
                        position: `absolute`,
                        bottom: 0,
                        right: 0,
                        backgroundColor: `#FF485E`,
                        color: `white`,
                        width: `30px`,
                        height: `30px`,
                        display: `flex`,
                        justifyContent: `center`,
                        alignItems: `center`,
                        borderRadius: `50%`,
                        border: `solid white 3px`,
                        zIndex: 2,
                        '&:hover': {
                          cursor: !isMutatingPicture && `pointer`,
                          width: `35px`,
                          height: `35px`,
                        },
                      }}
                      onClick={handleDeleteImage}
                    >
                      x
                    </Box>
                  ) : (
                    <label htmlFor="add-client-image-picker">
                      <Box
                        sx={{
                          position: `absolute`,
                          bottom: 0,
                          right: 0,
                          backgroundColor: `#19C9D1`,
                          color: `white`,
                          width: `30px`,
                          height: `30px`,
                          display: `flex`,
                          justifyContent: `center`,
                          alignItems: `center`,
                          borderRadius: `50%`,
                          border: `solid white 3px`,
                          zIndex: 2,
                          '&:hover': {
                            cursor: `pointer`,
                            width: `35px`,
                            height: `35px`,
                          },
                        }}
                      >
                        <input
                          accept=".jpg,.jpeg,.png,.gif,.JPG,.JPEG,.PNG,.GIF"
                          id="add-client-image-picker"
                          type="file"
                          style={{ display: `none` }}
                          onChange={handleImagePick}
                        />
                        +
                      </Box>
                    </label>
                  )}
                </Box>
              </Box>
            </motion.div>
          </AnimatePresence>
        </DialogTitle>

        <DialogContent
          sx={{
            display: `flex`,
            flexDirection: `column`,
            gap: `20px`,
            overflowY: `auto`,
          }}
        >
          <Grid
            container
            rowSpacing={2}
            columnSpacing={4}
            sx={{ paddingTop: `10px` }}
          >
            <Grid item xs={12} sm={6}>
              <motion.div
                initial={{ opacity: 0, y: -40 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.5, delay: 0.1 }}
              >
                <InputField
                  type="text"
                  label="Name"
                  defaultValue=""
                  value={addClientState.name}
                  onChange={(evt) =>
                    handleInputChange(`name`, evt.target.value)
                  }
                  readOnly={false}
                  inputLabelProps={getInputLabelProps()}
                  sxProps={getSxProps()}
                />
              </motion.div>
            </Grid>

            <Grid item xs={12} sm={6}>
              <motion.div
                initial={{ opacity: 0, y: -40 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.5, delay: 0.1 }}
              >
                <InputField
                  label="Phone Number"
                  inputLabelProps={getInputLabelProps()}
                  sxProps={getSxProps()}
                  onChange={(evt) =>
                    handleInputChange(`phone`, evt.target.value)
                  }
                />
              </motion.div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <motion.div
                initial={{ opacity: 0, y: -40 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.5, delay: 0.1 }}
              >
                <InputField
                  type="text"
                  label="Email"
                  defaultValue=""
                  onChange={(evt) =>
                    handleInputChange(`email`, evt.target.value)
                  }
                  readOnly={false}
                  inputLabelProps={getInputLabelProps()}
                  sxProps={getSxProps()}
                />
              </motion.div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <motion.div
                initial={{ opacity: 0, y: -40 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.5, delay: 0.1 }}
              >
                <InputField
                  type="text"
                  label="Company"
                  defaultValue=""
                  onChange={(evt) =>
                    handleInputChange(`company`, evt.target.value)
                  }
                  readOnly={false}
                  inputLabelProps={getInputLabelProps()}
                  sxProps={getSxProps()}
                />
              </motion.div>
            </Grid>

            <Grid item xs={12} sm={6}>
              <motion.div
                initial={{ opacity: 0, y: -40 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.5, delay: 0.1 }}
              >
                <InputField
                  type="text"
                  label="URL"
                  defaultValue=""
                  onChange={(evt) => handleInputChange(`url`, evt.target.value)}
                  readOnly={false}
                  inputLabelProps={getInputLabelProps()}
                  sxProps={getSxProps()}
                />
              </motion.div>
            </Grid>

            <Grid item xs={12} sm={6}>
              <motion.div
                initial={{ opacity: 0, y: -40 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.5, delay: 0.1 }}
              >
                <MySelectionField
                  label="Country"
                  options={countriesArray}
                  value={addClientState.country}
                  disabled={false}
                  onChange={(evt) =>
                    handleInputChange(`country`, evt.target.value)
                  }
                  labelColor={getSelectionFieldLabelColor()}
                  sxProps={getSxProps()}
                />
              </motion.div>
            </Grid>

            <Grid item xs={12} sm={6}>
              <motion.div
                initial={{ opacity: 0, y: -40 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.5, delay: 0.1 }}
              >
                <MySelectionField
                  label="State"
                  options={statesArray}
                  value={addClientState.state}
                  disabled={false}
                  onChange={(evt) =>
                    handleInputChange(`state`, evt.target.value)
                  }
                  labelColor={getSelectionFieldLabelColor()}
                  sxProps={getSxProps()}
                />
              </motion.div>
            </Grid>

            <Grid item xs={12} sm={6}>
              <motion.div
                initial={{ opacity: 0, y: -40 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.5, delay: 0.1 }}
              >
                <InputField
                  type="text"
                  label="Address"
                  defaultValue=""
                  onChange={(evt) =>
                    handleInputChange(`address`, evt.target.value)
                  }
                  readOnly={false}
                  inputLabelProps={getInputLabelProps()}
                  sxProps={getSxProps()}
                />
              </motion.div>
            </Grid>

            {/* <Grid item xs={12} sm={6}>
              <motion.div
                initial={{ opacity: 0, y: -40 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.5, delay: 0.1 }}
              >
                <FormControl
                  size="small"
                  fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        border: `solid rgba(40, 32, 61, 0.25) 1px`,
                      },
                      '&.Mui-focused fieldset': {
                        border: `solid rgba(40, 32, 61, 0.25) 1px`,
                      },
                      '& fieldset': {
                        borderColor: `rgba(40, 32, 61, 0.25)`,
                        borderRadius: `10px`,
                      },
                    },
                    '& .MuiInputLabel-root': {
                      '&.Mui-focused': {
                        color: `rgba(40, 32, 61, 0.5)`,
                      },
                    },
                  }}
                >
                  <InputLabel
                    id="demo-mutiple-chip-checkbox-label"
                    sx={{ color: `rgba(40, 32, 61, 0.5)` }}
                  >
                    Projects
                  </InputLabel>
                  <Select
                    labelId="demo-mutiple-chip-checkbox-label"
                    id="demo-mutiple-chip-checkbox"
                    multiple
                    value={projectsArray}
                    onChange={handleProjectsChange}
                    input={
                      <OutlinedInput
                        id="select-multiple-chip"
                        label="Projects"
                      />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: `flex`, flexWrap: `wrap`, gap: 0.5 }}>
                        {selected.map((selected) => (
                          <Chip
                            sx={{
                              backgroundColor: `rgba(25, 201, 209, 0.15)`,
                              color: `black`,
                              borderRadius: `10px`,
                            }}
                            clickable
                            key={selected.id}
                            label={selected.name}
                          />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {projects?.map((project) => (
                      <MenuItem
                        key={project._id}
                        value={project}
                        style={getStyles(project.name, projectsArray, theme)}
                      >
                        <Checkbox
                          checked={projectsArray.some(
                            (eachProject) => project.name === eachProject.name,
                          )}
                        />
                        <ListItemText primary={project.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </motion.div>
            </Grid> */}

            <Grid item xs={12} sm={6}>
              <motion.div
                initial={{ opacity: 0, y: -40 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.5, delay: 0.1 }}
              >
                <Autocomplete
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        border: `solid rgba(40, 32, 61, 0.25) 1px`,
                      },
                      '&.Mui-focused fieldset': {
                        border: `solid rgba(40, 32, 61, 0.25) 1px`,
                      },
                      '& fieldset': {
                        borderColor: `rgba(40, 32, 61, 0.25)`,
                        borderRadius: `10px`,
                      },
                    },
                    '& .MuiInputLabel-root': {
                      color: `rgba(40, 32, 61, 0.5)`,
                    },
                  }}
                  size="small"
                  fullWidth
                  multiple
                  id="checkboxes-tags-demo"
                  options={projects}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  value={projectsArray}
                  onChange={(event, newValue) => {
                    setProjectsArray(newValue)
                  }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: `bottom`,
                      horizontal: `left`,
                    },
                    getContentAnchorEl: null,
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected.name}
                      />
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="Project" />
                  )}
                />
              </motion.div>
            </Grid>

            <Grid item xs={12} sm={6}>
              <motion.div
                initial={{ opacity: 0, y: -40 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.5, delay: 0.1 }}
              >
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    backgroundColor: `white`,
                    borderRadius: `10px`,
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        border: `solid rgba(40, 32, 61, 0.5), 1px`,
                      },
                      '&.Mui-focused fieldset': {
                        border: `solid rgba(40, 32, 61, 0.5) 1px`,
                      },
                      '& fieldset': {
                        borderColor: `rgba(40, 32, 61, 0.25)`,
                        borderRadius: `10px`,
                      },
                    },
                    '& .MuiInputLabel-root': {
                      '&.Mui-focused': {
                        color: `rgba(40, 32, 61, 0.25)`,
                      },
                    },
                  }}
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{
                      color: `rgba(40, 32, 61, 0.5)`,
                    }}
                  >
                    Status *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="status"
                    label="Status *"
                    value={status || `ACTIVE`}
                    onChange={(e) => setStatus(e?.target?.value)}
                  >
                    {statusOptions &&
                      statusOptions?.map((status) => (
                        <MenuItem value={status?.id} key={status?.id}>
                          {status?.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </motion.div>
            </Grid>

            <Grid item xs={12}>
              <motion.div
                initial={{ opacity: 0, y: -100 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.5, delay: 0.1 }}
              >
                <MultilineInputField
                  type="text"
                  label="Description"
                  defaultValue=""
                  value={addClientState.description}
                  onChange={(evt) =>
                    handleInputChange(`description`, evt.target.value)
                  }
                  readOnly={false}
                  inputLabelProps={getInputLabelProps()}
                  sxProps={getSxProps()}
                />
              </motion.div>
            </Grid>
          </Grid>
        </DialogContent>

        <motion.div
          initial={{ opacity: 0, x: -1000 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -20 }}
          transition={{ duration: 1, delay: 0.1 }}
        >
          <Box
            sx={{
              display: `flex`,
              flexDirection: { xs: `column`, sm: `row` },
              justifyContent: `flex-end`,
              gap: `10px`,
              padding: `20px`,
            }}
          >
            <SecondaryButton title="Cancel" onClick={() => onClose()} />
            <PrimaryButton
              title={
                isMutatingPicture
                  ? `Uploading...`
                  : isMutatingAddClient
                  ? `Adding...`
                  : `Add Client`
              }
              isLoading={isMutatingAddClient || isMutatingPicture}
              onClick={() => handleSubmitForm()}
            />
          </Box>
        </motion.div>
        <Divider />
      </Dialog>
    </motion.div>
  )
}

export default React.memo(AddClient)
