import React from 'react'
import { useState, useRef } from 'react'
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Typography,
  Box,
  Snackbar,
  IconButton,
  Button,
  CircularProgress,
} from '@material-ui/core'
import cross from 'app/Assets/cross.svg'
import { Redirect } from 'react-router-dom'
import api from 'app/AxiosInstance'
import { baseUrl } from 'app/Utils/Config'
import CloseIcon from '@mui/icons-material/Close'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { applyUser } from 'app/Redux/slices/user'
import { changeSelectedItem } from 'app/Redux/slices/user/Drawer'
import { setCurrentAccount, setCurrentAttendance } from 'app/Redux/actions'

const Logout = ({ open, onClose }) => {
  // destructuring the props
  const colors = useSelector((state) => state.colors)
  const logoutRef = useRef()
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [openSnackBar, setOpenSnackBar] = useState(true)
  const token = localStorage.getItem(`AuthToken`)
  const dispatch = useDispatch()

  // handle logout button click
  const { isLoading, isError, error, refetch } = useQuery(
    `logout`,
    async () => {
      await api.get(`${baseUrl}/users/logout`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      localStorage.removeItem(`AuthToken`)
      dispatch(changeSelectedItem({ selectedItem: `Dashboard` }))
      dispatch(applyUser({ name: ``, systemRole: ``, profilePic: `` }))
      setIsLoggedIn(true)
      setOpenSnackBar(true)
    },
    {
      enabled: false,
      onSuccess: () => {
        dispatch(setCurrentAccount(``))
        dispatch(
          setCurrentAttendance({
            firstName: ``,
            lastName: ``,
          }),
        )
      },
      onError: () => {
        setOpenSnackBar(true)
      },
    },
  )

  const handleSnackBarClose = () => {
    setOpenSnackBar(false)
  }

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleSnackBarClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackBarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  )

  const handleKeyDown = (event) => {
    if (event.key === `Enter`) {
      refetch()
    }
  }

  return (
    <>
      {isLoggedIn && <Redirect to="/" />}
      {isError && (
        <Snackbar
          open={openSnackBar}
          autoHideDuration={6000}
          onClose={handleSnackBarClose}
          message={
            error?.response ? error.response.data.message : error.message
          }
          action={action}
        />
      )}
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            width: `300px`,
            height: `200px`,
            borderRadius: `20px 20px 20px 20px`,
          },
        }}
        onKeyDown={handleKeyDown}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            height: `48px`,
          }}
        >
          <Box sx={{ display: `flex`, flexDirection: `row-reverse` }}>
            <Box
              sx={{
                width: `36px`,
                height: `36px`,
                display: `flex`,
                justifyContent: `center`,
                alignItems: `centrer`,
                padding: `11px`,
                '&:hover': {
                  cursor: `pointer`,
                  opacity: 0.8,
                },
              }}
              onClick={onClose}
            >
              <img src={cross} alt="cross" width="13.87px" height="14px" />
            </Box>
          </Box>
        </DialogTitle>

        <DialogContent>
          <Box
            sx={{
              display: `flex`,
              flexDirection: `column`,
              justifyContent: `center`,
              alignItems: `center`,
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: `20px`,
                marginTop: `29.5px`,
                lineHeight: `24.2px`,
              }}
            >
              Are you Sure?
            </Typography>

            <Box
              variant="contained"
              ref={logoutRef}
              sx={{
                backgroundColor: colors.appColor,
                color: `white`,
                marginTop: `30px`,
                width: `173px`,
                height: `44px`,
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`,
                borderRadius: `10px`,
                '&:hover': {
                  cursor: `pointer`,
                  opacity: 0.8,
                },
              }}
              onClick={() => refetch()}
            >
              {isLoading ? (
                <CircularProgress
                  size={25}
                  color="secondary"
                  style={{ position: `absolute`, color: `white` }}
                />
              ) : (
                `logout`
              )}
            </Box>
          </Box>
        </DialogContent>
        <Divider />
      </Dialog>
    </>
  )
}

export default Logout
