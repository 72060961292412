import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import PrependRs from 'app/Utils/PrependRs'
import moment from 'moment'
import React, { useContext, useState } from 'react'
import receiptIcon from '../../../Assets/doc.svg'
import Image from 'mui-image'
import actionIcon from '../../../Assets/optionIcon.svg'
import UpdateTransaction from './UpdateTransaction'
import api from 'app/AxiosInstance'
import { baseUrl } from 'app/Utils/Config'
import { useMutation } from 'react-query'
import { SnackbarContext } from 'app/Components/SnackbarProvider'
import PreviewImage from 'app/Components/Gallery/PreviewImage'
import { getRole, isCEO, isCTO } from 'app/Utils/helper'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

const tableColumns = [
  { label: `Sr. No.`, value: `` },
  { label: `DATE`, value: `date` },
  { label: `TRANSACTION BY`, value: `name` },
  { label: `PAYMENT MODE`, value: `mode` },
  { label: `DETAILS`, value: `detail` },
  { label: `AMOUNT`, value: `amount` },
  { label: `RECEIPT`, value: `receipt` },
  { label: `ACTIONS`, value: `` },
]
const Transactions = ({ singleFund, refetchTransaction, fundsData }) => {
  const { fundsId } = useParams()

  const sortedFunds = singleFund?.transactions?.sort(
    (a, b) => new Date(b.date) - new Date(a.date),
  )

  const [anchorEl, setAnchorEl] = useState(null)
  const openSnackbar = useContext(SnackbarContext)
  const token = localStorage.getItem(`AuthToken`)

  const systemRole = getRole()

  const [imageUrl, setImageUrl] = useState(``)
  const [isPreview, setIsPreview] = useState(false)

  const [transaction, setTransaction] = useState(``)
  const [isTransactionUpdate, setIsTransactionUpdate] = useState(false)
  const [isDeleteTransaction, setIsDeleteTransaction] = useState(false)
  const { mutate: deleteTransaction, isLoading: deleteLoader } = useMutation(
    async () =>
      await api
        .patch(
          `${baseUrl}/funds/${fundsId}/deleteTransaction/${transaction?._id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then((res) => {
          if (res.data) {
            openSnackbar(`Transaction deleted successfully`, `success`)
            setIsDeleteTransaction(false)
          }
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: () => {
        refetchTransaction()
      },

      onError: (error) => {
        openSnackbar(
          error?.response ? error?.response?.data?.message : error?.message,
          `error`,
        )
        setIsDeleteTransaction(false)
      },
    },
  )
  let rowNumber = 0
  return (
    <>
      {isPreview && (
        <PreviewImage
          open={isPreview}
          onClose={() => setIsPreview(!isPreview)}
          imageUrl={imageUrl}
        />
      )}

      <Popover
        disableEnforceFocus
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        sx={{
          '& .MuiPaper-root': {
            width: `120px`,
            height: `max-content`,
            display: `flex`,
            justifyContent: `center`,
            alignItems: `center`,
          },
        }}
        anchorOrigin={{
          vertical: `bottom`,
          horizontal: `left`,
        }}
        transformOrigin={{
          vertical: `top`,
          horizontal: `center`,
        }}
      >
        <Box sx={{ display: `flex`, flexDirection: `column`, width: `100%` }}>
          <Box
            sx={{
              width: `100%`,
              height: `36px`,
              paddingLeft: `16px`,
              display: `flex`,
              alignItems: `center`,
              '&:hover': {
                cursor: `pointer`,
                backgroundColor: `rgba(40, 32, 61, 0.05)`,
              },
            }}
            onClick={(e) => {
              e?.stopPropagation()
              setIsTransactionUpdate(!isTransactionUpdate)
              setAnchorEl(null)
            }}
          >
            Edit
          </Box>

          <Box
            sx={{
              width: `100%`,
              height: `36px`,
              paddingLeft: `16px`,
              '&:hover': {
                cursor: `pointer`,
                backgroundColor: `rgba(40, 32, 61, 0.05)`,
              },
              display: `flex`,
              alignItems: `center`,
              backgroundColor: `white`,
              color: `black`,
            }}
            onClick={(e) => {
              e?.stopPropagation()
              setIsDeleteTransaction(!isDeleteTransaction)
              setAnchorEl(null)
            }}
          >
            Delete
          </Box>
        </Box>
      </Popover>

      {isTransactionUpdate && (
        <UpdateTransaction
          open={isTransactionUpdate}
          onClose={() => setIsTransactionUpdate(!isTransactionUpdate)}
          fundsData={fundsData}
          singleFund={sortedFunds}
          transactionData={transaction}
          refetchTransaction={() => refetchTransaction()}
        />
      )}

      {isDeleteTransaction && (
        <Dialog open={isDeleteTransaction}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this transaction?
          </DialogContent>
          <DialogActions>
            <Box
              sx={{
                display: `flex`,
                flexDirection: `row`,
                gap: `10px`,
                marginBottom: `10px`,
              }}
            >
              <Box
                sx={{
                  bgcolor: `rgba(40, 32, 61, 0.07)`,
                  color: `black`,
                  width: `85px`,
                  height: `36px`,
                  display: `flex`,
                  justifyContent: `center`,
                  alignItems: `center`,
                  '&:hover': { cursor: `pointer`, opacity: 0.8 },
                }}
                onClick={() => setIsDeleteTransaction(false)}
              >
                No
              </Box>
              <Box
                sx={{
                  bgcolor: `#FF485E`,
                  color: `white`,
                  width: `85px`,
                  height: `36px`,
                  display: `flex`,
                  justifyContent: `center`,
                  alignItems: `center`,
                  '&:hover': { cursor: `pointer`, opacity: 0.8 },
                }}
                onClick={() => deleteTransaction()}
              >
                {deleteLoader ? (
                  <CircularProgress
                    size={25}
                    color="secondary"
                    style={{ position: `absolute`, color: `white` }}
                  />
                ) : (
                  `Yes`
                )}
              </Box>
            </Box>
          </DialogActions>
        </Dialog>
      )}

      <Grid item xs={12}>
        <TableContainer
          className="scrollbar-hide"
          sx={{
            backgroundColor: `white`,
            border: `solid 1px rgba(40, 32, 61, 0.15)`,
            borderRadius: `10px`,
            marginTop: `20px`,
            height: {
              xs: `360px`,
              sm: `400px`,
            },
            whiteSpace: `nowrap`,
            position: `relative`,
          }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead className="h-10">
              <TableRow>
                {tableColumns?.map((item, index) => {
                  return (
                    <TableCell key={index} sx={{ padding: `0px 16px` }}>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: `12px`,
                          color: `#28203D`,
                          opacity: 0.4,
                        }}
                      >
                        {item?.label}
                      </Typography>
                    </TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedFunds?.[0]?.transactionBy?.name ? (
                sortedFunds?.map((data, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      '&:hover': {
                        cursor: `pointer`,
                        background: `linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(250,249,253,1) 15%, rgba(250,249,253,1) 85%, rgba(255,255,255,1) 100%)`,
                      },
                      width: `100%`,
                    }}
                  >
                    <TableCell>{++rowNumber}</TableCell>
                    <TableCell
                      sx={{
                        width: `15%`,
                        wordWrap: `break-word`,
                        fontSize: `14px`,
                        fontWeight: 400,
                        lineHeight: `16.94px`,
                      }}
                    >
                      {moment(data?.date).format(`YYYY-MM-DD`)}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: `15%`,
                        wordWrap: `break-word`,
                        fontSize: `14px`,
                        fontWeight: 400,
                        lineHeight: `16.94px`,
                      }}
                    >
                      {data?.transactionBy?.name}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: `15%`,
                        wordWrap: `break-word`,
                        fontSize: `14px`,
                        fontWeight: 400,
                        lineHeight: `16.94px`,
                      }}
                    >
                      {data?.paymentMode === `BANK_TRANSFER`
                        ? `Bank Transfer`
                        : data?.paymentMode === `JAZZ_CASH`
                        ? `Jazz Cash`
                        : `By Cash`}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: `40%`,
                        wordWrap: `break-word`,
                        minWidth: `300px`,
                      }}
                    >
                      <Typography
                        sx={{
                          display: `block`,
                          width: `100%`,
                          whiteSpace: `normal`,
                          textAlign: `justify`,
                          fontSize: `16px`,
                        }}
                      >
                        {data?.description
                          ? data?.description
                          : `No description added`}
                      </Typography>
                    </TableCell>

                    <TableCell>
                      {PrependRs({
                        amount: data?.amount || 0,
                        sx: {
                          width: `15%`,
                          wordWrap: `break-word`,
                          fontSize: `14px`,
                          fontWeight: 400,
                          lineHeight: `16.94px`,
                        },
                      })}
                    </TableCell>
                    <TableCell sx={{ width: `15%` }}>
                      {data?.receipt ? (
                        data?.receipt?.key?.includes(`pdf`) ? (
                          <Link
                            href={data?.receipt?.url}
                            sx={{ display: `flex`, gap: `10px` }}
                            underline="none"
                          >
                            <Image
                              src={receiptIcon}
                              alt="doc"
                              width="12px"
                              height="17px"
                            />
                            <Typography
                              sx={{
                                fontWeight: 500,
                                fontSize: `14px`,
                                color: `rgba(96, 57, 187, 1)`,
                                fontFamily: `Inter`,
                                textAlign: `center`,
                              }}
                            >
                              View Receipt
                            </Typography>
                          </Link>
                        ) : (
                          <Box
                            sx={{
                              display: `flex`,
                              justifyContent: `center`,
                              alignItems: `center`,
                              gap: `10px`,
                              cursor: `pointer`,
                            }}
                            onClick={() => {
                              setImageUrl(data?.receipt?.url)
                              setIsPreview(true)
                            }}
                          >
                            <Image
                              src={receiptIcon}
                              alt="doc"
                              width="12px"
                              height="17px"
                            />
                            <Typography
                              sx={{
                                fontWeight: 500,
                                fontSize: `14px`,
                                color: `rgba(96, 57, 187, 1)`,
                                fontFamily: `Inter`,
                                textAlign: `center`,
                              }}
                            >
                              View Receipt
                            </Typography>
                          </Box>
                        )
                      ) : (
                        ``
                      )}
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: `flex`,
                          flexDirection: `row`,
                          gap: `10px`,
                          marginX: `5px`,
                          justifyContent: `center`,
                          alignItems: `center`,
                        }}
                      >
                        <Box
                          sx={{
                            display: `flex`,
                            justifyContent: `center`,
                            alignItems: `center`,
                            width: `36px`,
                            height: `36px`,
                            '&:hover': {
                              backgroundColor: `rgba(40, 32, 61, 0.07)`,
                              borderRadius: 25,
                              cursor:
                                isCEO() || isCTO() ? `pointer` : `not-allowed`,
                            },
                          }}
                          onClick={(e) => {
                            if (isCEO() || isCTO()) {
                              e?.stopPropagation()
                              setAnchorEl(e?.currentTarget)
                              setTransaction(data)
                            }
                          }}
                        >
                          <img src={actionIcon} alt="actions" />
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={tableColumns.length + 1}
                    sx={{ border: 0 }}
                  >
                    <div
                      style={{
                        display: `flex`,
                        justifyContent: `center`,
                        alignItems: `center`,
                      }}
                    >
                      <Typography>No Transactions Found</Typography>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  )
}

export default Transactions
