import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import api from 'app/AxiosInstance'
import PrimaryButton from 'app/Components/Common/PrimaryButton'
import { SnackbarContext } from 'app/Components/SnackbarProvider'
import { getAllAccounts } from 'app/Services/General/getAllAccounts'
import { baseUrl } from 'app/Utils/Config'
import PrependRs from 'app/Utils/PrependRs'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

const tableColumns = [
  { label: `ACCOUNT`, value: `account` },
  { label: `BUDGET DESCRIPTION`, value: `description` },
  { label: `BUDGET`, value: `budget` },
  { label: `UTILIZED`, value: `utilized` },
  { label: `BALANCE`, value: `balance` },
]

const Reports = () => {
  const colors = useSelector((state) => state?.colors)
  const history = useHistory()
  const [accountsData, setAccountsData] = useState([])

  useEffect(() => {
    getAllAccounts().then((result) => {
      const newLoadAccounts = result?.options?.map((account) => {
        {
          return {
            label: account?.name,
            value: account?._id,
          }
        }
      })
      setAccountsData(newLoadAccounts)
    })
  }, [])

  const openSnackbar = useContext(SnackbarContext)

  const token = localStorage.getItem(`AuthToken`)

  const [filteredData, setFilteredData] = useState({
    account: ``,
    startDate: new Date(new Date().getFullYear(), 0, 1),
    endDate: new Date(new Date().getFullYear(), 11, 31),
    isBelow: true,
    isUtilized: false,
    isExceeded: false,
  })

  const {
    isLoading,
    data: budgetReports,
    refetch,
    isFetching,
    // isError,
  } = useQuery(
    `getBudgetReports`,
    async () =>
      await api
        .get(
          `${baseUrl}/accounts/accountsAndBudgets/reports?accountId=${
            filteredData?.account === `All` ? `` : filteredData?.account
          }&startDate=${moment(filteredData?.startDate).format(
            `YYYY-MM-DD`,
          )}&endDate=${moment(filteredData?.endDate).format(
            `YYYY-MM-DD`,
          )}&below=${filteredData?.isBelow}&utilized=${
            filteredData?.isUtilized
          }&exceeded=${filteredData?.isExceeded}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then((res) => {
          return res?.data?.data
        }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,

      onError: (error) => {
        openSnackbar(
          error?.response ? error?.response?.data?.message : error?.message,
          `error`,
        )
      },
    },
  )

  const handleFilteredData = (name, value) => {
    setFilteredData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }
  return (
    <Box
      sx={{
        display: `flex`,
        flexDirection: `column`,
        gap: `10px`,
        paddingX: { xs: `10px`, sm: `20px` },
        paddingBottom: `20px`,
        minHeight: `calc(100vh - 80px)`,
        backgroundColor: `rgba(40, 32, 61, 0.07)`,
      }}
    >
      <Box
        sx={{
          display: `flex`,
          flexDirection: { xs: `column`, sm: `row` },
          alignItems: `center`,
          justifyContent: `space-between`,
          gap: `10px`,
        }}
      >
        <FormControl
          size="small"
          sx={{
            backgroundColor: `white`,
            borderRadius: `10px`,
            width: { xs: `350px`, sm: `370px` },
            '& .MuiOutlinedInput-root': {
              '&:hover fieldset': {
                border: `solid ${colors.appColor} 1px`,
              },
              '&.Mui-focused fieldset': {
                border: `solid ${colors.appColor} 1px`,
              },
              '& fieldset': {
                borderColor: `rgba(40, 32, 61, 0.25)`,
                borderRadius: `10px`,
              },
            },
            '& .MuiInputLabel-root': {
              '&.Mui-focused': {
                color: `rgba(40, 32, 61, 0.25)`,
              },
            },
          }}
        >
          <InputLabel
            id="demo-simple-select-label"
            sx={{
              color: `rgba(40, 32, 61, 0.5)`,
            }}
          >
            Account *
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="account"
            label="Account *"
            value={filteredData?.account || `All`}
            onChange={(e) => handleFilteredData(`account`, e.target.value)}
          >
            <MenuItem value="All">All</MenuItem>
            {accountsData &&
              accountsData?.map((account) => (
                <MenuItem key={account?.value} value={account?.value}>
                  {account?.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <PrimaryButton
          title="Generate Reports"
          onClick={() =>
            history?.push({
              pathname: `/accounts/budgetReports/generateReports`,
              state: {
                reports: {
                  budgetReports: budgetReports,
                  filteredData: filteredData,
                },
              },
            })
          }
        />
      </Box>
      <Box
        sx={{
          backgroundColor: `white`,
          paddingY: `20px`,
          alignItems: `center`,
          justifyContent: `space-between`,
          display: `flex`,
          flexDirection: { xs: `column`, sm: `row` },
          gap: { xs: `10px`, sm: `0px` },
          borderRadius: `10px`,
        }}
      >
        <Grid container spacing={2} sx={{ width: `60%`, paddingX: `10px` }}>
          <Grid item xs={12} sm={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormControl fullWidth>
                <DatePicker
                  inputFormat="YYYY-MM-DD"
                  label="Start Date *"
                  value={
                    filteredData?.startDate ||
                    new Date(new Date().getFullYear(), 0, 1)
                  }
                  onChange={(e) => {
                    if (!e) {
                      handleFilteredData(
                        `startDate`,
                        new Date(new Date().getFullYear(), 0, 1),
                      )
                    } else {
                      handleFilteredData(`startDate`, new Date(e))
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            border: `solid  ${colors.appColor} 1px`,
                          },
                          '&.Mui-focused fieldset': {
                            border: `solid ${colors.appColor} 1px`,
                          },
                          '& fieldset': {
                            borderColor: `rgba(40, 32, 61, 0.5)`,
                            borderRadius: `10px`,
                          },
                          '& svg': { color: `#19C9D1` },
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: `rgba(40, 32, 61, 0.5)`,
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormControl fullWidth>
                <DatePicker
                  inputFormat="YYYY-MM-DD"
                  label="End Date *"
                  value={
                    filteredData?.endDate ||
                    new Date(new Date().getFullYear(), 11, 31)
                  }
                  onChange={(e) => {
                    if (!e) {
                      handleFilteredData(
                        `endDate`,
                        new Date(new Date().getFullYear(), 11, 31),
                      )
                    } else {
                      handleFilteredData(`endDate`, new Date(e))
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            border: `solid  ${colors.appColor} 1px`,
                          },
                          '&.Mui-focused fieldset': {
                            border: `solid ${colors.appColor} 1px`,
                          },
                          '& fieldset': {
                            borderColor: `rgba(40, 32, 61, 0.5)`,
                            borderRadius: `10px`,
                          },
                          '& svg': { color: `#19C9D1` },
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: `rgba(40, 32, 61, 0.5)`,
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormGroup sx={{ display: `flex`, flexDirection: `row` }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filteredData.isBelow}
                    onChange={(e) =>
                      handleFilteredData(`isBelow`, e.target.checked)
                    }
                    defaultChecked
                    sx={{
                      color: `rgba(40, 32, 61, 0.15)`,
                      '&.Mui-checked': {
                        color: colors?.appColor,
                      },
                    }}
                  />
                }
                label="Below"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filteredData.isUtilized}
                    onChange={(e) =>
                      handleFilteredData(`isUtilized`, e.target.checked)
                    }
                    sx={{
                      color: `rgba(40, 32, 61, 0.15)`,
                      '&.Mui-checked': {
                        color: colors?.appColor,
                      },
                    }}
                  />
                }
                label="Utilized"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filteredData.isExceeded}
                    onChange={(e) =>
                      handleFilteredData(`isExceeded`, e.target.checked)
                    }
                    sx={{
                      color: `rgba(40, 32, 61, 0.15)`,
                      '&.Mui-checked': {
                        color: colors?.appColor,
                      },
                    }}
                  />
                }
                label="Exceeded"
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: `flex`,
            gap: `10px`,
            alignItems: `center`,
            paddingX: `10px`,
          }}
        >
          <Typography
            sx={{
              color: `rgba(96, 57, 187, 1)`,
              fontSize: `14px`,
              fontWeight: 400,
              lineHeight: `16.94px`,
              cursor: `pointer`,
            }}
            onClick={() => {
              setFilteredData({
                account: ``,
                startDate: new Date(new Date().getFullYear(), 0, 1),
                endDate: new Date(new Date().getFullYear(), 11, 31),
                isBelow: true,
                isUtilized: false,
                isExceeded: false,
              })
            }}
          >
            Clear Filter
          </Typography>
          <Button
            sx={{
              color: `white`,
              backgroundColor: `rgba(25, 201, 209, 1) `,
              border: `solid rgba(25, 201, 209, 1) 1px`,
              borderRadius: `10px`,
              textTransform: `none`,
              width: `92px`,
              marginBottom: { xs: `10px`, md: `0px` },
              '&:hover': {
                backgroundColor: `rgba(25, 201, 209, 1)`,
                opacity: `0.8`,
              },
            }}
            onClick={() => refetch()}
          >
            Apply
          </Button>
        </Box>
      </Box>
      {isLoading || isFetching ? (
        <Backdrop open={isLoading || isFetching}>
          <CircularProgress
            sx={{
              position: `absolute`,
              top: `50%`,
              color: colors?.appColor,
            }}
          />
        </Backdrop>
      ) : !budgetReports || budgetReports?.length === 0 ? (
        <Typography
          sx={{
            display: `flex`,
            justifyContent: `center`,
            alignItems: `center`,
            height: `50vh`,
          }}
        >
          Budget(s) report not found for given data
        </Typography>
      ) : (
        budgetReports?.map((data, index) => (
          <Box key={index} sx={{ display: `flex`, flexDirection: `column` }}>
            {data?.limitStatus === `BELOW` && (
              <Box
                sx={{
                  backgroundColor: `white`,
                  paddingY: `20px`,
                  borderRadius: `10px`,
                }}
              >
                <Box
                  sx={{
                    display: `flex`,
                    justifyContent: `space-between`,
                    alignItems: `center`,
                    paddingX: `20px`,
                    flexDirection: { xs: `column`, sm: `row` },
                    gap: `10px`,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: `20px`,
                      fontWeight: 500,
                      lineHeight: `24.2px`,
                      color: `rgba(40, 32, 61, 1)`,
                      display: `flex`,
                      alignItems: `center`,
                      gap: `10px`,
                    }}
                  >
                    Below Budgets
                    <Typography
                      sx={{
                        color: `rgba(40, 32, 61, 0.6)`,
                      }}
                    >{`(${data?.total})`}</Typography>
                  </Typography>

                  <Box
                    sx={{
                      backgroundColor: `rgba(77, 198, 64, 0.1)`,
                      width: `220px`,
                      height: `56px`,
                      alignItems: `center`,
                      justifyContent: `center`,
                      display: `flex`,
                      borderRadius: `10px`,
                      paddingX: `8px`,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: `14px`,
                        fontWeight: 400,
                        lineHeight: `16.94px`,
                        display: `flex`,
                        alignItems: `center`,
                        color: `rgba(77, 198, 64, 1)`,
                        gap: `10px`,
                      }}
                    >
                      Balance
                      <Typography>
                        {PrependRs({
                          amount: data?.balance,
                          sx: {
                            fontWeight: 600,
                            fontSize: `18px`,

                            lineHeight: `21.78px`,
                          },
                        })}
                      </Typography>
                    </Typography>
                  </Box>
                </Box>
                <Grid item xs={12}>
                  <TableContainer
                    className="scrollbar-hide"
                    sx={{
                      backgroundColor: `white`,
                      borderTop: `solid 1px rgba(40, 32, 61, 0.15)`,

                      marginTop: `20px`,
                      height: {
                        xs: `360px`,
                        sm: `400px`,
                      },
                      whiteSpace: `nowrap`,
                      position: `relative`,
                      '& tbody': {
                        '& td, & th': {
                          borderColor: `rgba(40, 32, 61, 0.03)`,
                        },
                      },
                    }}
                  >
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead className="h-10 p-20">
                        <TableRow>
                          {tableColumns?.map((item, index) => {
                            return (
                              <TableCell
                                key={index}
                                sx={{ padding: `0px 16px` }}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: `12px`,
                                    color: `#28203D`,
                                    opacity: 0.4,
                                  }}
                                >
                                  {item?.label}
                                </Typography>
                              </TableCell>
                            )
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!data?.accountsAndBudgets &&
                        !data?.accountsAndBudgets?.length ? (
                          <TableRow>
                            <TableCell
                              colSpan={tableColumns.length + 1}
                              sx={{ border: 0 }}
                            >
                              <div
                                style={{
                                  display: `flex`,
                                  justifyContent: `center`,
                                  alignItems: `center`,
                                }}
                              >
                                <Typography>No budget Found</Typography>
                              </div>
                            </TableCell>
                          </TableRow>
                        ) : (
                          data?.accountsAndBudgets?.map((budget, key) => (
                            <TableRow
                              key={key}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                                '&:hover': {
                                  cursor: `pointer`,
                                  background: `linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(250,249,253,1) 15%, rgba(250,249,253,1) 85%, rgba(255,255,255,1) 100%)`,
                                },
                                width: `100%`,
                              }}
                            >
                              <TableCell
                                sx={{
                                  width: `20%`,
                                  wordWrap: `break-word`,
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: `14px`,
                                    lineHeight: `16.94px`,
                                    marginBottom: `5px`,
                                  }}
                                >
                                  {budget?.name}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: `12px`,
                                    lineHeight: `14.52px`,
                                    color: `rgba(40, 32, 61, 0.6)`,
                                  }}
                                >{`${moment(
                                  budget?.budget?.startDate,
                                  `YYYY-MM-DD`,
                                ).format(`MMM DD, YYYY`)} to ${moment(
                                  budget?.budget?.endDate,
                                  `YYYY-MM-DD`,
                                ).format(`MMM DD, YYYY`)}`}</Typography>
                              </TableCell>
                              <TableCell
                                sx={{
                                  width: `35%`,
                                  wordWrap: `break-word`,
                                }}
                              >
                                <Typography
                                  sx={{
                                    display: `block`,
                                    width: `100%`,
                                    whiteSpace: `normal`,
                                    textAlign: `justify`,
                                    fontSize: `14px`,
                                  }}
                                >
                                  {budget?.budget?.details
                                    ? budget?.budget?.details
                                    : `No description added`}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                {PrependRs({
                                  amount: budget?.budget?.total || 0,
                                  sx: {
                                    width: `15%`,
                                    wordWrap: `break-word`,
                                    fontSize: `14px`,
                                  },
                                })}
                              </TableCell>
                              <TableCell>
                                {PrependRs({
                                  amount: budget?.budget?.utilized || 0,
                                  sx: {
                                    width: `15%`,
                                    wordWrap: `break-word`,
                                    fontSize: `14px`,
                                  },
                                })}
                              </TableCell>
                              <TableCell>
                                {PrependRs({
                                  amount: budget?.budget?.remaining || 0,
                                  sx: {
                                    width: `15%`,
                                    wordWrap: `break-word`,
                                    fontSize: `14px`,
                                  },
                                })}
                              </TableCell>
                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Box>
            )}

            {data?.limitStatus === `UTILIZED` && (
              <Box
                sx={{
                  backgroundColor: `white`,
                  paddingY: `20px`,
                  borderRadius: `10px`,
                }}
              >
                <Box
                  sx={{
                    display: `flex`,
                    justifyContent: `space-between`,
                    alignItems: `center`,
                    paddingX: `20px`,
                    flexDirection: { xs: `column`, sm: `row` },
                    gap: `10px`,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: `20px`,
                      fontWeight: 500,
                      lineHeight: `24.2px`,
                      color: `rgba(40, 32, 61, 1)`,
                      display: `flex`,
                      alignItems: `center`,
                      gap: `10px`,
                    }}
                  >
                    Utilized Budgets
                    <Typography
                      sx={{
                        color: `rgba(40, 32, 61, 0.6)`,
                      }}
                    >{`(${data?.total})`}</Typography>
                  </Typography>

                  <Box
                    sx={{
                      backgroundColor: `rgba(37, 187, 251, 0.1)`,
                      width: `220px`,
                      height: `56px`,
                      alignItems: `center`,
                      justifyContent: `center`,
                      display: `flex`,
                      borderRadius: `10px`,
                      paddingX: `8px`,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: `14px`,
                        fontWeight: 400,
                        lineHeight: `16.94px`,
                        display: `flex`,
                        alignItems: `center`,
                        color: `rgba(37, 187, 251, 1)`,
                        gap: `10px`,
                      }}
                    >
                      Balance
                      <Typography>
                        {PrependRs({
                          amount: data?.balance,
                          sx: {
                            fontWeight: 600,
                            fontSize: `18px`,

                            lineHeight: `21.78px`,
                          },
                        })}
                      </Typography>
                    </Typography>
                  </Box>
                </Box>
                <Grid item xs={12}>
                  <TableContainer
                    className="scrollbar-hide"
                    sx={{
                      backgroundColor: `white`,
                      borderTop: `solid 1px rgba(40, 32, 61, 0.15)`,

                      marginTop: `20px`,
                      height: {
                        xs: `360px`,
                        sm: `400px`,
                      },
                      whiteSpace: `nowrap`,
                      position: `relative`,
                      '& tbody': {
                        '& td, & th': {
                          borderColor: `rgba(40, 32, 61, 0.03)`,
                        },
                      },
                    }}
                  >
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead className="h-10 p-20">
                        <TableRow>
                          {tableColumns?.map((item, index) => {
                            return (
                              <TableCell
                                key={index}
                                sx={{ padding: `0px 16px` }}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: `12px`,
                                    color: `#28203D`,
                                    opacity: 0.4,
                                  }}
                                >
                                  {item?.label}
                                </Typography>
                              </TableCell>
                            )
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!data?.accountsAndBudgets &&
                        !data?.accountsAndBudgets?.length ? (
                          <TableRow>
                            <TableCell
                              colSpan={tableColumns.length + 1}
                              sx={{ border: 0 }}
                            >
                              <div
                                style={{
                                  display: `flex`,
                                  justifyContent: `center`,
                                  alignItems: `center`,
                                }}
                              >
                                <Typography>No budget Found</Typography>
                              </div>
                            </TableCell>
                          </TableRow>
                        ) : (
                          data?.accountsAndBudgets?.map((budget, key) => (
                            <TableRow
                              key={key}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                                '&:hover': {
                                  cursor: `pointer`,
                                  background: `linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(250,249,253,1) 15%, rgba(250,249,253,1) 85%, rgba(255,255,255,1) 100%)`,
                                },
                                width: `100%`,
                              }}
                            >
                              <TableCell
                                sx={{
                                  width: `20%`,
                                  wordWrap: `break-word`,
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: `14px`,
                                    lineHeight: `16.94px`,
                                    marginBottom: `5px`,
                                  }}
                                >
                                  {budget?.name}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: `12px`,
                                    lineHeight: `14.52px`,
                                    color: `rgba(40, 32, 61, 0.6)`,
                                  }}
                                >{`${moment(
                                  budget?.budget?.startDate,
                                  `YYYY-MM-DD`,
                                ).format(`MMM DD, YYYY`)} to ${moment(
                                  budget?.budget?.endDate,
                                  `YYYY-MM-DD`,
                                ).format(`MMM DD, YYYY`)}`}</Typography>
                              </TableCell>
                              <TableCell
                                sx={{
                                  width: `35%`,
                                  wordWrap: `break-word`,
                                }}
                              >
                                <Typography
                                  sx={{
                                    display: `block`,
                                    width: `100%`,
                                    whiteSpace: `normal`,
                                    textAlign: `justify`,
                                    fontSize: `14px`,
                                  }}
                                >
                                  {budget?.budget?.details
                                    ? budget?.budget?.details
                                    : `No description added`}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                {PrependRs({
                                  amount: budget?.budget?.total || 0,
                                  sx: {
                                    width: `15%`,
                                    wordWrap: `break-word`,
                                    fontSize: `14px`,
                                  },
                                })}
                              </TableCell>
                              <TableCell>
                                {PrependRs({
                                  amount: budget?.budget?.utilized || 0,
                                  sx: {
                                    width: `15%`,
                                    wordWrap: `break-word`,
                                    fontSize: `14px`,
                                  },
                                })}
                              </TableCell>
                              <TableCell>
                                {PrependRs({
                                  amount: budget?.budget?.remaining || 0,
                                  sx: {
                                    width: `15%`,
                                    wordWrap: `break-word`,
                                    fontSize: `14px`,
                                  },
                                })}
                              </TableCell>
                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Box>
            )}
            {data?.limitStatus === `EXCEEDED` && (
              <Box
                sx={{
                  backgroundColor: `white`,
                  paddingY: `20px`,
                  borderRadius: `10px`,
                }}
              >
                <Box
                  sx={{
                    display: `flex`,
                    justifyContent: `space-between`,
                    alignItems: `center`,
                    paddingX: `20px`,
                    flexDirection: { xs: `column`, sm: `row` },
                    gap: `10px`,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: `20px`,
                      fontWeight: 500,
                      lineHeight: `24.2px`,
                      color: `rgba(40, 32, 61, 1)`,
                      display: `flex`,
                      alignItems: `center`,
                      gap: `10px`,
                    }}
                  >
                    Exceeded Budgets
                    <Typography
                      sx={{
                        color: `rgba(40, 32, 61, 0.6)`,
                      }}
                    >{`(${data?.total})`}</Typography>
                  </Typography>

                  <Box
                    sx={{
                      backgroundColor: `rgba(255, 72, 94, 0.1)`,
                      width: `268px`,
                      height: `56px`,
                      alignItems: `center`,
                      justifyContent: `center`,
                      display: `flex`,
                      borderRadius: `10px`,
                      paddingX: `8px`,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: `14px`,
                        fontWeight: 400,
                        lineHeight: `16.94px`,
                        display: `flex`,
                        alignItems: `center`,
                        color: `rgba(255, 72, 94, 1)`,
                        gap: `10px`,
                      }}
                    >
                      Exceeded Amount
                      <Typography>
                        {PrependRs({
                          amount: data?.balance,
                          sx: {
                            fontWeight: 600,
                            fontSize: `18px`,
                            lineHeight: `21.78px`,
                          },
                        })}
                      </Typography>
                    </Typography>
                  </Box>
                </Box>
                <Grid item xs={12}>
                  <TableContainer
                    className="scrollbar-hide"
                    sx={{
                      backgroundColor: `white`,
                      borderTop: `solid 1px rgba(40, 32, 61, 0.15)`,

                      marginTop: `20px`,
                      height: {
                        xs: `360px`,
                        sm: `400px`,
                      },
                      maxHeight: `400px`,
                      whiteSpace: `nowrap`,
                      position: `relative`,
                      '& tbody': {
                        '& td, & th': {
                          borderColor: `rgba(40, 32, 61, 0.03)`,
                        },
                      },
                    }}
                  >
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead className="h-10 p-20">
                        <TableRow>
                          {tableColumns?.map((item, index) => {
                            return (
                              <TableCell
                                key={index}
                                sx={{ padding: `0px 16px` }}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: `12px`,
                                    color: `#28203D`,
                                    opacity: 0.4,
                                  }}
                                >
                                  {item?.label}
                                </Typography>
                              </TableCell>
                            )
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!data?.accountsAndBudgets &&
                        !data?.accountsAndBudgets?.length ? (
                          <TableRow>
                            <TableCell
                              colSpan={tableColumns.length + 1}
                              sx={{ border: 0 }}
                            >
                              <div
                                style={{
                                  display: `flex`,
                                  justifyContent: `center`,
                                  alignItems: `center`,
                                }}
                              >
                                <Typography>No budget Found</Typography>
                              </div>
                            </TableCell>
                          </TableRow>
                        ) : (
                          data?.accountsAndBudgets?.map((budget, key) => (
                            <TableRow
                              key={key}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                                '&:hover': {
                                  cursor: `pointer`,
                                  background: `linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(250,249,253,1) 15%, rgba(250,249,253,1) 85%, rgba(255,255,255,1) 100%)`,
                                },
                                width: `100%`,
                              }}
                            >
                              <TableCell
                                sx={{
                                  width: `20%`,
                                  wordWrap: `break-word`,
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: `14px`,
                                    lineHeight: `16.94px`,
                                    marginBottom: `5px`,
                                  }}
                                >
                                  {budget?.name}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: `12px`,
                                    lineHeight: `14.52px`,
                                    color: `rgba(40, 32, 61, 0.6)`,
                                  }}
                                >{`${moment(
                                  budget?.budget?.startDate,
                                  `YYYY-MM-DD`,
                                ).format(`MMM DD, YYYY`)} to ${moment(
                                  budget?.budget?.endDate,
                                  `YYYY-MM-DD`,
                                ).format(`MMM DD, YYYY`)}`}</Typography>
                              </TableCell>
                              <TableCell
                                sx={{
                                  width: `35%`,
                                  wordWrap: `break-word`,
                                }}
                              >
                                <Typography
                                  sx={{
                                    display: `block`,
                                    width: `100%`,
                                    whiteSpace: `normal`,
                                    textAlign: `justify`,
                                    fontSize: `14px`,
                                  }}
                                >
                                  {budget?.budget?.details
                                    ? budget?.budget?.details
                                    : `No description added`}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                {PrependRs({
                                  amount: budget?.budget?.total || 0,
                                  sx: {
                                    width: `15%`,
                                    wordWrap: `break-word`,
                                    fontSize: `14px`,
                                  },
                                })}
                              </TableCell>
                              <TableCell>
                                {PrependRs({
                                  amount: budget?.budget?.utilized || 0,
                                  sx: {
                                    width: `15%`,
                                    wordWrap: `break-word`,
                                    fontSize: `14px`,
                                  },
                                })}
                              </TableCell>
                              <TableCell>
                                {PrependRs({
                                  amount: budget?.budget?.remaining || 0,
                                  sx: {
                                    width: `15%`,
                                    wordWrap: `break-word`,
                                    fontSize: `14px`,
                                  },
                                })}
                              </TableCell>
                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Box>
            )}
          </Box>
        ))
      )}
    </Box>
  )
}

export default Reports
