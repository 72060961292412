export const getBgColor = (value) => {
  if (
    value === `HIRED` ||
    value === `OFFER_EXTEND` ||
    value === `PAID` ||
    value === `COMPLETED` ||
    value === `OPEN` ||
    value === `APPROVED` ||
    value === `ACTIVE` ||
    value === `ASSIGNED`
  ) {
    return `rgba(77, 198, 64, 0.12)`
  } else if (
    value === `ON_HOLD` ||
    value === `TECHNICAL_ASSESSMENT` ||
    value === `IN_REVIEW` ||
    value === `APPLIED` ||
    value === `UNPAID` ||
    value === `PENDING` ||
    value === `OFFER_LETTER_SENT`
  ) {
    return `rgba(249, 171, 6, 0.12)`
  } else if (
    value === `REJECTED` ||
    value === `WITHDRAWN` ||
    value === `NOT_SELECTED` ||
    value === `CANCELLED` ||
    value === `CLOSE` ||
    value === `EXPIRED` ||
    value === `ABANDONED` ||
    value === `DECLINE` ||
    value === `OFFER_LETTER_REJECTED` ||
    value === `NOT_ELIGIBLE` ||
    value === `NOT_RESPONSIVE` ||
    value === `INACTIVE` ||
    value === `REPAIR`
  ) {
    return `rgba(255,72,94,0.12)`
  } else if (value === `INITIAL_SCREENING`) {
    return `rgba(125, 77, 236, 0.1)`
  } else if (
    value === `TECHNICAL_INTERVIEW` ||
    value === `FINAL_TECHNICAL_INTERVIEW`
  ) {
    return `rgba(37, 187, 251, 0.12)`
  } else if (value === `MEETING_WITH_HR` || value === `NEGOTIATIONS`) {
    return `rgba(198, 71, 242, 0.1)`
  } else if (value === `OFFER_LETTER_ACCEPTED` || value === `IDLE`) {
    return `rgba(25, 201, 209, 0.12)`
  } else if (value === 'DISCONTINUED') {
    return '#edecf0'
  }
}
export const getTextColor = (value) => {
  if (
    value === `HIRED` ||
    value === `OFFER_EXTEND` ||
    value === `PAID` ||
    value === `COMPLETED` ||
    value === `OPEN` ||
    value === `APPROVED` ||
    value === `ACTIVE` ||
    value === `ASSIGNED`
  ) {
    return `rgba(77, 198, 64)`
  } else if (
    value === `ON_HOLD` ||
    value === `TECHNICAL_ASSESSMENT` ||
    value === `IN_REVIEW` ||
    value === `APPLIED` ||
    value === `UNPAID` ||
    value === `PENDING` ||
    value === `OFFER_LETTER_SENT`
  ) {
    return `rgba(249, 171, 6)`
  } else if (
    value === `REJECTED` ||
    value === `WITHDRAWN` ||
    value === `NOT_SELECTED` ||
    value === `CANCELLED` ||
    value === `CLOSE` ||
    value === `EXPIRED` ||
    value === `ABANDONED` ||
    value === `DECLINE` ||
    value === `OFFER_LETTER_REJECTED` ||
    value === `NOT_ELIGIBLE` ||
    value === `NOT_RESPONSIVE` ||
    value === `INACTIVE` ||
    value === `REPAIR`
  ) {
    return `rgb(255,72,94)`
  } else if (value === `INITIAL_SCREENING`) {
    return `rgba(125, 77, 236, 1)`
  } else if (
    value === `TECHNICAL_INTERVIEW` ||
    value === `FINAL_TECHNICAL_INTERVIEW`
  ) {
    return `rgba(37, 187, 251, 1)`
  } else if (value === `MEETING_WITH_HR` || value === `NEGOTIATIONS`) {
    return `rgba(198, 71, 242, 1)`
  } else if (value === `OFFER_LETTER_ACCEPTED` || value === `IDLE`) {
    return `rgba(25, 201, 209, 1)`
  } else if (value === 'DISCONTINUED') {
    return 'rgba(119, 114, 132, 1)'
  } else if (value === `rgba(249, 171, 6, 0.12)`) return `rgba(249, 171, 6)`
  else if (value === `rgba(255, 72, 94, 0.1)`) return `rgb(255,72,94)`
  else if (value === `rgba(77, 198, 64, 0.12)`) return `rgba(77, 198, 64)`
}

export const getBackgroundColor = (value) => {
  if (value === `Worst`) return `rgba(255, 72, 94, 0.2)`
  if (value === `Bad`) return `rgba(255, 158, 110, 0.4)`
  if (value === `Unsatisfactory`) return `rgba(249, 171, 6, 0.2)`
  if (value === `Average`) return `rgba(37, 187, 251, 0.2)`
  if (value === `Satisfactory`) return `rgba(105, 37, 251, 0.15)`
  if (value === `Good`) return `rgba(77, 198, 64, 0.2)`
  if (value === `Excellent`) return `rgba(77, 198, 64, 0.2)`
}

export const getStatusBgColor = (value) => {
  if (value === `PENDING` || value === `ON_HOLD`)
    return `rgba(249, 171, 6, 0.12)`
  if (value === `OPEN` || value === `APPROVED`) return `rgba(77, 198, 64, 0.12)`
  if (value === `DECLINED` || value === `CLOSE`) return `rgba(255, 72, 94, 0.1)`
}
