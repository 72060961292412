import React, { useState, useContext, useCallback } from 'react'
import { Box, Typography, Drawer, Link } from '@material-ui/core'
import { useQuery, useMutation } from 'react-query'
import api from 'app/AxiosInstance'
import filtercross from 'app/Assets/cross.svg'
import userAvatar from 'app/Assets/userAvatar.jpg'
import { SnackbarContext } from 'app/Components/SnackbarProvider'
import SkeletonLoading from './SkeletonLoading'
import NotApplicable from 'app/Components/Common/NotApplicable'
import UpdateClient from '../UpdateClient'

// Redux hooks
import { useSelector } from 'react-redux'

// Icons
import { MdDelete } from 'react-icons/md'
import { MdModeEdit } from 'react-icons/md'

// Common Components
import DeleteConfirmationDialog from 'app/Components/Common/DeleteConfirmationDialog'

const ClientDetail = ({ open, onClose, id, refetchCallback }) => {
  const colors = useSelector((state) => state.colors)
  const openSnackbar = useContext(SnackbarContext)
  const [openDeleteDialog, setOpenDeleteDialog] = useState()
  const [clientId, setClientId] = useState(null)

  const {
    data: singleClient,
    isFetching,
    isRefetching,
    isLoading,
  } = useQuery(
    `singleClient`,
    async () => {
      const { data } = await api.get(`/clients/${id}`)
      if (!data) undefined

      return data.data
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onError: (error) => {
        openSnackbar(
          error.response ? error.response.data.message : error.message,
          `error`,
        )
      },
    },
  )

  const deleteClient = async () => {
    const response = await api.delete(`/clients/${clientId}`)
    return response
  }

  const { mutate: deleteClientMutation, isLoading: isMutatingDeleteClient } =
    useMutation(deleteClient, {
      onSuccess: () => {
        setOpenDeleteDialog((prev) => !prev)
        onClose()
        openSnackbar(`Deleted Successfully!!`, `success`)
        refetchCallback()
      },
      onError: (error) => {
        openSnackbar(
          error?.response ? error.response.data.message : error.message,
          `error`,
        )
      },
    })

  const clickDelete = (id) => {
    setClientId(id)
    setOpenDeleteDialog((prev) => setOpenDeleteDialog(!prev))
  }

  // delete callback
  const deleteClientClick = (id) => {
    deleteClientMutation(id)
  }

  const [updateClientOpen, setUpdateClientOpen] = useState(false)
  const clickEdit = () => {
    setUpdateClientOpen((prev) => !prev)
  }
  const closeUpdateClient = useCallback(() => {
    setUpdateClientOpen((prev) => !prev)
  }, [updateClientOpen])

  return (
    <>
      <DeleteConfirmationDialog
        open={openDeleteDialog}
        onClose={(prev) => setOpenDeleteDialog(!prev)}
        deleteFunc={deleteClientClick}
        loading={isMutatingDeleteClient}
      />

      {updateClientOpen && (
        <UpdateClient
          open={updateClientOpen}
          onClose={closeUpdateClient}
          clientInitialData={singleClient || {}}
          refetchAllClients={refetchCallback}
        />
      )}

      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        sx={{
          '& .MuiDrawer-paper': {
            width: { xs: `90%`, sm: `50%`, md: `27%` },
          },
        }}
      >
        <Box
          sx={{
            display: `flex`,
            flexDirection: `column`,
            padding: `30px`,
            gap: `10px`,
          }}
        >
          <img
            src={filtercross}
            alt="cross"
            style={{
              cursor: `pointer`,
              color: `#28203D`,
              opacity: 0.7,
              width: `10px`,
              height: `10px`,
            }}
            onClick={onClose}
          />

          {isFetching || isRefetching || isLoading ? (
            SkeletonLoading
          ) : (
            <>
              <Box
                sx={{
                  display: `flex`,

                  justifyContent: `space-between`,
                  alignItems: `center`,
                  marginTop: `10px`,
                }}
              >
                <Box
                  sx={{ display: `flex`, gap: `10px`, alignItems: `center` }}
                >
                  <img
                    src={
                      singleClient?.profilePicture?.url
                        ? singleClient.profilePicture.url
                        : userAvatar
                    }
                    alt="Profile Picture"
                    style={{
                      width: `50px`,
                      height: `50px`,
                      borderRadius: `100%`,
                    }}
                  />

                  {!singleClient.name ? (
                    NotApplicable
                  ) : (
                    <Box
                      sx={{
                        display: `flex`,
                        flexDirection: { xs: `column`, sm: `row` },
                        alignItems: `center`,
                        gap: `5px`,
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 800,
                          fontSize: `16px`,
                          lineHeight: `10px`,
                          fontFamily: `Inter`,
                        }}
                      >
                        {singleClient?.name ? singleClient.name : NotApplicable}
                      </Typography>
                    </Box>
                  )}
                </Box>

                <Box sx={{ display: `flex`, gap: `5px` }}>
                  <Box
                    sx={{
                      display: `flex`,
                      justifyContent: `center`,
                      alignItems: `center`,
                      backgroundColor: colors.appColor,
                      padding: `5px`,
                      borderRadius: `7px`,
                      '&:hover': { cursor: `pointer` },
                    }}
                    onClick={clickEdit}
                  >
                    <MdModeEdit
                      style={{
                        width: `15px`,
                        height: `15px`,
                        color: `white`,
                      }}
                      //   onClick={(event) => {
                      //     event.stopPropagation(), clickEdit(client)
                      //   }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: `flex`,
                      justifyContent: `center`,
                      alignItems: `center`,
                      backgroundColor: `red`,
                      padding: `5px`,
                      borderRadius: `7px`,
                      '&:hover': { cursor: `pointer` },
                    }}
                    onClick={() => clickDelete(singleClient?._id)}
                  >
                    <MdDelete
                      style={{ width: `15px`, height: `15px`, color: `white` }}
                      //   onClick={(event) => {
                      //     event.stopPropagation(), clickDelete(client._id)
                      //   }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: `flex`,
                  alignItems: `center`,
                  justifyContent: `flex-end`,
                }}
              >
                <Typography
                  sx={{
                    width: `auto%`,
                    textAlign: `center`,
                    backgroundColor:
                      singleClient?.status === `INACTIVE`
                        ? `rgba(255, 72, 94, 0.1)`
                        : `rgba(77, 198, 64, 0.12)`,

                    color:
                      singleClient?.status === `INACTIVE`
                        ? `rgba(255, 72, 94, 1)`
                        : `rgba(77, 198, 64, 1)`,

                    borderRadius: `20px`,
                    paddingY: `5px`,
                    paddingX: `10px`,
                  }}
                >
                  {singleClient?.status === `INACTIVE`
                    ? `Inactive`
                    : singleClient?.status === `ACTIVE`
                    ? `Active`
                    : ``}
                </Typography>
              </Box>

              <Box
                sx={{ display: `flex`, flexDirection: `column`, gap: `10px` }}
              >
                <Typography
                  sx={{
                    fontFamily: `Inter`,
                    fontWeight: 400,
                    fontSize: `14px`,
                    color: `#28203D`,
                    opacity: 0.7,
                  }}
                >
                  Projects
                </Typography>

                {singleClient?.projects?.length > 0
                  ? singleClient?.projects?.map((project) => {
                      return (
                        <Box
                          key={project._id}
                          sx={{
                            display: `flex`,
                            flexDirection: { xs: `column`, sm: `row` },
                            gap: `5px`,
                            justifyContent: `space-between`,
                            alignItems: `center`,
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: `Inter`,
                              fontWeight: 600,
                              fontSize: `14px`,
                              color: `black`,
                              marginRight: `auto`,
                            }}
                          >
                            {project.name}
                          </Typography>

                          <Box
                            sx={{
                              marginLeft: `auto`,
                              height: `max-content`,
                              borderRadius: `20px`,
                              paddingY: `2px`,
                              paddingX: `10px`,
                              display: `flex`,
                              justifyContent: `center`,
                              alignItems: `center`,
                              backgroundColor:
                                project.status === `Gathering Requirements`
                                  ? `rgba(125, 77, 236, 0.1)`
                                  : project.status === `Planning`
                                  ? `rgba(37, 187, 251, 0.12)`
                                  : project.status === `In-Progress`
                                  ? `rgba(249, 171, 6, 0.12)`
                                  : project.status === `Testing`
                                  ? `rgba(198, 71, 242, 0.1)`
                                  : project.status === `Completed`
                                  ? `rgba(77, 198, 64, 0.12)`
                                  : project.status === `On-Hold`
                                  ? `rgba(255, 114, 54, 0.12)`
                                  : project.status === `Canceled`
                                  ? `rgba(255, 72, 94, 0.1)`
                                  : project.status === `Maintenance` &&
                                    ` rgba(25, 201, 209, 0.12)`,
                              color:
                                project.status === `Gathering Requirements`
                                  ? `#7D4DEC`
                                  : project.status === `Planning`
                                  ? `#25BBFB`
                                  : project.status === `In-Progress`
                                  ? `#F9AB06`
                                  : project.status === `Testing`
                                  ? `#C647F2`
                                  : project.status === `Completed`
                                  ? `#4DC640`
                                  : project.status === `On-Hold`
                                  ? `#FF7236`
                                  : project.status === `Canceled`
                                  ? `#FF485E`
                                  : project.status === `Maintenance` &&
                                    `#19C9D1`,
                            }}
                          >
                            <Typography
                              noWrap
                              sx={{
                                textAlign: `center`,
                                whiteSpace: `wrap`,
                                wordBreak: `break-word`,
                                fontFamily: `Inter`,
                                fontWeight: 500,
                                fontSize: `14px`,
                              }}
                            >
                              {project.status}
                            </Typography>
                          </Box>
                        </Box>
                      )
                    })
                  : NotApplicable}
              </Box>

              <Box
                sx={{
                  display: `flex`,
                  flexDirection: `row`,
                  justifyContent: `space-between`,
                  marginTop: `20px`,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: `Inter`,
                    fontWeight: 400,
                    fontSize: `14px`,
                    color: `#28203D`,
                    opacity: 0.7,
                  }}
                >
                  Phone
                </Typography>

                <Typography
                  sx={{
                    fontFamily: `Inter`,
                    fontWeight: 500,
                    fontSize: `14px`,
                    color: `black`,
                  }}
                >
                  {singleClient?.phone ? singleClient.phone : NotApplicable}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: `flex`,
                  flexDirection: `row`,
                  justifyContent: `space-between`,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: `Inter`,
                    fontWeight: 400,
                    fontSize: `14px`,
                    lineHeight: `19.36px`,
                    color: `#28203D`,
                    opacity: 0.7,
                  }}
                >
                  Email
                </Typography>

                <Typography
                  sx={{
                    width: `60%`,
                    textAlign: `right`,
                    fontFamily: `Inter`,
                    fontWeight: 500,
                    fontSize: `14px`,
                    color: `black`,
                    wordBreak: `break-word`,
                  }}
                >
                  {singleClient?.email ? singleClient.email : NotApplicable}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: `flex`,
                  flexDirection: `row`,
                  justifyContent: `space-between`,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: `Inter`,
                    fontWeight: 400,
                    fontSize: `14px`,
                    lineHeight: `19.36px`,
                    color: `#28203D`,
                    opacity: 0.7,
                  }}
                >
                  Company Name
                </Typography>

                <Typography
                  sx={{
                    width: `60%`,
                    textAlign: `right`,
                    fontFamily: `Inter`,
                    fontWeight: 500,
                    fontSize: `14px`,
                    color: `black`,
                    whiteSpace: `wrap`,
                    wordBreak: `break-word`,
                  }}
                >
                  {singleClient?.company ? singleClient.company : NotApplicable}
                </Typography>
              </Box>
              {singleClient?.url && (
                <Box
                  sx={{
                    display: `flex`,
                    flexDirection: `row`,
                    justifyContent: `space-between`,
                    alignItems: `center`,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: `Inter`,
                      fontWeight: 400,
                      fontSize: `14px`,
                      lineHeight: `19.36px`,
                      color: `#28203D`,
                      opacity: 0.7,
                    }}
                  >
                    URL
                  </Typography>
                  <Link
                    flexWrap
                    underline="none"
                    rel="noopener"
                    target="_blank"
                    href={singleClient.url}
                  >
                    <Typography
                      noWrap={true}
                      sx={{
                        textAlign: `right`,
                        // width: `60%`,
                        fontFamily: `Inter`,
                        fontWeight: 500,
                        fontSize: `16px`,
                        color: `#19C9D1`,
                        whiteSpace: `wrap`,
                        wordBreak: `break-word`,
                      }}
                    >
                      {singleClient.url}
                    </Typography>
                  </Link>
                </Box>
              )}

              <Box
                sx={{
                  display: `flex`,
                  flexDirection: `row`,
                  justifyContent: `space-between`,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: `Inter`,
                    fontWeight: 400,
                    fontSize: `14px`,
                    lineHeight: `19.36px`,
                    color: `#28203D`,
                    opacity: 0.7,
                  }}
                >
                  Country
                </Typography>

                <Typography
                  sx={{
                    width: `60%`,
                    textAlign: `right`,
                    fontFamily: `Inter`,
                    fontWeight: 500,
                    fontSize: `14px`,
                    color: `black`,
                    whiteSpace: `wrap`,
                    wordBreak: `break-word`,
                  }}
                >
                  {singleClient?.country ? singleClient.country : NotApplicable}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: `flex`,
                  flexDirection: `row`,
                  justifyContent: `space-between`,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: `Inter`,
                    fontWeight: 400,
                    fontSize: `14px`,
                    lineHeight: `19.36px`,
                    color: `#28203D`,
                    opacity: 0.7,
                  }}
                >
                  State
                </Typography>

                <Typography
                  sx={{
                    width: `60%`,
                    textAlign: `right`,
                    fontFamily: `Inter`,
                    fontWeight: 500,
                    fontSize: `14px`,
                    color: `black`,
                    whiteSpace: `wrap`,
                    wordBreak: `break-word`,
                  }}
                >
                  {singleClient?.state ? singleClient.state : NotApplicable}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: `flex`,
                  flexDirection: `column`,
                  gap: `5px`,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: `Inter`,
                    fontWeight: 400,
                    fontSize: `14px`,
                    lineHeight: `19.36px`,
                    color: `#28203D`,
                    opacity: 0.7,
                    wordBreak: `break-word`,
                    whiteSpace: `wrap`,
                  }}
                >
                  Address
                </Typography>

                <Typography
                  sx={{
                    fontFamily: `Inter`,
                    fontWeight: 500,
                    fontSize: `14px`,
                    color: `black`,
                    wordBreak: `break-word`,
                  }}
                >
                  {singleClient?.address
                    ? `${singleClient.address}${
                        singleClient?.state ? `, ${singleClient.state}` : ``
                      }${
                        singleClient?.country ? `, ${singleClient.country}` : ``
                      }`
                    : NotApplicable}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: `flex`,
                  flexDirection: `column`,
                  gap: `5px`,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: `Inter`,
                    fontWeight: 400,
                    fontSize: `14px`,
                    lineHeight: `19.36px`,
                    color: `#28203D`,
                    opacity: 0.7,
                  }}
                >
                  Description
                </Typography>

                <Typography
                  sx={{
                    fontFamily: `Inter`,
                    fontWeight: 500,
                    fontSize: `14px`,
                    wordBreak: `break-word`,
                    whiteSpace: `wrap`,
                    color: `black`,
                  }}
                >
                  {singleClient?.description
                    ? singleClient.description
                    : NotApplicable}
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Drawer>
    </>
  )
}

export default ClientDetail
