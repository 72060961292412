import { Box, Skeleton } from '@material-ui/core'
import React from 'react'

const GraphSkeleton = () => {
  return (
    <Box
      sx={{
        height: `inherit`,
        display: `flex`,
        gap: 3,
        alignItems: `center`,
        justifyContent: `center`,
      }}
    >
      {[...Array(5)].map((_, index) => (
        <Box
          key={index}
          sx={{
            height: `100%`,
            display: `flex`,
            gap: 3,
            alignItems: `center`,
            justifyContent: `center`,
          }}
        >
          <Skeleton
            height="100%"
            animation="wave"
            width={13}
            sx={{ bgcolor: `rgba(40, 32, 61, 0.05)` }}
          />
          <Skeleton
            animation="wave"
            height="100%"
            width={13}
            sx={{
              bgcolor: `rgba(40, 32, 61, 0.05)`,
              marginTop: `100%`,
            }}
          />
          <Skeleton
            animation="wave"
            height="100%"
            width={13}
            sx={{
              marginTop: `100%`,
              bgcolor: `rgba(40, 32, 61, 0.05)`,
            }}
          />
        </Box>
      ))}
    </Box>
  )
}

export default GraphSkeleton
