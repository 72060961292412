import React from 'react'
import { FormControl, TextField } from '@mui/material'

const MultilineInputField = (props) => {
  const {
    type,
    name,
    label,
    defaultValue,
    value,
    onChange,
    readOnly,
    inputLabelProps,
    sxProps,
  } = props

  return (
    <FormControl fullWidth>
      <TextField
        multiline
        rows={3}
        id="outlined-multiline-flexible"
        type={type}
        name={name}
        value={value}
        label={label}
        variant="outlined"
        size="small"
        defaultValue={defaultValue}
        onChange={onChange}
        InputProps={{
          readOnly: readOnly,
        }}
        InputLabelProps={inputLabelProps}
        sx={sxProps}
      />
    </FormControl>
  )
}

export default MultilineInputField
