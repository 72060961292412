import { useMutation, useQuery } from 'react-query'
import api from './../../AxiosInstance/index'
import { baseUrl } from 'app/Utils/Config'
import { SnackbarContext } from '../SnackbarProvider'
import { useContext } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useQueryClient } from 'react-query'

//? Custom hook to get list of all projects

export function useTeams({ _id, query = ``, getTeams = false }) {
  const openSnackbar = useContext(SnackbarContext)
  const history = useHistory()
  const queryClient = useQueryClient()

  //* Query to fetch teams
  const fetchTeams = async () => {
    const { data } = await api.get(`${baseUrl}/teams?search=${query}`)
    return data?.data
  }

  const { data: teams, isLoading: isLoadingTeams } = useQuery(
    [`getAllTeams`, query],
    fetchTeams,
    {
      enabled: getTeams,
      onError: (error) => {
        openSnackbar(error?.response?.data?.message, `error`)
      },
      onSuccess: () => {
        queryClient.invalidateQueries(`getSingleTeam`)
      },
    },
  )

  const getTeamName = (id) => {
    let team = teams?.filter((team) => team._id === id)
    return team?.at(0)?.name
  }

  //* Delete Team

  const deleteTeam = async (callback) => {
    try {
      const response = await api.delete(`${baseUrl}/teams/${_id}`)
      if (response.data) {
        openSnackbar(`Team Deleted Successfully`, `success`)
        history.push(`/teams`)
        queryClient.invalidateQueries(`getAllTeams`)
        if (callback) {
          callback() // Call the callback function passed from the component
        }
      }
    } catch (error) {
      openSnackbar(
        error?.response ? error?.response?.data?.message : error?.message,
        `error`,
      )
    }
  }

  const { mutate: handleDeleteTeam, isLoading: isDeletingTeam } = useMutation(
    deleteTeam,
    {
      enabled: !!_id,
      onSuccess: () => {
        queryClient.invalidateQueries(`getAllTeams`)
      },
      onError: (error) => {
        openSnackbar(
          error?.response ? error?.response?.data?.message : error?.message,
          `error`,
        )
      },
    },
  )

  return {
    isLoadingTeams,
    teams,
    getTeamName,
    deleteTeam: handleDeleteTeam,
    isDeletingTeam,
  }
}
