import { Box, TableCell, Typography } from '@material-ui/core'
import { getBgColor, getTextColor } from 'app/Utils/MenuColors'
import React from 'react'

export default function ApplicantBgTableCell({ status }) {
  return (
    <TableCell
      align="left"
      sx={{
        padding: `8px`, // Adjust padding as necessary
        // textAlign: `center`, // Center text inside the TableCell
        maxWidth: `150px`,
      }}
    >
      <Box
        sx={{
          bgcolor: getBgColor(status),
          borderRadius: `20px`,
          padding: `5px 12px`, // Padding around the text
          display: `inline-flex`, // Inline flex to ensure the box size fits the content
          // justifyContent: `center`,
          // alignItems: `center`,
          maxWidth: `200px`,
        }}
      >
        <Typography
          fontWeight={500}
          fontSize={16}
          sx={{
            color: getTextColor(status),
            fontSize: `14px`,
          }}
        >
          {status}
        </Typography>
      </Box>
    </TableCell>
  )
}
