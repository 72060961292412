import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentAccount: ``,
  accountsList: ``,
}

const accountSlice = createSlice({
  name: `account`,
  initialState,
  reducers: {
    setCurrentAccount: (state, { payload }) => {
      state.currentAccount = payload
    },
    setAccountList: (state, { payload }) => {
      state.accountsList = payload
    },
  },
})

export const { setCurrentAccount, setAccountList } = accountSlice.actions
export const account = accountSlice.reducer
