import React from 'react'
import { Skeleton, Box } from '@mui/material'
import { motion } from 'framer-motion'
const SkeletonLoading = (
  <motion.div
    initial={{ opacity: 0, y: 80 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -20 }}
    transition={{ duration: 0.5, delay: 0.1 }}
  >
    <Box
      sx={{
        display: `flex`,
        flexDirection: `column`,
        gap: `10px`,
      }}
    >
      <Box
        sx={{
          display: `flex`,
          gap: `10px`,
          alignItems: `center`,
          marginTop: `10px`,
        }}
      >
        <Skeleton
          variant="circular"
          width={50}
          height={50}
          borderRadius={10}
          animation="wave"
        />

        <Skeleton variant="text" width="70%" height={20} animation="wave" />
      </Box>

      <Box
        sx={{
          display: `flex`,
          flexDirection: `column`,
          gap: `10px`,
        }}
      >
        <Box
          sx={{
            display: `flex`,
            flexDirection: `row`,
            justifyContent: `space-between`,
          }}
        >
          <Skeleton variant="text" width="30%" height={20} animation="wave" />

          <Skeleton variant="text" width="30%" height={20} animation="wave" />
        </Box>

        <Box
          sx={{
            display: `flex`,
            flexDirection: `row`,
            justifyContent: `space-between`,
          }}
        >
          <Skeleton variant="text" width="30%" height={20} animation="wave" />

          <Skeleton variant="text" width="30%" height={20} animation="wave" />
        </Box>

        <Box
          sx={{
            display: `flex`,
            flexDirection: `row`,
            justifyContent: `space-between`,
          }}
        >
          <Skeleton variant="text" width="40%" height={20} animation="wave" />

          <Skeleton variant="text" width="20%" height={20} animation="wave" />
        </Box>

        <Box
          sx={{
            display: `flex`,
            flexDirection: `row`,
            justifyContent: `space-between`,
          }}
        >
          <Skeleton variant="text" width="50%" height={20} animation="wave" />

          <Skeleton variant="text" width="10%" height={20} animation="wave" />
        </Box>

        <Box
          sx={{
            display: `flex`,
            flexDirection: `row`,
            justifyContent: `space-between`,
          }}
        >
          <Skeleton variant="text" width="30%" height={20} animation="wave" />

          <Skeleton variant="text" width="30%" height={20} animation="wave" />
        </Box>

        <Box
          sx={{
            display: `flex`,
            flexDirection: `row`,
            justifyContent: `space-between`,
          }}
        >
          <Skeleton variant="text" width="30%" height={20} animation="wave" />

          <Skeleton variant="text" width="30%" height={20} animation="wave" />
        </Box>

        <Box
          sx={{
            display: `flex`,
            flexDirection: `row`,
            justifyContent: `space-between`,
          }}
        >
          <Skeleton variant="text" width="40%" height={20} animation="wave" />

          <Skeleton variant="text" width="20%" height={20} animation="wave" />
        </Box>

        <Box
          sx={{
            display: `flex`,
            flexDirection: `row`,
            justifyContent: `space-between`,
          }}
        >
          <Skeleton variant="text" width="50%" height={20} animation="wave" />

          <Skeleton variant="text" width="10%" height={20} animation="wave" />
        </Box>
      </Box>
    </Box>
  </motion.div>
)

export default SkeletonLoading
