import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import { Box, Tab } from '@mui/material'
import React, { useState } from 'react'
import { styled } from '@mui/styles'
import BusinessDeveloperInvite from './BusinessDeveloperInvite'
import AllApplicants from './AllApplicants'

const TabLabel = styled(`span`)(({ selected }) => ({
  color: selected ? `#6039BB` : ``,
  textTransform: `none`,
  fontWeight: 600,
  fontSize: `18px`,
}))

const BusinessDeveloper = () => {
  const [value, setValue] = useState(`1`)

  const handleTabChange = (event, val) => {
    setValue(val)
  }

  return (
    <Box
      sx={{
        width: `100%`,
        minHeight: `calc(100vh - 80px)`,
        paddingLeft: { xs: `10px`, sm: `20px` },
        paddingRight: { xs: `10px`, sm: `20px` },
        paddingBottom: { xs: `10px`, sm: `20px` },
        backgroundColor: `rgba(40, 32, 61, 0.07)`,
      }}
    >
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: `divider` }}>
          <TabList
            variant="scrollable"
            allowScrollButtonsMobile
            onChange={handleTabChange}
            aria-label="lab API tabs example"
            TabIndicatorProps={{ style: { backgroundColor: `#19C9D1` } }}
          >
            <Tab
              label={<TabLabel selected={value === `1`}>Applicants</TabLabel>}
              value="1"
            />

            <Tab
              label={<TabLabel selected={value === `2`}>Invite</TabLabel>}
              value="2"
            />
          </TabList>
        </Box>

        <TabPanel value="1" sx={{ p: 0 }}>
          <AllApplicants />
        </TabPanel>

        <TabPanel value="2" sx={{ p: 0 }}>
          <BusinessDeveloperInvite />
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default BusinessDeveloper
